<div class="card">
  <div class="card-body">
    <div class="form-group">
      <div class="row">
        <div class="col-3">
          <h4 class="header">Change Date:</h4>
        </div>
        <div class="col-2">
          <input type="date" class="form-control" id="previousRacesStartDate" [(ngModel)]="inputDate" (change)="setDate(inputDate)">
        </div>
      </div>
    </div>
    <div class="form-group">
      <div class="row">
        <div class="col-3">
          <h4 class="header">Filter by Location:</h4>
        </div>
        <div class="col-4 header">
          <ng-multiselect-dropdown
            [placeholder]="'Filter Location'"
            [settings]="dropdownSettings"
            [data]="dropdownList"
            [(ngModel)]="selectedItems"
            (onSelect)="onItemSelect($event)" 
            (onDeSelect)="OnItemDeSelect($event)"
            (onSelectAll)="onSelectAll($event)"
            (onDeSelectAll)="onDeSelectAll($event)">
          </ng-multiselect-dropdown>
        </div>
      </div>
    </div>
    <div class="form-group">
      <div class="row">
        <div class="col-4">
          <h3 for="previousRacesStartDate">Races for {{currentDate}}</h3>
        </div>
      </div>
    </div>
    <p class="header">Page refreshed at: {{currentTimeStamp}}</p>
    <table class="table table-bordered table-striped" id="race-details-table">
      <thead class="thead-dark">
        <tr>
          <th style="text-align: center; font-weight: bold;">Race ID</th>
          <th style="text-align: center; font-weight: bold;">Location</th>
          <th style="text-align: center; font-weight: bold;width:60px">Race #</th>
          <th style="text-align: center; font-weight: bold;">Race Time</th>
          <th style="text-align: center; font-weight: bold;">Operator</th>
          <th style="text-align: center; font-weight: bold;">Race Name</th>
          <th style="text-align: center; font-weight: bold; width:300px">Race Report Status</th>
          <th style="text-align: center; font-weight: bold; width:150px">Race State / MQTT PC</th>
          <th style="text-align: center; font-weight: bold; width:150px">MQTT live data</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let race of report">
          <tr>
            <td class="emphasis" style="text-align: center;">{{race.ExternalRaceID}}</td>
            <td class="emphasis" style="text-align: center;">{{race.VenueName}}</td>
            <td class="emphasis" style="text-align: center;">{{race.RaceNumber}}</td>
            <td class="emphasis" style="text-align: center;">{{race.ScheduledTime}}</td>
            <td style="text-align: center;">{{race.Operator}}</td>
            <td style="text-align: center;">{{race.RaceName}}</td>
            <td style="text-align: center;">
              <table class="w-100">
                <tbody>
                  <tr>
                    <ng-container *ngFor="let raceState of race.RaceStates">
                      <td [ngStyle]="getRaceStateStyle(getFirstKey(raceState))">{{getFirstKey(raceState).toUpperCase()}}:{{raceState[getFirstKey(raceState)]}}</td>
                    </ng-container>
                  </tr>
                </tbody>
              </table>
            </td>
            <td class="align-items-center">
              <span [ngClass]="mqtt_heartbeat[race.ExternalRaceID].state"></span>
              &nbsp;{{ mqtt_heartbeat[race.ExternalRaceID]?.pc_number }}
            </td>
            <td>
              <span [ngClass]="{
                'red-circle': advance_live_data[race.ExternalRaceID].state === 'red',
                'green-circle': advance_live_data[race.ExternalRaceID].state === 'green',
                'blue-circle': advance_live_data[race.ExternalRaceID].state === 'blue'
              }"></span>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
    <p>Page refreshed at: {{currentTimeStamp}}</p>
  </div>
</div>
