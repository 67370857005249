import { Injectable } from '@angular/core';
import mqtt, { IClientOptions, IClientPublishOptions, IClientSubscribeOptions, MqttClient } from 'mqtt';

@Injectable({
  providedIn: 'root'
})
export class MqttService {
  client: MqttClient;
  private IOT_ENDPOINT = 'wss://azb0fvayw0v0f-ats.iot.ap-southeast-2.amazonaws.com';
  private CUSTOM_AUTHORIZER_NAME = 'roc-mqtt-authorizer';

  constructor() {
    const CLIENT_ID = `client-${Math.random().toString(36).substring(2, 15)}`;
    
    const options: IClientOptions = {
      clientId: CLIENT_ID,
      reconnectPeriod: 1000, // Reconnect every 1 second if disconnected
      keepalive: 60 // Send keepalive packets every 60 seconds
    };

    this.client = mqtt.connect(`${this.IOT_ENDPOINT}/mqtt?x-amz-customauthorizer-name=${this.CUSTOM_AUTHORIZER_NAME}`, options);

    this.client.on('connect', () => this.onConnect());
    this.client.on('message', (topic: string, payload: Buffer) => this.onMessage(topic, payload));
    this.client.on('error', (err: Error) => this.onError(err));
    this.client.on('close', () => this.onClose());
    this.client.on('reconnect', () => this.onReconnect());
    this.client.on('offline', () => this.onOffline());
  }

  private onConnect() {
    console.log('Connected to AWS IoT');
  }

  subscribe(topic: string, options?: IClientSubscribeOptions) {
    this.client.subscribe(topic, options || {}, (err: Error | null, granted: any) => {
      if (err) {
        console.log('Subscription error:', err);
      } else {
        console.log('Subscribed to topic:', topic);
      }
    });
  }

  publish(topic: string, message: any, options?: IClientPublishOptions) {
    this.client.publish(topic, JSON.stringify(message), options || {}, (err?: Error) => {
      if (err) {
        console.log('Publish error:', err);
      } else {
        console.log('Message sent to topic:', topic);
      }
    });
  }

  private onMessage(topic: string, payload: Buffer) {
    // console.log('Message received on topic', topic, ':', payload.toString());
  }

  private onError(err: Error) {
    console.log('Error:', err);
  }

  private onClose() {
    console.log('Connection closed');
  }

  private onReconnect() {
    console.log('Reconnecting...');
  }

  private onOffline() {
    console.log('Went offline');
  }
  disconnect() {
    if (this.client.connected) {
      this.client.end(false, {}, () => {
        console.log('Disconnected from AWS IoT');
      });
    }
  }
  unsubscribe(topic: string) {
    this.client.unsubscribe(topic);
  }
  parseHeartbeatMessage(message: any): any {
    let to_return_value = null;
    Object.keys(message).forEach(pcNumber => {
      if (pcNumber !== 'Timestamp' && pcNumber.includes('PC')) { 
        const raceId = message[pcNumber].ExternalRaceId;
        const state = message[pcNumber].State;
        const eventId = message[pcNumber].ExternalEventId;

        let color_state = 'red-circle';
        if (state === 1) {
          color_state = 'blue-circle';
        } else if (state === 2) {
          color_state = 'yellow-circle';
        } else if (state === 3) {
          color_state = 'orange-circle';
        } else if (state === 4) {
          color_state = 'green-circle';
        } else if (state === 5) {
          color_state = 'checkered-flag';
        } else {
          color_state = 'white-circle';
        }

        to_return_value = {
          race_id:raceId,
          state:color_state,
          event_id:eventId,
          pc_number:pcNumber
        }
        
      }
    });
    return to_return_value
  }


  parseLiveDataMessage(topic: string): string {
    const parts = topic.split('/');
    const raceIdIndex = parts.indexOf('AdvanceLiveData') - 1;
    return raceIdIndex >= 0 && raceIdIndex < parts.length ? parts[raceIdIndex] : '';
  }
}
