import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';

export let loaded: boolean = false;

@Injectable({ providedIn: 'root' })
export class ConfigService {
  private configKey = 'app_config';
  private config: any;

  constructor(private http: HttpClient) {}

  loadConfig(): Observable<any> {
    if (!loaded) {
      return this.fetchConfigFromApi();
    } else {
      return this.getConfigFromLocalStorage();
    }
  }

  private fetchConfigFromApi(): Observable<any> {
    const headers = new HttpHeaders({
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      Pragma: 'no-cache',
      Expires: '0',
    });

    const timestamp = new Date().getTime();
    return this.http
      .get(`./../../config.json?t=${timestamp}`, { headers })
      .pipe(
        tap((config) => {
          loaded = true;
          this.saveConfigToLocalStorage(config);
          this.config = config;
        }),
        catchError((error) => {
          console.error('Error fetching config:', error);
          return this.getConfigFromLocalStorage();
        })
      );
  }

  private saveConfigToLocalStorage(config: any): void {
    localStorage.setItem(this.configKey, JSON.stringify(config));
  }

  private getConfigFromLocalStorage(): Observable<any> {
    if (!this.config) {
      const storedConfig = localStorage.getItem(this.configKey);
      this.config = storedConfig ? JSON.parse(storedConfig) : null;
    }
    return of(this.config);
  }
}
