<div class="card">
  <div class="card-header flex-between">
    <h5>PC Status</h5>
    <div>
      <button class="btn btn-primary-soft" (click)="load()">Refresh</button>
    </div>
  </div>
  <div class="card-body">
    <table class="table table-docs table-hover">
      <thead>
        <tr>
          <th>Sl. No.</th>
          <th>PC Number</th>
          <th>IP Address:Port</th>
          <th>Status</th>
          <th>Operator</th>
          <th>Kit</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let pc of pcs; let i = index">
          <td>{{ i + 1 }}</td>
          <td>{{ pc.name || "-" }}</td>
          <td>{{ pc.ipaddress || "-" }}:{{ pc.port || "-" }}</td>
          <td *ngIf="!pc.found" class="text-success fw-bold">NOT IN USE</td>
          <td *ngIf="pc.found">
            Used by
            <a
              routerLink="/dash/venue-details"
              [queryParams]="{
                event_id: pc.found.ExternalEventID,
                venue_id: pc.found.ExternalVenueID,
                date: pc.found.MeetingDate,
                event_name: pc.found.VenueName
              }"
              class="nav-link"
              target="_blank"
              >{{ pc.found.VenueName }}
              <i
                class="fa fa-external-link color-inherit"
                aria-hidden="true"
              ></i>
            </a>
          </td>
          <td>{{ pc?.found?.AssignedOperatorFullName || "-" }}</td>
          <td>{{ pc?.found?.AssignedKit || "-" }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
