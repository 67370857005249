import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { getENV } from 'src/app/_helpers/helpers';
import { APIService } from 'src/app/services/api.service';
import { NotifierService } from 'src/app/services/notifier.service';


@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css'],
})
export class changePasswordComponent implements OnInit {
  @Input('user') user: any;
  @Output('hideModel') hideModel: any = new EventEmitter<boolean>();
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  role: string | null = localStorage.getItem('role');
  urlPrefix: string | null = null;
  config: any;

  constructor(
    private apiService: APIService,
    private notifier: NotifierService,
    private route: ActivatedRoute
  ) {
    this.config = this.route.snapshot.data['config'];
    if (this.role == 'Admin') {
      this.urlPrefix = 'admin';
    }
    if (this.role == 'Client') {
      this.urlPrefix = 'client';
    }
    if (this.role == 'Superadmin') {
      this.urlPrefix = 'sa';
    }
  }

  ngOnInit(): void { }

  passwordChecker(password: string) {
    let passwordObj: any = {
      strength: 0,
      policies: {
        'Must have atleast 8 characters': password.length > 7,
        'Must have atleast 1 uppercase letter, 1 lowercase letter, and 1 number':
          false,
        'Must have atleast 1 Special Character': false,
        'Longer Password': false,
      },
    };
    if (passwordObj.policies['Must have atleast 8 characters']) {
      passwordObj.strength += 1;
    }
    if (password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{0,}$/gm)) {
      passwordObj.strength += 1;
      passwordObj.policies[
        'Must have atleast 1 uppercase letter, 1 lowercase letter, and 1 number'
      ] = true;
    }
    if (password.length >= 10) {
      passwordObj.strength += 1;
      passwordObj.policies['Longer Password'] = true;
    }
    if (password.match(/[^A-z\s\d][\\\^]?/gm)) {
      passwordObj.strength += 1;
      passwordObj.policies['Must have atleast 1 Special Character'] = true;
    }
    return passwordObj;
  }

  getKeys(obj: any) {
    return Object.keys(obj);
  }

  password: string = '';
  confPassword: string = '';
  showPassword: boolean = false;
  async changePassword() {
    if (this.password == '') {
      this.notifier.alert(
        'Info',
        '',
        'Password can not be empty',
        'info',
        5000
      );
      return;
    }
    if (this.password != this.confPassword) {
      this.notifier.alert(
        'Info',
        '',
        'Password is not matching confirm Password',
        'info',
        5000
      );
      return;
    }
    if (this.passwordChecker(this.password)['strength'] < 3) {
      this.notifier.alert(
        'Info',
        '',
        "Entered Password doesn't meet the minimum password policy",
        'info',
        5000
      );
      return;
    }
    this.notifier.loading(true);
    let data: any = {
      a: 'changeUserPassword',
      email: this.user.email,
      password: this.password,
      userType: 'admin',
    };

    let header: any = {
      Authorization: localStorage.getItem('t'),
    };

    let apiURL: string;
    if (this.urlPrefix == 'admin') {
      apiURL = `${this.config[getENV()].apiURL}/${this.urlPrefix}/Settings/usermanagement`;
    } else {
      apiURL = `${this.config[getENV()].apiURL}/${this.urlPrefix}/Settings/usermanagement`;
    }

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.notifier.alert(
        'Success',
        '',
        'Password changed successfully...',
        'success',
        5000
      );
      this.hideModel.emit(true);
    } else {
      this.notifier.alert('Info', '', result.msg || result.error, 'info', 5000);
    }

    this.notifier.loading(false);
  }
}
