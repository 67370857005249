import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NotifierService } from './notifier.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment-timezone';
import { Acticity } from '../_helpers/activity';
import { DashService } from '../modules/globals/dash/dash.service';

export let tokenExpired: any = { expired: false, checking: false };

declare let window: any;

@Injectable({
  providedIn: 'root',
})
export class APIService {
  constructor(
    private http: HttpClient,
    public notifier: NotifierService,
    public navigate: Router,
    private route: ActivatedRoute,
    private dashService: DashService
  ) {
    let activity = new Acticity(this.dashService);
    activity.setLastActivityTime();
  }

  tokenExpired = tokenExpired;

  public static get API_ENDPOINT(): string {
    return 'https://api.swayam.cloud/prod';
  }
  public static get API_ENDPOINTClient(): string {
    return 'https://api.swayam.cloud/prod/client';
  }
  public static get API_ENDPOINTAdmin(): string {
    return 'https://api.swayam.cloud/prod/msopsusers';
  }
  public static get API_ENDPOINTFiles(): string {
    return 'https://api.swayam.cloud';
  }

  public static get API_ENDPOINTScheduler(): string {
    let urlPrefix: any =
      localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
    return `https://api.swayam.cloud/v3/${urlPrefix}/scheduler`;
  }

  public static get API_ENDPOINTV3(): string {
    return 'https://api.swayam.cloud/v3';
  }

  public static get API_ENDPOINTMongo(): string {
    return 'http://43.204.90.89:14189';
  }

  public static get API_ENDPOINTSchedulerOtp(): string {
    let urlPrefix: any =
      localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
    return `https://api.swayam.cloud/v3/${urlPrefix}/operations/schedulerotp`;
  }

  public static get OTP_Enabled(): boolean {
    return true;
  }

  // try relogin using Refresh token if token exists
  async reLoginWithRT(alerts: boolean = true) {
    if (this.tokenExpired['checking'] || true) {
      return false;
    }

    let token: string | null = localStorage.getItem('rt');
    if (token) {
      if (localStorage.getItem('t') && alerts) {
        this.notifier.alert(
          'Info',
          '',
          'Incoming Token is expired. Trying to login...',
          'info',
          5000
        );
      }
      let body: any = {
        rt: token,
      };
      let apiURL: string = `${APIService.API_ENDPOINTV3}/admin/users/loginv2`;
      let urlPrefix: string =
        localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
      if (urlPrefix == 'client') {
        apiURL = `${APIService.API_ENDPOINTV3}/market/login`;
        body['a'] = 'getAt';
      } else {
        apiURL = `${APIService.API_ENDPOINTV3}/admin/users/loginv2`;
        body['ac'] = 'getAt';
      }
      this.tokenExpired['checking'] = true;

      let result: any = await this.postDataPromis(apiURL, body);

      if (result.s == '1' || result.status == '1') {
        localStorage.setItem('t', result.at);
        if (alerts) {
          this.notifier.alert(
            'Success',
            '',
            'Logged in successfully..',
            'info',
            5000,
            true
          );
        }

        setTimeout(() => {
          window.location.reload();
        }, 1000);
        return true;
      } else {
        localStorage.clear();
        this.tokenExpired['checking'] = false;
        return false;
      }
    } else {
      localStorage.clear();
      this.tokenExpired['checking'] = false;

      return false;
    }
  }

  query(url: string, data: any, header: any = {}): Observable<any> {
    data.newConfigver = 2.5;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    for (var i in header) {
      headers = headers.set(i, header[i]);
    }

    return this.http.post(url, JSON.stringify(data), { headers: headers });
  }

  postDataPromis(url: string, data: any, header: any = {}): Promise<any> {
    let activity = new Acticity(this.dashService);
    activity.setLastActivityTime();
    return new Promise(async (resolve, reject) => {
      if (!header['Authorization'] && localStorage.getItem('t')) {
        header['Authorization'] = localStorage.getItem('t');
      }
      if (this.tokenExpired['expired']) {
        resolve({ status: '0', s: '0' });
        return;
      }

      if (
        this.tokenExpired['checking'] &&
        !(data['a'] === 'getAt' || data['ac'] === 'getAt')
      ) {
        return;
      }

      //if (!data.ver) data.newConfigver = 2.5;
      //if (!data.role) data.newConfigrole = localStorage.getItem("role");

      let headers = new HttpHeaders();
      headers = headers.set('Content-Type', 'application/json; charset=utf-8');
      if (Object.keys(header).indexOf('Authorization') > -1) {
        data['idToken'] = localStorage.getItem('idT') || undefined;
      }

      for (var i in header) {
        headers = headers.set(i, header[i]);
      }

      this.http.post(url, JSON.stringify(data), { headers: headers }).subscribe(
        async (data: any) => {
          if (this.tokenExpired['expired']) {
            resolve({ status: '0', s: '0' });
            return;
          }
          //console.log("data",data);
          if (data['status'] == '10' || data['s'] == '10') {
            let result: any = await this.reLoginWithRT();
            if (!result && !this.tokenExpired['checking']) {
              this.notifier.alert(
                'Error',
                '',
                (data.message || data.msg || 'Unknown Exception') +
                  '. Logging out...',
                'error',
                5000
              );
              this.tokenExpired['expired'] = true;
              if (!window.location.pathname.includes('login')) {
                setTimeout(() => {
                  let redirect: string =
                    window.location.pathname + window.location.search;
                  window.location.href = `/login?redirectURL=${redirect}`;
                }, 3000);
              }
              this.notifier.loading(false);
              return;
            }
          } else {
            this.tokenExpired['expired'] = false;
          }
          resolve(data);
        },
        async (error) => {
          if (this.tokenExpired['expired']) {
            resolve({ status: '0', s: '0' });
          }
          let errMsg: string;
          if (error.status == 0 || error.status == '0') {
            this.notifier.alert(
              'Error',
              '',
              (error?.error?.message || 'Unknown Exception') +
                '. Logging out...',
              'error',
              5000
            );
            this.tokenExpired['expired'] = true;
            if (!window.location.pathname.includes('login')) {
              setTimeout(() => {
                let redirect: string =
                  window.location.pathname + window.location.search;
                window.location.href = `/auth/login?redirectURL=${redirect}`;
              }, 3000);
            }
          } else if (error.status == 401 || error.status == '401') {
            let result: any = await this.reLoginWithRT();
            if (!result && !this.tokenExpired['checking']) {
              this.notifier.alert(
                'Error',
                '',
                (error?.error?.message || 'Unknown Exception') +
                  '. Logging out...',
                'error',
                5000
              );
              this.tokenExpired['expired'] = true;
              if (!window.location.pathname.includes('login')) {
                setTimeout(() => {
                  let redirect: string =
                    window.location.pathname + window.location.search;
                  window.location.href = `/auth/login?redirectURL=${redirect}`;
                }, 3000);
              }
              this.notifier.loading(false);
            }
          } else if (error.status == 0 || error.status == '0') {
            this.notifier.alert(
              'Error',
              '',
              'Unknown Exception. Please contact admin',
              'error',
              5000
            );
          }

          if (error instanceof Response) {
            let dt: any = {};
            const body: any = error.json() || dt;
            if (body.error_message) {
              body.error = body.error_message;
            }

            resolve(body);

            //const err = JSON.stringify(body); // body.error ||
            //errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
          } else {
            if (error.error.error_message) {
              error.error.error = error.error.error_message;
            }
          }
          resolve(error.error);
        }
      );
      // console.log("Reached End of the code"`);

      // resolve({ status: 0 });
    });
  }

  getDataPromis(url: string, data: any, header: any = {}): Promise<any> {
    let activity = new Acticity(this.dashService);
    activity.setLastActivityTime();
    return new Promise((resolve, reject) => {
      if (!header['Authorization'] && localStorage.getItem('t')) {
        header['Authorization'] = localStorage.getItem('t');
      }
      if (
        this.tokenExpired['checking'] &&
        !(data['a'] === 'getAt' || data['ac'] === 'getAt')
      ) {
        return;
      }
      //if (!data.ver) data.newConfigver = 2.5;
      //if (!data.role) data.newConfigrole = localStorage.getItem("role");

      let headers = new HttpHeaders();
      // headers = headers.set("Content-Type", "application/json; charset=utf-8");

      for (var i in header) {
        headers = headers.set(i, header[i]);
      }

      this.http.get(url, { headers: headers }).subscribe(
        (data) => {
          //console.log("data",data);
          //this.getDataPromisLog(url, data, header);
          return resolve(data);
        },
        (error) => {
          resolve(error.error || error.message);
          // let errMsg: string;
          // if (error instanceof Response) {
          //   const body = error.json() || "";
          //   const err = JSON.stringify(body); // body.error ||
          //   errMsg = `${error.status} - ${error.statusText || ""} ${err}`;
          // } else {
          //   errMsg = error.message ? error.message : error.toString();
          // }
          // return resolve(errMsg);
        }
      );
    });
  }

  getDataPromisLog(url: string, data: any, header: any = {}) {
    return new Promise((resolve, reject) => {
      //if (!data.ver) data.newConfigver = 2.5;
      //if (!data.role) data.newConfigrole = localStorage.getItem("role");

      let headers = new HttpHeaders();
      headers = headers.set('Content-Type', 'application/json; charset=utf-8');

      for (var i in header) {
        headers = headers.set(i, header[i]);
      }

      this.http.get('', { headers: headers }).subscribe(
        (data) => {
          return resolve(true);
        },
        (error) => {
          let errMsg: string;
          if (error instanceof Response) {
            const body = error.json() || '';
            const err = JSON.stringify(body); // body.error ||
            errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
          } else {
            errMsg = error.message ? error.message : error.toString();
          }
          return resolve(errMsg);
        }
      );
    });
  }

  postDataPromisNew(url: string, data: any, header: any = {}): Promise<any> {
    return new Promise(async (resolve, reject) => {
      //if (!data.ver) data.newConfigver = 2.5;
      //if (!data.role) data.newConfigrole = localStorage.getItem("role");

      let headers = new HttpHeaders();
      headers = headers.set('Content-Type', 'application/json; charset=utf-8');

      for (var i in header) {
        headers = headers.set(i, header[i]);
      }

      if (!header['Authorization']) {
        let result: any = await this.reLoginWithRT(false);
        if (result) {
          resolve({ s: '1', status: '1' });
        } else {
          resolve({ s: '1', status: '1' });
        }
      } else {
        this.http
          .post(url, JSON.stringify(data), { headers: headers })
          .subscribe(
            (data) => {
              //console.log("data",data);
              return resolve(data);
            },
            async (error) => {
              if (!error) return resolve({});
              let errMsg: string;

              if (error.status === 401 || error.status === '401') {
                let result: any = await this.reLoginWithRT(false);
                if (result) {
                  resolve({ status: 1, s: 1 });
                  return;
                }
              }

              if (error instanceof Response) {
                let dt: any = {};
                let body: any = error.json() || dt;
                if (body && body.error_message) {
                  body.error = body.error_message;
                }
                body.errorStatus = error.status;
                return resolve(body);

                //const err = JSON.stringify(body); // body.error ||
                //errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
              } else {
                if (error && error.error && error.error.error_message) {
                  error.error.error = error.error.error_message;
                }
              }
              return resolve({ errorStatus: error.status, error: error.error });
            }
          );
      }
    });
  }
}
