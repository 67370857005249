<div class="card">
  <div class="card-header">
    <h5>Race Management</h5>
  </div>
  <div class="card-body">
    <form>
      <div class="form-group">
        <label>Select your Location</label>
        <div *ngIf="locations.length > 0">
          <post-race-location-picker
            [locations]="locations"
            [selected]="selected"
            (selectedLocation)="selectLocation($event)"
            type="event"
          ></post-race-location-picker>
        </div>
        <div *ngIf="locations.length == 0">No events found</div>
      </div>
      <div class="form-group" *ngIf="selected">
        <label>Select your Race</label>
        <div>
          <post-race-location-picker
            [locations]="races"
            [selected]="selectedRace"
            (selectedLocation)="selectRace($event)"
            type="race"
          ></post-race-location-picker>
        </div>
      </div>
      <div class="divider_horizontal" *ngIf="selected && selectedRace"></div>

      <div class="form-group mt-2" *ngIf="selectedRace">
        <div class="card-view grid-4">
          <div>
            <div class="fw-bold fs-5">{{ selectedRace.RaceName }}</div>
            <div>Race Name</div>
          </div>
          <div>
            <div class="fw-bold fs-5">{{ selected.venue_name }}</div>
            <div>Location</div>
          </div>
          <div>
            <div class="fw-bold fs-5">{{ date }}</div>
            <div>Date</div>
          </div>
          <div>
            <div class="fw-bold fs-5">{{ selectedRace.RaceTime }}</div>
            <div>Time</div>
          </div>
        </div>
      </div>
      <div class="form-group mt-2" *ngIf="selected && selectedRace">
        <ng-container *ngFor="let button of tasks">
          <div class="card-view w-100">
            <div class="d-flex align-items-center">
              <div class="flex-grow-1">
                <h6 class="mb-0 no-wrap fw-bold">{{ button.label }}</h6>
              </div>
              <div class="d-flex align-items-center">
                <ng-container *ngIf="!button.loading">
                  <p class="text-danger fw-bold" *ngIf="button.error">
                    <i class="fa fa-exclamation-circle text-danger"></i>
                    {{ button.error }}
                  </p>

                  <p class="text-success fw-bold" *ngIf="button.completed">
                    <i class="fa fa-check-circle text-success"></i>
                    Completed
                  </p>
                </ng-container>
                <div
                  class="spinner-border spinner-border-sm text-secondary mr-2"
                  *ngIf="button.loading"
                ></div>
                <button
                  class="btn btn-success-soft"
                  (click)="reviewPDF(button)"
                  *ngIf="button.preview && button.completed && !button.loading"
                >
                  Review / Download
                </button>
                <button
                  class="btn btn-primary-soft"
                  (click)="onclick(true, button)"
                  *ngIf="!button.loading"
                >
                  Trigger
                </button>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </form>
  </div>
</div>

<!--<race-reports-pc-selector *ngIf="selectPC" [venue]="selected" (hideModel)="hideSelectPC($event)"></race-reports-pc-selector>-->
