<div class="screen">
  <div class="screen-container">
    <div class="screen-header">
      <div class="header-big">Staging Management</div>
      <div>
        <i class="fa fa-times text-white p-1 cursor-pointer" (click)="closePopup()"></i>
      </div>
    </div>
    <div class="screen-body">
      <!-- Copy Tasks -->
      <div class="task" *ngFor="let task of tasks">
        <div>Copy {{ task.name }}</div>
        <button class="btn btn-primary-soft" (click)="confirmTaskExecution(task)" [disabled]="task.loading">
          <span class="color-inherit cursor-pointer">
            Copy Data
            <span *ngIf="task.loading" class="spinner-border spinner-border-sm ml-2"></span>
          </span>
        </button>
      </div>

      <!-- Single Delete Task -->
      <div class="task">
        <div>Delete Data</div>
        <button class="btn btn-danger-soft" (click)="confirmDeletion()" [disabled]="loading">
          <span class="color-inherit cursor-pointer">
            Delete Data
            <span *ngIf="loading" class="spinner-border spinner-border-sm ml-2"></span>
          </span>
        </button>
      </div>
    </div>
  </div>

  <!-- Copy Confirmation Popup -->
  <div class="confirmation-popup" *ngIf="taskToConfirm">
    <div class="confirmation-container">
      <div class="confirmation-header screen-header">
        <div class="header-big">Confirm Task Execution</div>
        <div>
          <i class="fa fa-times text-white p-1 cursor-pointer" (click)="cancelConfirmation()"></i>
        </div>
      </div>
      <div class="confirmation-body">
        <p>Are you sure you want to copy {{ taskToConfirm.name }}?</p>
        <div class="dropdown-container">
          <select class="form-select" [(ngModel)]="destination">
            <option value="" disabled>Select Destination</option>
            <option value="dev">Dev</option>
            <option value="staging">Staging</option>
          </select>
        </div>
        <div class="flex-center">
          <button class="btn btn-primary-soft" [disabled]="!destination" (click)="executeTask(taskToConfirm)">Yes</button>
          <button class="btn btn-light" (click)="cancelConfirmation()">No</button>
        </div>
      </div>
    </div>
  </div>

  <!-- Delete Confirmation Popup -->
  <div class="confirmation-popup" *ngIf="showDeleteConfirmation">
    <div class="confirmation-container">
      <div class="confirmation-header screen-header">
        <div class="header-big">Confirm Data Deletion</div>
        <div>
          <i class="fa fa-times text-white p-1 cursor-pointer" (click)="cancelDeletion()"></i>
        </div>
      </div>
      <div class="confirmation-body">
        <p>Are you sure you want to delete data?</p>
        <div class="dropdown-container">
          <select class="form-select" [(ngModel)]="destination">
            <option value="" disabled>Select Destination</option>
            <option value="dev">Dev</option>
            <option value="staging">Staging</option>
          </select>
        </div>
        <div class="date-picker">
          <label>Select Date Range:</label>
          <input type="text" placeholder="Select Date Range" [(ngModel)]="selectedDateRange" #dateRangePicker />
        </div>
        <div class="flex-center">
          <button class="btn btn-danger-soft" [disabled]="!destination || !selectedDateRange" (click)="executeDeleteTask()">Yes</button>
          <button class="btn btn-light" (click)="cancelDeletion()">No</button>
        </div>
      </div>
    </div>
  </div>
</div>
