<div class="card">
  <div class="card-header flex-between">
    <h5>Venue Details</h5>
    <div class="flex-start">
      <select class="form-select" [(ngModel)]="filterStatus" (change)="applyFilter()">
        <option value="all">All</option>
        <option value="enabled">Enabled</option>
        <option value="disabled">Disabled</option>
      </select>
      <button class="btn btn-primary-soft flex-start" (click)="loadVenues()">
        <i class="fa fa-refresh color-inherit" aria-hidden="true"></i>
        Refresh
      </button>
      <button
        class="btn btn-success-soft flex-start"
        (click)="openAddVenueModal()"
      >
        <i class="fa fa-plus color-inherit" aria-hidden="true"></i>
        <span class="color-inherit word-wrap-none">
          Add Venue
        </span>
      </button>
    </div>
  </div>
  <div class="card-body">
    <nav>
      <div class="nav nav-tabs" id="nav-tab" role="tablist">
        <button
          class="nav-link tab"
          [class.active]="this.venueData.type == type"
          id="{{ type }}-tab"
          data-bs-toggle="tab"
          [attr.data-bs-target]="'#' + type"
          type="button"
          role="tab"
          [attr.aria-controls]="type"
          aria-selected="true"
          *ngFor="let type of types"
          (click)="selectRaceType(type)"
        >
          {{ type }}
        </button>
      </div>
    </nav>
    <div class="tab-content" id="nav-tabContent">
      <div
        class="tab-pane show"
        [class.active]="this.venueData.type == type"
        id="{{ type }}"
        role="tabpanel"
        [attr.aria-labelledby]="type + '-tab'"
        *ngFor="let type of types"
      >
        <table class="table table-docs table-hover">
          <thead>
            <tr>
              <th>Venue Name</th>
              <th>External Venue ID</th>
              <th>Status</th>
              <th>Club Code</th>
              <th>Venue Abbreviation</th>
              <th>Venue Country</th>
              <th>Venue State</th>
              <th>Jurisdiction</th>
              <th>Default Kit</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let venue of venues" [class.dimmed]="!venue.Enabled">
              <td>
                <span class="nav-link" (click)="openEditVenueModal(venue)">
                  {{ venue.VenueName || "-" }}
                </span>
              </td>
              <td>{{ venue.ExternalVenueID || "-" }}</td>
              <td>{{ venue.Enabled ? "Enabled" : "Disabled" }}</td>
              <td>{{ venue.ClubCode || "-" }}</td>
              <td>{{ venue.VenueAbbr || "-" }}</td>
              <td>{{ venue.VenueCountry || "-" }}</td>
              <td>{{ venue.VenueState || "-" }}</td>
              <td>{{ venue.Jurisdiction || "-" }}</td>
              <td>{{ venue.DefaultKit || "-" }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<div class="popup-screen" *ngIf="showModal">
  <div class="popup-container">
    <div class="popup-header">
      {{ isEdit ? "Edit Venue" : "Add Venue" }}
      <button type="button" class="close" (click)="showModal = false">
        &times;
      </button>
    </div>
    <div class="popup-body max-overflow">
      <form (ngSubmit)="onSubmit(isEdit ? 'updateVenue' : 'createVenue')">
        <div class="form-section">
          <div class="form-section-header">Venue Details</div>
          <div class="form-section-body grid-container">
            <div class="form-group">
              <label for="raceType">Race Type</label>
              <select
                id="raceType"
                [(ngModel)]="venueData.type"
                name="raceType"
                class="form-select"
                (change)="selectRaceType(venueData.type)"
                required
              >
                <option *ngFor="let type of types" [value]="type">
                  {{ type }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label for="jurisdiction">Jurisdiction</label>
              <select
                id="jurisdiction"
                [(ngModel)]="venueData.jurisdiction"
                name="jurisdiction"
                class="form-select"
                required
              >
                <option
                  *ngFor="let jurisdiction of jurisdictions"
                  [value]="jurisdiction"
                >
                  {{ jurisdiction }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label for="external_venue_id">External Venue ID</label>
              <input
                type="text"
                id="external_venue_id"
                [(ngModel)]="venueData.external_venue_id"
                name="external_venue_id"
                class="form-control"
                required
              />
            </div>
            <div class="form-group">
              <label for="venueName">Venue Name</label>
              <input
                type="text"
                id="venueName"
                [(ngModel)]="venueData.venueName"
                name="venueName"
                class="form-control"
                required
              />
            </div>
            <div class="form-group">
              <label for="kit">Kit (Default Kit)</label>
              <div class="flex-start">
                <ng-container
                  *ngIf="venueData.kit_name == 'new'; else showKitDropdown"
                >
                  <input
                    type="text"
                    id="newKitName"
                    [(ngModel)]="newKitName"
                    name="newKitName"
                    class="form-control"
                    placeholder="Enter new kit name"
                    required
                  />
                  <span class="nav-link">
                    <i
                      class="fa fa-times-circle text-danger"
                      (click)="cancelAddKit()"
                    ></i>
                  </span>
                </ng-container>
                <ng-template #showKitDropdown>
                  <select
                    id="kit"
                    [(ngModel)]="venueData.kit_name"
                    name="kit"
                    class="form-select"
                    (change)="onKitChange()"
                    required
                  >
                    <option *ngFor="let kit of kits" [value]="kit">
                      {{ kit }}
                    </option>
                  </select>
                  <span
                    class="nav-link flex-start text-nowrap"
                    (click)="addKit()"
                  >
                    <i class="fa fa-plus-circle color-inherit"></i>
                    Add New Kit
                  </span>
                </ng-template>
              </div>
            </div>
            <div class="form-group">
              <label for="connection_string">Connection String</label>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control width-70"
                  value="Server=.\SQLEXPRESS;DataBase=HRTraCe_"
                  readonly
                />
                <input
                  type="text"
                  id="connection_string"
                  [(ngModel)]="venueData.connection_string"
                  name="connection_string"
                  class="form-control width-30"
                  required
                />
              </div>
              <div class="connection-string-display">
                {{ getFullConnectionString() }}
              </div>
            </div>
            <div class="form-group">
              <label for="venue_status">Venue Status</label>
              <select
                id="venue_status"
                [(ngModel)]="venueData.venue_status"
                name="venue_status"
                class="form-select"
                required
              >
                <option [value]="true">Enabled</option>
                <option [value]="false">Disabled</option>
              </select>
            </div>
            <div class="form-group">
              <label for="club_code">Club Code</label>
              <input
                type="text"
                id="club_code"
                [(ngModel)]="venueData.club_code"
                name="club_code"
                class="form-control"
                required
              />
            </div>
            <div class="form-group">
              <label for="venue_abbr">Venue Abbreviation</label>
              <input
                type="text"
                id="venue_abbr"
                [(ngModel)]="venueData.venue_abbr"
                name="venue_abbr"
                class="form-control"
                required
              />
            </div>
            <div class="form-group">
              <label for="venue_country">Venue Country</label>
              <input
                type="text"
                id="venue_country"
                [(ngModel)]="venueData.venue_country"
                name="venue_country"
                class="form-control"
                required
              />
            </div>
            <div class="form-group">
              <label for="venue_state">Venue State</label>
              <input
                type="text"
                id="venue_state"
                [(ngModel)]="venueData.venue_state"
                name="venue_state"
                class="form-control"
                required
              />
            </div>
          </div>
        </div>
        <div class="form-section">
          <div class="form-section-header">LTS Connectors</div>
          <div class="form-section-body d-block">
            <div
              *ngFor="let connector of venueData.ltsConnectors; let i = index"
              class="connection-row flex-start"
            >
              <div class="form-group">
                <label for="ltsConnectionName">Connection Name</label>
                <input
                  type="text"
                  id="ltsConnectionName"
                  [(ngModel)]="connector.connectionName"
                  name="ltsConnectionName{{ i }}"
                  class="form-control"
                  required
                />
              </div>
              <div class="form-group">
                <label for="ltsConnectionType">Connection Type</label>
                <input
                  type="text"
                  id="ltsConnectionType"
                  [(ngModel)]="connector.connectionType"
                  name="ltsConnectionType{{ i }}"
                  class="form-control"
                  required
                />
              </div>
              <div class="form-group">
                <label for="ltsIpAddress">IP Address</label>
                <input
                  type="text"
                  id="ltsIpAddress"
                  [(ngModel)]="connector.ipAddress"
                  name="ltsIpAddress{{ i }}"
                  class="form-control"
                  required
                />
              </div>
              <div class="form-group">
                <label for="ltsPort">Port</label>
                <input
                  type="number"
                  id="ltsPort"
                  [(ngModel)]="connector.port"
                  name="ltsPort{{ i }}"
                  class="form-control"
                  required
                />
              </div>
              <div class="form-group">
                <button
                  type="button"
                  class="btn btn-danger-soft"
                  (click)="removeLTSConnector(i)"
                >
                  Remove Connector
                </button>
              </div>
            </div>
            <div class="form-group full-width">
              <button
                type="button"
                class="btn btn-primary-soft"
                (click)="addLTSConnector()"
              >
                Add LTS Connector
              </button>
            </div>
          </div>
        </div>
        <div class="form-section">
          <div class="form-section-header">RTCM Connectors</div>
          <div class="form-section-body d-block">
            <div
              *ngFor="let connector of venueData.rtcmConnectors; let i = index"
              class="connection-row flex-start"
            >
              <div class="form-group">
                <label for="rtcmConnectionName">Connection Name</label>
                <input
                  type="text"
                  id="rtcmConnectionName"
                  [(ngModel)]="connector.connectionName"
                  name="rtcmConnectionName{{ i }}"
                  class="form-control"
                  required
                />
              </div>
              <div class="form-group">
                <label for="rtcmConnectionType">Connection Type</label>
                <input
                  type="text"
                  id="rtcmConnectionType"
                  [(ngModel)]="connector.connectionType"
                  name="rtcmConnectionType{{ i }}"
                  class="form-control"
                  required
                />
              </div>
              <div class="form-group">
                <label for="rtcmIpAddress">IP Address</label>
                <input
                  type="text"
                  id="rtcmIpAddress"
                  [(ngModel)]="connector.ipAddress"
                  name="rtcmIpAddress{{ i }}"
                  class="form-control"
                  required
                />
              </div>
              <div class="form-group">
                <label for="rtcmPort">Port</label>
                <input
                  type="number"
                  id="rtcmPort"
                  [(ngModel)]="connector.port"
                  name="rtcmPort{{ i }}"
                  class="form-control"
                  required
                />
              </div>
              <div class="form-group">
                <button
                  type="button"
                  class="btn btn-danger-soft"
                  (click)="removeRTCMConnector(i)"
                >
                  Remove Connector
                </button>
              </div>
            </div>
            <div class="form-group full-width">
              <button
                type="button"
                class="btn btn-primary-soft"
                (click)="addRTCMConnector()"
              >
                Add RTCM Connector
              </button>
            </div>
          </div>
        </div>
        <div class="form-section">
          <div class="form-section-header">Mastering Connectors</div>
          <div class="form-section-body d-block">
            <div
              *ngFor="
                let connector of venueData.masteringConnectors;
                let i = index
              "
              class="connection-row flex-start"
            >
              <div class="form-group">
                <label for="masteringConnectorGroupID"
                  >Connector Group ID</label
                >
                <input
                  type="text"
                  id="masteringConnectorGroupID"
                  [(ngModel)]="connector.connectorGroupID"
                  name="masteringConnectorGroupID{{ i }}"
                  class="form-control"
                  required
                />
              </div>
              <div class="form-group">
                <label for="masteringConnectorGroupName"
                  >Connector Group Name</label
                >
                <input
                  type="text"
                  id="masteringConnectorGroupName"
                  [(ngModel)]="connector.ConnectorGroupName"
                  name="masteringConnectorGroupName{{ i }}"
                  class="form-control"
                  required
                />
              </div>
              <div class="form-group">
                <label for="masteringConnectionName">Connection Name</label>
                <input
                  type="text"
                  id="masteringConnectionName"
                  [(ngModel)]="connector.connectionName"
                  name="masteringConnectionName{{ i }}"
                  class="form-control"
                  required
                />
              </div>
              <div class="form-group">
                <label for="masteringConnectionType">Connection Type</label>
                <input
                  type="text"
                  id="masteringConnectionType"
                  [(ngModel)]="connector.connectionType"
                  name="masteringConnectionType{{ i }}"
                  class="form-control"
                  required
                />
              </div>
              <div class="form-group">
                <label for="masteringIpAddress">IP Address</label>
                <input
                  type="text"
                  id="masteringIpAddress"
                  [(ngModel)]="connector.ipAddress"
                  name="masteringIpAddress{{ i }}"
                  class="form-control"
                  required
                />
              </div>
              <div class="form-group">
                <label for="masteringPort">Port</label>
                <input
                  type="number"
                  id="masteringPort"
                  [(ngModel)]="connector.port"
                  name="masteringPort{{ i }}"
                  class="form-control"
                  required
                />
              </div>
              <div class="form-group">
                <button
                  type="button"
                  class="btn btn-danger-soft"
                  (click)="removeMasteringConnector(i)"
                >
                  Remove Connector
                </button>
              </div>
            </div>
            <div class="form-group full-width">
              <button
                type="button"
                class="btn btn-primary-soft"
                (click)="addMasteringConnector()"
              >
                Add Mastering Connector
              </button>
            </div>
          </div>
        </div>
        <div class="form-section">
          <div class="form-section-body">
            <div class="form-group full-width">
              <label for="notes">Notes</label>
              <div>
                <textarea id="venueNotesEditor" class="form-control"></textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="button-container flex-end">
          <button
            type="button"
            class="btn btn-light"
            (click)="showModal = false"
          >
            Cancel
          </button>
          <button type="submit" class="btn btn-success-soft">
            {{ isEdit ? "Update Venue" : "Create Venue" }}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
