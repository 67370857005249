import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { ActivatedRoute } from '@angular/router';
import { APIService } from 'src/app/services/api.service';
import { back_value } from '../../../../../../pre-race/app.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment-timezone';

import { getENV } from 'src/app/_helpers/helpers';

@Component({
  selector: 'race-reports-pc-selector',
  templateUrl: './pc-selector.component.html',
  styleUrls: ['./pc-selector.component.css']
})
export class PcSelectorComponent implements OnInit {
  @Output('hideModel') hideModel: EventEmitter<MatSelectChange> = new EventEmitter<MatSelectChange>();
  @Input('venue') venue: any;
  username: string = localStorage.getItem('un');
  pc: MatSelectChange = null;
  PCs: back_value[] = [];
  // assigedPcs: any = [];
  // pcError: any = null;
  loading: boolean = true;
  form!: FormGroup;

  config: any;
  constructor(private apiService: APIService, private router: ActivatedRoute, private formBuilder: FormBuilder
  ) {
    this.config = this.router.snapshot.data['config'];
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      PC: ['', Validators.required],
    });

    // this.loading = true;
    let promises: Promise<boolean>[] = [];
    promises.push(this.getPCs());
    // promises.push(this.getAssignedPCs());
    Promise.all(promises).then(() => {
      this.loading = false;
    });
  }

  async getPCs(): Promise<boolean> {
    return new Promise(async (resolve: any, reject: any) => {
      let apiURL = `${this.config[getENV()].raceAPI}/flask-operations?action=get_pcs`;
      let data = await this.apiService.getDataPromis(apiURL, {}, {});
      var pc_range = data.PCs;
      pc_range.forEach((e: any) => {
        var bb = { value: e.name } as back_value;
        this.PCs.push(bb);
      });
      resolve(true);
    });
  }

  // async getAssignedPCs(): Promise<boolean> {
  //   return new Promise(async (resolve: any, reject: any) => {
  //     let apiURL = `${this.config[getENV()].raceAPI}/flask-operations?action=get_assigned_pcs`;
  //     let data = await this.apiService.getDataPromis(apiURL, {}, {});
  //     if (data.status == '1' || data.s == '1') {
  //       this.assigedPcs = data.pcs.map((pc: any) => {
  //         pc['MeetingDate'] = moment(pc['MeetingDate'], 'YYYY-MM-DD').format(
  //           'DD MMMM YYYY'
  //         );
  //         return pc;
  //       });
  //     }
  //     resolve(true);
  //   });
  // }

  selectPC(event: any) {
    this.pc = event;
    // let selected: any = this.assigedPcs.find((pc: any) => {
    //   return pc['AssignedPCName'] == event.value;
    // });
    // if (selected) {
    //   this.pcError = selected;
    // } else {
    //   this.pcError = null;
    // }
  }
}
