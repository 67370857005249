import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { NotifierService } from 'src/app/services/notifier.service';

@Component({
  selector: 'table-view',
  templateUrl: './table-view.component.html',
  styleUrls: ['./table-view.component.css'],
})
export class TableViewComponent implements OnInit, OnDestroy {
  @Input('headers') headers: any;
  @Input('data') data: any;
  @Input('loading') loading: boolean = false;
  @Output() sendEvent: any = new EventEmitter<any>();
  @Input() formDisabled: boolean = false;

  constructor(public notifier: NotifierService) {}

  ngOnInit(): void {}

  default(event: Event) {
    // event.preventDefault();
    event.stopPropagation();
  }

  onFilterChange(event: any, item: any, onchange: boolean, h: any) {
    if (onchange) {
      this.sendEvent.emit({
        event: event,
        item: item,
        h: h,
        type: 'filter',
      });
    }
  }

  onDropdownChange(event: any, item: any, h: any) {
    this.sendEvent.emit({
      event: event,
      item: item,
      h: h,
      type: 'change',
    });
  }

  hideMenu(event: Event, h: any) {
    event.stopPropagation();
    let selectedMenus: any = this.headers.filter((h) => h.show);
    if (selectedMenus.length == 1) {
      this.notifier.alert(
        'Info',
        '',
        'Atleast one column should be enabled',
        'info',
        5000
      );
      return;
    }
    h.show = !h.show;
  }

  typeOf(value: any) {
    return typeof value;
  }

  ngOnDestroy(): void {}
}
