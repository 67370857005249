<div class="card relative">
  <div class="floating-scroller" *ngIf="draging && currentScrollPosition > 100" (dragover)="checkMouseMove($event)"></div>
  <div class="card-header flex-between">
    <h5>Global Menus</h5>
    <div class="flex-start">
      <button class="btn btn-light" (click)="load()">Refresh</button>
      <button class="btn btn-success" (click)="updateMenus()">Update</button>
    </div>
  </div>
  <div class="card-body">
    <ng-container *ngFor="let menu of menus; let i = index">
      <div *ngIf="i == 0 && draging && draging['menu_index'] != 0" [ngStyle]="{display: draging && draging['type'] == 'menu' ? 'block': 'none'}" class="dropzone" (drop)="drop($event, menu, 'menuzone', i, null)"
      (dragover)="dropStart($event)">
        <span>
          Before {{ menu['name'] }}
        </span>
      </div>

      <div
        class="menu-container"
        draggable="true"
        (dragstart)="dragStart($event, menu, 'menu', i, null)"
        (dragend)="endDrag($event)"
        (dragstop)="dragStop($event)"
        (drop)="drop($event, menu, 'menu', i, null)"
        (dragover)="dropStart($event)"
      >
        <div class="main-menu-container flex-between">
          <div class="flex-start">
            <span *ngIf="menu['edit'] == undefined">{{ menu["name"] }}</span>
            <input *ngIf="menu['edit'] != undefined" [(ngModel)]="menu['edit']" />
            <div class="flex-start">
              <ng-container *ngIf="menu['edit'] != undefined">
                <i class="fa fa-check hoverable" (click)="changeName(menu)"></i>
                <i
                  class="fa fa-times hoverable"
                  (click)="menu['edit'] = undefined"
                ></i>
              </ng-container>
              <ng-container *ngIf="menu['edit'] == undefined">
                <i
                  class="fa fa-pencil hoverable"
                  *ngIf="menu['temp']"
                  (click)="menu['edit'] = menu['name']"
                ></i>
              </ng-container>
            </div>
          </div>
          <button
            *ngIf="menu['temp']"
            class="btn btn-light flex-start"
            (click)="removeMainMenu(i)"
          >
            <i class="fa fa-trash"></i>
            Delete
          </button>
        </div>
        <div class="sub-menus-container">
          <div
            class="sub-menu-container"
            draggable="true"
            (dragstop)="dragStop($event)"
            (dragstart)="dragStart($event, submenu, 'submenu', i, index)"
            (drop)="drop($event, submenu, 'submenu', i, index)"
            (dragover)="dropStart($event)"
            *ngFor="let submenu of menu['sub_menus']; let index = index"
          >
            <div class="flex-start">
              <span *ngIf="submenu['edit'] == undefined">{{
                submenu["name"]
              }}</span>
              <input
                *ngIf="submenu['edit'] != undefined"
                [(ngModel)]="submenu['edit']"
              />
              <div class="flex-start">
                <ng-container *ngIf="submenu['edit'] != undefined">
                  <i
                    class="fa fa-check hoverable"
                    (click)="changeName(submenu)"
                  ></i>
                  <i
                    class="fa fa-times hoverable"
                    (click)="submenu['edit'] = undefined"
                  ></i>
                </ng-container>
                <ng-container *ngIf="submenu['edit'] == undefined">
                  <i
                    class="fa fa-pencil hoverable"
                    (click)="submenu['edit'] = submenu['name']"
                  ></i>
                  <i
                    class="fa fa-times pointer"
                    (click)="removeSubMenu(menu, index)"
                  ></i>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-start p-2">
          <button class="btn btn-light" (click)="addSubMenu(menu)">
            <span><i class="fa fa-plus"></i></span>
            <span>Add</span>
          </button>
        </div>
      </div>
      <div *ngIf="draging &&  draging['menu_index'] != i" [ngStyle]="{display: draging && draging['type'] == 'menu' ? 'block': 'none'}" class="dropzone" (drop)="drop($event, menu, 'menuzone', i + 1, null)"
      (dragover)="dropStart($event)">
        <span *ngIf="menus.length == i + 1">
          After {{ menu['name'] }}
        </span>
        <span *ngIf="menus.length > i + 1">
          After {{ menu['name'] }} 
        </span>
      </div>
    </ng-container>
    <div>
      <button class="btn btn-light" (click)="addMainMenu()">Add Menu</button>
    </div>
  </div>
</div>
