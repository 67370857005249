import { Component, OnInit } from '@angular/core';
import { AllDevices } from 'src/app/services/all-devices.service';
import { ActivatedRoute, Params } from '@angular/router';
import { APIService } from 'src/app/services/api.service';

import { getENV } from 'src/app/_helpers/helpers';

@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.css']
})
export class DevicesComponent implements OnInit {
  devices: any[] = [];
  currentPage: number = 1;
  totalPages: number = 0;
  sortBy: string = 'SerialNumber';
  sortOrder: string = 'asc';
  showHistoryModal:boolean = false;
  filters: any = {
    KitID: '',
    OriginalKit: '',
    SerialNumber: '',
    DeviceID: ''
  };
  selectedKit: any = null; // Currently selected kit for the modal
  showModal: boolean = false; // Controls visibility of the modal
  config: any;
  constructor(
    private route: ActivatedRoute,
    private apiService: APIService,
    private devicesService: AllDevices
  ){
    this.config = this.route.snapshot.data['config'];
  }
  ngOnInit(): void {
    this.fetchDevices();
  }

  fetchDevices(): void {
    const apiUrl = `${this.config[getENV()].raceAPI}/devices/all`;
    this.devicesService.getAllDevices(apiUrl, this.currentPage, 20, this.sortBy, this.sortOrder, this.filters).subscribe(response => {
      this.devices = response.data.devices;
      this.totalPages = response.data.total_pages;
    });
  }

  onSortChange(sortBy: string): void {
    if (this.sortBy === sortBy) {
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortBy = sortBy;
      this.sortOrder = 'asc';
    }
    this.fetchDevices();
  }

  onPageChange(page: number | string): void {
    if (typeof page === 'string') return;
    this.currentPage = page;
    this.fetchDevices();
  }

  onFilterChange(): void {
    this.currentPage = 1; // Reset to first page on filter change
    this.fetchDevices();
  }

  getSortClass(column: string): string {
    if (this.sortBy === column) {
      return this.sortOrder === 'asc' ? 'sortable asc' : 'sortable desc';
    }
    return 'sortable';
  }

  getPages(): (number | string)[] {
    const pages: (number | string)[] = [];
    const maxPagesToShow = 10;
    const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);

    let startPage = Math.max(1, this.currentPage - halfMaxPagesToShow);
    let endPage = Math.min(this.totalPages, this.currentPage + halfMaxPagesToShow);

    if (endPage - startPage < maxPagesToShow - 1) {
      startPage = Math.max(1, endPage - maxPagesToShow + 1);
    }

    if (endPage - startPage < maxPagesToShow - 1) {
      endPage = Math.min(this.totalPages, startPage + maxPagesToShow - 1);
    }

    if (startPage > 1) {
      pages.push(1);
      if (startPage > 2) {
        pages.push('...');
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    if (endPage < this.totalPages) {
      if (endPage < this.totalPages - 1) {
        pages.push('...');
      }
      pages.push(this.totalPages);
    }

    return pages;
  }

  openKitDetails(kit: any): void {
    this.selectedKit = kit.SerialNumber;
    this.showModal = true;
  }

  closeModal(): void {
    this.showModal = false;
    window.location.reload();
  }

  openKitHistory(kit: any):void {
    this.selectedKit = kit.SerialNumber;
    this.showHistoryModal = true;
  }

  closeHistoryModal():void {
    this.showHistoryModal =false;
  }
}
