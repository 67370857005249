<div class="card">
  <h5 class="card-header flex-start">
    <a
      class="btn btn-light flex-start"
      routerLink="/dash/post-race"
      fragment="home"
      *ngIf="selected != 'home'"
    >
      <i class="fa fa-angle-left"></i>
      Home
    </a>
    <div class="fw-bold">Post Race</div>
  </h5>
  <div class="card-body">
    <ng-container *ngIf="selected == 'home'">
      <post-race-home></post-race-home>
    </ng-container>
    <ng-container *ngIf="selected == 'hrv-race-reports'">
      <post-race-race-reports></post-race-race-reports>
    </ng-container>
    <ng-container *ngIf="selected == 'qr-harness-race-reports'">
      <post-race-race-reports></post-race-race-reports>
    </ng-container>
    <ng-container *ngIf="selected == 'nsw-harness-race-reports'">
      <post-race-race-reports></post-race-race-reports>
    </ng-container>
    <ng-container *ngIf="selected == 'race-management'">
      <post-race-race-management></post-race-race-management>
    </ng-container>
    <ng-container *ngIf="selected == 'eod-report'">
      <post-race-eod-report></post-race-eod-report>
    </ng-container>
    <ng-container *ngIf="selected == 'australian-turb-club-race-report'">
      <post-race-race-reports></post-race-race-reports>
    </ng-container>
    <ng-container *ngIf="selected == 'racing-victoria-club-race-report'">
      <post-race-race-reports></post-race-race-reports>
    </ng-container>
    <ng-container *ngIf="selected == 'brisbane-racing-club-race-report'">
      <post-race-race-reports></post-race-race-reports>
    </ng-container>
    <ng-container *ngIf="selected == 'queensland-racing-club-race-report'">
      <post-race-race-reports></post-race-race-reports>
    </ng-container>
    <ng-container *ngIf="selected == 'singapore-tracks-race-report'">
      <post-race-race-reports></post-race-race-reports>
    </ng-container>
    <ng-container *ngIf="selected == 'new-zealand-tracks-race-report'">
      <post-race-race-reports></post-race-race-reports>
    </ng-container>
    <ng-container
      *ngIf="selected == 'racing-south-australia-track-race-report'"
    >
      <post-race-race-reports></post-race-race-reports>
    </ng-container>
    <ng-container *ngIf="selected == 'australian-turb-club-eod-report'"
      >`
      <post-race-eod-report></post-race-eod-report>
    </ng-container>
    <ng-container *ngIf="selected == 'racing-victoria-club-eod-report'">
      <post-race-eod-report></post-race-eod-report>
    </ng-container>
    <ng-container *ngIf="selected == 'brisbane-racing-club-eod-report'">
      <post-race-eod-report></post-race-eod-report>
    </ng-container>
    <ng-container *ngIf="selected == 'queensland-racing-club-eod-report'">
      <post-race-eod-report></post-race-eod-report>
    </ng-container>
    <ng-container *ngIf="selected == 'singapore-tracks-eod-report'">
      <post-race-eod-report></post-race-eod-report>
    </ng-container>
    <ng-container *ngIf="selected == 'new-zealand-tracks-eod-report'">
      <post-race-eod-report></post-race-eod-report>
    </ng-container>
    <ng-container *ngIf="selected == 'racing-south-australia-track-eod-report'">
      <post-race-eod-report></post-race-eod-report>
    </ng-container>
  </div>
</div>
