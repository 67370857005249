import { assertListType } from 'graphql';
import { fetchVenues } from 'src/app/_helpers/helpers';
import { APIService } from 'src/app/services/api.service';
import { NotifierService } from 'src/app/services/notifier.service';

interface payload {
  action: string;
  startlist_id: string;
  venue_id: string;
  racereports_id: string;
  eod_id: string;
}

export default class VenueEmails {
  private apiURL: string;
  constructor(private apiService: APIService, private config: any, private notifier: NotifierService) {
    this.apiURL = `${this.config.raceAPI}/venue-operations`;
  }

  async loadEmails() {
    let result: any = await this.apiService.getDataPromis(
      this.apiURL + '?action=list_venue_emails',
      {},
      {}
    );

    if (result.status == '1' || result.s == '1') {
      let venueList: string[] = fetchVenues();
      result.venues = result.venues.filter((venue: any) => {
        return (
          venueList.length == 0 || venueList.includes(venue.ExternalVenueID)
        );
      });
      result.venues.sort((a: any, b: any) => {
        if (a.VenueName < b.VenueName) {
          return -1;
        }
        if (a.VenueName > b.VenueName) {
          return 1;
        }
        return 0;
      });
      return { status: '1', data: result.venues || [] };
    }
    return result;
  }

  async loadVenueEmail(venue_id: string) {
    if (!venue_id) {
      return { status: '0', data: [], error_message: 'Venue Id is mandatory' };
    }

    let result: any = await this.apiService.getDataPromis(
      this.apiURL + '?action=get_email_ids&venue_id=' + venue_id,
      {},
      {}
    );

    if (result.status == '1' || result.s == '1') {
      return { status: '1', data: result.email_ids || [] };
    }
    return result;
  }

  async saveVenueEmail(
    venue_id: string,
    racereports_id: string = '',
    unofficialracereports_id: string = '',
    eod_id: string = '',
    startlist_id: string = ''
  ) {
    const emailRegex = /^$|^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    let check: boolean = true;
    let correctEmails: number = 0;
    let failed: { [a: string]: string } = {
      startlist_id: '',
      racereports_id: '',
      unofficialracereports_id: '',
      eod_id: '',
    };
    if (!emailRegex.test(startlist_id.trim())) {
      check = false;
      failed['startlist_id'] = 'Invalid startlist_id email format';
    }


    if (!emailRegex.test(racereports_id.trim())) {
      check = false;
      failed['racereports_id'] = 'Invalid racereports_id email format';
    }

    if (!emailRegex.test(eod_id.trim())) {
      check = false;
      failed['eod_id'] = 'Invalid eod_id email format';
    }
    if (!emailRegex.test(unofficialracereports_id.trim())) {
      check = false;
      failed['unofficialracereports_id'] = 'Invalid unofficial_id email format';
    }

    if(startlist_id.trim().length) {
      correctEmails++;
    }
    if(racereports_id.trim().length) {
      correctEmails++;
    }
    if(unofficialracereports_id.trim().length) {
      correctEmails++;
    }
    if(eod_id.trim().length) {
      correctEmails++;
    }

    if (!check) {
      return {
        status: '0',
        failed: failed,
      };
    }

    // let payload: payload = {
    //   action: 'update_email_ids',
    //   venue_id: venue_id || '',
    //   racereports_id: racereports_id || '',
    //   eod_id: eod_id || '',
    //   startlist_id: startlist_id || '',
    // };

    if(correctEmails == 0) {
      this.notifier.alert('Info', '', 'Please enter at least one email address', 'info', 5000);
      return {
        status: '0',
        failed: failed,
      };
    }

    let result: any = await this.apiService.getDataPromis(
      this.apiURL +
        `?action=update_email_ids&venue_id=${venue_id}&racereports_id=${racereports_id.trim()}&unofficial_id=${unofficialracereports_id.trim()}&eod_id=${eod_id.trim()}&startlist_id=${startlist_id.trim()}`,
      {},
      {}
    );

    return result;
  }
}
