import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'button-status',
  templateUrl: './button-status.component.html',
  styleUrls: ['./button-status.component.css'],
})
export class ButtonStatusComponent implements OnInit, OnDestroy {
  @Input({ alias: 'filter', required: true }) filter: any;
  @Input({ alias: 'title', required: true }) title: any;
  @Input({ alias: 'multiple', required: true }) multiple: boolean;
  @Input({ alias: 'items', required: true }) data: {
    name: string;
    id: string;
  }[];
  filterText: string = '';

  constructor() {}

  get getSelected() {
    return this.filter.selected.join(', ');
  }

  isSelected(item: string) {
    return this.filter['selected'].includes(item);
  }

  stopPropogation(event: Event) {
    event.stopPropagation();
  }

  @ViewChild('parentcontainer', { static: true }) parentContainer!: ElementRef;

  clickOutside(event: Event) {
    if (!this.parentContainer.nativeElement.contains(event.target)) {
      this.isDropdownOpen = false;
    }
    return;
  }

  isDropdownOpen = false;

  toggleDropdown(event: any) {
    this.stopPropogation(event);
    this.isDropdownOpen = !this.isDropdownOpen;
    if (this.isDropdownOpen) {
      document.body.addEventListener('click', (event: any) => {
        this.clickOutside(event);
      });
    } else {
      document.body.removeEventListener('click', this.clickOutside);
    }
  }

  updateSelected(item_id: string) {
    if(!this.multiple) {
      this.filter['selected'] = [item_id];
      return;
    }
    if (this.filter['selected'].length > 0) {
      if (this.filter['selected'][0] == 'ALL') {
        this.filter['selected'] = [item_id];
      } else {
        let itemIndex: number = this.filter['selected'].findIndex((item: string) => {
          return item == item_id;
        })
        if(itemIndex > -1) {
          this.filter['selected'] = this.filter['selected'].filter((item: any, index: number) => {
            return index != itemIndex;
          });
        } else {
          this.filter['selected'] = [...this.filter['selected'], item_id];
        }
      }
    } else {
      this.filter['selected'] = [item_id];
    }
  }

  ngOnInit() {}

  ngOnDestroy(): void {
    document.body.removeEventListener('click', this.clickOutside);
  }
}
