import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  AfterViewInit,
  ElementRef,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { getENV } from 'src/app/_helpers/helpers';
import { APIService } from 'src/app/services/api.service';
import { NotifierService } from 'src/app/services/notifier.service';
import { locationMappers } from '../post-race/harness-reports/components/race-reports/locations';

declare let flatpickr: any;

@Component({
  selector: 'staging-management',
  templateUrl: './staging-management.component.html',
  styleUrls: ['./staging-management.component.css'],
})
export class StagingManagementComponent {
  @Input() event: any;
  @Output('hideModal') hideModal = new EventEmitter<void>();

  tasks = [
    { id: 'tol', name: 'TOL Configuration', loading: false },
    { id: 'race_configuration', name: 'Race Configuration', loading: false },
    { id: 'db_backup', name: 'Database Backup', loading: false },
    { id: 'event_and_races', name: 'Event and Races', loading: false },
  ];
  taskToConfirm: { id: string; name: string; loading: boolean } | null = null;
  taskToDelete: { id: string; name: string; loading: boolean } | null = null;
  destination: string = '';
  loading = false;
  showDeleteConfirmation: boolean = false;

  config: any;
  @ViewChild('datePicker') datePicker: any;
  @ViewChild('dateRangePicker') dateRangePicker: ElementRef;

  selectedDateRange: string = null;

  constructor(
    private apiService: APIService,
    private router: ActivatedRoute,
    private notifier: NotifierService
  ) {
    this.config = this.router.snapshot.data['config'];
  }

  closePopup() {
    this.hideModal.emit();
  }

  confirmTaskExecution(task: { id: string; name: string; loading: boolean }) {
    this.taskToConfirm = task;
  }

  confirmTaskDeletion(task: { id: string; name: string; loading: boolean }) {
    this.taskToDelete = task;
    setTimeout(() => {
      flatpickr(this.dateRangePicker.nativeElement, {
        mode: 'range',
        maxDate: new Date(new Date().setDate(new Date().getDate() + 7)),
        onChange: (selectedDates: Date[], dateStr: string) => {
          if (selectedDates.length === 2) {
            const diffTime = Math.abs(
              selectedDates[1].getTime() - selectedDates[0].getTime()
            );
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

            if (diffDays > 7) {
              this.notifier.alert(
                'Error',
                '',
                'Date range cannot exceed 7 days',
                'error',
                5000
              );
              this.selectedDateRange = null;
              this.dateRangePicker.nativeElement.value = '';
              return;
            }
            if (diffDays < 1) {
              this.notifier.alert(
                'Error',
                '',
                'Date range must be at least 2 days',
                'error',
                5000
              );
              this.selectedDateRange = null;
              this.dateRangePicker.nativeElement.value = '';
              return;
            }
            this.selectedDateRange = dateStr;
          }
        },
      });
    }, 10);
  }

  cancelConfirmation() {
    this.taskToConfirm = null;
    this.destination = '';
  }

  cancelDeletion() {
    this.taskToDelete = null;
    this.showDeleteConfirmation = false;
    this.destination = '';
    this.selectedDateRange = '';
  }

  async executeTask(task: { id: string; name: string; loading: boolean }) {
    this.taskToConfirm = null;
    this.taskToDelete = null;
    task.loading = true;

    const payload = {
      action: 'copy_data_between_env',
      location: locationMappers[this.event['venue']] || this.event['venue'],
      race_date: this.event['event_date'],
      event_id: this.event['event_id'],
      jurisdiction: this.event['jurisdictionCode'],
      type: this.event['raceType'],
      race_id: '1231230',
      race_number: 0,
      operator_name: this.event['operator'],
      pc_number: this.event['pc'],
      venue_id: this.event['venue_id'],
      destination_environment: this.destination,
      file_type: task.id,
    };

    const apiURL: string = `${this.config['prod'].raceAPI}/environment`;

    try {
      const result: any = await this.apiService.postDataPromis(
        apiURL,
        payload,
        {}
      );

      if (result.status == '1' || result.s == '1') {
        this.notifier.alert(
          'Success',
          '',
          result.response || 'Task Executed Successfully',
          'success',
          5000
        );
      } else {
        this.notifier.alert(
          'Info',
          '',
          result.response || result.error_message || result.message,
          'info',
          5000
        );
      }
    } catch (error) {
      this.notifier.alert(
        'Error',
        '',
        'An error occurred while executing the task.',
        'error',
        5000
      );
    } finally {
      task.loading = false;
    }
  }

  confirmDeletion() {
    this.showDeleteConfirmation = true;
    setTimeout(() => {
      flatpickr(this.dateRangePicker.nativeElement, {
        mode: 'range',
        maxDate: new Date(new Date().setDate(new Date().getDate() + 7)),
        onChange: (selectedDates: Date[], dateStr: string) => {
          if (selectedDates.length === 2) {
            const diffTime = Math.abs(selectedDates[1].getTime() - selectedDates[0].getTime());
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

            if (diffDays > 7) {
              this.notifier.alert('Error', '', 'Date range cannot exceed 7 days', 'error', 5000);
              this.selectedDateRange = null;
              this.dateRangePicker.nativeElement.value = '';
              return;
            }
            if (diffDays < 1) {
              this.notifier.alert('Error', '', 'Date range must be at least 2 days', 'error', 5000);
              this.selectedDateRange = null;
              this.dateRangePicker.nativeElement.value = '';
              return;
            }
            this.selectedDateRange = dateStr;
          }
        },
      });
    }, 10);
  }

  async executeDeleteTask() {
    this.loading = true;
    this.showDeleteConfirmation = false;
    const payload = {
      action: 'copy_data_between_env',
      location: locationMappers[this.event['venue']] || this.event['venue'],
      race_date: this.event['event_date'],
      event_id: this.event['event_id'],
      jurisdiction: this.event['jurisdictionCode'],
      type: this.event['raceType'],
      race_id: '1231230',
      race_number: 0,
      operator_name: this.event['operator'],
      pc_number: this.event['pc'],
      venue_id: this.event['venue_id'],
      destination_environment: this.destination,
      action_type: 'delete',
      from_date: this.selectedDateRange ? this.selectedDateRange.split(' to ')[0] : '',
      to_date: this.selectedDateRange ? this.selectedDateRange.split(' to ')[1] : '',
    };

    const apiURL: string = `${this.config['prod'].raceAPI}/environment`;

    try {
      const result: any = await this.apiService.postDataPromis(
        apiURL,
        payload,
        {}
      );

      if (result.status == '1' || result.s == '1') {
        this.notifier.alert(
          'Success',
          '',
          result.response || 'Task Executed Successfully',
          'success',
          5000
        );
      } else {
        this.notifier.alert(
          'Info',
          '',
          result.response || result.error_message || result.message,
          'info',
          5000
        );
      }
    } catch (error) {
      this.notifier.alert(
        'Error',
        '',
        'An error occurred while executing the task.',
        'error',
        5000
      );
    } finally {
      this.showDeleteConfirmation = false;
      this.loading = false;
    }
  }
}
