<div
  class="modal fade"
  [class.show]="showModal"
  [style.display]="showModal ? 'block' : 'none'"
  tabindex="-1"
  role="dialog"
  aria-labelledby="kitDetailsModalLabel"
  aria-modal="true"
  style="background-color: rgba(0, 0, 0, 0.5)"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <form (submit)="submitForm()">
        <div class="modal-header">
          <h5 class="modal-title" id="kitDetailsModalLabel">
            {{ isEditMode ? "Edit" : "Add" }} Race Details
          </h5>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            (click)="toggleModal()"
          ></button>
        </div>
        <div
          class="modal-body"
          *ngIf="form['CodeType'] != 'GREYHOUNDS'; else showGreyHounds"
        >
          <!-- Form Fields -->
          <div class="mb-3">
            <label for="serialNumber" class="form-label">Serial Number</label>
            <input
              type="text"
              class="form-control"
              id="serialNumber"
              [(ngModel)]="form['SerialNumber']"
              name="SerialNumber"
              [disabled]="isEditMode"
            />
          </div>
          <div class="mb-3">
            <label for="tagID" class="form-label">Tag ID</label>
            <input
              type="text"
              class="form-control"
              id="tagID"
              [(ngModel)]="form['TagID']"
              name="TagID"
            />
          </div>
          <div class="mb-3">
            <label for="status" class="form-label">Status</label>
            <select
              class="form-control"
              [(ngModel)]="form['Status']"
              name="Status"
              [ngStyle]="{
                'background-color': getSelectedOptionColor(form['Status']),
                'border-color': getSelectedOptionBorderColor(form['Status']),
                color: getTextColor(getSelectedOptionColor(form['Status']))
              }"
            >
              <option
                *ngFor="let status of objectKeys(labels)"
                [value]="labels[status]"
                [ngStyle]="{
                  'background-color': getColor(status),
                  'border-color': getBorderColor(status),
                  color: getTextColor(getColor(status))
                }"
              >
                {{ labels[status] }}
              </option>
            </select>
          </div>
          <div class="mb-3">
            <label for="stPurchaseDate" class="form-label"
              >ST Purchase Date</label
            >
            <input
              type="date"
              class="form-control"
              id="stPurchaseDate"
              [(ngModel)]="form['STPurchaseDate']"
              name="STPurchaseDate"
            />
          </div>
          <div class="mb-3">
            <label for="clientPurchaseDate" class="form-label"
              >Client Purchase Date</label
            >
            <input
              type="date"
              class="form-control"
              id="clientPurchaseDate"
              [(ngModel)]="form['ClientPurchaseDate']"
              name="ClientPurchaseDate"
            />
          </div>
          <div class="mb-3">
            <label for="warrantyExtended" class="form-label"
              >Warranty Extended</label
            >
            <select
              class="form-select"
              id="warrantyExtended"
              [(ngModel)]="form['WarrantyExtended']"
              name="WarrantyExtended"
            >
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
          <div class="mb-3">
            <label for="warrantyStatus" class="form-label"
              >Warranty Status</label
            >
            <input
              type="text"
              class="form-control"
              id="warrantyStatus"
              [(ngModel)]="form['WarrantyStatus']"
              name="WarrantyStatus"
            />
          </div>
          <div class="mb-3">
            <label for="warrantyDate" class="form-label">Warranty Date</label>
            <input
              type="date"
              class="form-control"
              id="warrantyDate"
              [(ngModel)]="form['WarrantyDate']"
              name="WarrantyDate"
            />
          </div>
          <div class="mb-3">
            <label for="kitID" class="form-label">Kit ID</label>
            <input
              type="text"
              class="form-control"
              id="kitID"
              [(ngModel)]="form['KitID']"
              name="KitID"
            />
          </div>
          <div class="mb-3">
            <label for="originalKit" class="form-label">Original Kit</label>
            <input
              type="text"
              class="form-control"
              id="originalKit"
              [(ngModel)]="form['OriginalKit']"
              name="OriginalKit"
            />
          </div>
          <div class="mb-3">
            <label for="venueState" class="form-label">Venue State</label>
            <input
              type="text"
              class="form-control"
              id="venueState"
              [(ngModel)]="form['VenueState']"
              name="VenueState"
            />
          </div>
          <div class="mb-3">
            <label for="codeType" class="form-label">Code Type</label>
            <select
              class="form-select"
              id="codeType"
              [(ngModel)]="form['CodeType']"
              name="CodeType"
            >
              <option value="HARNESS">HARNESS</option>
              <option value="GALLOPS">GALLOPS</option>
              <option value="GREYHOUNDS">GREYHOUNDS</option>
            </select>
          </div>
          <div class="mb-3">
            <label for="location" class="form-label">Location</label>
            <input
              type="text"
              class="form-control"
              id="location"
              [(ngModel)]="form['Location']"
              name="Location"
            />
          </div>
          <div class="mb-3">
            <label for="firmwareVersion" class="form-label"
              >Firmware Version</label
            >
            <input
              type="text"
              class="form-control"
              id="firmwareVersion"
              [(ngModel)]="form['FirmwareVersion']"
              name="FirmwareVersion"
            />
          </div>
          <div class="mb-3">
            <label for="hardwareVersion" class="form-label"
              >Hardware Version</label
            >
            <input
              type="text"
              class="form-control"
              id="hardwareVersion"
              [(ngModel)]="form['HardwareVersion']"
              name="HardwareVersion"
            />
          </div>
          <div class="mb-3">
            <label for="uid" class="form-label">UID (if applicable)</label>
            <input
              type="text"
              class="form-control"
              id="uid"
              [(ngModel)]="form['UID']"
              placeholder="UID"
              name="UID"
            />
          </div>
          <div class="mb-3">
            <label for="profile" class="form-label">Profile</label>
            <input
              type="text"
              class="form-control"
              id="profile"
              [(ngModel)]="form['Profile']"
              name="Profile"
            />
          </div>
          <div class="mb-3">
            <label for="radio" class="form-label">Radio</label>
            <input
              type="text"
              class="form-control"
              id="radio"
              [(ngModel)]="form['Radio']"
              name="Radio"
            />
          </div>
        </div>
        <ng-template #showGreyHounds>
          <div class="modal-body">
            <div class="mb-3">
              <label for="serialNumber" class="form-label">Serial Number</label>
              <input
                type="text"
                class="form-control"
                id="serialNumber"
                [(ngModel)]="form['SerialNumber']"
                name="SerialNumber"
                [disabled]="isEditMode"
              />
            </div>
            <div class="mb-3">
              <label for="tagID" class="form-label">Transponder ID</label>
              <input
                type="text"
                class="form-control"
                id="tagID"
                [(ngModel)]="form['TransponderID']"
                name="TransponderID"
              />
            </div>
            <div class="mb-3">
              <label for="warrantyDate" class="form-label"
                >Warranty Expiry Date</label
              >
              <input
                type="date"
                class="form-control"
                id="warrantyDate"
                [(ngModel)]="form['WarrantyExpDate']"
                name="WarrantyExpDate"
              />
            </div>
            <div class="mb-3">
              <label for="SetNumber" class="form-label">Set Number</label>
              <input
                type="number"
                class="form-control"
                id="SetNumber"
                [(ngModel)]="form['SetNumber']"
                name="SetNumber"
              />
            </div>
            <div class="mb-3">
              <label for="Location" class="form-label">Location</label>
              <input
                type="text"
                class="form-control"
                id="Location"
                [(ngModel)]="form['Location']"
                name="Location"
              />
            </div>
            <div class="mb-3">
              <label for="codeType" class="form-label">Code Type</label>
              <select
                class="form-select"
                id="codeType"
                [(ngModel)]="form['CodeType']"
                name="CodeType"
              >
                <option value="HARNESS">HARNESS</option>
                <option value="GALLOPS">GALLOPS</option>
                <option value="GREYHOUNDS">GREYHOUNDS</option>
              </select>
            </div>
            <div class="mb-3">
              <label for="kitID" class="form-label">Kit ID</label>
              <input
                type="text"
                class="form-control"
                id="kitID"
                [(ngModel)]="form['KitID']"
                name="KitID"
              />
            </div>
            <div class="mb-3">
              <label for="venueState" class="form-label">Venue State</label>
              <input
                type="text"
                class="form-control"
                id="venueState"
                [(ngModel)]="form['VenueState']"
                name="VenueState"
              />
            </div>
            <div class="mb-3">
              <label for="status" class="form-label">Status</label>
              <select
                class="form-control"
                [(ngModel)]="form['Status']"
                name="Status"
                [ngStyle]="{
                  'background-color': getSelectedOptionColor(form['Status']),
                  'border-color': getSelectedOptionBorderColor(form['Status']),
                  color: getTextColor(getSelectedOptionColor(form['Status']))
                }"
              >
                <option
                  *ngFor="let status of objectKeys(labels)"
                  [value]="labels[status]"
                  [ngStyle]="{
                    'background-color': getColor(status),
                    'border-color': getBorderColor(status),
                    color: getTextColor(getColor(status))
                  }"
                >
                  {{ labels[status] }}
                </option>
              </select>
            </div>
          </div>
        </ng-template>
        <div class="modal-footer">
          <button type="submit" class="btn btn-primary">
            {{ isEditMode ? "Save Changes" : "Add Kit" }}
          </button>
          <button
            type="button"
            class="btn btn-secondary"
            (click)="toggleModal()"
          >
            Close
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
