import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { APIService } from 'src/app/services/api.service';
import { NotifierService } from 'src/app/services/notifier.service';
import events from '../venve-details/events';
import { checkForVenuePermission, convertTimestamp, getENV, getRaceType } from 'src/app/_helpers/helpers';


declare let $: any;
declare let window: any;

@Component({
  selector: 'app-race-overview',
  templateUrl: './race-overview.component.html',
  styleUrls: ['./race-overview.component.css'],
})
export class RaceOverviewComponent implements OnInit, AfterViewInit {
  upcoming: any = [];
  previous: any = {
    date: '',
    events: [],
  };

  config: any;

  constructor(
    private apiService: APIService,
    private notifier: NotifierService,
    private router: ActivatedRoute
  ) {
    this.config = this.router.snapshot.data['config'];
  }

  async ngOnInit(): Promise<void> {
    for (let index = 0; index < 3; index++) {
      let date: any = moment().add(index, 'days');
      let dt: any = {
        date: date.format('YYYY-MM-DD'),
        dateStr: date.format('DD MMMM YYYY'),
        loading: true,
        events: [],
      };
      this.loadEventsBasedOnDates(date.format('YYYY-MM-DD'), dt);
      this.upcoming.push(dt);
    }

    let date: any = moment().subtract(1, 'days');
    this.previous['date'] = date.format('YYYY-MM-DD');
    this.previous['loading'] = true;
    this.previous['dateStr'] = date.format('DD MMMM YYYY');
    this.previous['events'] = [];
    this.loadEventsBasedOnDates(this.previous['date'], this.previous);
  }

  async ngAfterViewInit(): Promise<void> {
    $('.datepicker').val(
      this.previous['dateStr'] ||
        moment().subtract(1, 'days').format('DD MMMM YYYY')
    );

    $('.datepicker')
      .datepicker({
        autoclose: true,
        // minViewMode: 1,
        format: 'dd MM yyyy',
        endDate: moment().subtract(1, 'days').format('DD MMMM YYYY'),
        orientation: 'bottom auto',
      })
      .on('changeDate', async (selected: any) => {
        this.previous['date'] = $('.datepicker').val();
        this.previous['loading'] = true;
        this.previous['dateStr'] = $('.datepicker').val();
        this.previous['events'] = [];
        this.loadEventsBasedOnDates(
          moment($('.datepicker').val(), 'DD MMMM YYYY').format('YYYY-MM-DD'),
          this.previous
        );
      });
  }

  manualCard: boolean = false;
  showManualRaceCards() {
    this.manualCard = true;
  }

  hideManualCard(event: any) {
    this.manualCard = false;
    if(event) {
      window.location.reload();
    }
  }

  // async getEvent(event: any) {
  //   return new Promise(async (resolve: any) => {
  //     try {
  //       let apiURL: string = `${
  //         this.config[getENV()].raceAPI
  //       }/getevent?ExternalEventId=${event['external_event_id']}`;
  //       let result: any = await this.apiService.getDataPromis(apiURL, {}, {});
  //       if (result && typeof result == 'object') {
  //         // event['event_state'] = result['event_state'];
  //         event['kit'] = result['event']['kit'];
  //         event['rail_status'] =
  //           result['event']['custom_split'] != undefined ||
  //           result['event']['custom_rail'] != undefined ||
  //           result['event']['rail'] != undefined;
  //       }
  //     } catch (error) {
  //       event.event_state = 'FAILED_TO_LOAD';
  //       event.loading = false;
  //     }
  //     resolve(true);
  //   });
  // }

  // async getRaceResult(event: any, races: any = []) {
  //   return new Promise(async (resolve: any) => {
  //     let apiURL: string = `${
  //       this.config[getENV()].raceAPI
  //     }/getraceresults?ExternalEventId=${
  //       event.external_event_id
  //     }&ExternalVenueId=${event.external_venue_id}&${races
  //       .map((race: any) => {
  //         return `raceIds%5B%5D=${race['ExternalRaceId']}`;
  //       })
  //       .join('&')}`;

  //     let result: any = await this.apiService.getDataPromis(apiURL, {}, {});

  //     if (result && typeof result == 'object') {
  //       let statues = Object.values(result);
  //       if (statues.length == 0) {
  //         statues = ['DNS'];
  //       } else if (statues.length != races.length) {
  //         statues.push('PLANNED');
  //       }
  //       races.forEach((race: any) => {
  //         if (result[race['ExternalRaceId']] != undefined) {
  //           race['raceStatus'] = result[race['ExternalRaceId']].toUpperCase();
  //         } else {
  //           race['raceStatus'] = 'DNS';
  //         }
  //       });
  //       // statues = ['DNS', 'DNS', 'DNS'];
  //       if (statues.includes('DNS') || statues.includes('dns')) {
  //         if (statues.includes('PLANNED') && event.isToday) {
  //           event.status = 'RACING';
  //         } else if (!statues.includes('FINISHED')) {
  //           event.status = '-';
  //         } else {
  //           event.status = 'INCOMPLETED';
  //         }
  //       } else if (statues.includes('PLANNED')) {
  //         event.status = 'PLANNED';
  //         if (statues.includes('FINISHED') && event.isToday) {
  //           event.status = 'RACING';
  //         }
  //       } else {
  //         if (statues.includes('FINISHED')) {
  //           event.status = 'COMPLETED';
  //         }
  //       }
  //     }

  //     resolve(true);
  //   });
  // }

  // async loadRacesBasedOnEvent(event: any): Promise<boolean> {
  //   return new Promise(async (resolve: any) => {
  //     let apiURL: string = `${
  //       this.config[getENV()].raceAPI
  //     }/getracedetails?ExternalVenueId=${
  //       event.external_venue_id
  //     }&ExternalEventId=${event.external_event_id}`;

  //     let result: any = await this.apiService.getDataPromis(apiURL, {}, {});

  //     if (result && Array.isArray(result) && result.length > 0) {
  //       let check: boolean = true;
  //       result.sort(
  //         (a, b) => a.SquentialRaceOrderNumber - b.SquentialRaceOrderNumber
  //       );

  //       result.forEach((race: any) => {
  //         race['RaceTimeStr'] = race['RaceTime'];
  //         if (!race['Track']) {
  //           check = false;
  //         }
  //         // race['RaceTime'] = convertTimestamp(race['RaceTime']);
  //       });
  //       event['eqTraceStatus'] = check;
  //       event.races = result;
  //       // this.getRaceResult(event, event.races);
  //       event.loading = false;
  //       resolve(true);
  //     } else {
  //       event.status = 'PLANNED';
  //       resolve(false);
  //     }

  //     resolve(true);
  //   });
  // }

  async loadEventsBasedOnDates(date: string, data: any): Promise<any> {
    return new Promise(async (resolve: any) => {
      let result: any = null;
      if (events[date]) {
        result = {
          eventNames: events[date],
        };
      } else {
        let apiURL: string = `${
          this.config[getENV()].raceAPI
        }/event-list?formatted_date=${date}`;

        result = await this.apiService.getDataPromis(apiURL, {}, {});
      }

      if (result.eventNames && result.eventNames.length > 0) {
        result.eventNames = result.eventNames.filter((event: any) => {
          return checkForVenuePermission(event['external_venue_id']);
        });
        if (!events[date]) {
          data['events'] = result.eventNames;
          data.loading = false;
          // let promises: Promise<boolean>[] = [];
          result.eventNames.forEach((event: any) => {
            event['event_state'] = event['event_state'] || 'PLANNED';
            event['raceType'] = getRaceType(event['external_venue_id'])[
              'raceTypeCode'
            ];
            if (event['raceType'] == 'TH') {
              if (event['venue_name'] == 'Cranbourne') {
                event['venue_name'] = 'Cranbourne Turf';
              }
            }
            event['prepare'] =
              event.kit_id &&
              event.race_track_status &&
              event.rail_status != undefined;
            // event.loading = true;
            event['dateStr'] = moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY');
            // event['isToday'] =
            //   moment(date, 'YYYY-MM-DD').startOf('day').valueOf() ==
            //   moment().startOf('day').valueOf();
          });
          // result.eventNames.sort((a: any, b: any) => {
          //   let a_time: number = moment(a['earliest_start_time'], 'HH:mm:ss').valueOf();
          //   let b_time: number = moment(b['earliest_start_time'], 'HH:mm:ss').valueOf();
          //   return b_time - a_time;
          // })
          // Promise.all(promises).then((values: any) => {
          //   result.eventNames.sort((a: any, b: any) => {
          //     let aFirstRaceTimeMs = null;
          //     if (a?.races?.length > 0) {
          //       aFirstRaceTimeMs = moment(
          //         a.races[0].RaceTime,
          //         'DD-MM-YYYY-HH:mm:ss'
          //       ).valueOf();
          //     }
          //     let bFirstRaceTimeMs = null;
          //     if (b?.races?.length > 0) {
          //       bFirstRaceTimeMs = moment(
          //         b.races[0].RaceTime,
          //         'DD-MM-YYYY-HH:mm:ss'
          //       ).valueOf();
          //     }

          //     return aFirstRaceTimeMs - bFirstRaceTimeMs;
          //   });
          //   resolve(result.eventNames);
          // });
          events[date] = result.eventNames;
        } else {
          data.loading = false;
          data.events = events[date];
          resolve(result.eventNames);
        }
      } else {
        data.loading = false;
        resolve([]);
      }
    });
  }
}
