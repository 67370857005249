<div class="alarm_screen alarm_screen_box">
  <div class="alarm_container">
    <div class="alarm_header">
      <h4 class="alarm_header_text">
        {{ action == "add" ? "Create" : action }} Policy
      </h4>
    </div>
    <div class="alarm_body alarm_padding">
      <div class="row">
        <label
          >Policy Name
          <span class="error-msg" *ngIf="errors['policyName']['error']">
            <i class="fa fa-exclamation-triangle"></i>
            {{ errors["policyName"]["msg"] }}</span
          ></label
        >
        <input
          autocomplete="off"
          *ngIf="action == 'add'"
          type="text"
          class="form-control"
          [(ngModel)]="policy.policyName"
        />
        <input
          autocomplete="off"
          *ngIf="action != 'add'"
          type="text"
          class="form-control"
          [ngModel]="policy.policyName"
          disabled
        />
      </div>
      <div class="row">
        <label for="exampleInputEmail1">Select Race Types</label>
        <div class="flex-start">
          <span class="flex-start pr-2" *ngFor="let raceType of raceTypes">
            <input
              autocomplete="off"
              type="checkbox"
              id="raceType-{{ raceType.name }}"
              (change)="selectRaceType($event, raceType)"
              [checked]="raceType.selected"
            />
            <label for="raceType-{{ raceType.name }}">{{ raceType.name }}</label>
          </span>
        </div>
      </div>
      <div class="row">
        <div class="flex-between">
          <label for="exampleInputEmail1">Select Venues</label>
          <span class="flex-start">
            <input
              type="checkbox"
              id="select-all-venues"
              (change)="selectAllVenues()"
              [attr.selected]="policy?.venueList?.length == venues?.length"
            />
            <label for="select-all-venues">Select All</label>
          </span>
        </div>
        <ng-select
          [items]="venues"
          [multiple]="true"
          bindLabel="VenueName"
          bindValue="ExternalVenueID"
          [(ngModel)]="policy.venueList"
          placeholder="Select Venues"
          [closeOnSelect]="false"
          [searchable]="true"
          [hideSelected]="false"
        >
        </ng-select>
      </div>
      <div class="row">
        <label
          >Services Selected
          <span class="error-msg" *ngIf="errors['menuList']['error']">
            <i class="fa fa-exclamation-triangle"></i>
            {{ errors["menuList"]["msg"] }}</span
          ></label
        >
        <menu-selector
          [edit]="true"
          [menus]="services"
          [selected]="policy['mList']"
        ></menu-selector>
      </div>
      <!-- <div class="row" [ngStyle]="{display: urlPrefix == 'client'? 'block' : 'none'}">
        <label for="exampleInputEmail1">AWS Account List</label>
        <select id="instancesList" class="form-control single-select" multiple data-live-search="true"
          (change)="fetchAccountGroups()">
          <option *ngFor="let account of accounts" value="{{account.accountId}}">{{account.accountId}}
            ({{account.accountName}})</option>
        </select>
        <span class="error-msg" *ngIf="errors['accountId']['error']">
          {{errors['accountId']['msg']}}</span>
      </div> -->
      <!-- <div class="tags-container form-group">
        <div class="container-header flex-between">
          <label for="exampleInputEmail1">Tags</label>
          <div class="flex-start" (click)="tagsEnabled = tagsEnabled ? false : true">
            <input autocomplete="off" type="checkbox" class="pointer-none-class" name="" id=""
              [attr.checked]="!tagsEnabled ? true : null">
            <span>
              All Tags
            </span>
          </div>
        </div>
        <div class="container-body" [ngStyle]="{display: tagsEnabled ? 'block' : 'none'}">
          <div class="account-conntainers" *ngFor="let account of accountDetailsList">
            <div class="account-header">
              {{account.accountId}}
            </div>
            <div class="account-body">
              <ng-container *ngIf="getObjectKeys(account['accountId']).length > 0">
                <div class="account-conntainers"
                  *ngFor="let region of getObjectKeys(tagKeyValuesObj[account['accountId']])">
                  <div class="account-header flex-between">
                    <div>
                      {{region}}
                    </div>
                    <div class="pointer" (click)="removeTagRegion(tagKeyValuesObj[account['accountId']], region)">
                      <i class="fa fa-times"></i>
                    </div>
                  </div>
                  <div class="account-body" *ngIf="getObjectKeys(tagsState[account['accountId']][region]).length > 0">
                    <ng-container *ngFor="let key of getObjectKeys(tagKeyValuesObj[account['accountId']][region])">
                      <div class="flex-start">
                        <select class="single-select" data-live-search="true"
                          (change)="newKeySet(key, $event, account['accountId'], region)">
                          <option value="{{ key }}" selected>{{key}}</option>
                          <option *ngFor="let key_ of keysToSelect(account['accountId'], region)" [value]="key_">{{ key_
                            }}</option>
                        </select>
                        <div>
                          <ng-select [items]="tagsState[account['accountId']][region][key]" [addTag]="true"
                            [virtualScroll]="true" bindLabel="id" bindValue="id" [multiple]="true"
                            placeholder="Select Values"
                            [(ngModel)]="tagKeyValuesObj[account['accountId']][region][key]">
                          </ng-select>
                        </div>
                        <div class="flex-start">
                          <input autocomplete="off" type="checkbox" name="" id=""
                            (click)="selectAllValues($event, account['accountId'], region, key)">
                          <label for="" class="nowrap">Select All</label>
                        </div>
                        <button class="btn btn-light" (click)="removeKeySet(account['accountId'], region, key)">
                          <i class="fa fa-minus"></i>
                        </button>
                        <img src="../assets/img/loading_2.svg" alt="loading scans"
                          [ngStyle]="{display: loadingTags ? 'block' : 'none'}" class="tags_width" />
                      </div>
                    </ng-container>
                    <div class="center" *ngIf="keysToSelect(account['accountId'], region).length > 0">
                      <button class=" btn btn-light" (click)="newKeySet(null, 'FETCH', account['accountId'], region)">
                        <i class="fa fa-plus"></i> Select Tag
                      </button>
                    </div>
                  </div>
                  <div class="account-body center"
                    *ngIf="getObjectKeys(tagsState[account['accountId']][region]).length == 0 && !loadingTags">
                    No Tags Found
                  </div>
                  <div class="account-body center"
                    *ngIf="getObjectKeys(tagsState[account['accountId']][region]).length == 0 && loadingTags">
                    Looking for Tags...
                  </div>
                </div>
              </ng-container>
              <ng-container>
                <div class="flex-start">
                  <select class="form-select"
                    (change)="addTagRegion(tagsState[account['accountId']], account['accountId'], $event)">
                    <option value="-">Select Region</option>
                    <option [value]="region.id" *ngFor="let region of getUnselectedRegion(account['accountId'])">{{
                      region.name }}</option>
                  </select>
                  <img src="../assets/img/loading_2.svg" alt="loading scans"
                    [ngStyle]="{display: loadingTags ? 'block' : 'none'}" class="tags_width" />
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div> -->
      <div class="row" *ngIf="urlPrefix == 'client'">
        <label>Access Type</label>
        <div class="flex-start">
          <input
            autocomplete="off"
            type="radio"
            [(ngModel)]="policy.accessType"
            value="readOnly"
            id="policyAccessReadOnly"
            name="policyAccessType"
          />
          <label for="policyAccessReadOnly">Read Only</label>
        </div>
        <!-- <div class="flex-start">
          <input autocomplete="off" type="radio" [(ngModel)]="policy.accessType" value="readOnly(startstop)"
            id="policyAccessReadOnlyStartStop" name="policyAccessType">
          <label for="policyAccessReadOnlyStartStop">Read Only (Start/Stop)</label>
        </div> -->
        <div class="flex-start">
          <input
            autocomplete="off"
            type="radio"
            [(ngModel)]="policy.accessType"
            value="readandwrite"
            id="policyAccessReadAndWrite"
            name="policyAccessType"
          />
          <label for="policyAccessReadAndWrite">Read and Write</label>
        </div>
      </div>
      <div class="alarm_actions">
        <div class="alarm_div">
          <button
            class="btn alarm_btn"
            (click)="updateEntry()"
            [ngClass]="{ 'click-disabled': !writeAccess }"
            [attr.disabled]="!writeAccess ? true : null"
          >
            {{ action == "add" ? "Create" : "Update" }}
          </button>
          <button class="btn btn-light" (click)="close()">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</div>
