<div class="card mt-3" style="overflow-y: auto;">
    <div class="card-body">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="">Home</a></li>
          <li class="breadcrumb-item"><a href="/dash/kit-management">Kit Management</a></li>
          <li class="breadcrumb-item active" aria-current="page">Kits Overview ({{kitId}})</li>
        </ol>
      </nav>
      <div class="d-flex justify-content-between align-items-center mb-3">
        <h2>Device Overview - {{kitId}}</h2>
        <button type="button" class="btn btn-success" (click)="openAddKit()">Add Device</button>
      </div>
      <div class="table-responsive">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Serial Number</th>
              <th>Tag ID</th>
              <th>Status</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let kit of kitsData">
              <td>{{ kit.SerialNumber }}</td>
              <td>{{ kit.TagID }}</td>
              <td>{{ kit.Status }}</td>
              <td>
                <button type="button" class="btn btn-primary btn-sm" (click)="openKitDetails(kit)">Update Device</button>
              </td>
              <td>
                <button type="button" class="btn btn-primary btn-sm" (click)="openKitHistory(kit)">Add Issue</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  
  <!-- Modal Component -->
  <app-device-history [showModal]="showHistoryModal" [SerialNumber]="selectedKit" (modalClose)="closeHistoryModal()"></app-device-history>
  <app-kit-details-modal 
    [selectedKit]="selectedKit"  
    [kitId]="kitId"  
    [codeType]="codeType"
    [venueState]="venueState"
    [showModal]="showModal" 
    (modalClose)="closeModal()"
  >
  </app-kit-details-modal>
  