import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
  pure: true,
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], filterText: string): any[] {
    if (!items || !filterText) {
      return items;
    }
    filterText = filterText.toLowerCase().trim();

    return items.filter((item: any) => {
      return item.name.toLowerCase().includes(filterText);
    });
  }
}
