import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment-timezone';
import { Subscription } from 'rxjs';
import {
  checkForVenuePermission,
  convertTimestamp,
  getENV,
  getRaceType,
} from 'src/app/_helpers/helpers';
import { APIService } from 'src/app/services/api.service';
import { MqttService } from 'src/app/services/mqtt.service';
import { NotifierService } from 'src/app/services/notifier.service';
import { typeBasedLocations } from '../race-reports/locations';

declare let $: any;
declare let Jhxlsx: any;
declare let window: any;

@Component({
  selector: 'app-on-course-ops-sectionals',
  templateUrl: './on-course-ops-sectionals.component.html',
  styleUrls: [
    './on-course-ops-sectionals.component.css',
    './../on-course-ops-judges/on-course-ops-judges.component.css',
    './../race-reports/race-reports.component.css',
  ],
})
export class OnCourseOpsSectionalsComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  sectional_topic = 'TRACKING/+/+/+/+/SectionTimes';

  config: any;
  constructor(
    private apiService: APIService,
    private notifier: NotifierService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private mqttService: MqttService
  ) {
    this.config = this.activeRoute.snapshot.data['config'];
  }

  date: any = null;
  querySubscription: Subscription;
  params: any = {};
  subs: any = {};
  currentTimeMs: number = 0;
  internal: any = null;
  ngOnInit(): void {
    this.internal = setInterval(() => {
      this.currentTimeMs =
        new Date().getTime() + new Date().getTimezoneOffset() * 60000;
      if (
        this.nextRaceMs['ms'] > 0 &&
        this.currentTimeMs > this.nextRaceMs['ms'] - 30000
      ) {
        this.showNotification(this.nextRaceMs['race']);
        this.loadEntrants(this.nextRaceMs['race'], true);
        let nextRace = this.races.find((race: any) => {
          return (
            race['RaceTimeMs'] > this.currentTimeMs &&
            race['ExternalRaceId'] !== this.nextRaceMs['race']['ExternalRaceId']
          );
        });
        if (nextRace) {
          this.nextRaceMs['ms'] = nextRace['RaceTimeMs'];
          this.nextRaceMs['race'] = nextRace;
        } else {
          this.nextRaceMs['ms'] = 0;
          this.nextRaceMs['race'] = null;
          clearInterval(this.internal);
        }
      }
    }, 1000);
    this.querySubscription = this.activeRoute.queryParams.subscribe(
      (params: any) => {
        if (params.event_id) {
          this.params['event_id'] = params.event_id;
        }
        if (params.race_id) {
          this.params['race_id'] = params.race_id;
        }
        if (params.date) {
          this.date = params.date;
        }
      }
    );
  }

  connect(race: any) {
    if (
      !(
        race.RaceState.toUpperCase() == 'PLANNED' ||
        race.RaceState.toUpperCase() == 'RUNNING' ||
        race.RaceState.toUpperCase() == 'RACING'
      )
    ) {
      return;
    }
    this.subs[race.ExternalRaceId] = {
      ExternalVenueId: race.ExternalVenueId,
      ExternalEventId: race.ExternalEventId,
      ExternalRaceId: race.ExternalRaceId,
    };
    this.mqttService.subscribe(
      `TRACKING/+/${race.ExternalVenueId}/${race.ExternalEventId}/${race.ExternalRaceId}/SectionTimes`
    );
    this.mqttService.client.on('message', (topic: string, payload: Buffer) => {
      const message = JSON.parse(payload.toString());
      if (message.ExternalRaceId != race.ExternalRaceId) {
        return;
      }
      if (topic.includes('SectionTimes')) {
        race['RaceState'] = 'RUNNING';
        let animal = race['entrants'].find((animal: any) => {
          return animal['ExternalAnimalID'] == message['ExternalAnimalId'];
        });
        if (animal) {
          if (!animal['sections']) {
            animal['sections'] = [];
          }
          if (!animal['sectionsCompleted']) {
            animal['sectionsCompleted'] = [];
          }
          if (!race['raceHeaders']) {
            race['raceHeaders'] = [];
          }
          if (!race['raceHeadersMain']) {
            race['raceHeadersMain'] = [];
          }
          const raceLength = parseInt(race['RaceLength']);
          message['SectionItemSet'].forEach((section: any) => {
            animal['Rank'] = section['Rank'];
            section['SectionTime'] = section['SectionTime'].replace('.', ':');
            section['IntermediateTime'] = section['IntermediateTime'].replace(
              '.',
              ':'
            );
            let sectionName: string = '';
            let [hours, minutes, seconds, milliseconds] =
              section['SectionTime'].split(':');
            const sectionTime = moment
              .duration({
                hours: parseInt(hours),
                minutes: parseInt(minutes),
                seconds: parseInt(seconds),
                milliseconds: parseInt(milliseconds.substring(0, 3)),
              })
              .asMilliseconds();

            [hours, minutes, seconds, milliseconds] =
              section['IntermediateTime'].split(':');
            const intermediateTime = moment
              .duration({
                hours: parseInt(hours),
                minutes: parseInt(minutes),
                seconds: parseInt(seconds),
                milliseconds: parseInt(milliseconds.substring(0, 3)),
              })
              .asMilliseconds();

            if (getRaceType(race['ExternalVenueId'])['raceTypeCode'] == 'TH') {
              const sectionNumber = parseInt(
                section['SectionName'].replace('m', '')
              );
              if (animal['sectionsCompleted'].indexOf(sectionNumber) > -1) {
                return;
              }
              if (animal['sections'].length == 0) {
                sectionName = `Start - ${raceLength - sectionNumber}m`;
              } else if (sectionNumber < raceLength) {
                sectionName = `${raceLength - sectionNumber + 200}m - ${
                  raceLength - sectionNumber
                }m`;
              } else {
                sectionName = `${raceLength - sectionNumber + 200}m - Finish`;
              }
              if (race['raceHeaders'].indexOf(sectionName) < 0) {
                race['raceHeaders'].push(sectionName);
              }
              let section_ = animal['sections'].find((section_: any) => {
                return section_['sectionName'] == sectionName;
              });
              if (!section_) {
                animal['sections'].push({
                  sectionName: sectionName,
                  sectionTime: moment.utc(sectionTime).format('m:ss.SS'),
                  sectionTimeMs: sectionTime,
                  intermediateTime: moment
                    .utc(intermediateTime)
                    .format('m:ss.SS'),
                  intermediateTimeMs: intermediateTime,
                });
                animal['sectionsCompleted'].push(sectionNumber);
              }
            } else if (
              getRaceType(race['ExternalVenueId'])['raceTypeCode'] == 'HR'
            ) {
              const TimingLineId = section['TimingLineId'];
              if (TimingLineId != undefined) {
                sectionName = (function () {
                  if (TimingLineId == 0) {
                    return `Lead Time`;
                  } else if (TimingLineId == 1) {
                    return `1st Quarter`;
                  } else if (TimingLineId == 2) {
                    return `2nd Quarter`;
                  } else if (TimingLineId == 3) {
                    return `3rd Quarter`;
                  } else {
                    return `4th Quarter`;
                  }
                })();
                if (animal['sectionsCompleted'].indexOf(sectionName) > -1) {
                  return;
                }
                if (race['raceHeaders'].indexOf(sectionName) < 0) {
                  race['raceHeaders'].push(sectionName);
                }
                let section_ = animal['sections'].find((section_: any) => {
                  return section_['sectionName'] == sectionName;
                });
                // section_['sectionTimeMs'] = sectionTime;
                // section_['intermediateTimeMs'] = intermediateTime;
                // section_['sectionTime'] = moment
                //   .utc(section_['sectionTimeMs'])
                //   .format('m:ss.SS');
                // section_['intermediateTime'] = moment
                //   .utc(section_['intermediateTimeMs'])
                //   .format('m:ss.SS');
                if (!section_) {
                  sectionName = section['SectionName'];
                  animal['sections'].push({
                    sectionName: sectionName,
                    sectionTime: moment.utc(sectionTime).format('m:ss.SS'),
                    sectionTimeMs: sectionTime,
                    intermediateTime: moment
                      .utc(intermediateTime)
                      .format('m:ss.SS'),
                    intermediateTimeMs: intermediateTime,
                  });
                  animal['sectionsCompleted'].push(sectionName);
                }
              }
            } else {
              animal['sections'].push({
                sectionName: sectionName,
                sectionTime: moment.utc(sectionTime).format('m:ss.SS'),
                sectionTimeMs: sectionTime,
                intermediateTime: moment
                  .utc(intermediateTime)
                  .format('m:ss.SS'),
                intermediateTimeMs: intermediateTime,
              });
            }
          });
        }
        let firstEntrant = race['entrants'][0];
        if (firstEntrant['sections']) {
          let firstEntrantLastSection: any =
            firstEntrant['sections'][firstEntrant['sections'].length - 1];
          let section = race['raceHeadersMain'].find((section_: any) => {
            return (
              section_['sectionName'] == firstEntrantLastSection['sectionName']
            );
          });
          if (section) {
            section = {
              ...section,
              ...{
                sectionTime: firstEntrantLastSection.sectionTime,
                sectionTimeMs: firstEntrantLastSection.sectionTimeMs,
                intermediateTime: firstEntrantLastSection.intermediateTime,
                intermediateTimeMs: firstEntrantLastSection.intermediateTimeMs,
              },
            };
          } else {
            race['raceHeadersMain'].push({
              sectionName: firstEntrantLastSection.sectionName,
              sectionTime: firstEntrantLastSection.sectionTime,
              sectionTimeMs: firstEntrantLastSection.sectionTimeMs,
              intermediateTime: firstEntrantLastSection.intermediateTime,
              intermediateTimeMs: firstEntrantLastSection.intermediateTimeMs,
            });
          }
        }
      }
    });
  }

  disconnect(race_id: string = null) {
    if (race_id) {
      if (this.subs[race_id]) {
        this.mqttService.unsubscribe(
          `TRACKING/+/${this.subs[race_id].ExternalVenueId}/${this.subs[race_id].ExternalEventId}/${this.subs[race_id].ExternalRaceId}/SectionTimes`
        );
        delete this.subs[race_id];
      }
    } else {
      Object.keys(this.subs).forEach((key: any) => {
        this.mqttService.unsubscribe(
          `TRACKING/+/${this.subs[key].ExternalVenueId}/${this.subs[key].ExternalEventId}/${this.subs[key].ExternalRaceId}/SectionTimes`
        );
        delete this.subs[this.subs[key]];
      });
    }
  }

  showNotification(race: any) {
    if (Notification.permission === 'granted') {
      const notification = new Notification(`${this.selected['venue_name']}`, {
        body: `Upcoming Race #${race.SquentialRaceOrderNumber} ${race.RaceName} is starting in 30 seconds!`,
        icon: `https://tsd-prd01-logos.s3.ap-southeast-2.amazonaws.com/venues/${this.selected['external_venue_id']}.png`, // Add an appropriate icon path
      });

      notification.onclick = () => {
        window.focus();
      };
    }
  }

  getOverallTime(race: any, entrant): string {
    if (!entrant.sections || entrant.sections.length == 0) return '-';
    let totalmilliseconds: number = 0;
    entrant.sections.forEach((section: any) => {
      totalmilliseconds += section.sectionTimeMs;
    });

    return moment.utc(totalmilliseconds).format('m:ss.SS');
  }

  convertTimeStamp(time: string) {
    const [hours, minutes, seconds, milliseconds] = time.split(':');
    const totalMinutes = parseInt(hours) * 60 + parseInt(minutes);
    return `${totalMinutes
      .toString()
      .padStart(2, '0')}:${seconds}:${milliseconds}`;
  }

  requestNotificationPermission() {
    if (Notification.permission !== 'granted') {
      Notification.requestPermission();
    }
  }

  async ngAfterViewInit(): Promise<void> {
    this.requestNotificationPermission();
    this.date = this.date || moment().format('DD MMMM YYYY');
    $('.datetimepicker').val(this.date);
    $('.datetimepicker')
      .datepicker({
        autoclose: true,
        // minViewMode: 1,
        format: 'dd MM yyyy',
        orientation: 'bottom auto',
        endDate: moment().add(2, 'days').toDate(),
      })
      .on('changeDate', (selected: any) => {
        this.date = $('.datetimepicker').val();
        this.fetchEvents();
      });

    setTimeout(() => {
      this.fetchEvents();
    }, 100);
  }

  selected: any = null;
  locations: any = [];
  races: any = [];
  async fetchEvents() {
    return new Promise(async (resolve: any) => {
      this.nextRaceMs = {
        ms: 0,
        race: null,
      };
      this.notifier.loading(true);
      this.selected = null;
      this.locations = [];
      this.races = [];
      const date = this.date
        ? moment(this.date, 'DD MMMM YYYY').format('YYYY-MM-DD')
        : moment().format('YYYY-MM-DD');
      let raceTypes: any = localStorage.getItem('raceTypes');
      if (raceTypes) {
        raceTypes = JSON.parse(raceTypes);
      }
      let apiURL: string = `${
        this.config[getENV()].raceAPI
      }/event-list?formatted_date=${date}`;

      if(raceTypes && raceTypes.length > 0) {
        apiURL += `&race_types=${raceTypes.join(',')}`;
      }

      let result: any = await this.apiService.getDataPromis(apiURL, {}, {});

      if (result.eventNames && result.eventNames.length > 0) {
        result.eventNames = result.eventNames.filter((event: any) => {
          return checkForVenuePermission(event['external_venue_id']);
        });
        let resultEventNames = result.eventNames;

        resultEventNames.forEach((event: any, index: number) => {
          event['operator'] = null;
          let raceType = getRaceType(event['external_venue_id'])['raceTypeStr'];

          if (
            raceType == 'Thoroughbred Racing' &&
            event['venue_name'] == 'Cranbourne'
          ) {
            event['venue_name'] = 'Cranbourne Turf';
          }

          const jurisdiction = (typeBasedLocations[raceType] as any).find(
            (j: any) => {
              let location: any = j.Locations.find((location: any) => {
                return (
                  location.Name.toLowerCase() ==
                  event['venue_name'].toLowerCase()
                );
              });
              if (location) {
                event['type'] = location.type;
              }
              return location;
            }
          );

          if (jurisdiction) {
            event['State'] = jurisdiction.State;
            event['JurisdictionCode'] = jurisdiction.JurisdictionCode;
            event['RaceType'] = raceType;
            event['date'] = date;
          }

          event['name'] = event['venue_name'];
          event['id'] = event['external_event_id'];
          // this.loadEvent(event, index);
        });

        this.locations = resultEventNames;
        if (this.params.event_id) {
          const selectedEvent = this.locations.find(
            (event: any) => event.external_event_id === this.params.event_id
          );
          if (selectedEvent) {
            this.selectEvent(selectedEvent);
          } else {
            this.notifier.loading(false);
          }
        } else {
          this.notifier.loading(false);
        }
      } else {
        this.locations = [];
        this.notifier.loading(false);
      }
      resolve(true);
    });
  }

  async loadHistoricalSectionalData(race: any) {
    let apiURL: string = `${
      this.config[getENV()].raceAPI
    }/sectional?ExternalRaceId=${race.ExternalRaceId}`;
    let result: any = await this.apiService.postDataPromis(apiURL, {}, {});

    const raceType = getRaceType(race['ExternalVenueId'])['raceTypeCode'];

    if (Array.isArray(result)) {
      race['raceHeaders'] = [];
      race['raceHeadersMain'] = [];

      result = result.filter((section: any) => {
        return section['SRank'];
      })

      const groupedSections = result.reduce((acc, section) => {
        if (!acc[section.ExternalAnimalID]) {
          acc[section.ExternalAnimalID] = [];
        }
        acc[section.ExternalAnimalID].push(section);
        return acc;
      }, {});

      const raceLength = parseInt(race['RaceLength']);

      race.entrants
        .filter((entrant: any) => entrant.RaceState.toUpperCase() != 'DNS')
        .forEach((entrant: any) => {
          entrant['sections'] = [];
          entrant['sectionsCompleted'] = [];
          let sections: any = groupedSections[entrant.ExternalAnimalID] || [];
          if(raceType == 'TH') {
            sections.sort((a: any, b: any) => {
              let sec_a: number = Number(a.SectionName.replace('m', ''));
              let sec_b: number = Number(b.SectionName.replace('m', ''));
              return sec_b - sec_a;
            });
          } else {
            sections.sort((a: any, b: any) => {
              let sec_a: number = a.SegmentNumber;
              let sec_b: number = b.SegmentNumber;
              return sec_a - sec_b;
            });
          }

          entrant['Rank'] = sections[sections.length - 1]?.SRank || 0;

          sections.forEach((section: any) => {
            section['SectionTime'] = section['SectionTime']
              .replace('.', ':')
            section['IntermediateTime'] = section['IntermediateTime']
              .replace('.', ':')

            let [hours, minutes, seconds, milliseconds] =
              section['SectionTime'].split(':');
            milliseconds = milliseconds.slice(0, 3)

            const sectionTime = moment
              .duration({ hours, minutes, seconds, milliseconds })
              .asMilliseconds();

            let [intHours, intMinutes, intSeconds, intMilliseconds] =
              section['IntermediateTime'].split(':');
            intMilliseconds = intMilliseconds.slice(0, 3);
            const intermediateTime = moment
              .duration({
                hours: intHours,
                minutes: intMinutes,
                seconds: intSeconds,
                milliseconds: intMilliseconds,
              })
              .asMilliseconds();

            let sectionName: string = '';

            if (raceType === 'TH') {
              const sectionNumber = parseInt(
                section['SectionName'].replace('m', '')
              );
              if (sectionNumber < 0) {
                return;
              }
              if (entrant['sectionsCompleted'].indexOf(sectionNumber) > -1) {
                return;
              }
              if (entrant['sections'].length == 0) {
                sectionName = `Start - ${sectionNumber}m`;
              } else if (sectionNumber > 0) {
                sectionName = `${sectionNumber + 200}m - ${
                  (sectionNumber)
                }m`;
              } else {
                sectionName = `200m - Finish`;
              }
              entrant['sectionsCompleted'].push(sectionNumber);
            } else if (raceType === 'HR') {
              const SegmentNumber = section['SegmentNumber'];
              if (SegmentNumber != undefined) {
                sectionName = (function () {
                  if (SegmentNumber == 0) return `Lead Time`;
                  else if (SegmentNumber == 1) return `1st Quarter`;
                  else if (SegmentNumber == 2) return `2nd Quarter`;
                  else if (SegmentNumber == 3) return `3rd Quarter`;
                  else return `4th Quarter`;
                })();
                if (entrant['sectionsCompleted'].indexOf(sectionName) > -1) {
                  return;
                }
                entrant['sectionsCompleted'].push(sectionName);
              }
            } else {
              sectionName = section['SectionName'];
            }

            if (race['raceHeaders'].indexOf(sectionName) < 0) {
              race['raceHeaders'].push(sectionName);
            }

            entrant['sections'].push({
              sectionName: sectionName,
              sectionTime: moment.utc(sectionTime).format('m:ss.SS'),
              sectionTimeMs: sectionTime,
              intermediateTime: moment.utc(intermediateTime).format('m:ss.SS'),
              intermediateTimeMs: intermediateTime,
              averageSpeed: section['AverageSpeed'],
              topSpeed: section['TopSpeed'],
            });

          });
        });

      if (race.entrants.length > 0) {
        let entrants = [...race['entrants']]

        race['raceHeaders'].forEach((header: any, index: number) => {
          let sectionals = entrants.filter((entrant: any) => {
            return entrant.sections && entrant.sections.length > 0;
          }).map((entrant: any) => {
            return entrant.sections[index];
          })
          let lowestSection = sectionals.sort((a: any, b: any) => {
            return (a?.sectionTimeMs || 0) - (b?.sectionTimeMs || 0);
          })[0];
          race['raceHeadersMain'].push({
            sectionName: lowestSection.sectionName,
            sectionTime: lowestSection.sectionTime,
            sectionTimeMs: lowestSection.sectionTimeMs,
            intermediateTime: lowestSection.intermediateTime,
            intermediateTimeMs: lowestSection.intermediateTimeMs,
          })
          // let firstEntrant = entrants.sort((a: any, b: any) => {
          //   const aSection = a.sections[index];
          //   const bSection = b.sections[index];
          //   return (aSection?.sectionTimeMs || 0) - (bSection?.sectionTimeMs || 0);
          // })[0];

          // let section = firstEntrant['sections'].find((s: any) => s.sectionName === header);

          // race['raceHeadersMain'].push({
          //   sectionName: section.sectionName,
          //   sectionTime: section.sectionTime,
          //   sectionTimeMs: section.sectionTimeMs,
          //   intermediateTime: section.intermediateTime,
          //   intermediateTimeMs: section.intermediateTimeMs,
          // });

        });
        entrants.forEach((entrant: any) => {
          if (
            entrant.sections && entrant.sections.length < race['raceHeaders'].length
          ) {
            entrant['Rank'] = '-';
            while (entrant['sections'].length < race['raceHeaders'].length) {
              entrant['sections'].push({
                sectionName: '-',
                sectionTime: '-',
                sectionTimeMs: 0,
                intermediateTime: '-',
                intermediateTimeMs: 0,
                averageSpeed: '-',
                topSpeed: '-',
              });
            }
          }
        });
      }
    }
  }

  nextRaceMs: { ms: number; race: any } = {
    ms: 0,
    race: null,
  };
  async selectEvent(event: any) {
    this.disconnect();
    this.notifier.loading(true);

    this.router.navigate(['/dash/on-course-ops/sectionals'], {
      queryParams: { event_id: event['external_event_id'], date: this.date },
    });
    this.selected = event;

    // this.loadEvent(event.external_event_id);

    const apiURL = `${
      this.config[getENV()].raceAPI
    }/getracedetails?ExternalVenueId=${
      event.external_venue_id
    }&ExternalEventId=${event.external_event_id}`;

    const result = await this.apiService.getDataPromis(apiURL, {}, {});

    if (result && Array.isArray(result) && result.length > 0) {
      result.sort(
        (a, b) => a.SquentialRaceOrderNumber - b.SquentialRaceOrderNumber
      );

      result.forEach((race: any) => {
        race['RaceTimeMs'] = moment(
          race['RaceTime'],
          'YYYY-MM-DD-HH:mm:ss'
        ).valueOf();
        race['statusSelected'] = '';
        race['RaceState'] = race['RaceState']
          ? race['RaceState'].toUpperCase()
          : 'PLANNED';
        if (
          (race['RaceTimeMs'] > this.currentTimeMs ||
            race['RaceState'] == 'PLANNED') &&
          this.nextRaceMs['ms'] == 0
        ) {
          this.nextRaceMs['ms'] = race['RaceTimeMs'];
          this.nextRaceMs['race'] = race;
        }

        race['eventName'] = event.name;
        race['raceStatus'] = race['RaceState']?.toUpperCase() || 'PLANNED';
        race['RaceTimeStr'] = race['RaceTime'];
        race['RaceTime'] = convertTimestamp(
          race['RaceTime'],
          event['venue_state']
        );

        race['queryParamsVenue'] = {
          event_id: race['ExternalEventId'],
          venue_id: race['ExternalVenueId'],
          date: race['eventDate'],
          event_name: race['eventName'],
        };

        race['queryParamsRace'] = {
          race_id: race['ExternalRaceId'],
        };

        race[
          'name'
        ] = `#${race['SquentialRaceOrderNumber']} ${race['RaceName']}`;
        race['id'] = race['SquentialRaceOrderNumber'];
      });

      this.races = result;

      // this.getRaceResult(result);

      this.notifier.loading(false);
    }
  }

  async loadEntrants(race: any, force: boolean = false) {
    if (race.expanded && !force) {
      race.expanded = false;
      this.disconnect(race.ExternalRaceId);
      return;
    }
    if (race.expanded && force) {
      this.disconnect(race.ExternalRaceId);
    }
    race.expanded = true;
    if (race.entrants && race.entrants.length > 0) {
      this.connect(race);
      return;
    }
    race.loading = true;
    let apiURL: string = `${
      this.config[getENV()].raceAPI
    }/race-entrant-details?ExternalRaceId=${
      race.ExternalRaceId
    }&ExternalEventId=${race.ExternalEventId}`;

    let result: any = await this.apiService.getDataPromis(apiURL, {}, {});

    if (typeof result == 'string') {
      race.error = result;
      race.entrants = [];
      return;
    }
    race.entrants = result.entrants || [];
    this.connect(race);

    race.entrants.forEach((horse: any) => {
      horse['RaceState'] = horse['RaceState']
        ? horse['RaceState'].toUpperCase()
        : 'PLANNED';
      horse['RaceStateNew'] = horse['RaceState'];
      horse['imagePath'] = (() => {
        if (result.code_type === 'HARNESS') {
          return `https://tsd-prd01-silks.s3.ap-southeast-2.amazonaws.com/Harness_Racing_Australia/${horse.StartNumber}.png`;
        } else if (this.selected.external_venue_id.includes('STC')) {
          return `https://tsd-prd01-silks.s3.ap-southeast-2.amazonaws.com/singapore/${horse.ExternalSilkID}.png`;
        } else if (this.selected.external_venue_id.startsWith('GR')) {
          return `https://tsd-prd01-silks.s3.ap-southeast-2.amazonaws.com/Greyhounds/${horse.StartNumber}.png`;
        } else {
          return `https://tsd-prd01-silks.s3.ap-southeast-2.amazonaws.com/Racing_Australia/${horse.ExternalSilkID}.png`;
        }
      })();
    });
    this.loadHistoricalSectionalData(race);
    race.loading = false;
  }

  export(event: any, race: any) {
    event.stopPropagation();
    let data: any = {
      sheetName: `${race.ExternalEventId}-${race.ExternalRaceId}`,
      data: [],
    };

    data['data'].push([
      { text: 'Rank' },
      { text: 'SCN' },
      { text: 'Barrier/Box' },
      { text: 'Animal Name' },
      ...race.raceHeadersMain.map((h: any) => {
        return {
          text: `${h.sectionName} ${h.intermediateTime} (${h.sectionTime})`,
        };
      }),
      { text: 'Overall Time' },
    ]);

    race.entrants.forEach((horse: any) => {
      data['data'].push([
        { text: horse.Rank || '-' },
        { text: horse.StartNumber },
        { text: horse.BarrierNumber },
        { text: horse.AnimalName },
        ...(horse.sections
          ? horse.sections.map((h: any) => {
              return {
                text:
                  horse.RaceState.toUpperCase() != 'DNS'
                    ? h['sectionTime'] || '-'
                    : '-',
              };
            })
          : race['raceHeaders'].map((h: any) => {
              return { text: '-' };
            })),
        {
          text:
            horse.RaceState.toUpperCase() != 'DNS'
              ? this.getOverallTime(race, horse) || '-'
              : '-',
        },
      ]);
    });

    var options = {
      fileName: `${race.ExternalEventId}-${race.ExternalRaceId}`,
    };

    Jhxlsx.export([data], options);
  }

  ngOnDestroy(): void {
    this.querySubscription.unsubscribe();
    this.disconnect();
    clearInterval(this.internal);
  }
}
