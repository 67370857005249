import { Component, OnDestroy, OnInit, ChangeDetectorRef } from "@angular/core";
import { HttpEvent, HttpEventType } from "@angular/common/http";

import * as moment from "moment";
import { FileUploadService } from "src/app/services/fileupload.service";
import { APIService } from "src/app/services/api.service";
import { NotifierService } from "src/app/services/notifier.service";

declare let flatpickr: any;
declare let window: any;
@Component({
  templateUrl: "./feedback.component.html",
  styleUrls: ["./feedback.component.css"],
})
export class feedbackComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem("eId");
  ut = localStorage.getItem("ut");
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  writeAccess: boolean =
    localStorage.getItem("acT") == "readandwrite" ||
    this.urlPrefix == "admin" ||
    localStorage.getItem("ut") == "admin";
  loading = true;
  showCreateShedule = false;
  showLog = false;

  funRef: any = {};
  feedbackType = "Support";
  notes = "";

  public regions = [
    { id: "ap-south-1", name: "Asia Pacific (Mumbai) ap-south-1" },
    { id: "us-east-1", name: "US East (N. Virginia) us-east-1" },
    { id: "us-east-2", name: "US East (Ohio) us-east-2" },
    { id: "us-west-1", name: "US West (N. California) us-west-1" },
    { id: "us-west-2", name: "US West (Oregon)us-west-2" },
    { id: "ap-northeast-3", name: "Asia Pacific (Osaka-Local) ap-northeast-3" },
    { id: "ap-northeast-2", name: "Asia Pacific (Seoul) ap-northeast-2" },
    { id: "ap-southeast-1", name: "Asia Pacific (Singapore) ap-southeast-1" },
    { id: "ap-southeast-2", name: "Asia Pacific (Sydney) ap-southeast-2" },
    { id: "ap-northeast-1", name: "Asia Pacific (Tokyo) ap-northeast-1" },
    { id: "ca-central-1", name: "Canada (Central) ca-central-1" },
    { id: "eu-central-1", name: "Europe (Frankfurt) eu-central-1" },
    { id: "eu-west-1", name: "Europe (Ireland) eu-west-1" },
    { id: "eu-west-2", name: "Europe (London) eu-west-2" },
    { id: "eu-west-3", name: "Europe (Paris) eu-west-3" },
    { id: "eu-north-1", name: "Europe (Stockholm) eu-north-1" },
    { id: "me-south-1", name: "Middle East (Bahrain) me-south-1" },
    { id: "sa-east-1", name: "South America (São Paulo) sa-east-1" },
    { id: "ap-east-1", name: "Asia Pacific (Hong Kong) ap-east-1" },
    { id: "af-south-1", name: "Africa (Cape Town) af-south-1" },
    { id: "ap-southeast-3", name: "Asia Pacific (Jakarta) ap-southeast-3" },
    { id: "eu-south-1", name: "Europe (Milan) eu-south-1" },
  ];
  public selectedRegion = "Asia Pacific (Mumbai) ap-south-1";
  public scheduleMaps: any;

  public logShData: any;
  public logData: any;
  public otpModal = false;
  public otpValue = "";

  public cancelOTP = false;
  public otpValid = false;
  public otpValided = false;
  public optErrorMSG = "";
  public otpPurpose = "";
  feedbackFile: any;
  feedbackFileBase64Data: any;

  constructor(
    private apiServer: APIService,
    public notifier: NotifierService,
    private cdRef: ChangeDetectorRef,
    private fileUploadService: FileUploadService
  ) {}

  ngOnInit(): void {
    this.funRef = {
      //showData: this.showData,
    };

    setTimeout(() => {
      flatpickr(".datetimepicker", { dateFormat: "d-M-Y" });

      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }, 500);
  }

  changeInput(event: any) {
    event.preventDefault();
    this.handleFiles(event.target.files);
  }

  uploadFiles: any = [];
  uploadFilesMeta: any = [];
  handleFiles(files: any = []) {
    this.uploadFiles = [...files];
    this.uploadFilesMeta = this.uploadFiles.map((file: File) => {
      return { ...file };
    });
  }

  submitFiles() {
    this.notifier.loading(true);
    if(this.uploadFiles.length > 0) {
      this.uploadFiles.forEach((file: any, index: number) => {
        this.uploadFilesMeta[index]["status"] = "uploading";
        this.uploadFilesMeta[index]["loaded"] = 0;
        this.uploadFilesMeta[index]["total"] = file["size"];
        this.generatePresignedUrl(file, index);
      });
    } else {
      this.saveFeedback();
    }
  }

  removeFile(index: number) {
    this.uploadFiles.splice(index, 1);
    this.uploadFilesMeta.splice(index, 1);
  }

  async saveFeedback(fileResponse: any = null, index: number = 0) {
    if (!this.notes.trim()) {
      alert("Please Enter Your Request");
      this.notifier.loading(false);
      return;
    }
    let data = {
      //action: "list_ec2_instances",
      //account_id: this.accountId,
      //region_id: this.regionId,

      a: "save",
      uid: localStorage.getItem("clientId"),
      eid: localStorage.getItem("eId"),
      name: localStorage.getItem("un"),
      type: this.feedbackType,
      notes: this.notes,
      fileName: fileResponse ? fileResponse['fileName'] : undefined,
    };

    let header = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/users/feedback`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);
    if (result.s == "1" || result.status == "1") {
      this.notes = '';
      let element: any = document.querySelector('#feedbackFile');
      element.value = '';
      this.uploadFiles = [];
      this.notifier.alert("Success", '', 'Thank you for your valuable feedback', 'success', 5000);
    } else {
      this.notifier.alert("Error", "", result.msg, "error", 5000);
    }

    this.notifier.loading(false);
  }


  async generatePresignedUrl(file: File, index: number) {
    let data: any = {
      a: "generatePreUrl",
      fileName: file['name']
    };

    let header: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/users/feedback`;

    let result: any = await this.apiServer.postDataPromis(
      apiURL,
      data,
      header
    );

    if (result.s == "1" || result.status == "1") {
      await this.uploadFile(file, result, index);
    }
  }

  async uploadFile(file: File, result: any, index: number) {
    await this.uploadFileAPI(result, file, this.uploadFilesMeta[index], index);
  }

  async uploadFileAPI(url: any, file: any, meta: any, index: number) {
    return new Promise(async (resolve: any, reject: any) => {
      this.fileUploadService
        .uploadFile(url["preUrl"], file)
        .subscribe(async (event: HttpEvent<any>) => {
          switch (event.type) {
            case HttpEventType.Sent:
              break;
            case HttpEventType.ResponseHeader:
              break;
            case HttpEventType.UploadProgress:
              meta["status"] = "uploading";
              let progress = Math.round((event.loaded / (event.total || 0)) * 100);
              meta.loaded = event.loaded;
              meta.percentage = progress;
              break;
            case HttpEventType.Response:
              await this.saveFeedback(url, index);
              resolve(true);
          }
        });
    });
  }

  ngOnDestroy(): void {
  }
}
