<div id="popup" class="popup">
  <div class="popup-container">
    <div class="popup-header flex-between">
      <span class="color-inherit">
        {{ title }}
      </span>
      <span class="cursor-pointer color-inherit" (click)="hideModel.emit(false)" *ngIf="closeTitle">
        <i class="fa fa-times color-inherit"></i>
      </span>
    </div>
    <div class="popup-content">
      <div class="popup-body">
        <ng-content select="main-body"></ng-content>
        <div class="center mt-2 font-size-1-2">
          {{ message }}
        </div>
      </div>
      <div class="popup-buttons flex-center">
        <ng-content select="email-indicator"></ng-content>
        <ng-container *ngIf="!buttons">
          <button
            id="continueBtn"
            class="btn btn-primary-soft flex-start"
            (click)="hideModel.emit(true)"
          >
            Yes
          </button>
          <button
            id="logoutBtn"
            class="btn btn-light"
            (click)="hideModel.emit(false)"
          >
            Cancel
          </button>
        </ng-container>
        <ng-container *ngIf="buttons">
          <button
            id="continueBtn"
            class="btn btn-primary-soft flex-start"
            (click)="hideModel.emit(true)"
            *ngIf="buttons.includes('yes')"
          >
            Yes
          </button>
          <button
            id="continueBtn"
            class="btn btn-primary-soft flex-start"
            (click)="hideModel.emit(true)"
            *ngIf="buttons.includes('confirm')"
          >
            Confirm
          </button>
          <button
            id="logoutBtn"
            class="btn btn-light"
            (click)="hideModel.emit(false)"
            *ngIf="buttons.includes('no')"
          >
            No
          </button>
          <button
            id="logoutBtn"
            class="btn btn-light"
            (click)="hideModel.emit(null)"
            *ngIf="buttons.includes('close')"
          >
            Close
          </button>
        </ng-container>
      </div>
    </div>
  </div>
</div>
