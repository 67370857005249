import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { getENV } from 'src/app/_helpers/helpers';
import { APIService } from 'src/app/services/api.service';
import { NotifierService } from 'src/app/services/notifier.service';


@Component({
  selector: 'app-delete-user',
  templateUrl: './delete-policy.component.html',
  styleUrls: ['./delete-policy.component.css'],
})
export class DeleteUserComponent implements OnInit {
  @Input('user') user: any;
  @Output('hideModel') hideModel: any = new EventEmitter<boolean>();
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  role: string | null = localStorage.getItem('role');
  urlPrefix: string | null = null;
  config: any;

  constructor(
    private apiService: APIService,
    private notifier: NotifierService,
    private route: ActivatedRoute
  ) {
    this.config = this.route.snapshot.data['config'];
    if (this.role == 'Admin') {
      this.urlPrefix = 'admin';
    }
    if (this.role == 'Client') {
      this.urlPrefix = 'client';
    }
    if (this.role == 'Superadmin') {
      this.urlPrefix = 'sa';
    }
  }

  ngOnInit(): void { }

  async deleteUser() {
    this.notifier.loading(true);
    let data: any = {
      a: 'deleteUser',
      email: this.user.email,
      userType: 'admin',
    };

    let header: any = {
      Authorization: localStorage.getItem('t'),
    };

    let apiURL: string;
    if (this.urlPrefix == 'admin') {
      apiURL = `${this.config[getENV()].apiURL}/${this.urlPrefix}/Settings/usermanagement`;
    } else {
      apiURL = `${this.config[getENV()].apiURL}/${this.urlPrefix}/Settings/usermanagement`;
    }

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.notifier.alert(
        'Success',
        '',
        'User deleted successfully',
        'success',
        5000
      );
      this.hideModel.emit(true);
    } else {
      this.notifier.alert('Info', '', result.msg || result.error, 'info', 5000);
    }

    this.notifier.loading(false);
  }
}
