import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';

const sydneyTimeZone = 'Australia/Sydney';

@Pipe({
  name: 'raceFilter',
  pure: true,
})
export class RacesPipe implements PipeTransform {
  transform(items: any[], event_name: any, status: any, time?: any): any[] {
    if (event_name.length == 1 && status.length == 1 && time[0] == 'ALL') {
      if (event_name[0] == 'ALL' && status[0] == 'ALL') {
        return items;
      }
    }
    return items.filter((item: any) => {
      let event_check: boolean = false;
      let status_check: boolean = false;
      let time_check: boolean = false;
      if (event_name.length > 0) {
        if (event_name[0] == 'ALL') {
          event_check = true;
        } else {
          event_check = event_name.includes(item.eventName);
        }
      }

      time_check =
        time == 'ALL' ||
        (function () {
          let [hours, minutes] = item['RaceTime'].split('H');
          let localRaceStartTime: number = moment()
            .tz(sydneyTimeZone)
            .startOf('day')
            .add(hours, 'hours')
            .add(minutes, 'minutes')
            .valueOf();
          let selectedValue: number;
          let selectedName: string = time[0];

          if (selectedName == '10 mins') {
            selectedValue = moment()
              .tz(sydneyTimeZone)
              .add(10, 'minutes')
              .valueOf();
          } else if (selectedName == '30 mins') {
            selectedValue = moment()
              .tz(sydneyTimeZone)
              .add(30, 'minutes')
              .valueOf();
          } else {
            selectedValue = moment()
              .tz(sydneyTimeZone)
              .add(1, 'hours')
              .valueOf();
          }

          let currentTimeValue: number = moment().tz(sydneyTimeZone).valueOf();

          if (
            localRaceStartTime > currentTimeValue &&
            localRaceStartTime < selectedValue
          ) {
            return true;
          } else {
            return false;
          }
        })();

      if (status.length > 0) {
        if (status[0] == 'ALL') {
          status_check = true;
        } else {
          status_check =
            status.includes(item.raceStatus) ||
            (function () {
              let horse_status: string[] = item['AnimalDetail'].map(
                (state: any) => {
                  return state['RaceState'];
                }
              );
              return (
                status.filter((element: any) => horse_status.includes(element))
                  .length > 0
              );
            })();
        }
      }
      return event_check && status_check && time_check;
    });
  }
}
