import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'eod-confirm-box',
  templateUrl: './confirm-box.component.html',
  styleUrls: ['./confirm-box.component.css'],
})
export class EODConfirmBoxComponent {
  @Input('title') title: string = 'Confirm';
  @Input('closeTitle') closeTitle: boolean = false;
  @Input('buttons') buttons: any = null;
  @Input('message') message: string;
  @Output() hideModel = new EventEmitter<boolean>();

  constructor() {}
}
