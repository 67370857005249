import {
  Component,
  EventEmitter,
  Input,
  Output,
  AfterViewInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { getENV } from 'src/app/_helpers/helpers';
import { APIService } from 'src/app/services/api.service';
import { NotifierService } from 'src/app/services/notifier.service';

declare var CKEDITOR: any;

@Component({
  selector: 'race-reports-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.css'],
})
export class NotesComponent implements AfterViewInit {
  @Input('title') title: string = 'Notes';
  @Input({ alias: 'meta', required: true }) meta: any;
  edit: boolean = false;
  @Output() hideModel: EventEmitter<string> = new EventEmitter<string>();

  limits = {
    event_notes: 500,
    race_notes: 200,
  };

  config: any;
  editorInstance: any;

  constructor(
    private apiService: APIService,
    private notifier: NotifierService,
    private activeRoute: ActivatedRoute
  ) {
    this.config = this.activeRoute.snapshot.data['config'];
  }

  ngAfterViewInit() {
    if (this.meta.action === 'save_venue_notes' && this.edit) {
      this.initializeCKEditor();
    }
  }

  editNotesToggle() {
    this.edit = true;
    this.meta.notes_edit = this.meta.notes || '';
    if (this.meta.action === 'save_venue_notes') {
      setTimeout(() => {
        this.initializeCKEditor();
      });
    }
  }

  initializeCKEditor() {
    this.editorInstance = CKEDITOR.replace('venueNotesEditor', {
      contentsCss: ['https://cdn.ckeditor.com/4.16.0/full-all/contents.css'],
      fontSize_defaultLabel: '20px',
      removeButtons: 'Image,Iframe'
    });
    this.editorInstance.setData(this.meta.notes || '');
  }

  async saveNotes() {
    if (this.meta.action === 'save_venue_notes') {
      this.meta.notes_edit = this.editorInstance.getData();
    }

    if (
      this.limits[this.meta.action] &&
      this.meta.notes_edit.length > this.limits[this.meta.action]
    ) {
      this.notifier.alert(
        'Info',
        '',
        'Notes cannot be more than ' +
          this.limits[this.meta.action] +
          ' characters',
        'info',
        5000
      );
      return;
    }
    this.notifier.loading(true);
    let apiURL: string = `${this.config[getENV()].raceAPI}/flask-operations`;
    let payload: any = {
      action: this.meta.action,
      notes: this.meta.notes_edit || this.meta.notes,
    };

    if (this.meta.action == 'race_notes') {
      payload['race_id'] = this.meta.id;
    } else if (this.meta.action == 'event_notes') {
      payload['event_id'] = this.meta.id;
    } else if (this.meta.action == 'save_venue_notes') {
      payload['venue_id'] = this.meta.id;
    }

    let result: any = await this.apiService.postDataPromis(apiURL, payload, {});

    if (result.status == '1' || result.s == '1') {
      this.notifier.alert(
        'Success',
        '',
        'Notes saved successfully',
        'success',
        5000
      );
      this.meta.notes = this.meta.notes_edit;
    } else {
      this.notifier.alert(
        'Info',
        '',
        result.error_message || result.error,
        'info',
        5000
      );
    }
    this.edit = false;
    this.notifier.loading(false);
  }
}
