import { Component, OnInit, AfterViewInit } from '@angular/core';
import { VenueDetailsService } from '../../services/venue-details.service';
import { ActivatedRoute } from '@angular/router';
import { NotifierService } from 'src/app/services/notifier.service';
import { APIService } from 'src/app/services/api.service';
import { getENV, getRaceType } from 'src/app/_helpers/helpers';

declare var CKEDITOR: any;
@Component({
  selector: 'app-venue-details',
  templateUrl: './venue-details.component.html',
  styleUrls: ['./venue-details.component.css'],
})

export class VenueDetailsConfigComponent implements OnInit, AfterViewInit {
  venues = [];
  venueData = {
    type: 'Harness Racing',
    venueName: '',
    connection_string: '',
    kit_name: 'DEFAULT',
    venue_status: true,
    DefaultKit: '',
    external_venue_id: '',
    club_code: '',
    venue_abbr: '',
    venue_country: 'Australia',
    venue_state: 'VIC',
    jurisdiction: '',
    ltsConnectors: [],
    rtcmConnectors: [],
    masteringConnectors: [],
    notes: '',
  };
  isEdit = false;
  showModal = false;

  config: any;
  editorInstance: any;
  filterStatus: string = 'enabled';
  constructor(
    private venueDetailsService: VenueDetailsService,
    private activatedRoute: ActivatedRoute,
    private apiService: APIService,
    private notifier: NotifierService
  ) {
    this.config = this.activatedRoute.snapshot.data['config'];
  }

  ngOnInit() {
    this.loadVenues();
    this.loadJurisdictions(this.venueData.type);
  }

  ngAfterViewInit() {
    if (this.showModal) {
      this.initializeCKEditor();
    }
  }

  initializeCKEditor() {
    this.editorInstance = CKEDITOR.replace('venueNotesEditor', {
      contentsCss: ['https://cdn.ckeditor.com/4.16.0/full-all/contents.css'],
      fontSize_defaultLabel: '20px',
      removeButtons: 'Image,Iframe',
    });

    this.editorInstance.setData(this.venueData.notes || '');
  }

  async saveNotes() {
    this.venueData.notes = this.editorInstance.getData();
  }
  jurisdictions = [];
  types: string[] = [
    'Harness Racing',
    'Thoroughbred Racing',
    'Greyhound Racing',
  ];
  selectedJurisdiction: string = '';

  allVenues: any = [];
  selectRaceType(type: string) {
    this.venueData.type = type;
    this.loadJurisdictions(type);
    this.venues = this.allVenues.filter((venue: any) => {
      return venue.raceType == type;
    });
    this.applyFilter();
  }

  getFullConnectionString(payload: boolean = false): string {
    if (payload) {
      return (
        'Server=.' +
        '\\' +
        `SQLEXPRESS;DataBase=HRTraCe_${this.venueData.connection_string}`
      );
    } else {
      return `Server=.\\SQLEXPRESS;DataBase=HRTraCe_${this.venueData.connection_string}`;
    }
  }

  async loadJurisdictions(type: string) {
    try {
      const response = await this.venueDetailsService.getJurisdictions(
        this.config,
        type
      );

      if (response.status == '1' || response.s == '1') {
        this.jurisdictions = response['response'];
      }

      if (this.jurisdictions.length > 0) {
        this.venueData.jurisdiction = this.jurisdictions[0];
      }
    } catch (error) {
      console.error('Error loading jurisdictions:', error);
    }
  }

  async loadVenues() {
    let result: any = await this.venueDetailsService.loadVenues(this.config);
    if (result.status == '1' || result.s == '1') {
      this.allVenues = result.venues
        .map((venue: any) => {
          return {
            ExternalVenueID: venue.ExternalVenueID,
            VenueName: venue.VenueName,
            Enabled: venue.Enabled,
            Notes: venue.Notes,
            ClubCode: venue.ClubCode,
            VenueAbbr: venue.VenueAbbr,
            VenueCountry: venue.VenueCountry,
            VenueState: venue.VenueState,
            Jurisdiction: venue.Jurisdiction,
            DefaultKit: venue.DefaultKit,
            raceType: getRaceType(venue.ExternalVenueID)['raceTypeStr'],
          };
        })
        .sort((a, b) => a.VenueName.localeCompare(b.VenueName));
      this.applyFilter();
    }
  }

  applyFilter() {
    this.venues = this.allVenues.filter((venue: any) => {
      return (
        (this.filterStatus === 'all' ||
          (this.filterStatus === 'enabled' && venue.Enabled) ||
          (this.filterStatus === 'disabled' && !venue.Enabled)) &&
        venue.raceType == this.venueData.type
      );
    });
  }
  // Method to handle form submission
  async onSubmit(action: string) {
    if (this.venueData['kit_name'] == 'new') {
      this.venueData['kit_name'] = this.newKitName;
    }
    if (
      this.venueData.ltsConnectors.length === 0 &&
      this.venueData.rtcmConnectors.length === 0 &&
      this.venueData.masteringConnectors.length === 0
    ) {
      this.notifier.alert(
        'Info',
        '',
        'At least one connector must be present.',
        'info',
        5000
      );
      return;
    }
    const payload = {
      action: 'venue_configuration',
      data: {
        ...this.venueData,
        connection_string: this.getFullConnectionString(true),
      },
    };
    try {
      const response = await this.venueDetailsService.venueConfiguration(
        this.config,
        payload.data
      );
      if (action === 'createVenue') {
        this.venues.push({ ...this.venueData });
      } else if (action === 'updateVenue') {
        const index = this.venues.findIndex(
          (v) => v.venueName === this.venueData.venueName
        );
        if (index !== -1) {
          this.venues[index] = { ...this.venueData };
        }
      }
      this.showModal = false;
    } catch (error) {
      console.error('Error in venue configuration:', error);
    }
  }

  // Method to add a new LTS Connector
  addLTSConnector() {
    this.venueData.ltsConnectors.push({
      connectionName: '',
      connectionType: '',
      ipAddress: '',
      port: null,
    });
  }

  // Method to remove an LTS Connector
  removeLTSConnector(index: number) {
    this.venueData.ltsConnectors.splice(index, 1);
  }

  // Method to add a new RTCM Connector
  addRTCMConnector() {
    this.venueData.rtcmConnectors.push({
      connectionName: '',
      connectionType: '',
      ipAddress: '',
      port: null,
    });
  }

  // Method to remove an RTCM Connector
  removeRTCMConnector(index: number) {
    this.venueData.rtcmConnectors.splice(index, 1);
  }

  // Method to add a new Mastering Connector
  addMasteringConnector() {
    this.venueData.masteringConnectors.push({
      connectorGroupID: '',
      ConnectorGroupName: '',
      connectionName: '',
      connectionType: '',
      ipAddress: '',
      port: null,
    });
  }

  // Method to remove a Mastering Connector
  removeMasteringConnector(index: number) {
    this.venueData.masteringConnectors.splice(index, 1);
  }

  // Method to open the modal for adding a new venue
  openAddVenueModal() {
    this.isEdit = false;
    this.venueData = {
      type: 'Harness Racing',
      venueName: '',
      connection_string: '',
      kit_name: 'DEFAULT',
      venue_status: true,
      external_venue_id: '',
      DefaultKit: 'DEFAULT',
      club_code: '',
      venue_abbr: '',
      venue_country: 'Australia',
      venue_state: 'VIC',
      jurisdiction: '',
      ltsConnectors: [],
      rtcmConnectors: [],
      masteringConnectors: [],
      notes: '',
    };
    this.loadJurisdictions(this.venueData.type);
    this.loadKits();
    this.showModal = true;
    setTimeout(() => {
      this.initializeCKEditor();
    });
  }

  addKit() {
    this.venueData['kit_name'] = 'new';
    this.venueData.ltsConnectors = [];
    this.venueData.rtcmConnectors = [];
    this.venueData.masteringConnectors = [];
  }

  cancelAddKit() {
    this.venueData['kit_name'] = this.venueData['DefaultKit'];
    if (this.venueData['kit_name']) {
      this.loadKitDetails();
    }
  }

  newKitName: string = '';

  onKitChange() {
    if (this.venueData.kit_name == 'new') {
      this.venueData.ltsConnectors = [];
      this.venueData.rtcmConnectors = [];
      this.venueData.masteringConnectors = [];
    } else {
      this.loadKitDetails();
    }
  }

  async loadKitDetails() {
    try {
      let result = await this.venueDetailsService.getKitDetails(
        this.config,
        this.venueData['kit_name']
      );

      if (result.kits && result.kits.length > 0) {
        const kit = result.kits[0];
        this.venueData.ltsConnectors = kit.lts.map((connector: any) => ({
          connectionName: connector.connector_name,
          connectionType: connector.connector_type,
          ipAddress: connector.ip_address,
          port: connector.port,
        }));
        this.venueData.rtcmConnectors = kit.rtcm.map((connector: any) => ({
          connectionName: connector.connector_name,
          connectionType: connector.connector_type,
          ipAddress: connector.ip_address,
          port: connector.port,
        }));
        this.venueData.masteringConnectors = kit.master.map(
          (connector: any) => ({
            connectorGroupID: connector.connector_group_id,
            ConnectorGroupName: connector.connector_group_name,
            connectionName: connector.connector_name,
            connectionType: connector.connector_type,
            ipAddress: connector.ip_address,
            port: connector.port,
          })
        );
      }
    } catch (error) {}
  }

  async loadVenueDetails() {
    try {
      let response: any = await this.venueDetailsService.getLocationDetails(
        this.config,
        this.venueData['external_venue_id'],
        this.venueData['type'],
        this.venueData['venueName']
      );

      if (response && response.venues) {
        let connectionString = response.venues.connectionString.replace(
          'Server=.\\SQLEXPRESS;DataBase=HRTraCe_',
          ''
        );

        this.venueData = {
          type: this.venueData.type,
          DefaultKit: response.venues.DefaultKit,
          venueName: response.venues.VenueName,
          connection_string: connectionString,
          kit_name: response.venues.DefaultKit,
          venue_status: response.venues.Enabled,
          external_venue_id: this.venueData.external_venue_id,
          club_code: response.venues.ClubCode,
          venue_abbr: response.venues.VenueAbbr,
          venue_country: response.venues.VenueCountry,
          venue_state: response.venues.VenueState,
          jurisdiction: response.venues.Jurisdiction,
          ltsConnectors: this.venueData.ltsConnectors,
          rtcmConnectors: this.venueData.rtcmConnectors,
          masteringConnectors: this.venueData.masteringConnectors,
          notes: response.venues.Notes,
        };
      }
    } catch (error) {
      console.log('FAILED TO LOAD VENUE: ', error);
    }
  }

  kits: any = [];
  async loadKits() {
    try {
      let response: any = await this.venueDetailsService.getKits(
        this.config,
        this.venueData['external_venue_id']
      );

      this.kits = response.kits;

      if (!this.venueData['kit_name']) {
        this.venueData['kit_name'] = this.kits[0];
      }

      let result = await this.venueDetailsService.getKitDetails(
        this.config,
        this.venueData['kit_name']
      );

      if (result.kits && result.kits.length > 0) {
        const kit = result.kits[0];
        this.venueData.ltsConnectors = kit.lts.map((connector: any) => ({
          connectionName: connector.connector_name,
          connectionType: connector.connector_type,
          ipAddress: connector.ip_address,
          port: connector.port,
        }));
        this.venueData.rtcmConnectors = kit.rtcm.map((connector: any) => ({
          connectionName: connector.connector_name,
          connectionType: connector.connector_type,
          ipAddress: connector.ip_address,
          port: connector.port,
        }));

        this.venueData.masteringConnectors = kit.master.map(
          (connector: any) => ({
            connectorGroupID: connector.connector_group_id,
            ConnectorGroupName: connector.connector_group_name,
            connectionName: connector.connector_name,
            connectionType: connector.connector_type,
            ipAddress: connector.ip_address,
            port: connector.port,
          })
        );
      }
    } catch (error) {}
  }

  // Method to open the modal for editing an existing venue
  openEditVenueModal(venue) {
    this.isEdit = true;
    const raceType: string = getRaceType(venue.ExternalVenueID)['raceTypeStr'];
    this.venueData = {
      type: raceType,
      venueName: venue.VenueName,
      connection_string: '',
      DefaultKit: 'DEFAULT',
      kit_name: null,
      venue_status: true,
      external_venue_id: venue.ExternalVenueID,
      club_code: '',
      venue_abbr: '',
      venue_country: 'Australia',
      venue_state: 'VIC',
      jurisdiction: '',
      ltsConnectors: [],
      rtcmConnectors: [],
      masteringConnectors: [],
      notes: '',
    };
    let promises: any = [];
    promises.push(this.loadJurisdictions(this.venueData.type));
    promises.push(this.loadVenueDetails());
    promises.push(this.loadKits());
    this.showModal = true;
    Promise.all(promises).then((values: any) => {
      setTimeout(() => {
        this.initializeCKEditor();
      });
    });
  }
}
