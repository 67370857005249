import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';

interface Statuses {
  SD: string;
  PR: string;
  GD: string;
  AP: string;
  GB: string;
  LG: string;
  LD: string;
  R: string;
  F: string;
}

@Injectable({
  providedIn: 'root'
})
export class RaceRunnerService {
  static API_URL = "/demos/api/php";
  static SILKS_URL = 'https://d5r512lauia4d.cloudfront.net/';
  static DEBUG = window.location.host.match(/stg/);
  static WS_URL = `wss://stream.tpd.zone/realtime_${RaceRunnerService.DEBUG ? 'ws_dev' : '1.4'}`
  static VERSION = `2024.03.19${RaceRunnerService.DEBUG ? '-dev' : ''}`;

  private static statuses: Statuses = {
    SD: 'Saddling',
    PR: 'Parading',
    GD: 'Going Down',
    AP: 'At the Post',
    GB: 'Going Behind',
    LG: 'Loading',
    LD: 'Loaded',
    R: 'Running',
    F: 'Finished'
  };

  static dateTimeFormat = new Intl.DateTimeFormat('en-AU', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  });

  static dateTimeShortFormat = new Intl.DateTimeFormat('en-AU', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  });

  static timeFormat = new Intl.DateTimeFormat('en-AU', {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric'
  });

  static relativeTimeFormat = (mseconds: number) => `${mseconds < 0 ? '-' : ''}${new Date(Math.abs(mseconds)).toLocaleTimeString('en-AU', {
    timeZone: 'UTC',
    hour12: false,
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  })}`;

  constructor(private http: HttpClient) { }

  getStatusName(statusCode: keyof Statuses): string {
    return RaceRunnerService.statuses[statusCode] || 'Unknown';
  }

  static getStatuses(): Statuses {
    return this.statuses;
  }

  formatDateTime(date: string | number | Date): string {
    return RaceRunnerService.dateTimeFormat.format(new Date(date));
  }

  formatShortDateTime(date: string | number | Date): string {
    return RaceRunnerService.dateTimeShortFormat.format(new Date(date));
  }

  formatTime(date: string | number | Date): string {
    return RaceRunnerService.timeFormat.format(new Date(date));
  }

  formatRelativeTime(mseconds: number): string {
    return RaceRunnerService.relativeTimeFormat(mseconds);
  }

  getRaceData(raceId: string): Promise<any> {
    // Mock data for demonstration
    const sampleData = {
      race: {
        sc: raceId,
        country: 'AU',
        racecourse: 'Sydney',
        post_time: new Date().toISOString(),
        distance: '2000m',
        obstacle: 'Flat'
      },
      runners: {
        1: { stall_draw: 1, cl: '1', name: 'Runner One', status: 'SD' },
        2: { stall_draw: 2, cl: '2', name: 'Runner Two', status: 'PR' },
        3: { stall_draw: 3, cl: '3', name: 'Runner Three', status: 'GD' }
      }
    };

    return of(sampleData).pipe(delay(1000)).toPromise();
  }

  getSilkUrl(runner: any): string {
    return `${RaceRunnerService.SILKS_URL}${runner.sc}${runner.cl}.jpg`;
  }
}
