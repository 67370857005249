import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ServiceWorkerService {
  async deregisterServiceWorkerIfExists(): Promise<void> {
    if ('serviceWorker' in navigator) {
      try {
        const registrations = await navigator.serviceWorker.getRegistrations();
        if (registrations.length > 0) {
          for (let registration of registrations) {
            const result = await registration.unregister();
            if (result) {
              console.log('Service worker deregistered successfully');
            }
          }
          await this.clearCaches();
        }
      } catch (error) {
        console.error('Error during service worker deregistration:', error);
      }
    }
  }

  private async clearCaches(): Promise<void> {
    try {
      const cacheNames = await caches.keys();
      await Promise.all(cacheNames.map(cacheName => caches.delete(cacheName)));
      console.log('Caches cleared');
    } catch (error) {
      console.error('Error clearing caches:', error);
    }
  }
}
