import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-mqtt-state',
  templateUrl: './mqttstate.component.html',
  styleUrls: ['./mqttstate.component.css']
})
export class MqttStateComponent {
  @Input('data') data: any;

  ngOnChanges() {
    // console.log('Input data changed:', this.data);
  }
}
