import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { APIService } from 'src/app/services/api.service';
import { NotifierService } from 'src/app/services/notifier.service';
import VenueEmails from '../services/emails';

import { getENV, getRaceType } from 'src/app/_helpers/helpers';

declare let Jhxlsx: any;

export interface venueEmail {
  VenueName: string;
  ExternalVenueID: string;
  StartlistEmailDL: string;
  RaceReportsEmailDL: null;
  UnofficialEmailDL: null;
  EODEmailDL: string;
}
@Component({
  selector: 'app-venue-emails',
  templateUrl: './venue-emails.component.html',
  styleUrls: ['./venue-emails.component.css'],
})
export class VenueEmailsComponent implements OnInit {
  emails: venueEmail[] = [];
  config: any;
  loading: { [a: string]: boolean } = { venues: false };
  venueEmailObj: VenueEmails;

  headers: any = [
    {
      id: 'VenueName',
      name: 'Venue Name',
      click: (venue: any) => {
        this.editVenueEmail(venue);
      },
      filter: true,
    },
    {
      id: 'ExternalVenueID',
      name: 'Venue ID',
      filter: true,
    },
    {
      id: 'StartlistEmailDL',
      name: 'Start List Email ID',
      // filter: true,
    },
    {
      id: 'RaceReportsEmailDL',
      name: 'Official Race Report Email ID',
      // filter: true,
    },
    {
      id: 'UnofficialEmailDL',
      name: 'Unofficial Race Report Email ID',
      // filter: true,
    },
    {
      id: 'EODEmailDL',
      name: 'EOD Email ID',
      // filter: true,
    },
  ];
  constructor(
    private apiService: APIService,
    public notifier: NotifierService,
    private router: ActivatedRoute
  ) {
    this.config = this.router.snapshot.data['config'];
  }

  ngOnInit() {
    this.venueEmailObj = new VenueEmails(this.apiService, this.config[getENV()], this.notifier);
    this.refresh();
  }

  refresh() {
    this.load();
  }

  async load() {
    this.loading['venues'] = true;
    let result: any = await this.venueEmailObj.loadEmails();
    if (result.status == '1') {
      this.emails = result.data.map((venue: any) => {
        let raceType = getRaceType(venue['ExternalVenueID'])['raceTypeStr'];
        if(raceType == 'Thoroughbred Racing') {
          if (venue['VenueName'] == 'Cranbourne') {
            venue['VenueName'] = 'Cranbourne Turf';
          }
        }
        return venue;
      });
    } else {
      this.notifier.alert('Info', '', result.error_message, 'info', 5000);
    }
    this.loading['venues'] = false;
  }

  editVenue: venueEmail = null;
  editVenueEmail(venue: venueEmail) {
    this.editVenue = venue;
  }

  removeVenueEmail(event: boolean) {
    if (event) {
      this.refresh();
    }
    this.editVenue = null;
  }

  export() {
    let data: any = {
      sheetName: 'Emails Configuration',
      data: [],
    };

    data['data'].push([
      { text: '#' },
      ...this.headers.map((h: any) => {
        return { text: h['name'] };
      }),
    ]);

    if (this.emails.length > 0) {
      this.emails.forEach((email: any, index: number) => {
        data['data'].push([
          { text: index + 1 },
          ...this.headers.map((h: any) => {
            return { text: email[h.id] || '-' };
          }),
        ]);
      });
    }

    var options = {
      fileName: 'Emails Configuration',
    };

    Jhxlsx.export([data], options);
  }
}
