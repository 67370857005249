import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
//import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import * as moment from 'moment';
import { APIService } from 'src/app/services/api.service';
import { NotifierService } from 'src/app/services/notifier.service';
import { DashService } from './dash.service';
import { Subscription } from 'rxjs';
import { Acticity, uuid } from 'src/app/_helpers/activity';
import {
  PrepareforRace,
  PrepareService,
} from './modules/pages/components/pre-race/pre-race.service';

import { getENV } from 'src/app/_helpers/helpers';

declare var $: any;

export let internetActive: boolean;

export let regions: any = {
  enabled: [],
  disabled: [],
};

export let cftrequired: any = {
  'tell-me': {
    cft: moment('09-05-2023', 'DD-MM-YYYY').valueOf(),
    link: '/dash/tell-me',
  },
};

export let emsIntegration: any = [
  '/dash/reports/view?reportType=inventory',
  '/dash/site24x7/monitors',
];

export interface CookieOptions {
  expires?: number | Date;
  path?: string;
  domain?: string;
  secure?: boolean;
}

@Component({
  selector: 'app-dash',
  templateUrl: './dash.component.html',
  styleUrls: ['./dash.component.css'],
})
export class DashComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem('eId');
  username = localStorage.getItem('un');
  started: boolean = true;
  masterEmailId: string | null = localStorage.getItem('masterId');
  isUserAdmin: boolean = localStorage.getItem('isUserAdmin') == '1';

  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';

  menuList: any = [];
  accessType: string | null = localStorage.getItem('acT');
  access: string | null = localStorage.getItem('accessType');
  searchValue: string = '';
  recentSearchs: any = [];
  expiresIn: any = localStorage.getItem('expiresIn');
  accessList: any = localStorage.getItem('menuList');
  showSearchResult: boolean = false;
  searchResults: any;
  currentRoute: string | null = null;

  notification: any[] = [];
  noticount: any = 0;
  viewNotificationTrigger = false;
  config: any;
  dashSubscription: Subscription = null;
  inactiveModal: boolean = false;
  constructor(
    private router: Router,
    private apiServer: APIService,
    private notifier: NotifierService,
    private activeRoute: ActivatedRoute,
    private dashService: DashService,
    private prepareService: PrepareService
  ) {
    this.config = this.activeRoute.snapshot.data['config'];
    if (this.accessList) {
      let temp: any = [];
      try {
        let accessList: any = JSON.parse(this.accessList);
        temp = [...Object.keys(accessList)];
        for (const key in accessList) {
          if (accessList.hasOwnProperty(key)) {
            const values = accessList[key].map(
              (value: any) => `${key}:${value}`
            );
            temp.push(...values);
          }
        }
        this.accessList = temp;
      } catch (error) {
        this.accessList = [];
      }
    }
  }

  showExpired: boolean = false;

  dashServiveMessage: any;

  expandEvent(event: any, preapre: PrepareforRace) {
    preapre.expanded = !preapre.expanded;
    event.preventDefault();
    event.stopPropagation();
  }

  timer: any = null;
  bc = new BroadcastChannel('triplesdata');
  prepareForRaceVenue: any = null;
  process: any = { prepares: [], reports: [], eod_report: [] };
  baseStationStatusCode: string = null;

  get getButtons() {
    if (this.baseStationStatusCode == 'LOGOUT_LOADING') {
      return [];
    } else if (this.baseStationStatusCode == 'LOGOUT_COMPLETED') {
      return ['close'];
    } else if (this.baseStationStatusCode == 'LOGOUT_FAILED') {
      return ['close'];
    } else if (this.baseStationStatusCode == 'LOGIN_PENDING') {
      return ['yes', 'no'];
    } else if (this.baseStationStatusCode == 'LOGIN_LOADING') {
      return [];
    } else if (this.baseStationStatusCode == 'LOGIN_COMPLETED') {
      return ['close'];
    } else if (this.baseStationStatusCode == 'LOGIN_FAILED') {
      return ['close'];
    } else {
      return ['close'];
    }
  }

  async baseStation(): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      let payload: any = {
        action: "base_station",
        operation: this.prepare.type,
        location: this.prepare.location,
      };

      let apiURL: string = `${
        this.config[getENV()].raceAPI
      }/flask-operations`;
      let result: any = await this.apiServer.postDataPromis(apiURL, payload, {})
      if(result.s == '1' || result.status == '1') {
        resolve(true);
      } else {
        resolve(false);
      }
    });
  }

  prepare: any = {
    type: '',
    prepareId: ''
  }
  async handlePopupConfirmation(event: any) {
    const prepare = this.prepareService.prepares.find(
      (p) => p.payload.event_id === this.prepare.prepareId
    );

    if(event == null) {
      this.baseStationStatusCode = null;
      return;
    }

    if(!prepare) return;
    if(event) {
      this.baseStationStatusCode = 'LOGIN_LOADING';
      let status: boolean = await this.baseStation();
      if(status) {
        this.baseStationStatusCode = 'LOGIN_COMPLETED';
        prepare.resumePrepare(this.prepare.type);
      } else {
        this.baseStationStatusCode = 'LOGIN_FAILED';
      }
    } else {
      this.baseStationStatusCode = null;
      prepare.resumePrepare(this.prepare.type);
    }
  }

  async ngOnInit() {
    this.prepareService.callPreviousPrepares(this.config);
    this.prepareService.dataSource$.subscribe((message: any) => {
      this.process['prepares'] = message;
    });
    this.bc.onmessage = (event: MessageEvent) => {
      let data = JSON.parse(event.data);
      if (data.id == uuid) {
        return;
      }
      if (data.message == 'RESET_TIMER') {
        this.resetActivityTimer(false);
      } else if (data.message == 'SHOW_TIMER') {
        this.showTimer();
      }
    };
    this.inactiveModal = false;
    this.dashSubscription = this.dashService.dashObervable.subscribe(
      async (message: string) => {
        if (message == 'SHOW_INACTIVITY_MODAL') {
          this.bc.postMessage(
            JSON.stringify({ id: uuid, message: 'SHOW_TIMER' })
          );
          this.showTimer();
        } else {
          try {
            let data = JSON.parse(message);
            if (data.type == 'BASE_STATION_LOGIN') {
              this.prepare.type = 'connect';
              this.prepare.prepareId = data.prepareId;
              this.prepare.location = data.location;
              this.baseStationStatusCode = 'LOGIN_PENDING';
            }
            if (data.type == 'BASE_STATION_LOGOUT') {
              this.prepare.type = 'disconnect';
              this.prepare.prepareId = data.prepareId;
              this.prepare.location = data.location;
              this.baseStationStatusCode = 'LOGOUT_LOADING';
              let status: boolean = await this.baseStation();
              if(status) {
                this.baseStationStatusCode = 'LOGOUT_COMPLETED';
              } else {
                this.baseStationStatusCode = 'LOGOUT_FAILED';
              }
            }
          } catch(error) {
            console.log(error)
          }
        }
      }
    );
    let menus: any = JSON.parse(localStorage.getItem('menuList') || '{}');

    if (menus && menus.hasOwnProperty('Settings')) {
      delete menus['Settings'];
    }

    this.menuList = Object.keys(menus).map((menu: any) => {
      return {
        label: menu,
        submenus: menus[menu].map((menu_: any) => {
          return {
            label: menu_,
          };
        }),
      };
    });

    // window.addEventListener("online", () => {
    //   alert("Internet is active");
    //   internetActive = true;
    // });
    // window.addEventListener("offline", () => {
    //   alert("Internet is down");
    //   internetActive = false;
    // });

    if (localStorage.getItem('accountStatus') == 'Inactive') {
      return false;
    }
    // this.fetchRecentSearch();
    // if (this.ut == 'user') {
    //   this.urlPrefix = 'client';
    // }

    // await this.loadHeader();
    //localStorage.setItem('agl', JSON.stringify(this.agl));
    // if (!localStorage.getItem('selectedRegion')) {
    //   localStorage.setItem('selectedRegion', this.regions[0].name);
    //   localStorage.setItem('selectedRegionId', this.regions[0].id);
    //   localStorage.setItem('regionId', this.regions[0].id);
    //   this.notifier.changeRegionNotification(
    //     localStorage.getItem('selectedRegionId')
    //   );
    // } else {
    //   this.notifier.changeRegionNotification(
    //     localStorage.getItem('selectedRegionId')
    //   );
    // }
    return true;
  }

  deletePrepare(prepare: PrepareforRace, index: number) {
    this.prepareService.removePrepare(prepare, index);
  }
  showTimer() {
    this.inactiveModal = true;
    this.timer = setTimeout(() => {
      this.inactiveModal = false;
      this.logout();
    }, 300000);
  }
  //TODO : save on Change

  checkIfAvaForUser(routeName: string): boolean {
    if (!this.accessList) {
      return false;
    }
    if (
      this.accessList.length === 0 ||
      this.accessList.indexOf(routeName) > -1
    ) {
      return true;
    } else {
      return false;
    }
  }

  resetActivityTimer(message: boolean = true) {
    let activity = new Acticity(this.dashService);
    activity.setLastActivityTime(message);
    this.inactiveModal = false;
    clearTimeout(this.timer);
    this.timer = null;
  }

  async navigateTo(param: any) {
    // await this.setRecentSearch(param);
    this.searchValue = '';
    this.router.navigateByUrl(param.url);
  }

  getPercentage(val?: number) {
    if (!val) {
      val = 0;
    }
    var r = 12;
    var c = Math.PI * (r * 2);

    if (val < 0) {
      val = 0;
    }
    if (val > 100) {
      val = 100;
    }

    let pct = ((100 - val) / 100) * c;

    return pct;
  }

  width: any;
  checkingCFT: boolean = false;

  async loadHeader() {
    //for notification
    // this.loadNotificationCount();
    // setInterval(() => {
    //   this.loadNotificationCount();
    // }, 3600000);

    this.notifier.loading(true);
  }

  getMiddleString(text: string) {
    return text.substring(text.indexOf('(') + 1, text.lastIndexOf(')'));
  }

  filterText: string = '';
  showMore: boolean = false;

  // loadAGL() {
  //   this.accountGroups = JSON.parse(
  //     localStorage.getItem("selectedAccountGroupData")
  //   );
  //   this.clientNames = JSON.parse(
  //     localStorage.getItem("selectedClientNameData")
  //   );
  //   this.accountIds = JSON.parse(localStorage.getItem("accountIdData"));

  //   this.selectedRegion = localStorage.getItem("selectedRegion");
  //   //localStorage.setItem("selectedRegion", regions.name);
  //   localStorage.getItem("selectedRegionId");

  //   //for (var i = 0; i < this.agl[0].length; i++) {
  //   //  if()
  //   //}
  //   //this.accountGroups = {
  //   //  selected: this.agl[0].groupName,
  //   //  list: []
  //   //};

  //   //for (let i = 0; i < this.agl.length; i++) {
  //   //  this.accountGroups.list.push(this.agl[i]);
  //   //}
  // }

  searchOnList(data: string, pattern: string) {
    if (!pattern) return true;

    if (data.toLowerCase().indexOf(pattern.toLowerCase()) != -1) return true;
    return false;
  }

  async copyToClipboard(event: any, text: string) {
    event.preventDefault();
    event.stopPropagation();
    if (navigator.clipboard?.writeText) {
      try {
        await navigator.clipboard.writeText(text);
      } catch (e) {
        console.error('Error while copying code', e);
      }
    }
  }

  async logout() {
    this.inactiveModal = false;
    this.notifier.loading(true);
    let data = {
      a: 'logout',
      email: this.userId || localStorage.getItem('eId'),
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };

    //https://api.swayam.cloud/v3/admin/users/fetchaccountgroupsv2
    let apiURL = `${this.config[getENV()].apiURL}/${
      this.urlPrefix
    }/Settings/usermanagement`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.notifier.alert('Success', '', result.msg, 'success', 5000);
    }

    localStorage.clear();
    setTimeout(() => {
      this.router.navigateByUrl('/auth/login');
      this.notifier.loading(false);
    }, 100);
  }

  hideLeftMenu() {
    $('.leftmenu').addClass('leftmenuhidden');
    $('.rightcontent').addClass('leftmenuhidden');
    $('#menuClosebtn').hide();
    $('#menuShowbtn').show();
    const leftmenu: any = document.querySelector('.leftmenu');
    leftmenu.style.minWidth = '0px';
  }

  showLeftMenu() {
    $('.leftmenu').removeClass('leftmenuhidden');
    $('.rightcontent').removeClass('leftmenuhidden');
    $('#menuClosebtn').show();
    $('#menuShowbtn').hide();
    const leftmenu: any = document.querySelector('.leftmenu');
    leftmenu.style.minWidth = '200px';
  }

  // showSearch() {
  //   this.showSearchResult = true;
  // }

  // hideSearch() {
  //   this.showSearchResult = false;
  // }

  // onSearchChange() {
  //   let searchValue = this.searchValue;
  //   if (!searchValue) return;
  //   searchValue = searchValue.toLowerCase();
  //   this.searchResults = this.searchData.items.filter((a: any) => {
  //     if (a.keys.indexOf(searchValue) != -1) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   });
  // }

  //for notification
  notirefe(event: any) {
    // this.loadNotificationCount();
  }
  async loadNotificationCount() {
    let data = {};
    let header = {
      Authorization: 'Bearer ' + localStorage.getItem('t'),
    };

    let apiURL = `https://apis-eks.swayam.cloud/global_notification/count_notifications/?email_id=${this.userId}&user_type=internal`;
    try {
      await this.apiServer.postDataPromis(apiURL, data, header).then((data) => {
        if (data['total_notification_count'] <= 99) {
          this.noticount = data['total_notification_count'];
        } else if (data['total_notification_count'] >= 100) {
          this.noticount = '99+';
        } else {
          this.noticount = 0;
        }
      });
    } catch (error) {
      this.noticount = 0;
    }
  }

  viewNotification(event: any) {
    this.viewNotificationTrigger = !this.viewNotificationTrigger;
  }

  ngOnDestroy(): void {
    this.dashSubscription.unsubscribe();
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  }
}
