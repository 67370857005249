<div class="modal fade" [class.show]="showModal" [style.display]="showModal ? 'block' : 'none'" tabindex="-1" role="dialog" aria-labelledby="deviceHistoryModalLabel" aria-modal="true" style="background-color: rgba(0,0,0,0.5);">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <form (ngSubmit)="addHistoryEntry()">
                <div class="modal-header">
                    <h5 class="modal-title" id="deviceHistoryModalLabel">{{ SerialNumber ? 'Add Issue for ' + SerialNumber : 'Add Issue' }}</h5>
                    <button type="button" class="btn-close" aria-label="Close" (click)="toggleModal()"></button>
                </div>
                <div class="modal-body">
                    <div class="mb-3">
                        <label for="historyTitle" class="form-label">Title</label>
                        <input type="text" class="form-control" id="historyTitle" [(ngModel)]="newHistory.Title" name="title" required>
                    </div>
                    <div class="mb-3">
                        <label for="historyDate" class="form-label">Date</label>
                        <input type="date" class="form-control" id="historyDate" [(ngModel)]="newHistory.Date" name="date">
                    </div>
                    <div class="mb-3">
                        <label for="historyCategory" class="form-label">Category</label>
                        <input type="text" class="form-control" id="historyCategory" [(ngModel)]="newHistory.Category" name="category">
                    </div>
                    <div class="mb-3">
                        <label for="historyDetails" class="form-label">Additional Details</label>
                        <textarea class="form-control" id="historyDetails" [(ngModel)]="newHistory.AdditionalDetails" name="details"></textarea>
                    </div>
                    <button type="submit" class="btn btn-primary float-end">Add Issue Entry</button>
                </div>
                <br/>
                <br/>
                <hr>
                <div class="px-3 pb-3">
                    <h5 class="mt-4">Previous Issues</h5>
                    <ul class="list-group">
                        <li *ngFor="let history of deviceHistory" class="list-group-item">
                            <p><strong>Title:</strong> {{ history.Title }}</p>
                            <p><strong>Date:</strong> {{ history.Date | date:'mediumDate' }}</p>
                            <p><strong>Category:</strong> {{ history.Category }}</p>
                            <p><strong>Details:</strong> {{ history.AdditionalDetails }}</p>
                        </li>
                    </ul>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" (click)="toggleModal()">Close</button>
                </div>
            </form>
        </div>
    </div>
</div>
