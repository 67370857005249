export const locations: any = {
  'hrv-race-reports': {
    'select-location': [
      { name: 'Ballarat', state: 'VIC', id: 'Ballarat' },
      { name: 'Bendigo', state: 'VIC', id: 'Bendigo' },
      { name: 'Cranbourne', state: 'VIC', id: 'Cranbourne' },
      { name: 'Geelong', state: 'VIC', id: 'Geelong' },
      { name: 'Kilmore', state: 'VIC', id: 'Kilmore' },
      { name: 'Mildura', state: 'VIC', id: 'Mildura' },
      { name: 'Shepparton', state: 'VIC', id: 'Shepparton' },
      { name: 'Melton', state: 'VIC', id: 'Melton' },
    ],
    'alternate-location': [
      {
        name: 'Mooroopna at Shepparton',
        state: 'VIC',
        id: 'Mooroopna at Shepparton',
      },
      {
        name: 'Kyabram at Shepparton',
        state: 'VIC',
        id: 'Kyabram at Shepparton',
      },
    ],
  },
  'nsw-harness-race-reports': [
    { name: 'Menagle Park', state: 'NSW', id: 'Menagle Park' },
    { name: 'Penrith', state: 'NSW', id: 'Penrith' },
  ],
  'qr-harness-race-reports': [
    { name: 'Albion Park', state: 'QLD', id: 'Albion Park' },
    { name: 'Redcliffe', state: 'QLD', id: 'Redcliffe' },
  ],
  'australian-turb-club-race-report': [
    { name: 'Royal Randwick', state: 'ATC', id: 'Royal Randwick' },
    { name: 'Rosehill Gardens', state: 'ATC', id: 'Rosehill Gardens' },
    { name: 'Kensington', state: 'ATC', id: 'Kensington' },
  ],
  'racing-victoria-club-race-report': {
    'metro-tracks': [
      { name: 'The Valley', state: 'RVC', id: 'The Valley' },
      {
        name: 'The Valley - Night Races',
        state: 'RVC',
        id: 'The Valley - Night Races',
      },
      { name: 'Flemington', state: 'RVC', id: 'Flemington' },
      { name: 'Caulfield', state: 'RVC', id: 'Caulfield' },
      {
        name: 'Sportsbet Sandown Lakeside',
        state: 'RVC',
        id: 'Sportsbet Sandown Lakeside',
      },
      {
        name: 'Sportsbet Sandown Hillside',
        state: 'RVC',
        id: 'Sportsbet Sandown Hillside',
      },
    ],
    'country-tacks': [
      {
        name: 'Sportsbet Pakenham Main',
        state: 'RVC',
        id: 'Sportsbet Pakenham Main',
      },
      {
        name: 'Sportsbet Pakenham Main - Night',
        state: 'RVC',
        id: 'Sportsbet Pakenham Main - Night',
      },
      {
        name: 'Sportsbet Pakenham Synthetic',
        state: 'RVC',
        id: 'Sportsbet Pakenham Synthetic',
      },
      {
        name: 'Sportsbet Pakenham Synthetic - Night',
        state: 'RVC',
        id: 'Sportsbet Pakenham Synthetic - Night',
      },
      { name: 'Cranbourne', state: 'RVC', id: 'Cranbourne' },
      {
        name: 'Cranbourne - Night Races',
        state: 'RVC',
        id: 'Cranbourne - Night Races',
      },
    ],
  },
  'brisbane-racing-club-race-report': {
    'brisbane-racing': [
      { name: 'Doomben', state: 'BRC', id: 'Doomben' },
      { name: 'Eagle Farm', state: 'BRC', id: 'Eagle Farm' },
    ],
    'queensland-racing': [
      {
        name: 'Aquis Park Gold Coast',
        state: 'QRC',
        id: 'Aquis Park Gold Coast',
      },
      {
        name: 'Aquis Park Gold Coast - Poly Track',
        state: 'QRC',
        id: 'Aquis Park Gold Coast - Poly Track',
      },
      { name: 'Sunshine Coast', state: 'QRC', id: 'Sunshine Coast' },
      {
        name: 'Sunshine Coast - Night Races',
        state: 'QRC',
        id: 'Sunshine Coast - Night Races',
      },
      {
        name: 'Sunshine Coast - Poly Track',
        state: 'QRC',
        id: 'Sunshine Coast - Poly Track',
      },
      { name: 'Ipswich', state: 'QRC', id: 'Ipswich' },
      { name: 'Toowoomba', state: 'QRC', id: 'Toowoomba' },
      {
        name: 'Toowoomba - Night Races',
        state: 'QRC',
        id: 'Toowoomba - Night Races',
      },
    ],
    'north-queensland-racing': [
      {
        name: 'Ladbrokes Cannon Park (Cairns)',
        state: 'QRC',
        id: 'Ladbrokes Cannon Park (Cairns)',
      },
      { name: 'Mackay', state: 'QRC', id: 'Mackay' },
      { name: 'Rockhampton', state: 'QRC', id: 'Rockhampton' },
      { name: 'Townsville', state: 'QRC', id: 'Townsville' },
    ],
  },
  'queensland-racing-club-race-report': [],
  'singapore-tracks-race-report': {
    'singapore-turf-club': [
      { name: 'Singapore Turf Club', state: 'STC', id: 'Singapore Turf Club' },
    ],
    'new-zealand': [
      { name: 'Pukekohe Park', state: 'ARC', id: 'Pukekohe Park' },
      { name: 'Ellerslie', state: 'ARC', id: 'Ellerslie' },
    ],
  },
  'new-zealand-tracks-race-report': [],
  'racing-south-australia-track-race-report': [
    { name: 'Morphettville', state: 'RSA', id: 'Morphettville' },
    { name: 'Morphettville Parks', state: 'RSA', id: 'Morphettville Parks' },
    {
      name: 'Thomas Farms RC Murray Bridge',
      state: 'RSA',
      id: 'Thomas Farms RC Murray Bridge',
    },
    { name: 'Gawler', state: 'RSA', id: 'Gawler' },
    { name: 'Sportsbet Gawler', state: 'RSA', id: 'Sportsbet Gawler' },
  ],
};

export const locationMappers: any = {
  'Tabcorp Park Menangle': 'Tabcorp Pk Menangle',
  'Sportsbet Gawler': 'Gawler',
  'Thomas Farms Rc Murray Bridge': 'Thomas Farms RC Murray Bridge',
  'Gold Coast Poly Track': 'Aquis Park Gold Coast Poly',
};

export const mappers: any = {
  'australian-turb-club-race-report': {
    name: 'Australian Turb Club',
  },
  'racing-victoria-club-race-report': {
    'metro-tracks': 'Metro Tracks',
    'country-tacks': 'Country Tracks',
    name: 'Racing Victoria Club',
  },
  'brisbane-racing-club-race-report': {
    'brisbane-racing': 'Brisbane Racing',
    'queensland-racing': 'Queensland Racing',
    'north-queensland-racing': 'North Queensland Racing',
    name: 'Brisbane Racing Club',
  },
  'singapore-tracks-race-report': {
    'singapore-turf-club': 'Singapore Turf Club',
    'new-zealand': 'New Zealand',
    name: 'International Tracks',
  },
  'racing-south-australia-track-race-report': {
    name: 'Racing South Australia Track',
  },
  'hrv-race-reports': {
    name: 'Harness Racing Victoria',
    'select-location': 'Select Location',
    'alternate-location': 'Alternate Locations',
  },
  'qr-harness-race-reports': {
    name: 'Harness Racing Queensland',
  },
  'nsw-harness-race-reports': {
    name: 'Harness Racing NSW',
  },
};

export const typeBasedLocations = {
  'Harness Racing': [
    {
      Jurisdiction: 'Harness Victoria',
      JurisdictionCode: 'HRV',
      State: 'VIC',
      Locations: [
        {
          Name: 'Ballarat',
          shortName: 'BAC',
          PrimaryKit: ['HRV_1'],
          SecondaryKits: ['DEFAULT', 'DEFAULT_2'],
          ExternalVenueID: '851869C7E5B436D1E055005E00000019',
        },
        {
          Name: 'Bendigo',
          shortName: '',
          PrimaryKit: ['HRV_1'],
          SecondaryKits: ['DEFAULT', 'DEFAULT_2'],
          ExternalVenueID: '851869C7E8A736D1E055005E00000019',
        },
        {
          Name: 'Tabcorp Park Melton',
          shortName: '',
          PrimaryKit: ['HRV_1'],
          SecondaryKits: ['DEFAULT', 'DEFAULT_2'],
          ExternalVenueID: '851869C7F1C936D1E055005E00000019',
        },
        {
          Name: 'Cranbourne',
          shortName: '',
          PrimaryKit: ['HRV_Cranbourne'],
          SecondaryKits: ['DEFAULT', 'DEFAULT_2'],
          ExternalVenueID: '851869C7E8B236D1E055005E00000019',
        },
        {
          Name: 'Shepparton',
          shortName: '',
          PrimaryKit: ['HRV_1'],
          SecondaryKits: ['DEFAULT', 'DEFAULT_2'],
          ExternalVenueID: '851869C7E95E36D1E055005E00000019',
        },
        {
          Name: 'Mildura',
          shortName: '',
          PrimaryKit: ['HRV_Mildura'],
          SecondaryKits: ['DEFAULT', 'DEFAULT_2'],
          ExternalVenueID: '851869C7E95836D1E055005E00000019',
        },
        {
          Name: 'Geelong',
          shortName: '',
          PrimaryKit: ['HRV_1'],
          SecondaryKits: ['DEFAULT', 'DEFAULT_2'],
          ExternalVenueID: '851869C7E8BA36D1E055005E00000019',
        },
        {
          Name: 'Kilmore',
          shortName: '',
          PrimaryKit: ['HRV_1'],
          SecondaryKits: ['DEFAULT', 'DEFAULT_2'],
          ExternalVenueID: '851869C7E95536D1E055005E00000019',
        },
        {
          Name: 'Mooroopna',
          shortName: '',
          PrimaryKit: ['HRV_1'],
          SecondaryKits: ['DEFAULT', 'DEFAULT_2'],
        },
        {
          Name: 'Kyabram',
          shortName: '',
          PrimaryKit: ['HRV_1'],
          SecondaryKits: ['DEFAULT', 'DEFAULT_2'],
        },
        {
          Name: 'Melton',
          shortName: '',
          PrimaryKit: ['HRV_1'],
          SecondaryKits: ['DEFAULT', 'DEFAULT_2'],
          ExternalVenueID: '851869C7F1C936D1E055005E00000019',
        },
      ],
      Type: 'Harness Racing',
      Actions: [
        { name: 'Get Official Result', action: 'instant_api' },
        { name: 'Generate PDF', action: 'generate_pdf', preview: true },
        // { name: 'Send TOL Mail', action: 'send_tol_mail' },
        // { name: 'Upload Race files to S3', action: 'send_to_hrv' },
        // {
        //   name: 'Publish to Customer',
        //   action: 'send_mail_official',
        //   cooldown: true,
        // },
        {
          name: 'Publish to Customer',
          action: 'publish',
          status: 'publish_status',
          cooldown: true,
          preview_email: true,
        },
      ],
    },
    {
      Jurisdiction: 'Harness Queensland',
      JurisdictionCode: 'HRQ',
      State: 'QLD',
      Locations: [
        {
          Name: 'Redcliffe',
          shortName: '',
          PrimaryKit: ['QLD_Harness(QLD_KIT4)'],
          SecondaryKits: ['DEFAULT', 'DEFAULT_2'],
          ExternalVenueID: '851869C7F28736D1E055005E00000019',
        },
        {
          Name: 'Albion Park',
          shortName: '',
          PrimaryKit: ['QLD_Harness(QLD_KIT4)'],
          SecondaryKits: ['DEFAULT', 'DEFAULT_2'],
          ExternalVenueID: '851869C7F1D036D1E055005E00000019',
        },
      ],
      Type: 'Harness Racing',
      Actions: [
        { name: 'Get Official Result', action: 'instant_api' },
        {
          name: 'Generate PDF',
          action: 'generate_pdf',
          preview: true,
          conditions: [
            {
              key: 'RaceLength',
              equation: 'not equals',
              value: '947',
            },
          ],
        },
        {
          name: 'Generate PDF',
          action: 'generate_pdf_redcliffe',
          preview: true,
          conditions: [
            {
              key: 'RaceLength',
              equation: 'equals',
              value: '947',
            },
          ],
        },
        // { name: 'Upload Race files to S3', action: 'upload_files_harness' },
        {
          name: 'Publish to Customer',
          action: 'publish',
          status: 'publish_status',
          cooldown: true,
          preview_email: true,
        },
      ],
    },
    {
      Jurisdiction: 'Harness NSW',
      JurisdictionCode: 'HNSW',
      State: 'NSW',
      Locations: [
        {
          Name: 'Penrith',
          shortName: '',
          PrimaryKit: ['Penrith(HRNSW_KIT1)'],
          SecondaryKits: ['DEFAULT', 'DEFAULT_2'],
          ExternalVenueID: '851869C7ED1C36D1E055005E00000019',
        },
        {
          Name: 'Newcastle',
          shortName: '',
          PrimaryKit: ['Newcastle(HRNSW_KIT2)'],
          SecondaryKits: ['DEFAULT', 'DEFAULT_2'],
          ExternalVenueID: '851869C7ED1736D1E055005E00000019',
        },
        {
          Name: 'Tabcorp Park Menangle',
          shortName: '',
          PrimaryKit: ['Menangle(HRNSW_KIT1)'],
          SecondaryKits: ['DEFAULT', 'DEFAULT_2'],
          ExternalVenueID: '851869C7ED1436D1E055005E00000019',
        },
        {
          Name: 'Tabcorp Pk Menangle',
          shortName: '',
          PrimaryKit: ['Menangle(HRNSW_KIT1)'],
          SecondaryKits: ['DEFAULT', 'DEFAULT_2'],
          ExternalVenueID: '851869C7ED1436D1E055005E00000019',
        },
        {
          Name: 'Tabcorp Park Menangle',
          shortName: '',
          PrimaryKit: ['Menangle(HRNSW_KIT1)'],
          SecondaryKits: ['DEFAULT', 'DEFAULT_2'],
          ExternalVenueID: '851869C7ED1436D1E055005E00000019',
        },
        {
          Name: 'Bathurst',
          shortName: '',
          PrimaryKit: ['Bathurst(HRNSW_KIT3)'],
          SecondaryKits: ['DEFAULT', 'DEFAULT_2'],
          ExternalVenueID: '851869C7F94036D1E055005E00000019',
        },
      ],
      Type: 'Harness Racing',
      Actions: [
        { name: 'Get Official Result', action: 'instant_api' },
        { name: 'Generate PDF', action: 'generate_pdf', preview: true },
        // { name: 'Upload Race files to S3', action: 'upload_files_harness' },
        // {
        //   name: 'Email to Customer',
        //   action: 'send_mail_official',
        //   cooldown: true,
        // },
        {
          name: 'Publish to Customer',
          action: 'publish',
          status: 'publish_status',
          cooldown: true,
          preview_email: true,
        },
      ],
    },
  ],
  'Thoroughbred Racing': [
    {
      Jurisdiction: 'New Zealand Racing',
      JurisdictionCode: 'ARC',
      State: 'NZ',
      Locations: [
        {
          Name: 'Pukekohe Park',
          shortName: 'PUKE',
          PrimaryKit: ['NZ_Pukekohe(NZ_KIT1)'],
          SecondaryKits: ['DEFAULT', 'DEFAULT_2'],
          ExternalVenueID: 'NZL65',
        },
        {
          Name: 'Ellerslie',
          shortName: 'ELLS',
          PrimaryKit: ['NZ_Ellerslie(NZ_KIT1)'],
          SecondaryKits: ['DEFAULT', 'DEFAULT_2'],
          ExternalVenueID: 'NZL22',
        },
      ],
      Type: 'Thoroughbred Racing',
      Actions: [
        { name: 'Get Official Result', action: 'get_lif_file' },
        { name: 'Generate PDF', action: 'generate_pdf', preview: true },
        {
          name: 'Publish to Customer',
          action: 'publish',
          status: 'publish_status',
          cooldown: true,
          preview_email: true,
        },
      ],
    },
    {
      Jurisdiction: 'Racing Victoria',
      JurisdictionCode: 'RVC',
      State: 'VIC',
      Locations: [
        {
          Name: 'Flemington',
          shortName: 'FLEM',
          ftpLocation: 'Flemington',
          PrimaryKit: ['RV_1', 'RV_2', 'RV_3'],
          SecondaryKits: [
            'DEFAULT',
            'DEFAULT_2',
            'RV_1 permanent BS',
            'RV_2 permanent BS',
            'RV_3 permanent BS',
          ],
          ExternalVenueID: '151',
        },
        {
          Name: 'Sportsbet-Pakenham',
          shortName: 'PAKM',
          ftpLocation: 'Pakenham',
          PrimaryKit: ['RV_1', 'RV_2', 'RV_3'],
          SecondaryKits: [
            'DEFAULT',
            'DEFAULT_2',
            'RV_1 permanent BS',
            'RV_2 permanent BS',
            'RV_3 permanent BS',
          ],
          ExternalVenueID: '915',
        },
        {
          Name: 'Sportsbet-Pakenham Synthetic',
          shortName: 'PAKM',
          ftpLocation: 'Pakenham',
          PrimaryKit: ['RV_1', 'RV_2', 'RV_3'],
          SecondaryKits: [
            'DEFAULT',
            'DEFAULT_2',
            'RV_1 permanent BS',
            'RV_2 permanent BS',
            'RV_3 permanent BS',
          ],
          ExternalVenueID: '913',
        },
        {
          Name: 'The Valley',
          shortName: 'MVAL',
          ftpLocation: 'Moonee Valley',
          PrimaryKit: ['RV_1', 'RV_2', 'RV_3'],
          SecondaryKits: [
            'DEFAULT',
            'DEFAULT_2',
            'RV_1 permanent BS',
            'RV_2 permanent BS',
            'RV_3 permanent BS',
          ],
          ExternalVenueID: '266',
        },
        {
          Name: 'The Valley - Night',
          shortName: 'MVAL',
          ftpLocation: 'Moonee Valley',
          PrimaryKit: ['RV_1', 'RV_2', 'RV_3'],
          SecondaryKits: [
            'DEFAULT',
            'DEFAULT_2',
            'RV_1 permanent BS',
            'RV_2 permanent BS',
            'RV_3 permanent BS',
          ],
          ExternalVenueID: '266',
        },
        {
          Name: 'Caulfield',
          shortName: 'CAUL',
          ftpLocation: 'Caulfield',
          PrimaryKit: ['RV_1', 'RV_2', 'RV_3'],
          SecondaryKits: [
            'DEFAULT',
            'DEFAULT_2',
            'RV_1 permanent BS',
            'RV_2 permanent BS',
            'RV_3 permanent BS',
          ],
        },
        {
          Name: 'Ladbrokes Park Lakeside',
          shortName: 'SANL',
          ftpLocation: 'Sandown',
          PrimaryKit: ['RV_1', 'RV_2', 'RV_3'],
          SecondaryKits: [
            'DEFAULT',
            'DEFAULT_2',
            'RV_1 permanent BS',
            'RV_2 permanent BS',
            'RV_3 permanent BS',
          ],
        },
        {
          Name: 'Sportsbet Sandown Lakeside',
          shortName: 'SANL',
          ftpLocation: 'Sandown',
          PrimaryKit: ['RV_1', 'RV_2', 'RV_3'],
          SecondaryKits: [
            'DEFAULT',
            'DEFAULT_2',
            'RV_1 permanent BS',
            'RV_2 permanent BS',
            'RV_3 permanent BS',
          ],
          ExternalVenueID: '880',
        },
        {
          Name: 'Cranbourne Turf',
          shortName: 'CRAN',
          FolderLocation: 'Cranbourne Turf',
          ftpLocation: 'Cranbourne',
          PrimaryKit: ['RV_1', 'RV_2', 'RV_3'],
          SecondaryKits: [
            'DEFAULT',
            'DEFAULT_2',
            'RV_1 permanent BS',
            'RV_2 permanent BS',
            'RV_3 permanent BS',
          ],
          ExternalVenueID: '114',
        },
        {
          Name: 'Sportsbet Sandown Hillside',
          shortName: 'SANH',
          ftpLocation: 'Sandown',
          PrimaryKit: ['RV_1', 'RV_2', 'RV_3'],
          SecondaryKits: [
            'DEFAULT',
            'DEFAULT_2',
            'RV_1 permanent BS',
            'RV_2 permanent BS',
            'RV_3 permanent BS',
          ],
          ExternalVenueID: '879',
        },
        {
          Name: 'Sportsbet Sandown',
          shortName: 'SANH',
          ftpLocation: 'Sandown',
          PrimaryKit: ['RV_1', 'RV_2', 'RV_3'],
          SecondaryKits: [
            'DEFAULT',
            'DEFAULT_2',
            'RV_1 permanent BS',
            'RV_2 permanent BS',
            'RV_3 permanent BS',
          ],
        },
      ],
      Type: 'Thoroughbred Racing',
      Actions: [
        { name: 'Get Official Result', action: 'get_lif_file' },
        { name: 'Generate PDF', action: 'generate_pdf', preview: true },
        {
          name: 'Publish to Customer',
          action: 'publish',
          status: 'publish_status',
          cooldown: true,
          preview_email: true,
        },
      ],
    },
    {
      Jurisdiction: 'Queensland Racing',
      JurisdictionCode: 'QRC',
      State: 'QLD',
      Locations: [
        {
          Name: 'Sunshine Coast',
          shortName: 'SCST',
          PrimaryKit: ['GC-SCTC-IPSW(QLD_KIT2)'],
          SecondaryKits: [
            'BRC_(QLD_KIT1)',
            'QLD_Toowoomba(QLD_KIT3)',
            'DEFAULT',
            'DEFAULT_2',
          ],
          ExternalVenueID: '461',
        },
        {
          Name: 'Sunshine Coast Poly Track',
          shortName: 'SCST',
          PrimaryKit: ['GC-SCTC-IPSW(QLD_KIT2)'],
          SecondaryKits: [
            'BRC_(QLD_KIT1)',
            'QLD_Toowoomba(QLD_KIT3)',
            'DEFAULT',
            'DEFAULT_2',
          ],
          ExternalVenueID: '955',
        },
        {
          Name: 'Aquis Park Gold Coast Poly',
          shortName: 'GCPT',
          PrimaryKit: ['GC-SCTC-IPSW(QLD_KIT2)'],
          SecondaryKits: [
            'BRC_(QLD_KIT1)',
            'QLD_Toowoomba(QLD_KIT3)',
            'DEFAULT',
            'DEFAULT_2',
          ],
          ExternalVenueID: '957',
        },
        {
          Name: 'Ipswich',
          shortName: 'IPSW',
          PrimaryKit: ['GC-SCTC-IPSW(QLD_KIT2)'],
          SecondaryKits: [
            'BRC_(QLD_KIT1)',
            'QLD_Toowoomba(QLD_KIT3)',
            'DEFAULT',
            'DEFAULT_2',
          ],
          ExternalVenueID: '200',
        },
        {
          Name: 'Toowoomba',
          shortName: 'TWBA',
          PrimaryKit: ['QLD_Toowoomba(QLD_KIT3)'],
          SecondaryKits: ['GC-SCTC-IPSW(QLD_KIT2)', 'DEFAULT', 'DEFAULT_2'],
          ExternalVenueID: '393',
        },
        {
          Name: 'Mackay',
          shortName: 'MKAY',
          PrimaryKit: ['NthQld (QLD_KIT5)'],
          SecondaryKits: ['DEFAULT', 'DEFAULT_2'],
          ExternalVenueID: '242',
        },
        {
          Name: 'Townsville',
          shortName: 'TOWN',
          PrimaryKit: ['NthQld (QLD_KIT5)'],
          SecondaryKits: ['DEFAULT', 'DEFAULT_2'],
          ExternalVenueID: '396',
        },
        {
          Name: 'Ladbrokes Cannon Park',
          shortName: 'CRNS',
          PrimaryKit: ['NthQld (QLD_KIT5)'],
          SecondaryKits: ['DEFAULT', 'DEFAULT_2'],
          ExternalVenueID: '69',
        },
        {
          Name: 'Aquis Park Gold Coast',
          shortName: 'GCST',
          PrimaryKit: ['GC-SCTC-IPSW(QLD_KIT2)'],
          SecondaryKits: [
            'BRC_(QLD_KIT1)',
            'QLD_Toowoomba(QLD_KIT3)',
            'DEFAULT',
            'DEFAULT_2',
          ],
          ExternalVenueID: '165',
        },
        {
          Name: 'Rockhampton',
          shortName: '',
          PrimaryKit: ['Rocky(QLD_KIT6)'],
          SecondaryKits: ['DEFAULT', 'DEFAULT_2'],
          ExternalVenueID: '347',
        },
      ],
      Type: 'Thoroughbred Racing',
      Actions: [
        { name: 'Get Official Result', action: 'get_lif_file' },
        { name: 'Generate PDF', action: 'generate_pdf', preview: true },
        {
          name: 'Publish to Customer',
          action: 'publish',
          status: 'publish_status',
          cooldown: true,
          preview_email: true,
        },
      ],
    },
    {
      Jurisdiction: 'Australian Turf Club',
      JurisdictionCode: 'ATC',
      State: 'NSW',
      Locations: [
        {
          Name: 'Royal Randwick',
          shortName: 'RAND',
          PrimaryKit: ['ATC_1'],
          SecondaryKits: ['DEFAULT', 'DEFAULT_2'],
          ExternalVenueID: '342',
        },
        {
          Name: 'Rosehill Gardens',
          shortName: 'RHIL',
          PrimaryKit: ['ATC_1'],
          SecondaryKits: ['DEFAULT', 'DEFAULT_2'],
          ExternalVenueID: '349',
        },
        {
          Name: 'Kensington',
          shortName: 'KENS',
          PrimaryKit: ['ATC_1'],
          SecondaryKits: ['DEFAULT', 'DEFAULT_2'],
          ExternalVenueID: '854',
        },
        {
          Name: 'Warwick Farm',
          shortName: '',
          type: 'LPS',
          PrimaryKit: ['ATC_KIT2'],
          SecondaryKits: ['DEFAULT', 'DEFAULT_2'],
          ExternalVenueID: '420',
        },
        {
          Name: 'Canterbury Park',
          shortName: '',
          type: 'LPS',
          PrimaryKit: ['ATC_KIT2'],
          SecondaryKits: ['DEFAULT', 'DEFAULT_2'],
          ExternalVenueID: '76',
        },
      ],
      Type: 'Thoroughbred Racing',
      Actions: [
        {
          name: 'Get Official Result',
          action: 'get_lif_file',
          conditions: [
            {
              venues: ['Royal Randwick', 'Rosehill Gardens', 'Kensington'],
            },
          ],
        },
        {
          name: 'Get Official Result',
          action: 'get_lif_file',
          type: 'LPS',
          conditions: [
            {
              venues: ['Warwick Farm', 'Canterbury Park'],
              type: 'LPS',
            },
          ],
        },
        // { name: 'Upload Race files to S3', action: 'upload_files' },
        {
          name: 'Generate PDF',
          action: 'generate_pdf',
          type: 'LPS',
          preview: true,
          conditions: [
            {
              venues: ['Warwick Farm', 'Canterbury Park'],
              type: 'LPS',
            },
          ],
        },
        {
          name: 'Send Mail - Unofficial',
          action: 'send_mail_unofficial',
          cooldown: true,
          preview_key: 'UnofficialEmailDL',
        },
        {
          name: 'Publish to Customer',
          action: 'publish',
          status: 'publish_status',
          cooldown: true,
          preview_email: true,
        },
      ],
    },
    {
      Jurisdiction: 'Brisbane Racing',
      JurisdictionCode: 'BRC',
      State: 'QLD',
      Locations: [
        {
          Name: 'Eagle Farm',
          shortName: 'EAGL',
          PrimaryKit: ['BRC_(QLD_KIT1)'],
          SecondaryKits: [
            'GC-SCTC-IPSW(QLD_KIT2)',
            'QLD_Toowoomba(QLD_KIT3)',
            'DEFAULT',
            'DEFAULT_2',
          ],
          ExternalVenueID: '135',
        },
        {
          Name: 'Doomben',
          shortName: 'DOOM',
          PrimaryKit: ['BRC_(QLD_KIT1)'],
          SecondaryKits: [
            'GC-SCTC-IPSW(QLD_KIT2)',
            'QLD_Toowoomba(QLD_KIT3)',
            'DEFAULT',
            'DEFAULT_2',
          ],
          ExternalVenueID: '130',
        },
      ],
      Type: 'Thoroughbred Racing',
      Actions: [
        { name: 'Get Official Result', action: 'get_lif_file' },
        { name: 'Generate PDF', action: 'generate_pdf', preview: true },
        {
          name: 'Publish to Customer',
          action: 'publish',
          status: 'publish_status',
          cooldown: true,
          preview_email: true,
        },
      ],
    },
    {
      Jurisdiction: 'Racing South Australia',
      JurisdictionCode: 'RSA',
      State: 'SA',
      Locations: [
        {
          Name: 'Morphettville Parks',
          shoName: 'MRPK',
          oldShortName: 'MORP',
          PrimaryKit: ['Morphettville-MurrayBridge(SA_KIT1)'],
          SecondaryKits: ['DEFAULT', 'DEFAULT_2'],
          ExternalVenueID: '884',
        },
        {
          Name: 'Morphettville',
          shoName: 'MRPK',
          oldShortName: 'MORP',
          PrimaryKit: ['Morphettville-MurrayBridge(SA_KIT1)'],
          SecondaryKits: ['DEFAULT', 'DEFAULT_2'],
          ExternalVenueID: '272',
        },
        {
          Name: 'Gawler',
          shortName: 'GAWL',
          PrimaryKit: ['SA_Gawler(SA_KIT1)'],
          SecondaryKits: ['DEFAULT', 'DEFAULT_2'],
          ExternalVenueID: '156',
        },
        {
          Name: 'Thomas Farms RC Murray Bridge',
          shortName: 'MUBR',
          PrimaryKit: ['Morphettville-MurrayBridge(SA_KIT1)'],
          SecondaryKits: ['DEFAULT', 'DEFAULT_2'],
          ExternalVenueID: '2131',
        },
        {
          Name: 'Sportsbet Gawler',
          shortName: '',
          PrimaryKit: ['SA_Gawler(SA_KIT1)'],
          SecondaryKits: ['DEFAULT', 'DEFAULT_2'],
          ExternalVenueID: '156',
        },
        {
          Name: 'Gawler',
          shortName: '',
          PrimaryKit: ['SA_Gawler(SA_KIT1)'],
          SecondaryKits: ['DEFAULT', 'DEFAULT_2'],
          ExternalVenueID: '156',
        },
      ],
      Type: 'Thoroughbred Racing',
      Actions: [
        { name: 'Get Official Result', action: 'get_lif_file' },
        { name: 'Generate PDF', action: 'generate_pdf', preview: true },
        {
          name: 'Publish to Customer',
          action: 'publish',
          status: 'publish_status',
          cooldown: true,
          preview_email: true,
        },
      ],
    },
    {
      Jurisdiction: 'Singapore Racing',
      JurisdictionCode: 'STC',
      State: 'SG',
      Locations: [
        {
          Name: 'Singapore Turf Club',
          shortName: 'STC',
          PrimaryKit: ['Singapore(STC)'],
          SecondaryKits: ['DEFAULT', 'DEFAULT_2'],
          ExternalVenueID: 'STC001',
        },
      ],
      Type: 'Thoroughbred Racing',
      Actions: [
        { name: 'Get Official Result', action: 'get_lif_file' },
        // { name: 'Generate PDF', action: 'generate_pdf', preview: true },
        {
          name: 'Publish to Customer',
          action: 'publish',
          status: 'publish_status',
          cooldown: true,
          preview_email: true,
        },
      ],
    },
    {
      Jurisdiction: 'Magic Millions',
      JurisdictionCode: 'MMR',
      State: 'MMR',
      Locations: [
        {
          Name: 'Newcastle',
          PrimaryKit: [
            'BRC_(QLD_KIT1)',
            'GC-SCTC-IPSW(QLD_KIT2)',
            'QLD_Toowoomba(QLD_KIT3)',
            'HRV_Mildura',
            'Newcastle(HRNSW_KIT2)',
          ],
          SecondaryKits: [],
          ExternalVenueID: '301',
        },
        {
          Name: 'Seymour',
          PrimaryKit: [
            'BRC_(QLD_KIT1)',
            'GC-SCTC-IPSW(QLD_KIT2)',
            'QLD_Toowoomba(QLD_KIT3)',
            'HRV_Mildura',
            'Newcastle(HRNSW_KIT2)',
          ],
          SecondaryKits: [],
          ExternalVenueID: '355',
        },
        {
          Name: 'Gold Coast Poly Track',
          PrimaryKit: [
            'BRC_(QLD_KIT1)',
            'GC-SCTC-IPSW(QLD_KIT2)',
            'QLD_Toowoomba(QLD_KIT3)',
            'HRV_Mildura',
            'Newcastle(HRNSW_KIT2)',
          ],
          SecondaryKits: [],
          ExternalVenueID: '957',
        },
      ],
      Type: 'Thoroughbred Racing',
      Actions: [
        // { name: 'Get Official Result', action: 'get_lif_file' },
        { name: 'Generate PDF', action: 'generate_pdf', preview: true },
        {
          name: 'Publish to Customer',
          action: 'publish',
          status: 'publish_status',
          cooldown: true,
          preview_email: true,
        },
      ],
    },
  ],
  'Greyhound Racing': [
    {
      Jurisdiction: 'TASRacing',
      JurisdictionCode: 'GR',
      State: 'GR',
      Locations: [
        {
          Name: 'Launceston',
          shortName: 'LAUN',
        },
        {
          Name: 'Hobart',
          shortName: 'HOB',
        },
      ],
      Type: 'Greyhound Racing',
      Actions: [
        { name: 'Get Official Result', action: 'get_lif_file' },
        { name: 'Generate PDF', action: 'generate_pdf', preview: true },
      ],
    },
  ],
};

export const buttonActions = [
  {
    name: 'Get Official Result',
    action: 'get_lif_file',
    type: 'Thoroughbred Racing',
    slug: 'OR',
    states: ['ATC', 'BRC', 'QRC', 'RSA', 'STC', 'RVC', 'ARC', 'GR'],
  },
  {
    name: 'Get Official Result',
    action: 'instant_api',
    type: 'Harness Racing',
    slug: 'OR',
    states: ['HNSW', 'HRQ', 'HRV'],
  },
  // {
  //   name: 'Send TOL',
  //   action: 'send_tol_mail',
  //   type: 'Harness Racing',
  //   slug: 'CUST',
  //   states: ['HRV'],
  // },
  // {
  //   name: 'Upload Race files to S3',
  //   action: 'upload_files',
  //   slug: 'TSD',
  //   states: ['ATC', 'BRC', 'NZ', 'QRC', 'RSA', 'STC'],
  // },
  // {
  //   name: 'Upload Race files to S3',
  //   action: 'race_check_hrv',
  //   slug: 'TSD',
  //   states: ['HRV'],
  // },
  {
    name: 'Upload Files to FTP',
    action: 'Upload Files to FTP',
    slug: 'TSD',
    states: ['HRQ'],
  },
  {
    name: 'Generate PDF',
    action: 'generate_pdf',
    slug: 'PDF',
    states: ['HRQ'],
    conditions: [
      {
        state: 'HRQ',
        condition: {
          key: 'RaceLength',
          equation: 'not equals',
          value: '947',
        },
      },
    ],
  },
  {
    name: 'Generate PDF',
    action: 'generate_pdf_redcliffe',
    slug: 'PDF',
    states: ['HRQ'],
    conditions: [
      {
        state: 'HRQ',
        condition: {
          key: 'RaceLength',
          equation: 'equals',
          value: '947',
        },
      },
    ],
  },
  {
    name: 'Generate PDF',
    action: 'generate_pdf',
    slug: 'PDF',
    states: ['NZ', 'QRC', 'RSA', 'RSW', 'HNSW', 'HRV', 'BRC', 'GR', 'MMR'],
  },
  {
    name: 'LPS',
    action: 'LPS',
    slug: 'PDF',
    states: ['ATC'],
  },
  {
    name: 'Default',
    action: 'Default',
    slug: 'PDF',
    states: ['ATC'],
  },
  {
    name: 'Processed',
    action: 'Processed',
    slug: 'PDF',
    states: ['ATC'],
  },
  // {
  //   name: 'Publish to Customer',
  //   action: 'send_to_hrv',
  //   slug: 'CUST',
  //   states: ['ATC', 'BRC', 'NZ', 'QRC', 'RSA', 'STC'],
  // },
  // {
  //   name: 'Publish to Customer',
  //   action: 'send_to_hrv',
  //   slug: 'TSD',
  //   states: ['HRV'],
  // },
  // {
  //   name: 'Publish to Customer',
  //   action: 'upload_files_harness',
  //   slug: 'CUST',
  //   states: ['HRQ'],
  // },
  {
    name: 'Send EMail - Official',
    action: 'Send EMail - Official',
    cooldown: true,
    slug: 'CUST',
    states: ['HNSW', 'HRV', 'RVC', 'BRC', 'QRC', 'ARC', 'ATC', 'RSA', 'STC'],
  },
  {
    name: 'Send Mail - Unofficial',
    action: 'send_mail_unofficial',
    cooldown: true,
    slug: 'CUST',
    states: ['ATC'],
  },
  {
    name: 'Upload Files to Champions Data S3',
    action: 'Upload Files to Champions Data S3',
    slug: 'CUST',
    states: ['RVC', 'RSA'],
  },
  {
    name: 'Upload Files to ATC FTP',
    action: 'Upload Files to ATC FTP',
    slug: 'CUST',
    states: ['ATC'],
  },
  {
    name: 'Upload Files to QLD FTP',
    action: 'Upload Files to QLD FTP',
    slug: 'CUST',
    states: ['BRC', 'QRC'],
  },
  {
    name: 'Upload Files to HRV S3',
    action: 'Upload Files to HRV S3',
    slug: 'TSD',
    states: ['HRV'],
  },
  // {
  //   name: 'Publish to Customer',
  //   action: 'send_mail_official',
  //   cooldown: true,
  //   slug: 'CUST',
  //   states: ['HRV'],
  // },
  // {
  //   name: 'Upload Race files to S3',
  //   action: 'upload_files_harness',
  //   slug: 'TSD',
  //   states: ['HRQ'],
  // },
  // {
  //   name: 'Generate PDF',
  //   action: 'generate_pdf',
  //   slug: 'PDF',
  //   states: ['BRC'],
  // },
  // {
  //   name: 'Publish to Customer',
  //   action: 'publish',
  //   slug: 'CUST',
  //   states: ['HRQ', 'HNSW', 'HRV'],
  // },
];
