import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { APIService } from 'src/app/services/api.service';
import { NotifierService } from 'src/app/services/notifier.service';

import VenueEmails from '../../../pages/components/services/emails';
import { getENV } from 'src/app/_helpers/helpers';

declare let window: any;
declare let Jhxlsx: any;
@Component({
  //moduleId: module.id.toString(),
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css'],
})
export class UsersComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  userList: any = [];

  funRef: any = {
    selectUser: this.selectUser,
    updateUserActionCaller: this.updateUserActionCaller,
  };

  filterText: string = '';

  selectedUser: any = null;

  isUserAdmin: boolean = localStorage.getItem('isUserAdmin') == '1';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin' ||
    this.isUserAdmin;

  loading: boolean = true;

  groupByAdmins: boolean = false;

  venues: any = [];

  userHeaders: any = [
    {
      id: 'clientName',
      name: 'Client Name',
    },
    { id: 'emaiId', name: 'Email ID', click: 'selectUser' },
    {
      id: 'mobileNumber',
      name: 'Phone Number',
    },
    // {
    //   id: 'accountDetailsList',
    //   name: 'AWS Account Groups',
    // },
  ];

  adminUsersheader: any = [
    {
      id: 'userName',
      name: 'User Name',
      click: 'updateUserActionCaller',
    },
    {
      id: 'userId',
      name: 'User ID',
    },
    {
      id: 'cmpName',
      name: 'Company Name',
    },
    {
      id: 'phNumber',
      name: 'Phone Number',
    },
    // {
    //   id: 'accountGroup',
    //   name: 'Account Groups',
    // },
  ];

  userTypes: any = {
    userAdmin: 'Admin',
    user: 'General',
    superAdmin: 'Master Admin',
  };

  updateUser: any;
  userAction: string | null = null;
  config: any;

  constructor(
    private apiServer: APIService,
    private notifier: NotifierService,
    private route: ActivatedRoute
  ) {
    this.config = this.route.snapshot.data['config'];
  }
  ngOnInit(): void {
    this.load();
  }

  updateUserActionCaller(ref: any, value: any) {
    ref.updateUserAction(value);
  }

  ngOnDestroy(): void {
  }

  selectUser(ref: any, value: any) {
    ref.selectedUser = value;
  }

  statusFilter: string = 'ALL';
  filteredUserList() {
    return this.userList.filter((user: any) => {
      let check: boolean = true;
      if (this.groupByAdmins) {
        check =
          user['userType'] == 'userAdmin' || user['userType'] == 'superAdmin';
      }
      if (this.statusFilter != 'ALL' && check) {
        if (this.statusFilter == 'ACTIVE') {
          check = user['accountStatus'];
        } else {
          check = !user['accountStatus'];
        }
      }
      return (
        (user['clientName'].toLowerCase().indexOf(this.filterText) > -1 ||
          user['emaiId'].toLowerCase().indexOf(this.filterText) > -1) &&
        check
      );
    });
  }

  export() {
    var dict: any = {
      sheetName: 'Budgets',
      data: [],
    };
    let headers: any = [
      { text: 'Sl. No.' },
      { text: 'Client Name' },
      { text: 'Email ID' },
      { text: 'Phone Number' },
      { text: 'AWS Account Groups' },
      { text: 'User Type' },
      { text: 'Created By' },
    ];
    dict.data.push(headers);
    this.filteredUserList().forEach((user: any, index: number) => {
      let data = [];
      data.push({ text: index + 1 });
      data.push({ text: user['userName'] });
      data.push({ text: user['email'] });
      data.push({ text: user['mobileNumber'] });
      data.push({
        text:
          user['accountDetailsList'] && user['accountDetailsList'].length > 0
            ? user['accountDetailsList']
              .map((account: any) => {
                return account['accountId'];
              })
              .join(', ')
            : '-',
      });
      data.push({ text: this.userTypes[user['userType']] });
      data.push({ text: user['masterClientId'] });
      dict.data.push(data);
    });
    var options = {
      fileName: `Users - ${this.userId}`,
    };
    Jhxlsx.export([dict], options);
  }

  async sendResendLink(user: any) {
    user['resend'] = true;
    let data: any = {
      action: 'resendRegistrationLink',
      clientEmailID: user['emaiId'],
      clientName: user['clientName'],
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${this.config[getENV()].apiURL}/${this.urlPrefix}/users/resendlink`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.s == '1' || result.status == '1') {
      this.notifier.alert('Success', '', result.msg, 'success', -1);
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }
    user['resend'] = false;
  }

  async loadVenues() {
    let venueEmail: VenueEmails = new VenueEmails(
      this.apiServer,
      this.config[getENV()],
      this.notifier
    );
    let result: any = await venueEmail.loadEmails();
    if(result.status == '1' || result.s == '1') {
      this.venues = result.data;
    } else {
      this.venues = [];
    }
  }

  showSuperAdmin() {
    if (this.groupByAdmins) {
      this.userList.unshift({
        clientId: window.uuidv4(),
        clientName: localStorage.getItem('un'),
        clientCmpName: localStorage.getItem('com'),
        emaiId: this.userId,
        mobileNumber: localStorage.getItem('ph'),
        userType: 'superAdmin',
        // accountDetailsList: accounts
        //   ? accounts.map((account: any) => {
        //       return {
        //         accountId: account.accountId,
        //         accountName: account.accountName,
        //       };
        //     })
        //   : [],
        policyStatus: false,
        // mappedAccounts: 0,
        otpReq: localStorage.getItem('otpReq') == '1',
        tagsEnabled: false,
        masterClientId: this.userId,
        accountStatus: 'active',
      });
    } else {
      this.userList.splice(0, 1);
    }
  }

  async load() {
    this.notifier.loading(true);
    this.loadVenues();
    this.userList = [];
    this.selectedUser = null;
    this.loading = true;
    let data: any = {
      a: 'fetchUsers',
      requester: this.userId,
    };

    // if (this.urlPrefix == "admin") {
    //   data = { userId: this.userId };
    // }

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${this.config[getENV()].apiURL}/${this.urlPrefix}/Settings/usermanagement`;

    if (this.urlPrefix == 'client') {
      data['a'] = 'fetchAll';
      data['email'] = this.userId;
    }

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.s == '1' || result.status == '1') {
      if (
        this.ut == 'admin' &&
        this.urlPrefix == 'client' &&
        this.groupByAdmins
      ) {
        let accounts: any = localStorage.getItem('accountIdData');
        if (accounts) {
          accounts = JSON.parse(accounts)['list'];
        }
      }

      if (this.urlPrefix == 'admin') {
        this.userList = result.data;
      } else {
        this.userList = result.clients;
      }
      this.userList = this.userList
        .filter((user: any) => {
          return this.userTypes[user['userType']];
        })
        .map((user: any) => {
          if (this.urlPrefix == 'admin') {
            user['clientName'] = user['userName'];
            user['emaiId'] = user['email'];
            user['userId'] = user['email'];
            user['cmpName'] = user['company'];
            user['phNumber'] = user['mobile'];
            // user['accountGroup'] = user.accountGroups;
          } else {
            user['clientName'] = user['userName'];
            user['emaiId'] = user['email'];
            user['clientName'] = user['userName'];
            user['clientCmpName'] = user['company'];
            user['mobileNumber'] = user['mobile'];
            user['userType'] = user['userType'];
            user['masterClientId'] = user['masterId'];
            // user["accountDetailsList"] = user["accountIdList"].join(', ');
            // user["accountsList"] = user["accountIdList"];
            user['otpReq'] = user['mfaRequired'];
          }
          return user;
        });
      if (this.groupByAdmins) {
        this.showSuperAdmin();
      }
    } else {
      this.notifier.alert('Info', '', result.error || result.m, 'info', 5000);
    }

    this.notifier.loading(false);
    this.loading = false;
  }

  updateUserAction(group: any) {
    this.userAction = 'update';
    this.updateUser = group;
  }

  async hideUpdateUser(event: any) {
    this.updateUser = null;
    if (event) {
      await this.load();
    }
  }

  createUser() {
    this.userAction = 'add';
    if (this.urlPrefix == 'client') {
      this.updateUser = {
        clientId: window.uuidv4(),
        clientName: '',
        clientCmpName: 'TripleSdata',
        emaiId: '',
        mobileNumber: '',
        designation: '',
        accessType: 'readOnly',
        userType: 'user',
        accountDetailsList: [],
        otpReq: true,
        policyStatus: false,
      };
    } else {
      this.updateUser = {
        userName: '',
        userId: '',
        phNumber: '',
        cmpName: localStorage.getItem('com'),
        userType: 'user',
        accountGroup: [],
      };
    }
  }

  callFunction(name: any, param: any) {
    this.funRef[name](this, param);
  }
}
