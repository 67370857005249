<div class="card">
  <div class="card-body">
    <div class="flex-between mb-2">
      <a
        routerLink="/dash/race-overview"
        [queryParams]="{ date: date }"
        class="btn btn-light inline-flex-start"
      >
        <i class="fa fa-angle-left" aria-hidden="true"></i>
        <span>Back to Event Day Overview</span>
      </a>
      <div class="flex-start">
        <a
          *ngIf="currentDateEvents['prev']"
          (click)="
            navigate(
              currentDateEvents['prev']['external_event_id'],
              currentDateEvents['prev']['external_venue_id'],
              date,
              currentDateEvents['prev']['venue_name']
            )
          "
          class="btn btn-light"
          >Prev:
          <strong>{{ currentDateEvents["prev"]["venue_name"] }}</strong></a
        >
        <a
          *ngIf="currentDateEvents['next']"
          (click)="
            navigate(
              currentDateEvents['next']['external_event_id'],
              currentDateEvents['next']['external_venue_id'],
              date,
              currentDateEvents['next']['venue_name']
            )
          "
          class="btn btn-light"
          >Next:
          <strong>{{ currentDateEvents["next"]["venue_name"] }}</strong></a
        >
      </div>
    </div>
    <div class="row">
      <table>
        <tbody>
          <tr>
            <td>
              <img
                src="https://tsd-prd01-logos.s3.ap-southeast-2.amazonaws.com/venues/{{
                  venueId
                }}.png"
                alt="Location Image"
                class="venue-image"
              />
            </td>
            <td>
              <div class="flex-start mb-3">
                <label>Race Location: </label>
                <span>{{ eventName }}</span>
              </div>
              <div class="flex-start mb-3">
                <label>Event Date: </label>
                <span>{{ date }}</span>
              </div>
              <div class="flex-start mb-3">
                <label>Venue ID: </label>
                <span>{{ venueId }}</span>
              </div>
              <div class="flex-start mb-3">
                <label>Event ID: </label>
                <span>{{ eventId }}</span>
              </div>
              <div class="flex-start mb-3">
                <label>Event Status: </label>
                <span *ngIf="event['status_edit']" class="flex-start">
                  <select
                    [(ngModel)]="event['status_edit']"
                    class="form-select"
                  >
                    <option *ngFor="let item of event_states" [value]="item">
                      {{ item }}
                    </option>
                  </select>
                  <span class="flex-start">
                    <i
                      class="fa fa-check-circle text-success pointer"
                      (click)="selectEventStatus()"
                    ></i>
                    <i
                      class="fa fa-times-circle text-danger pointer"
                      (click)="event.status_edit = undefined"
                    ></i>
                  </span>
                </span>
                <span *ngIf="!event['status_edit']">
                  <div class="flex-start">
                    <span
                      class="status-text-container alert inline-flex-start"
                      [ngClass]="{
                        'alert-primary':
                          event?.status?.toUpperCase() == 'RUNNING',
                        'alert-success': event.status == 'COMPLETED',
                        'alert-dark':
                          event.status == 'PLANNED' ||
                          event.status == 'PUBLISHED',
                        'alert-danger':
                          event.status == 'INCOMPLETED' ||
                          event.status == 'CANCELLED' ||
                          event.status == 'DIDNOTTRACK' ||
                          event.status == 'ABANDONED'
                      }"
                    >
                      <i
                        class="fa fa-circle color-inherit"
                        aria-hidden="true"
                      ></i>
                      <span class="color-inherit">{{
                        event["status"] || "-"
                      }}</span>
                    </span>
                    <i
                      class="fa fa-pencil pointer"
                      (click)="event.status_edit = event.status || ''"
                    ></i>
                  </div>
                </span>
              </div>
              <div class="flex-start mb-3">
                <label>Operator Name: </label>
                <span>{{ event["operator"] || "-" }}</span>
              </div>
              <div class="flex-start mb-3">
                <label>PC Number: </label>
                <span class="flex-start">
                  <span>{{ event["pc"] || "-" }}</span>
                  <span class="nav-link" *ngIf="event['logoff'] == '1' || !event['pc']" (click)="prepareForRace('manual_login')">
                    Manual Login
                  </span>
                </span>
              </div>
            </td>
            <td *ngIf="event">
              <div class="flex-start mb-3">
                <label>Kit:</label>
                <span class="flex-start">
                  <ng-container *ngIf="event.kit_edit != undefined">
                    <select
                      class="form-select"
                      [disabled]="formDisabled"
                      [(ngModel)]="event.kit_edit"
                    >
                      <option [value]="kit" *ngFor="let kit of kits">
                        {{ kit }}
                      </option>
                    </select>
                    <span class="flex-start">
                      <i
                        class="fa fa-check-circle text-success pointer"
                        (click)="selectKit()"
                      ></i>
                      <i
                        class="fa fa-times-circle text-danger pointer"
                        (click)="event.kit_edit = undefined"
                      ></i>
                    </span>
                  </ng-container>
                  <ng-container *ngIf="event.kit_edit == undefined">
                    <span>{{ event.kit || "Not Selected" }}</span>
                    <i
                      class="fa fa-pencil pointer"
                      *ngIf="!kitsLoading && !formDisabled"
                      (click)="event.kit_edit = event.kit || ''"
                    ></i>
                  </ng-container>

                  <img
                    class="image_loader"
                    src="./assets/img/loading_2.svg"
                    alt=""
                    *ngIf="kitsLoading"
                  />
                </span>
              </div>
              <!-- <div class="flex-start mb-3">
                <label>Barrier Tag Assignment:</label>
                <span class="flex-start">
                  <select
                    class="form-select"
                    [disabled]="formDisabled"
                    [(ngModel)]="event.barrier_tag_assignment"
                  >
                    <option [value]="kit" *ngFor="let kit of barrierKits">
                      {{ kit }}
                    </option>
                  </select>
                  <img
                    class="image_loader"
                    *ngIf="kitsLoading"
                    src="./assets/img/loading_2.svg"
                    alt=""
                  />
                </span>
              </div> -->
              <div class="flex-start mb-3">
                <label>Tag Assignment:</label>
                <span class="flex-start">
                  <ng-container *ngIf="event.tag_assignment_edit != undefined">
                    <select
                      class="form-select"
                      [disabled]="formDisabled"
                      [(ngModel)]="event.tag_assignment_edit"
                    >
                      <option value="sequential">Sequential</option>
                      <option value="set">Set</option>
                    </select>
                    <span class="flex-start">
                      <i
                        *ngIf="!kitsLoading && !formDisabled"
                        class="fa fa-check-circle text-success pointer"
                        (click)="selectTagAssignment()"
                      ></i>
                      <i
                        class="fa fa-times-circle text-danger pointer"
                        (click)="event.tag_assignment_edit = undefined"
                      ></i>
                    </span>
                  </ng-container>
                  <ng-container *ngIf="event.tag_assignment_edit == undefined">
                    <span>{{
                      event.tag_assignment
                        ? tagAssignmentOptionsMapping[event.tag_assignment]
                        : "Not Selected"
                    }}</span>
                    <i
                      class="fa fa-pencil pointer"
                      *ngIf="!kitsLoading"
                      (click)="event.tag_assignment_edit = event.tag_assignment"
                    ></i>
                  </ng-container>
                </span>
              </div>
              <div class="flex-start mb-3">
                <label>Rail:</label>
                <span class="flex-start">
                  <ng-container *ngIf="event.rail_edit != undefined">
                    <select
                      class="form-select"
                      [disabled]="formDisabled"
                      [(ngModel)]="event.rail_edit"
                    >
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                      <option value="13">13</option>
                      <option value="14">14</option>
                      <option value="15">15</option>
                      <option value="split">Split (Input custom text)</option>
                      <option value="custom">
                        Individual Rail Number (Input custom number)
                      </option>
                    </select>
                    <input
                      *ngIf="event.rail_edit === 'custom'"
                      type="number"
                      id="custom_rail"
                      name="custom_rail"
                      class="form-control"
                      placeholder="Enter your custom rail number"
                      [disabled]="formDisabled"
                      [(ngModel)]="event.custom_rail"
                    />
                    <input
                      *ngIf="event.rail_edit === 'split'"
                      type="text"
                      id="custom_split"
                      name="custom_split"
                      class="form-control"
                      placeholder="Enter custom split text"
                      [disabled]="formDisabled"
                      [(ngModel)]="event.custom_split"
                    />

                    <span class="flex-start">
                      <i
                        class="fa fa-check-circle text-success pointer"
                        (click)="selectRail()"
                      ></i>
                      <i
                        class="fa fa-times-circle text-danger pointer"
                        (click)="event.rail_edit = undefined"
                      ></i>
                    </span>
                  </ng-container>

                  <ng-container *ngIf="event.rail_edit == undefined">
                    <span>{{
                      (event.rail == "custom"
                        ? event.custom_rail
                        : event.custom_split || event.rail) || "Not Selected"
                    }}</span>
                    <span class="flex-start">
                      <i
                        *ngIf="!kitsLoading && !formDisabled"
                        class="fa fa-pencil pointer"
                        (click)="
                          event.rail_edit = event.rail || event.custom_rail
                        "
                      ></i>
                      <span
                        class="btn btn-primary-soft flex-start"
                        [class.btn-success-soft]="event.rail_green"
                        (click)="generateRail()"
                        *ngIf="
                          event['raceType'] != 'Greyhound Racing' &&
                          !rail_loading
                        "
                      >
                        <i
                          class="fa fa-check-circle color-inherit"
                          aria-hidden="true"
                          *ngIf="event.rail_green; else rail_calculator"
                        ></i>

                        <ng-template #rail_calculator>
                          <i
                            class="fa fa-calculator color-inherit"
                            aria-hidden="true"
                          ></i>
                        </ng-template>
                        Rail Calculator
                      </span>
                      <img
                        class="image_loader"
                        *ngIf="rail_loading"
                        src="./assets/img/loading_2.svg"
                        alt=""
                      />
                    </span>
                  </ng-container>
                </span>
              </div>
              <div class="flex-start mb-3">
                <label>Weather: </label>
                <span
                  *ngIf="event['weather_edit'] !== undefined"
                  class="flex-start"
                >
                  <select
                    [(ngModel)]="event['weather_edit']"
                    class="form-select"
                  >
                    <option *ngFor="let item of weather_options" [value]="item">
                      {{ item }}
                    </option>
                  </select>
                  <span class="flex-start">
                    <i
                      class="fa fa-check-circle text-success pointer"
                      (click)="selectWeatherStatus()"
                    ></i>
                    <i
                      class="fa fa-times-circle text-danger pointer"
                      (click)="event.weather_edit = undefined"
                    ></i>
                  </span>
                </span>
                <span *ngIf="event['weather_edit'] === undefined">
                  <div class="flex-start">
                    <span class="status-text-container alert inline-flex-start">
                      <i
                        class="fa fa-circle color-inherit"
                        aria-hidden="true"
                      ></i>
                      <span class="color-inherit">{{
                        event["weather"] || "-"
                      }}</span>
                    </span>
                    <i
                      class="fa fa-pencil pointer"
                      (click)="event.weather_edit = event.weather || ''"
                    ></i>
                  </div>
                </span>
              </div>
              <div class="flex-start mb-3">
                <label>Track Condition: </label>
                <span
                  *ngIf="event['track_condition_edit'] !== undefined"
                  class="flex-start"
                >
                  <select
                    [(ngModel)]="event['track_condition_edit']"
                    class="form-select"
                  >
                    <option
                      *ngFor="let item of track_condition_options"
                      [value]="item"
                    >
                      {{ item }}
                    </option>
                  </select>
                  <input
                    *ngIf="event.weather === 'custom'"
                    type="text"
                    id="custom_weather"
                    name="custom_weather"
                    class="form-control"
                    placeholder="Enter custom split text"
                    [disabled]="formDisabled"
                    [(ngModel)]="event.custom_weather"
                  />
                  <span class="flex-start">
                    <i
                      class="fa fa-check-circle text-success pointer"
                      (click)="selectTrackConditionStatus()"
                    ></i>
                    <i
                      class="fa fa-times-circle text-danger pointer"
                      (click)="event.track_condition_edit = undefined"
                    ></i>
                  </span>
                </span>
                <span *ngIf="event['track_condition_edit'] === undefined">
                  <div class="flex-start">
                    <span class="status-text-container alert inline-flex-start">
                      <i
                        class="fa fa-circle color-inherit"
                        aria-hidden="true"
                      ></i>
                      <span class="color-inherit">{{
                        event["track_condition"] || "-"
                      }}</span>
                    </span>
                    <i
                      class="fa fa-pencil pointer"
                      (click)="
                        event.track_condition_edit = event.track_condition || ''
                      "
                    ></i>
                  </div>
                </span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row p-2" *ngIf="event">
      <ng-container *ngIf="event['kit'] == event['old_kit']; else newKit">
        <div
          *ngIf="event['pdf_status'] == 'GENERATED'"
          class="alert alert-success"
          role="alert"
        >
          Generated PDF, please click
          <a
            class="nav-link"
            [attr.href]="event['pdf_file_path']"
            target="_blank"
            >this link</a
          >
          to view the start list file.
        </div>
        <div
          *ngIf="event['pdf_status'] == 'NOT_GENERATED'"
          class="alert alert-warning"
          role="alert"
        >
          Please press generate button to generate the start list file
        </div>
        <div
          *ngIf="event['pdf_status'] == 'GENERATING'"
          class="alert alert-primary flex-between"
          role="alert"
        >
          <div>
            Generating PDF, please wait. Please refrain from pressing generate
            button again.
          </div>
          <img class="image_loader" src="./assets/img/loading_2.svg" alt="" />
        </div>
        <div
          *ngIf="event['pdf_status'] == 'LOADING'"
          class="alert alert-primary flex-between"
          role="alert"
        >
          <div>Loading Start List</div>
          <img class="image_loader" src="./assets/img/loading_2.svg" alt="" />
        </div>
        <div
          *ngIf="event['pdf_status'] == 'FAILED'"
          class="alert alert-danger"
          role="alert"
        >
          Error encountered while generating: {{ event["pdf_error"] }}
        </div>
      </ng-container>
      <ng-template #newKit>
        <div class="alert alert-warning" role="alert" *ngIf="!event['old_kit']">
          Kit has been assigned. Please <strong>Submit</strong> and Perform
          <strong>Generate Start List</strong>
          again.
        </div>
        <div class="alert alert-warning" role="alert" *ngIf="event['old_kit']">
          Kit has been changed. Please <strong>Submit</strong> and Perform
          <strong>Generate Start List</strong>
          again.
        </div>
      </ng-template>
    </div>
    <div class="row pt-2" *ngIf="event">
      <div class="flex-end">
        <!-- <button class="btn btn-primary-soft" (click)="generateRail()">
          Rail Calculator
        </button> -->
        <button *ngIf="ut == 'admin'" class="btn btn-primary-soft" (click)="showStaging()">Staging Management</button>
        <button
          class="btn btn-primary-soft"
          (click)="jiraTracker = this.venueId"
        >
          Notes
        </button>
        <button class="btn btn-primary-soft" (click)="submitVenue()">
          Submit
        </button>
        <button
          class="btn btn-primary-soft flex-start"
          (click)="updateScratchings()"
          [disabled]="event.loadingScrating"
        >
          <img
            src="./assets/img/loading_2.svg"
            class="img-18"
            alt=""
            *ngIf="event.loadingScrating"
          />
          Update Scratchings
        </button>
        <button
          class="btn btn-primary-soft flex-start"
          (click)="generateStartList()"
          [ngClass]="{ 'btn-success-soft': event['pdf_status'] == 'GENERATED' }"
          [attr.disabled]="
            event['pdf_status'] == 'GENERATING' ||
            event['pdf_status'] == 'LOADING'
              ? true
              : null
          "
        >
          <img
            src="./assets/img/loading_2.svg"
            class="img-18"
            alt=""
            *ngIf="
              event['pdf_status'] == 'GENERATING' ||
              event['pdf_status'] == 'LOADING'
            "
          />
          <i
            class="fa fa-check-circle color-inherit"
            *ngIf="event['pdf_status'] == 'GENERATED'"
          ></i>
          Generate Start List
        </button>
        <button
          class="btn btn-primary-soft flex-start"
          [class.btn-success-soft]="
            event['startlist'] && !loadingStartListEmail
          "
          (click)="emailStartList()"
          *ngIf="event && event['pdf_status'] == 'GENERATED'"
          [attr.disabled]="loadingStartListEmail ? true : null"
        >
          <img
            src="./assets/img/loading_2.svg"
            class="img-18"
            alt=""
            *ngIf="loadingStartListEmail"
          />
          <i
            class="fa fa-check-circle color-inherit"
            *ngIf="event['startlist'] && !loadingStartListEmail"
          ></i>
          Email Start List
        </button>
        <ng-container>
          <button
            class="btn btn-primary-soft flex-start"
            (click)="prepareForRace('login')"
            *ngIf="event['raceType'] != 'Greyhound Racing'"
            [attr.disabled]="kitsLoading ? true : null"
            [ngClass]="{ 'btn-success-soft': event['pc'] }"
          >
            <i class="fa fa-check-circle color-inherit" *ngIf="event['pc']"></i>
            {{ event["pc"] ? "Rerun" : "Prepare System" }}
          </button>
          <a
            routerLink="/dash/post-race/race-reports"
            [ngClass]="{
              'btn-success-soft':
                event['publishCompleted'] != 0 &&
                event['publishCompleted'] == races.length
            }"
            [attr.disabled]="!this.event['pc'] ? true : null"
            [queryParams]="{ event_id: this.eventId, date: date }"
            class="btn btn-primary-soft flex-start"
            ><i
              class="fa fa-check-circle color-inherit"
              *ngIf="
                event['publishCompleted'] != 0 &&
                event['publishCompleted'] == races.length
              "
            ></i
            >Race Reports</a
          >
          <button
            class="btn btn-primary-soft flex-start"
            [ngClass]="{ 'btn-success-soft': event['eod_report'] }"
            (click)="showEODReport()"
            [disabled]="loading_eod"
          >
            <img
              src="./assets/img/loading_2.svg"
              class="img-18"
              alt=""
              *ngIf="loading_eod"
            />

            <i
              class="fa fa-check-circle color-inherit"
              *ngIf="event['eod_report']"
            ></i>
            EOD Report
          </button>
          <button
            class="btn btn-primary-soft flex-start"
            (click)="prepareForRace('logout')"
            *ngIf="event['raceType'] != 'Greyhound Racing'"
            [ngClass]="{ 'btn-success-soft': event['logoff'] == '1' }"
            [attr.disabled]="!event['pc'] || kitsLoading ? true : null"
          >
            <i
              class="fa fa-check-circle color-inherit"
              *ngIf="event['logoff'] == '1'"
            ></i>
            Log Out
          </button>
        </ng-container>
      </div>
    </div>
    <table-view
      [headers]="headers"
      [data]="races"
      [loading]="loading"
      [formDisabled]="formDisabled"
      (sendEvent)="handleTableEvent($event)"
    ></table-view>
  </div>
</div>
<pre-race
  *ngIf="prepareForRaceVenue"
  [type]="type"
  url="/dash/post-race"
  [queryParams]="{
    location: eventName,
    state: location ? location['state'] : undefined
  }"
  [fragment]="location ? location['type'] : 'home'"
  [venue]="prepareForRaceVenue"
  [date]="date"
  [relogin]="type == 'login' && event['pc'] && event['logoff'] == '1'"
  [venue_id]="venueId"
  [pc]="event['pc']"
  [operator]="event['operator']"
  [event_id]="eventId"
  [pc_start_stop]="event['pc_start_stop']"
  [kit]="event.kit"
  (hideModel)="hidePrerace($event)"
></pre-race>

<app-eod-report
  *ngIf="eodReport"
  [races]="races"
  [event]="event"
  (hideModel)="hideEODReport($event)"
></app-eod-report>
<rail_calculator
  *ngIf="railCalculatorData"
  [data]="railCalculatorData"
  (hideModel)="hideRailCalculator($event)"
></rail_calculator>
<eod-confirm-box
  title="EOD Report"
  message="Not all races are in a Published State, are you sure you want to send the End of Day report?"
  *ngIf="eodReportNotReady"
  (hideModel)="hideEODReportWarning($event)"
></eod-confirm-box>

<eod-confirm-box
  title="Start List Email"
  message="Are you sure you want to send the Start List Email?"
  *ngIf="sendStartListEmail"
  (hideModel)="hideEmailStartList($event)"
>
  <div
    class="center alert alert-primary p-1"
    ngProjectAs="main-body"
    role="alert"
    *ngIf="venueEmailData && venueEmailData['data'].length > 0"
  >
    Email will be sent to
    <strong>{{ venueEmailData["data"][0]["StartlistEmailDL"] }}</strong>
  </div>
</eod-confirm-box>

<app-jira-tracker
  *ngIf="jiraTracker"
  [venue_id]="jiraTracker"
  [venue_name]="eventName"
  [jurisdiction]="event['Jurisdiction']"
  (hideModal)="jiraTracker = null"
></app-jira-tracker>

<eod-confirm-box
  title="Logout Confirmation"
  message=""
  *ngIf="logoutConfirm"
  [buttons]="[]"
  (hideModel)="hideEmailStartList($event)"
>
  <div ngProjectAs="main-body">
    <div class="mb-3">
      Please confirm the following actions before logging out
    </div>
    <div class="form-group flex-start">
      <input
        id="database_backup"
        type="checkbox"
        [(ngModel)]="logoutConfirm['database_backup']"
      />
      <label for="database_backup">Do a Database Backup</label>
    </div>
    <div class="form-group flex-start">
      <input
        id="close_the_trace"
        type="checkbox"
        [(ngModel)]="logoutConfirm['close_the_trace']"
      />
      <label for="close_the_trace">Close the Trace</label>
    </div>
    <div class="form-group flex-start">
      <input
        id="issues_logging"
        type="checkbox"
        [(ngModel)]="logoutConfirm['issues_logging']"
      />
      <label for="issues_logging"
        >Log a Ticket for Venue Issues with a link to Portal</label
      >
    </div>
    <div class="form-group flex-start">
      <input
        id="faulty_tags"
        type="checkbox"
        [(ngModel)]="logoutConfirm['faulty_tags']"
      />
      <label for="faulty_tags"
        >Log a Ticket for Faulty Tags with a link to Portal</label
      >
    </div>
    <div class="form-group flex-start">
      <input
        id="pc_start_stop"
        type="checkbox"
        [(ngModel)]="logoutConfirm['pc_start_stop']"
      />
      <label for="pc_start_stop"
        >Automatically shut down the PC upon completion of the logout process</label
      >
    </div>
    <div class="flex-center mt-4">
      <button
        (click)="prepareForRace('logout', true)"
        class="btn btn-primary-soft"
        [disabled]="
          !logoutConfirm['database_backup'] ||
          !logoutConfirm['close_the_trace'] ||
          !logoutConfirm['issues_logging'] ||
          !logoutConfirm['faulty_tags']
        "
      >
        Confirm
      </button>
      <button (click)="logoutConfirm = null" class="btn btn-light">
        Close
      </button>
    </div>
  </div>
</eod-confirm-box>

<eod-confirm-box
  title="Login Confirmation"
  message=""
  *ngIf="loginConfirm"
  [buttons]="[]"
  (hideModel)="hideEmailStartList($event)"
>
  <div ngProjectAs="main-body">
    <div class="mb-3">
      Please confirm the following actions before logging in
    </div>
    <div class="form-group flex-start">
      <input
        id="pc_start_stop"
        type="checkbox"
        [(ngModel)]="loginConfirm['pc_start_stop']"
      />
      <label for="pc_start_stop"
        >Automatically start the PC before the login process</label
      >
    </div>
    <div class="flex-center mt-4">
      <button
        (click)="prepareForRace('login', true)"
        class="btn btn-primary-soft"
      >
        Confirm
      </button>
      <button (click)="loginConfirm = null" class="btn btn-light">
        Close
      </button>
    </div>
  </div>
</eod-confirm-box>
<staging-management *ngIf="staging" [event]="staging" (hideModal)="staging = null"></staging-management>

<div *ngIf="showRaceTimePopup" class="popup-screen">
  <div class="popup-container">
    <div class="popup-header">
      Update Race Time
      <button type="button" class="close" (click)="closeRaceTimePopup()">
        &times;
      </button>
    </div>
    <div class="popup-body">
      <div class="flex-start align-items-start">
        <div>
          <label>Current Race Time: </label>
          <div>{{ selectedRace?.RaceTime }}</div>
        </div>
        <div class="arrow">→</div>
        <div>
          <label>New Race Time: </label>
          <div class="flex-start">
            <select class="form-select" [(ngModel)]="newRaceTime.hour">
              <option *ngFor="let hour of hours" [value]="hour">{{ hour }}</option>
            </select>
            :
            <select class="form-select" [(ngModel)]="newRaceTime.minute">
              <option *ngFor="let minute of minutes" [value]="minute">{{ minute }}</option>
            </select>
          </div>
          <div class="flex-start">
            <span class="nav-link pointer" (click)="addMinutes(5)">+5 mins</span>
            <span class="nav-link pointer" (click)="addMinutes(15)">+15 mins</span>
            <span class="nav-link pointer" (click)="addMinutes(30)">+30 mins</span>
          </div>
        </div>
      </div>
      <div class="mt-3 flex-end">
        <button class="btn btn-primary-soft" (click)="resetRaceTIme()" *ngIf="selectedRace.timeChanged">Reset</button>
        <button class="btn btn-success-soft" (click)="updateRaceTime()">Update</button>
        <button class="btn btn-light" (click)="closeRaceTimePopup()">Cancel</button>
      </div>
    </div>
  </div>
</div>
