<div class="card">
  <div class="card-header flex-between">
    <h5>Greyhound Monitoring</h5>
    <div class="flex-start">
      <!-- <button-status [filter]="filters['venue']" title="Venue" [multiple]="true" [items]="events"></button-status>
      <button-status [filter]="filters['status']" title="Status" [multiple]="true" [items]="statuses"></button-status> -->
      <button-status [filter]="filters['time']" title="Starts in" [multiple]="false" [items]="times"></button-status>
    </div>
  </div>
  <div class="card-body">
    <div class="overview-section flex-start">
      <div class="overview-field flex-1">
        <div class="circle-dotted" [ngClass]="{'status-alive': status.heartbeat === 'alive', 'status-down': status.heartbeat === 'down'}"></div>
        <div class="label-text">Heartbeat</div>
      </div>
      <div class="overview-field flex-1">
        <div class="circle-dotted" [ngClass]="{'status-alive': status.lap === 'alive', 'status-no-connection': status.lap === 'NoConnection'}"></div>
        <div class="label-text">Mylaps</div>
      </div>
      <div class="overview-field flex-1">
        <div class="circle-dotted text-success fw-bold number-status">{{status['completedRaces']}}</div>
        <div class="label-text">Completed Races</div>
      </div>
    </div>
    <table-view [headers]="headers" [data]="races | raceFilter:filters['venue']['selected']:filters['status']['selected']:filters['time']['selected']" [loading]="loading"></table-view>
  </div>
</div>
