<div id="popup" class="popup">
  <div class="popup-container">
    <div class="popup-header">Delete Policy</div>
    <div class="popup-content">
      <ng-container *ngIf="policyUser.length > 0; else elseBlock">
        <div class="alert alert-danger text-start" role="alert">
          <strong>This policy is attached to the following users:</strong>
          <ul>
            <li class="text-start" *ngFor="let user of policyUser">
              {{ user }}
            </li>
          </ul>
          <p class="color-inherit">
            Please remove the policy from the users before deleting it.
          </p>
        </div>
      </ng-container>
      <ng-template #elseBlock>
        <p>
          Are you sure you want to delete the policy
          <strong>{{ policy["policyName"] }}</strong
          >.
        </p>
      </ng-template>
      <div class="popup-buttons flex-end">
        <button
          id="continueBtn"
          class="btn btn-danger-soft"
          (click)="deletePolicy()"
          *ngIf="this.policyUser.length == 0"
        >
          Delete
        </button>
        <button
          id="logoutBtn"
          class="btn btn-light"
          (click)="hideModel.emit(false)"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>
