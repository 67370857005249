import {
  Component,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { NgSelectModule, NgOption } from '@ng-select/ng-select';
import { ActivatedRoute } from '@angular/router';
import { APIService } from 'src/app/services/api.service';
import { NotifierService } from 'src/app/services/notifier.service';
import { regions } from 'src/app/modules/globals/dash/dash.component';
import countryCodes from 'src/app/modules/auth/components/register/countryCodes';

import VenueEmails from '../../../../../pages/components/services/emails';
import { getENV } from 'src/app/_helpers/helpers';
import { race } from 'rxjs';

declare let $: any;

@Component({
  selector: 'update-user',
  templateUrl: './update-user.component.html',
  styleUrls: ['./update-user.component.css'],
})
export class UpdateUserComponent implements OnInit, OnDestroy {
  @Input('userDetails') userDetails: any;
  @Input('action') action: any;
  @Input('venues') venues: any = [];

  raceTypes = [
    { name: 'Hurdle', selected: false },
    { name: 'Flat', selected: false },
    { name: 'Steeple', selected: false },
    { name: 'MS', selected: false },
    { name: 'BreezeUp', selected: false },
    { name: 'SS', selected: false },
    { name: 'Greyhound', selected: false },
  ];
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin' ||
    localStorage.getItem('isUserAdmin') == '1';
  isUserAdmin: boolean = localStorage.getItem('isUserAdmin') == '1';
  userType: string = 'user';
  loading = true;
  instances: any = [];
  accountGroups: any = [];
  services: any = [];
  regions: any = regions;
  polices: any = [];
  policyName: string = '';
  accounts: any = [];
  twowayfactor: boolean = true;
  policyType: string = 'manual';
  accessType: string = 'readOnly';
  chcekFor2FA: boolean = true;
  currentTag: string | null = null;
  tagKeys: any = [];
  tagValues: any = [];
  tagKeyValuesObj: any = {};
  selectedAccountID: any;
  viewPolicy: any = null;

  @Output() hideModel = new EventEmitter<any>();
  config: any;

  constructor(
    private apiService: APIService,
    private notifier: NotifierService,
    private route: ActivatedRoute
  ) {
    this.config = this.route.snapshot.data['config'];
  }

  loaded: boolean = false;
  ngOnInit(): void {
    this.userDetails = JSON.parse(JSON.stringify(this.userDetails));
    if (!this.userDetails.raceTypes) {
      this.userDetails.raceTypes = [];
    } else {
      this.raceTypes.forEach((raceType: any) => {
        if (this.userDetails.raceTypes.includes(raceType.name)) {
          raceType.selected = true;
        }
      });
    }

    this.userType = this.userDetails['userType'];
    if (this.userDetails.policyEnabled) {
      this.policyType = 'predefined';
      this.policyName = this.userDetails.policyName;
      setTimeout(() => {
        $('#policyList').selectpicker('refresh');
      }, 100);
    } else {
      this.policyType = 'manual';
      this.policyName = '';
      this.userDetails.policyName = '';
      this.accessType = this.userDetails.accessType;
    }
    this.loaded = true;
    if (this.urlPrefix == 'admin') {
      this.userDetails.clientCmpName = this.userDetails.cmpName;
      this.userDetails.emaiId = this.userDetails.userId;
      this.userDetails.mobileNumber = this.userDetails.phNumber;
      this.userDetails.clientName = this.userDetails.userName;
      this.userDetails.userType = 'user';
      // this.accessType = 'readOnly';
    } else {
      this.twowayfactor = this.userDetails.otpReq;
      // if (this.action == 'update') {
      //   if (
      //     this.userDetails.tagsEnabled &&
      //     this.userDetails.tagsList &&
      //     Object.keys(this.userDetails.tagsList).length > 0
      //   ) {
      //     this.tagKeyValuesObj = JSON.parse(
      //       JSON.stringify(this.userDetails.tagsList)
      //     );
      //     this.tagsState = JSON.parse(
      //       JSON.stringify(this.userDetails.tagsList)
      //     );
      //     Object.keys(this.tagKeyValuesObj).forEach((account: string) => {
      //       Object.keys(this.tagKeyValuesObj[account]).forEach(
      //         (region: string) => {
      //           this.loadTags({ accountId: account }, region);
      //           Object.keys(this.tagKeyValuesObj[account][region]).forEach(
      //             async (key: string) => {
      //               await this.loadValues(account, region, key);
      //               await this.fetchAccountGroups();
      //               setTimeout(() => {
      //                 document
      //                   .querySelectorAll('.single-select')
      //                   .forEach((element: any) => {
      //                     $(element).selectpicker('refresh');
      //                   });
      //               }, 100);
      //             }
      //           );
      //         }
      //       );
      //     });
      //   }
      // }
    }

    // $('#tagKeysFilter').selectpicker();
    // $('#tagValuesFilter').selectpicker();
    this.load();
  }

  selectRaceType(event: any, raceType: string) {
    if (!this.userDetails.raceTypes) {
      this.userDetails.raceTypes = [];
    }
    if (event.target.checked) {
      this.userDetails.raceTypes.push(raceType);
    } else {
      this.userDetails.raceTypes = this.userDetails.raceTypes.filter(
        (type: string) => {
          return type != raceType;
        }
      );
    }
  }

  checkIfUserTypeExists(raceType: string) {
    return this.userDetails.raceTypes.includes(raceType);
  }

  selectAllVenues() {
    if (
      !this.userDetails.venueList &&
      this.userDetails.venueList.length == this.venues.length
    ) {
      this.userDetails.venueList = [];
    } else {
      this.userDetails.venueList = this.venues.map((venue: any) => {
        return venue.ExternalVenueID;
      });
    }
  }

  // tags
  loadingTags: boolean = false;
  async loadTags(account: any, regionId: string, nextToken: any = null) {
    // this.notifier.loading(true);
    this.loadingTags = true;
    if (!nextToken) {
      this.tagsState[account['accountId']][regionId] = {};
    }
    let data = {
      action: 'listEc2Tags',
      accountId: account['accountId'],
      region: regionId,
      nextToken: nextToken,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };

    let apiURL = `${this.config[getENV()].apiURL}/${
      this.urlPrefix
    }/operations/listec2instances`;

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    this.loadingTags = false;

    if (this.destroyed) {
      return;
    }

    if (result.status == '1' || result.s == '1') {
      if (result.tagKeyList) {
        result.tagKeyList.forEach((key: string) => {
          this.tagsState[account['accountId']][regionId][key] = [];
        });
      }
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }

    if (result.nextToken) {
      await this.loadTags(account, regionId, result.nextToken);
    }
    // this.notifier.loading(false);
  }

  async loadValues(accountId: string, regionId: string, key: string) {
    this.loadingTags = true;
    // this.notifier.loading(true);
    let data = {
      action: 'listEc2TagsValues',
      accountId: accountId,
      region: regionId,
      tagKey: key,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${this.config[getENV()].apiURL}/${
      this.urlPrefix
    }/operations/listec2instances`;

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (this.destroyed) {
      return false;
    }

    if (result.status == '1' || result.s == '1') {
      this.tagsState[accountId][regionId][key] = result['tagKeyList'].map(
        (value: any) => {
          return { id: value };
        }
      );
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }

    this.loadingTags = false;
    return true;

    // this.notifier.loading(false);
  }

  setValues(account: any) {
    let values: string[] = $('#tagValuesFilter').val();
    if (
      (!values || values.length == 0) &&
      this.tagKeyValuesObj[account['currentTag']]
    ) {
      delete this.tagKeyValuesObj[account['currentTag']];
    }
    this.tagKeyValuesObj[account['currentTag']] = values;
    setTimeout(() => {
      $('#tagKeysFilter').selectpicker('refresh');
    }, 100);
  }

  selectPolicy() {
    this.viewPolicy = this.polices.find((policy: any) => {
      return policy['policyName'] == this.userDetails.policyName;
    });
  }

  getValues(key: string) {
    return this.tagKeyValuesObj[key] || [];
  }

  keysToSelect(accountId: string, regionId: string) {
    return Object.keys(this.tagsState[accountId][regionId]).filter(
      (x) => !Object.keys(this.tagKeyValuesObj[accountId][regionId]).includes(x)
    );
  }

  newKeySet(
    oldKey: string | null = null,
    newKeyEvent: any,
    accountId: string,
    regionId: string
  ) {
    if (oldKey) {
      // Object.defineProperty(
      //   this.tagKeyValuesObj[accountId][regionId],
      //   newKeyEvent.target.value,
      //   Object.getOwnPropertyDescriptor(
      //     this.tagKeyValuesObj[accountId][regionId],
      //     oldKey
      //   )
      // );
      delete this.tagKeyValuesObj[accountId][regionId][oldKey];
      this.loadValues(accountId, regionId, newKeyEvent.target.value);
    } else if (newKeyEvent == 'FETCH') {
      let newKeyEvent = this.keysToSelect(accountId, regionId)[0];
      this.tagKeyValuesObj[accountId][regionId][newKeyEvent] = [];
      this.loadValues(accountId, regionId, newKeyEvent);
    }
    setTimeout(() => {
      document.querySelectorAll('.single-select').forEach((element: any) => {
        $(element).selectpicker('refresh');
      });
    }, 100);
  }

  resetValues(account: any) {
    if (this.tagKeyValuesObj[account['currentTag']]) {
      delete this.tagKeyValuesObj[account['currentTag']];
      $('#tagValuesFilter').val('');
      $('#tagValuesFilter').selectpicker('refresh');
      setTimeout(() => {
        $('#tagKeysFilter').selectpicker('refresh');
      }, 100);
    }
  }

  getUnselectedRegion(accountId: string) {
    if (!this.tagKeyValuesObj[accountId]) {
      return;
    }
    return this.regions['enabled'].filter((region: any) => {
      return (
        Object.keys(this.tagKeyValuesObj[accountId]).indexOf(region.id) < 0
      );
    });
  }

  removeKeySet(accountId: string, regionId: string, key: string) {
    delete this.tagKeyValuesObj[accountId][regionId][key];
  }

  addTagRegion(account: any, accountId: string, event: any): void {
    if (event.target.value == '') {
      return;
    }
    account[event.target.value] = {};
    this.tagKeyValuesObj[accountId][event.target.value] = {};
    this.loadTags({ accountId: accountId }, event.target.value);
  }

  removeTagRegion(account: any, regionId: string): void {
    try {
      delete account[regionId];
    } catch (error) {
      account[regionId] = undefined;
    }
  }

  getHTML(key: string) {
    return `
      <div class='flex_option'>
        <span>${key}</span>
        ${
          this.getValues(key).length > 0
            ? `<span>${this.getValues(key).length}</span>`
            : ''
        }
      </div>
    `;
  }

  uploadFileError(event: any) {
    // if (event) {
    //   this.twowayfactor = true;
    // } else {
    //   this.twowayfactor = false;
    // }
    this.chcekFor2FA = false;
  }

  async loadAdminAccountGroups() {
    this.notifier.loading(true);
    let data = { a: 'fetch' };

    let header = {
      Authorization: localStorage.getItem('t'),
    };

    let apiURL = `${this.config[getENV()].apiURL}/${
      this.urlPrefix
    }/Settings/accounts`;

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (result.s == '1' || result.status == '1') {
      this.accountGroups = result.groups.map((group: any) => {
        return { groupName: group };
      });
      setTimeout(() => {
        $('#accountGroupLists').selectpicker('refresh');
        $('#accountGroupLists').val(this.userDetails.accountGroup);
        $('#accountGroupLists').selectpicker('refresh');
      }, 100);
    } else {
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
    }

    this.notifier.loading(false);
  }

  checkTwoWayFactor() {
    if (!this.twowayfactor) {
      this.chcekFor2FA = true;
    }
  }

  async load() {
    let mList: any = JSON.parse(localStorage.getItem('menuList') || '{}');
    this.services = Object.keys(mList).map((menu: any) => {
      if (mList[menu].length == 1 && mList[menu][0] == menu) {
        mList[menu] = [];
      }
      return {
        label: menu,
        selected: false,
        expanded: false,
        submenus: mList[menu].map((menu_: any) => {
          return {
            label: menu_,
            selected: false,
          };
        }),
      };
    });
    this.notifier.loading(true);
    this.fetchPolicyDetails();
    //  else {
    //   this.loadAdminAccountGroups();
    //   return;
    // }

    // let accounts: any = localStorage.getItem('accountIdData');

    // if (accounts) {
    //   let accountsObj: any = JSON.parse(accounts);
    //   if (accountsObj['list'].length > 0) {
    //     this.accounts = accountsObj['list'];

    //     setTimeout(() => {
    //       $('#instancesList').selectpicker('refresh');
    //       $('#instancesList').val(this.userDetails['accountsList']);

    //       $('#instancesList').selectpicker('refresh');
    //       this.fetchAccountGroups();
    //     }, 100);
    //   }
    // }

    // this.notifier.loading(true);
    // let data = {
    //   emailId: this.userId,
    // };

    // let header = {
    //   Authorization: localStorage.getItem("t"),
    // };

    // let apiURL = `${this.config[getENV()].apiURL}/${this.urlPrefix}/listawsaccounts`;

    // let result = await this.apiService.postDataPromis(apiURL, data, header);

    // if (result.s == "1" || result.status == "1") {
    //   this.services = result.menuList;
    //   $("#serviceList").selectpicker("refresh");
    //   setTimeout(() => {
    //     $("#serviceList").val(this.userDetails.menuList);
    //     $("#serviceList").selectpicker("refresh");
    //   }, 100);
    // } else {
    //   this.notifier.alert("Info", "", result.msg, "info", 5000);
    // }

    // this.notifier.loading(false);
  }

  selectAllValues(event: any, accountId: string, region: string, key: string) {
    if (event.target.checked) {
      this.tagKeyValuesObj[accountId][region][key] = this.tagsState[accountId][
        region
      ][key].map((value: any) => {
        return value.id;
      });
    } else {
      this.tagKeyValuesObj[accountId][region][key] = [];
    }
  }

  countryCodesObj: any = countryCodes;
  selectedCountryCode: string = '+61';
  selectCode(event: any) {
    this.selectedCountryCode = event.target.value;
  }

  tagsState: any = {};

  getObjectKeys(object: Object): string[] {
    return object ? Object.keys(object) : [];
  }

  async fetchAccountGroups() {
    // this.notifier.loading(true);

    setTimeout(() => {
      //   document.querySelectorAll('.multi-select').forEach((element: any) => {
      //     $(element).selectpicker('refresh');
      //   });
      document.querySelectorAll('.single-select').forEach((element: any) => {
        $(element).selectpicker('refresh');
      });
    }, 100);

    // let accountIdList = $('#instancesList')
    //   .val()
    //   .map((instance: any) => {
    //     if (!this.tagsState[instance]) {
    //       this.tagsState[instance] = {};
    //       this.tagKeyValuesObj[instance] = {};
    //     }
    //     return { accountId: instance };
    //   });
    // this.userDetails.accountsList = accountIdList;

    return;

    // let data = {
    //   clientId: this.userId,
    //   action: 'fetchgroup',
    //   accountIdList: accountIdList,
    // };

    // let header = {
    //   Authorization: localStorage.getItem('t'),
    // };

    // let apiURL = `${this.config[getENV()].apiURL}/${this.urlPrefix}/operations/ec2groups`;

    // let result = await this.apiService.postDataPromis(apiURL, data, header);

    // if (result.s == '1' || result.status == '1') {
    //   this.accountGroups = result.list;
    //   setTimeout(() => {
    //     $('#instancesGroup').selectpicker('refresh');
    //   }, 100);
    // } else {
    //   if (this.action == 'update') {
    //     this.notifier.alert('Info', '', result.msg, 'info', 5000);
    //   }
    // }

    // this.notifier.loading(false);
  }

  async fetchPolicyDetails() {
    this.notifier.loading(true);
    let data = {
      a: 'fetchAll',
      clientId: this.userId,
      userType: this.urlPrefix,
    };
    let header = {
      Authorization: localStorage.getItem('t'),
    };

    let apiURL = `${this.config[getENV()].apiURL}/${
      this.urlPrefix
    }/Settings/policies`;

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (result.s == '1' || result.status == '1') {
      this.polices = result.policies;
      setTimeout(() => {
        $('#policyList').selectpicker('refresh');
        $('#policyList').selectpicker('val', this.userDetails.policyName);
      }, 100);
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }

    this.notifier.loading(false);
  }

  errors: any = {
    clientName: {
      policy: {
        required: true,
        type: 'text',
      },
      error: false,
      msg: 'Name is Mandatory Field',
    },
    mobileNumber: {
      policy: {
        type: 'text',
        required: true,
      },
      error: false,
      msg: 'Phone Number is Mandatory Field',
    },
    designation: {
      policy: {
        type: 'text',
        required: true,
      },
      error: false,
      msg: 'Designation is Mandatory Field',
    },
    emaiId: {
      policy: {
        required: true,
        type: 'text',
      },
      error: false,
      msg: 'Email ID is Mandatory Field',
    },
    policy: {
      policy: {
        required: false,
      },
      error: false,
      msg: 'Select Policy',
    },
    menuList: {
      policy: {
        required: false,
        check: () => {
          return this.userType == 'user' ? true : false;
        },
      },
      error: false,
      msg: 'Atleast one menu is required',
    },
  };

  phoneNumberValidate(e: any) {
    if (
      [46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+C
      (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+V
      // (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+X
      (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
      // Allow: home, end, left, right
      (e.keyCode >= 35 && e.keyCode <= 39)
    ) {
      // let it happen, don't do anything
      return;
    }
    // Ensure that it is a number and stop the keypress
    if (
      (e.shiftKey || e.keyCode < 48 || e.keyCode > 57) &&
      (e.keyCode < 96 || e.keyCode > 105)
    ) {
      e.preventDefault();
    }
  }

  validation() {
    let check = true;
    Object.keys(this.errors).forEach((error: any) => {
      if (
        this.errors[error]['policy']['required'] &&
        (this.errors[error]['policy']['check'] == undefined ||
          this.errors[error]['policy']['check']())
      ) {
        if (
          this.errors[error]['policy']['type'] == 'text' &&
          (!this.userDetails[error] ||
            this.userDetails[error].toString().trim() == '')
        ) {
          check = false;
          this.errors[error]['error'] = true;
        } else {
          this.errors[error]['error'] = false;
        }
      } else {
        this.errors[error]['error'] = false;
      }
    });
    if (this.urlPrefix == 'client') {
      if (
        this.policyType == 'predefined' &&
        this.userType == 'user' &&
        (!this.userDetails['policyName'] ||
          this.userDetails['policyName'].trim() == '')
      ) {
        this.errors['policy']['error'] = true;
        check = false;
      } else {
        this.errors['policy']['error'] = false;
      }

      if (this.userType == 'user') {
        const selectedRaceTypes = this.raceTypes.filter((raceType: any) => {
          return raceType.selected;
        });
        if (selectedRaceTypes.length == 0) {
          this.notifier.alert(
            'Info',
            '',
            'Select atleast one Race Type',
            'info',
            5000
          );
          check = false;
        }
      }
    }
    return check;
  }

  getSelectedMenusLength(menu: any) {
    return menu['submenus']
      ? menu['submenus'].filter((menu_: any) => {
          return menu_['selected'];
        }).length
      : 0;
  }

  async updateEntry() {
    if (!this.validation()) {
      return;
    }
    if (this.action == 'local') {
      this.close(true);
      return;
    }
    let data;
    if (this.policyType == 'predefined') {
      this.userDetails.tagsEnabled = false;
    }
    let menuList: any = {};
    this.services.forEach((menu: any) => {
      if (menu['selected'] || this.getSelectedMenusLength(menu) > 0) {
        menuList[menu['label']] = [menu['label']];
        if (menu['submenus'] && menu['submenus'].length > 0) {
          menuList[menu['label']] = menu['submenus']
            .filter((menu_: any) => {
              return (
                menu_['selected'] ||
                menu['selected'] ||
                this.userType == 'userAdmin'
              );
            })
            .map((menu_: any) => {
              return menu_['label'];
            });
        }
      }
    });

    if (
      this.userType == 'user' &&
      this.policyType == 'manual' &&
      Object.keys(menuList).length == 0
    ) {
      this.errors['menuList']['error'] = true;
      return;
    } else {
      this.errors['menuList']['error'] = false;
    }

    this.notifier.loading(true);

    if (this.urlPrefix == 'client') {
      data = {
        email: this.userDetails.emaiId.trim(),
        a: this.action == 'add' ? 'add' : 'updateUser',
        ut: this.urlPrefix.trim(),
        userType: this.userType.trim(),
        superAdmin:
          this.ut == 'admin' ? this.userId : localStorage.getItem('masterId'),
        mobile: this.userDetails.mobileNumber.toString(),
        designation: this.userDetails.designation,
        companyName: this.userDetails.clientCmpName.trim(),
        clientName: this.userDetails.clientName.trim(),
        otpReq: this.twowayfactor,
        // accountIdList:
        //   this.policyType == 'manual'
        //     ? this.accounts
        //         .filter((account: any) => {
        //           return selectedAccounts.indexOf(account.accountId) > -1;
        //         })
        //         .map((account: any) => {
        //           return account.accountId;
        //         })
        //     : undefined,
        policyName:
          this.policyType == 'predefined'
            ? this.userDetails.policyName
            : undefined,
        masterClientId:
          this.userDetails['userType'] != this.userType || this.action == 'add'
            ? this.userId?.trim()
            : this.userDetails['masterClientId'].trim(),
        updatePolicy:
          this.action == 'update' && this.policyType == 'manual' ? false : true,
        mlist: this.policyType == 'manual' ? menuList : undefined,
        venueList:
          this.policyType == 'manual' ? this.userDetails.venueList : undefined,
        raceTypes: this.raceTypes
          .filter((raceType: any) => {
            return raceType.selected;
          })
          .map((raceType: any) => {
            return raceType.name;
          }),
        accessType:
          this.policyType == 'manual' && this.userType == 'user'
            ? this.accessType.trim()
            : undefined,
        // tagsList: this.userDetails.tagsEnabled
        //   ? this.tagKeyValuesObj
        //   : undefined,
        // tagsEnabled: this.userDetails.tagsEnabled,
        updateUserType:
          this.action == 'update' &&
          this.userDetails['userType'] != this.userType
            ? true
            : false,
      };
      if (this.userType == 'userAdmin') {
        // data['accountIdList'] = this.accounts.map((account: any) => {
        //   return account.accountId;
        // });
        data['policyName'] = undefined;
        data['updatePolicy'] = false;
        data['raceTypes'] = this.raceTypes.map((raceType: any) => {
          return raceType.name;
        });
        let menuListAdmin: any = {};
        let masterVenueList: string[] = (this.venues || []).map(
          (venue: any) => {
            return venue.ExternalVenueID;
          }
        );
        this.services.forEach((menu: any) => {
          if (menu['submenus'].length == 0) {
            menuList[menu['label']] = [menu['label']];
          }
          menuListAdmin[menu['label']] = [menu['label']];
          if (menu['submenus'] && menu['submenus'].length > 0) {
            menuListAdmin[menu['label']] = menu['submenus'].map(
              (menu_: any) => {
                return menu_['label'];
              }
            );
          }
        });
        data['mlist'] = menuListAdmin;
        data['venueList'] = masterVenueList;
        data['accessType'] = this.accessType;
        // data['tagsList'] = undefined;
        // data['tagsEnabled'] = false;
        data['accessType'] = 'readandwrite';
      }
    } else {
      data = {
        a: this.action == 'add' ? 'add' : 'updateuser',
        email: this.userDetails.emaiId,
        mobile: this.userDetails.mobileNumber.toString(),
        companyName: this.userDetails.clientCmpName,
        userName: this.userDetails.clientName,
        raceTypes: this.raceTypes.map((raceType: any) => {
          return raceType.name;
        }),
        updatePolicy:
          this.action == 'update' && this.policyType == 'manual' ? false : true,
        mlist: this.policyType == 'manual' ? menuList : undefined,
        // accountGroups: $('#accountGroupLists').val(),
        requester: this.userId,
        policyName:
          this.policyType == 'predefined'
            ? this.userDetails.policyName
            : undefined,
      };
    }

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL: string;
    if (this.urlPrefix == 'admin') {
      apiURL = `${this.config[getENV()].apiURL}/${
        this.urlPrefix
      }/Settings/usermanagement`;
    } else {
      apiURL = `${this.config[getENV()].apiURL}/${
        this.urlPrefix
      }/Settings/usermanagement`;
    }

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.notifier.alert(
        'Success',
        '',
        result.msg || result.m,
        'success',
        5000
      );
      if (this.action == 'add') {
        this.close(true);
      } else {
        this.close(true);
      }
    } else {
      this.notifier.alert(
        'Error',
        '',
        result.m || result.error || result.msg,
        'error',
        5000
      );
    }
    this.notifier.loading(false);
  }

  destroyed: boolean = false;
  ngOnDestroy(): void {
    this.destroyed = true;
  }

  close(sendData: boolean = false) {
    if (sendData) {
      this.hideModel.emit(this.userDetails);
    } else {
      this.hideModel.emit(false);
    }
  }
}
