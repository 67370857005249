import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'confirm-box',
  templateUrl: './confirm-box.component.html',
  styleUrls: ['./confirm-box.component.css'],
})
export class ConfirmBoxComponent {
  @Output() hideModel = new EventEmitter<boolean>();

  constructor() {}
}
