<div class="card">
  <div class="card-header flex-between">
    <h5>{{ type == "clients" ? "Client" : "Admin" }} Users</h5>
    <div class="flex-start">
      <button class="btn btn-light" (click)="load()">Refresh</button>
      <button class="btn btn-success-soft" (click)="inviteUser()">
        Invite
      </button>
    </div>
  </div>
  <div class="card-body">
    <div class="overflow-auto">
      <table class="table table-docs table-hover">
        <thead>
          <tr>
            <th>S.N.</th>
            <th *ngFor="let h of headers">
              {{ h.name }}
              <span
                *ngIf="h.sort && h.sort.sort"
                (click)="notifier.sortTable(h, clients)"
              >
                <i
                  class="fa fa-sort-amount-desc"
                  aria-hidden="true"
                  *ngIf="h.sort.direction == 'Des'"
                ></i>
                <i
                  class="fa fa-sort-amount-asc"
                  aria-hidden="true"
                  *ngIf="h.sort.direction == 'Asc'"
                ></i>
              </span>

              <span *ngIf="h.filter" class="inputseach">
                <input
                  autocomplete="off"
                  type="text"
                  [(ngModel)]="h.filterText"
                />
                <i class="fa fa-search" aria-hidden="true"></i>
              </span>
            </th>
          </tr>
        </thead>

        <tbody
          [ngStyle]="{
            display: clients.length > 0 ? 'table-row-group' : 'none'
          }"
          *ngFor="let i of clients; let j = index"
        >
          <tr *ngIf="notifier.filterTableColums(headers, i)">
            <td>{{ j + 1 }}</td>
            <td *ngFor="let h of headers">
              <span
                *ngIf="h.click"
                class="nav-link"
                (click)="callFunction(h.click, i)"
              >
                <span>
                  {{ i[h.id] }}
                </span>
              </span>
              <span *ngIf="!h.click">
                {{ i[h.id] }}
              </span>
            </td>
          </tr>
        </tbody>
        <tbody
          [ngStyle]="{
            display: clients.length == 0 ? 'table-row-group' : 'none'
          }"
        >
          <tr>
            <td [attr.colspan]="headers.length + 1">
              <div class="no_data text-center">No Users Found</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<invite-client *ngIf="updateUser" [userDetails]="updateUser" [action]="action" [type]="type" (hideModel)="close($event)"></invite-client>