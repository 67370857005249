import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { APIService } from 'src/app/services/api.service';

import { getENV } from 'src/app/_helpers/helpers';

@Component({
  selector: 'app-kit-management-details',
  templateUrl: './kit-management-details.component.html',
  styleUrls: ['./kit-management-details.component.css']
})
export class KitManagementDetailsComponent implements OnInit {
  kitsData: any[] = [];
  kitId: string = '';
  config: any;
  selectedKit: any = null; // Currently selected kit for the modal
  showModal: boolean = false; // Controls visibility of the modal
  showHistoryModal: boolean = false;
  venueState: string = '';
  codeType: string = '';

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private apiService: APIService,
  ) {
    this.config = this.route.snapshot.data['config'];
   }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.kitId = decodeURIComponent(params['kitId']);
      this.codeType = params['codeType'];
      this.venueState = params['venueState'];
      this.fetchKitDetails(this.kitId);
    });
  }

  async fetchKitDetails(kitId: string): Promise<void> {
    // this.http.get<any[]>('https://4otldh9um5.execute-api.ap-southeast-2.amazonaws.com/tsd-prd-api/devices/kits?kit_id=' + kitId).subscribe(response => {
    //   this.kitsData = response;
    // });
    let apiURL: string =
      `${this.config[getENV()].raceAPI}/devices/kits?kit_id=` + kitId;
    let response = await this.apiService.getDataPromis(apiURL, {}, {});
    this.kitsData = response;
  }

  openKitDetails(kit: any): void {
    this.selectedKit = kit.SerialNumber;
    this.showModal = true;
  }

  openAddKit(): void {
    this.selectedKit = null;
    this.showModal = true;
  }

  openKitHistory(kit: any): void {
    this.selectedKit = kit.SerialNumber;
    this.showHistoryModal = true;
  }

  closeModal(): void {
    this.showModal = false;
    // Reload the current page to refresh data, if needed
    window.location.reload();
  }

  closeHistoryModal(): void {
    this.showHistoryModal = false;
  }
}
