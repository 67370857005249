import {
  Component,
  OnInit,
  Input,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import * as moment from 'moment';
import { APIService } from 'src/app/services/api.service';
import { NotifierService } from 'src/app/services/notifier.service';
import { ActivatedRoute } from '@angular/router';
import {
  checkForVenuePermission,
  convertTimestamp,
  getENV,
  getRaceType,
} from '../../../../../../../_helpers/helpers';
import {
  buttonActions,
  typeBasedLocations,
} from '../post-race/harness-reports/components/race-reports/locations';

// declare $ for jQuery usage
declare let $: any;

@Component({
  selector: 'app-grayhound-monitoring',
  templateUrl: './grayhound-monitoring.component.html',
  styleUrls: ['./grayhound-monitoring.component.css'],
})
export class GrayhoundMonitoringComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  races: any = [];
  loading: boolean = false;
  filters: any = {
    venue: { selected: ['ALL'] },
    status: { selected: ['ALL'] },
    time: { selected: ['ALL'] },
  };
  times: { name: string; id: string }[] = [];
  headers: any = [
    {
      id: 'MinutesLeft',
      name: 'Time to Race',
      style: {
        width: '100px',
      },
      type: 'component',
      component: {
        name: 'MinutesLeft',
      },
    },
    {
      id: 'SquentialRaceOrderNumber',
      name: 'Race Number',
      style: {
        width: '100px',
      },
      type: 'string',
      filter: true,
      show: true,
    },
    {
      id: 'eventName',
      name: 'Venue',
      type: 'string',
      align: 'left',
      show: true,
      filter: true,
      link: {
        href: '/dash/venue-details',
        queryParamsKey: 'queryParamsVenue',
      },
    },
    {
      id: 'ExternalRaceId',
      name: 'Race ID',
      type: 'string',
      show: false,
      filter: true,
    },
    {
      id: 'RaceName',
      name: 'Race Name',
      align: 'left',
      type: 'string',
      // filter: true,
      show: true,
      link: {
        href: '/dash/race-details',
        queryParamsKey: 'queryParamsRace',
      },
    },
    {
      id: 'RaceState',
      name: 'Race Status',
      type: 'component',
      align: 'center',
      // filter: true,
      component: {
        name: 'RaceStatus',
      },
      show: true,
    },
    {
      id: 'status',
      name: 'Runner Status',
      type: 'component',
      // filter: true,
      component: {
        name: 'HorseStatusViewComponent',
      },
      show: true,
    },
    {
      id: 'buttonActions',
      name: 'Report Status',
      type: 'component',
      // filter: true,
      component: {
        name: 'RaceStatusViewComponent',
      },
      show: true,
    },
    {
      id: 'RaceTime',
      name: 'Race Time',
      type: 'string',
      show: true,
      datepicker: {
        class: 'datetimepicker',
      },
    },
    {
      id: 'DataQualityClass',
      name: 'Good/Bad Data',
      click: 'toggleGoodorBadData',
      type: 'component',
      component: {
        name: 'clickspan',
      },
    },
    {
      id: 'Operator',
      name: 'Operator',
      type: 'string',
      show: false,
    },
  ];

  config: any;
  events: any = [];
  statuses: { id: string; name: string }[] = [
    { name: 'PLANNED', id: 'PLANNED' },
    { name: 'DNS', id: 'DNS' },
    { name: 'DNT', id: 'DNT' },
    { name: 'DNF', id: 'DNF' },
    { name: 'DSQ', id: 'DSQ' },
    { name: 'OFFICIAL', id: 'OFFICIAL' },
    { name: 'FINISHED', id: 'FINISHED' },
    { name: 'PUBLISHED', id: 'PUBLISHED' },
    { name: 'ABANDONED', id: 'ABANDONED' },
  ];

  intervals: any = null;

  constructor(
    private apiService: APIService,
    private notifier: NotifierService,
    private router: ActivatedRoute
  ) {
    this.config = this.router.snapshot.data['config'];
  }

  async ngOnInit(): Promise<void> {
    this.times = [];
    this.times.push({
      name: '10 mins',
      id: '10 mins',
    });
    this.times.push({
      name: '30 mins',
      id: '30 mins',
    });
    this.times.push({
      name: '1 Hour',
      id: '1 Hour',
    });
  }

  ngOnDestroy(): void {
    this.clearIntervals();
  }

  startIntervals(): void {
    if (this.races.length > 0) {
      this.callHeartbeatAction();

      this.callMylapsAction();

      this.callRacesAction();

      this.status['completedRaces'] = 0;
      this.races.forEach((race) => {
        this.callRaceAction(race);
      });
    }

    this.intervals = setTimeout(() => {
      this.startIntervals();
    }, 10000);
  }

  clearIntervals(): void {
    if (this.intervals) {
      clearTimeout(this.intervals);
      this.intervals = null;
    }
  }

  async ngAfterViewInit(): Promise<void> {
    $('.datetimepicker').val(moment().format('DD MMMM YYYY'));
    $('.datetimepicker')
      .datepicker({
        autoclose: true,
        format: 'dd MM yyyy',
        orientation: 'bottom auto',
      })
      .on('changeDate', async (selected: any) => {
        this.loading = true;
        this.clearIntervals();
        await this.loadData();
        this.startIntervals();
        this.loading = false;
      });
    setTimeout(async () => {
      this.loading = true;
      await this.loadData();
      this.startIntervals();
      this.loading = false;
    }, 10);
  }

  async loadData(): Promise<void> {
    this.races = [];
    let date: string = moment(
      $('.datetimepicker').val(),
      'DD MMMM YYYY'
    ).format('DD-MM-YYYY');
    if (date) {
      await this.loadEventsBasedOnDates(
        moment(date, 'DD-MM-YYYY').format('YYYY-MM-DD')
      );
    }
    if (this.races && this.races.length > 0) {
      this.races = this.races.sort((a: any, b: any): number => {
        return a['MinutesLeft'] - b['MinutesLeft'];
      });
    }
  }

  async loadEventsBasedOnDates(date: string): Promise<boolean> {
    return new Promise(async (resolve: any) => {
      let raceTypes: any = localStorage.getItem('raceTypes');
      if (raceTypes) {
        raceTypes = JSON.parse(raceTypes);
      }
      let apiURL: string = `${
        this.config[getENV()].raceAPI
      }/event-list?formatted_date=${date}`;

      if (raceTypes && raceTypes.length > 0) {
        apiURL += `&race_types=${raceTypes.join(',')}`;
      }

      let result: any = await this.apiService.getDataPromis(apiURL, {}, {});

      this.events = [];

      if (result.eventNames && result.eventNames.length > 0) {
        result.eventNames = result.eventNames.filter((event: any) => {
          return (
            checkForVenuePermission(event['external_venue_id']) &&
            event['external_venue_id'].startsWith('GR')
          );
        });
        this.events = result.eventNames.map(
          (event: any): { id: string; name: string } => {
            let raceType = getRaceType(event['external_venue_id'])[
              'raceTypeStr'
            ];
            if (
              event['venue_name'] == 'Cranbourne' &&
              raceType == 'Thoroughbred Racing'
            ) {
              event['venue_name'] = 'Cranbourne Turf';
            }
            return {
              id: event['external_event_id'],
              name: event['venue_name'],
            };
          }
        );
        let promises: any = [];
        result.eventNames.forEach((event: any) => {
          let raceType = getRaceType(event['external_venue_id']);
          event['raceType'] = raceType['raceTypeStr'];
          if (raceType['raceTypeStr'] == 'Thoroughbred Racing') {
            if (event['venue_name'] == 'Cranbourne') {
              event['venue_name'] = 'Cranbourne Turf';
            }
          }
          const jurisdiction = typeBasedLocations[event['raceType']]?.find(
            (jurisdiction: any) =>
              jurisdiction.Locations.some(
                (location: any) =>
                  location.Name.toLowerCase() ===
                  event['venue_name'].toLowerCase()
              )
          );
          if (jurisdiction) {
            event['jurisdictionCode'] = jurisdiction.JurisdictionCode;
            event['stateCode'] = jurisdiction.State;
          }
          event['name'] = event['venue_name'];
          promises.push(this.loadRacesBasedOnEvent(event));
        });
        if (promises.length > 0) {
          Promise.all(promises).then((values: any) => {
            resolve(true);
          });
        } else {
          resolve(true);
        }
      } else {
        resolve(true);
      }
    });
  }

  async loadRacesBasedOnEvent(event: any): Promise<boolean> {
    return new Promise(async (resolve: any) => {
      let apiURL: string = `${
        this.config[getENV()].raceAPI
      }/getracedetails?ExternalVenueId=${
        event['external_venue_id']
      }&ExternalEventId=${event['external_event_id']}`;

      let result: any = await this.apiService.getDataPromis(apiURL, {}, {});
      let raceDate = $('.datetimepicker').val();
      if (result && Array.isArray(result) && result.length > 0) {
        let raceType = getRaceType(event['external_venue_id'])['raceTypeStr'];
        result.forEach((race: any) => {
          race.DataQualityClass = race.DataQuality
            ? 'green-circle'
            : 'red-circle';
          race['status'] = {
            PLANNED: 0,
            DNS: 0,
            DNT: 0,
            DNF: 0,
            DSQ: 0,
            OFFICIAL: 0,
            FINISHED: 0,
            PUBLISHED: 0,
            ABANDONED: 0,
            CLEANED: race['AnimalDetail'].length
          };
          // race['raceStatus'] = race['RaceState'] || 'PLANNED';
          race['RaceState' ] = 'CLEANED';
          race['buttonActions'] = [
            {
              slug: 'OR',
              status: 'PLANNED',
              actions: [],
            },
            {
              slug: 'PDF',
              status: 'PLANNED',
              actions: [],
            },
            {
              slug: 'CUST',
              status: 'PLANNED',
              actions: [],
            },
            {
              slug: 'TSD',
              status: 'PLANNED',
              actions: [],
            },
          ];
          let buttonActionsJSON = JSON.parse(JSON.stringify(buttonActions));
          buttonActionsJSON
            .filter((report: any) => {
              if (report.conditions) {
                const condition = report.conditions.find(
                  (c: any) => c.state === event['jurisdictionCode']
                );
                if (condition) {
                  const { key, equation, value } = condition.condition;
                  const raceValue = race[key];
                  switch (equation) {
                    case 'equals':
                      return raceValue === value;
                    case 'not equals':
                      return raceValue !== value;
                    default:
                      return true;
                  }
                }
                return true;
              }
              return true;
            })
            .forEach((report: any) => {
              if (
                (report.states == 'ALL' ||
                  report.states.includes(event['jurisdictionCode']) ||
                  report.states.includes(event['stateCode'])) &&
                (!report.type || report.type == raceType)
              ) {
                let reportStatus = race['ReportStatus'].find((report_: any) => {
                  return report_['StepType'] == report['action'];
                });
                let action = race['buttonActions'].find(
                  (buttonAction: any) => buttonAction.slug == report['slug']
                );

                if (reportStatus) {
                  action['actions'].push({
                    name: report['name'],
                    status: reportStatus['StepCompletionStatus'].toUpperCase(),
                    comment: reportStatus['Comments'] || '',
                  });
                } else {
                  action['actions'].push({
                    name: report['name'],
                    status: 'PLANNED',
                    comment: '',
                  });
                }
              }
            });

          race['buttonActions'].forEach((action: any) => {
            let success = action['actions'].find((action_: any) => {
              return action_['status'] == 'COMPLETED';
            });
            let failed = action['actions'].find((action_: any) => {
              return action_['status'] == 'FAILED';
            });
            if (failed) {
              action['status'] = 'FAILED';
            } else if (success) {
              action['status'] = 'COMPLETED';
            } else {
              action['status'] = 'PLANNED';
            }
          });

          race['eventName'] = event['name'];
          race['eventDate'] = $('.datetimepicker').val();
          race['RaceTimeStr'] = race['RaceTime'];
          race['RaceTime'] = convertTimestamp(
            race['RaceTime'],
            event['venue_state'],
            true
          );
          race['queryParamsVenue'] = {
            event_id: race['ExternalEventId'],
            venue_id: race['ExternalVenueId'],
            date: race['eventDate'],
            event_name: race['eventName'],
          };
          race['queryParamsRace'] = {
            race_id: race['ExternalRaceId'],
            event_id: race['ExternalEventId'],
          };
          race['MinutesLeft'] = this.calculateMinutesLeft(
            raceDate,
            race['RaceTime']
          );
        });
        this.races = [...this.races, ...result];
      }
      resolve(true);
    });
  }

  calculateMinutesLeft(raceDate, raceTimeStr) {
    let raceHour = parseInt(raceTimeStr.substring(0, 2));
    let raceMinute = parseInt(raceTimeStr.substring(3, 5));
    let raceDateTime = moment(raceDate, 'DD MMMM YYYY').set({
      hour: raceHour,
      minute: raceMinute,
      second: 0,
      millisecond: 0,
    });
    let currentTime = moment();
    let minutesLeft = raceDateTime.diff(currentTime, 'minutes');
    return minutesLeft;
  }

  async callHeartbeatAction(): Promise<any> {
    const apiURL =
      'https://50b3p4zwm3.execute-api.ap-southeast-2.amazonaws.com/tsd-prod-api';
    const url = `${apiURL}/greyhound-monitor?action=heartbeat`;
    try {
      const response = await this.apiService.getDataPromis(url, {}, {});
      if (response.status == '1' || response.s == '1') {
        this.status['heartbeat'] = 'alive';
      } else {
        this.status['heartbeat'] = 'down';
      }
    } catch (error) {
      this.status['heartbeat'] = 'down';
      console.error('Error calling heartbeat action:', error);
      throw error;
    }
  }

  status: any = {
    lap: 'NoConnection',
    heartbeat: 'down',
  };
  async callMylapsAction(): Promise<any> {
    const apiURL =
      'https://50b3p4zwm3.execute-api.ap-southeast-2.amazonaws.com/tsd-prod-api';
    const url = `${apiURL}/greyhound-monitor?action=mylaps`;
    try {
      const response = await this.apiService.getDataPromis(url, {}, {});
      if (response.status == '1' || response.s == '1') {
        this.status['lap'] = response.connection_status || 'NoConnection';
      } else {
        this.status['lap'] = 'NoConnection';
      }
    } catch (error) {
      this.status['lap'] = 'NoConnection';
      console.error('Error calling mylaps action:', error);
      throw error;
    }
  }

  async callRacesAction(): Promise<any> {
    const apiURL =
      'https://50b3p4zwm3.execute-api.ap-southeast-2.amazonaws.com/tsd-prod-api';
    const url = `${apiURL}/greyhound-monitor?action=races`;
    try {
      const response = await this.apiService.getDataPromis(url, {}, {});
      if (response.status == '1' || response.s == '1') {
        const races = response.races || [];
        this.races.forEach((race: any) => {
          let race_ = races.find((race_: any) => {
            return race_['raceid'] == race['ExternalRaceId'];
          });
          if (race_) {
            race['RaceState'] = race_['status'].toUpperCase();
          }
        });
        console.log(this.races);
      } else {
        throw new Error('Failed to retrieve data');
      }
    } catch (error) {
      console.error('Error calling races action:', error);
      throw error;
    }
  }

  async callRaceAction(race: any): Promise<any> {
    const apiURL =
      'https://50b3p4zwm3.execute-api.ap-southeast-2.amazonaws.com/tsd-prod-api';
    const url = `${apiURL}/greyhound-monitor?action=race&externalRaceId=${race.ExternalRaceId}`;
    try {
      const response = await this.apiService.getDataPromis(url, {}, {});
      if (response.status == '1' || response.s == '1') {
        if (response.runners && response.runners.length > 0) {
          race['status'] = {
            PLANNED: 0,
            DNS: 0,
            DNT: 0,
            DNF: 0,
            DSQ: 0,
            OFFICIAL: 0,
            FINISHED: 0,
            PUBLISHED: 0,
            ABANDONED: 0,
          };
          this.status['completedRaces'] += 1;
          response.runners.forEach((runner: any) => {
            race['status'][runner?.status?.toUpperCase() || 'PLANNED'] += 1;
          });
        }
      }
    } catch (error) {
      console.error(
        `Error calling race action for ${race.ExternalRaceId}:`,
        error
      );
      throw error;
    }
  }
}
