import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

interface Device {
  SerialNumber: string;
  DeviceID: string | null;
  KitID: string | null;
  OriginalKit: string;
  WarrantyStatus: string;
  RemainingWarrantyMonths: number | null;
}

interface DeviceResponse {
  data: {
    total_items: number;
    total_pages: number;
    current_page: number;
    devices: Device[];
  };
}

@Injectable({
  providedIn: 'root'
})
export class AllDevices {
  // private apiUrl = 'https://4otldh9um5.execute-api.ap-southeast-2.amazonaws.com/tsd-prd-api/devices/all';

  constructor(private http: HttpClient) { }

  getAllDevices(apiURL, page: number = 1, perPage: number = 20, sortBy: string = '', sortOrder: string = 'asc', filters: any = {}): Observable<DeviceResponse> {
    if (sortBy == 'DeviceID') {
      sortBy = 'TagID'
    }
    let params = new HttpParams()
      .set('page', page.toString())
      .set('per_page', perPage.toString())
      .set('sort_by', sortBy)
      .set('sort_order', sortOrder);

    for (let key in filters) {
      if (filters[key]) {
        params = params.set(key, filters[key]);
      }
    }

    return this.http.get<DeviceResponse>(apiURL, { params });
  }
}
