import { Component, OnDestroy, OnInit } from '@angular/core';
import { APIService } from 'src/app/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';

import { getENV } from 'src/app/_helpers/helpers';
import { Subscription } from 'rxjs';
interface Kit {
  id: number;
  KitID: string;
  VenueState: string;
  CodeType: string;
}

@Component({
  selector: 'app-operator-kit-management',
  templateUrl: './operator-kit-management.component.html',
  styleUrls: ['./operator-kit-management.component.css'],
})
export class OperatorKitManagementComponent implements OnInit, OnDestroy {
  kits: Kit[] = [];
  config: any;
  groupedKits: { [codeType: string]: { [venueState: string]: Kit[] } } = {};
  selectedKit: string = null;

  constructor(
    private apiService: APIService,
    private router: ActivatedRoute,
    private navigator: Router
  ) {
    this.config = this.router.snapshot.data['config'];
  }

  querySubscription: Subscription;

  ngOnInit(): void {
    this.fetchKits();
    this.querySubscription = this.router.queryParams.subscribe((params) => {
      if (params['kit']) {
        this.selectedKit = params['kit'];
      } else {
        this.selectedKit = null;
      }
    });
  }

  ngOnDestroy(): void {
    this.querySubscription.unsubscribe();
  }

  selectKit(kit: string): void {
    this.navigator.navigate(['/dash/operator-kit-management'], {
      queryParams: { kit: kit },
    });
  }

  hideKit() {
    this.navigator.navigate(['/dash/operator-kit-management']);
  }

  async fetchKits(): Promise<void> {
    let apiURL: string = `${this.config[getENV()].raceAPI}/devices/kit_ids`;
    let response = await this.apiService.getDataPromis(apiURL, {}, {});
    console.log(response);
    this.kits = response.data;

    this.groupKits();
    //   this.http.get<{ data: Kit[] }>('https://4otldh9um5.execute-api.ap-southeast-2.amazonaws.com/tsd-prd-api/devices/kit_ids')
    //     .subscribe(response => {
    //       this.kits = response.data;
    //       this.groupKits();
    //       console.log(this.groupedKits);
    //     });
  }

  groupKits(): void {
    this.groupedKits = this.kits.reduce((acc: any, kit) => {
      acc[kit.CodeType] = acc[kit.CodeType] || {};
      acc[kit.CodeType][kit.VenueState] =
        acc[kit.CodeType][kit.VenueState] || [];
      acc[kit.CodeType][kit.VenueState].push(kit);
      return acc;
    }, {});
  }
}
