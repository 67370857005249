import { Component } from '@angular/core';

@Component({
  selector: 'app-update-venue',
  templateUrl: './update-venue.component.html',
  styleUrls: ['./update-venue.component.css']
})
export class UpdateVenueComponent {

}
