import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'app-reportstatus',
  templateUrl: './reportstatus.component.html',
  styleUrls: ['./reportstatus.component.css'],
})
export class ReportstatusComponent implements OnChanges {
  @Input('data') data: any;
  ngOnChanges() {
  }
}
