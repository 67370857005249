import { Component } from '@angular/core';

import { APIService } from 'src/app/services/api.service';
import { NotifierService } from 'src/app/services/notifier.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { HttpClient, HttpEvent, HttpEventType  } from '@angular/common/http';
import { FileUploadService } from 'src/app/services/fileupload.service';
import {
  checkForVenuePermission,
  convertTimestamp,
  getENV,
  getRaceType,
} from 'src/app/_helpers/helpers';
@Component({
  selector: 'app-silk-upload',
  templateUrl: './silk-upload.component.html',
  styleUrls: ['./silk-upload.component.css']
})
export class SilkUploadComponent {
  config: any;
  imageUrl: string | ArrayBuffer | null = null;
  selectedFile: File | null = null;
  userName: string | null = null;
  existingImage: string = "";
  loading: boolean = false;
  meta: any = {
    uploadStatus: '', // Default to empty string
    file: null,
    fileError: '',
    presigned_url: '',
    uploaded: 0,
    percentage: 0,
  };
  racingTypeDict: { [key: string]: string } = {
    'Greyhound': 'Greyhounds',
    'Harness': 'Harness_Racing_Australia',
    'Thoroughbred': 'Racing_Australia'
  };
  selectedRaceType: string | null = null; // Selected race type
  racingTypeKeys: string[] = []; // Keys from racingTypeDict
  constructor(
    private apiService: APIService,
    private notifier: NotifierService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private fileUploadService: FileUploadService
  ) {
    this.config = this.activeRoute.snapshot.data['config'];
    this.racingTypeKeys = Object.keys(this.racingTypeDict); // Get keys from dictionary
  }
  onFileSelected(event: any): void {
    this.notifier.loading(true);
    const file: File = event.target.files[0];
    this.selectedFile = file;

    if (file) {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        this.imageUrl = e.target.result;
        const imageName = this.selectedFile? this.selectedFile.name : "";
        this.checkForExistingImage(imageName)
      };

      reader.readAsDataURL(file);
    }
    this.notifier.loading(false);
  }

  private checkForExistingImage(fileName: string): void {
    if (!this.selectedRaceType) {
      this.notifier.alert("Error", "", "Please select a race type", "error", 5000);
      return;
    }
  
    this.notifier.loading(true);
    const randomString = Math.random().toString(36).substring(2, 15);
    const env = getENV();
    let primaryBucket: string;
    let fallbackBucket: string;
  
    if (env === 'dev') {
      primaryBucket = "tsd-dev-silks";
      fallbackBucket = "tsd-dev01-silks";
    } else if (env === 'staging') {
      primaryBucket = "tsd-staging-silks";
      fallbackBucket = "tsd-staging01-silks";
    } else {
      primaryBucket = "tsd-prd-silks";
      fallbackBucket = "tsd-prd01-silks";
    }
  
    const prefix = this.racingTypeDict[this.selectedRaceType];
    
    const checkImage = (bucket: string): void => {
      const imageUrl = `https://${bucket}.s3.ap-southeast-2.amazonaws.com/${prefix}/${fileName}?v=${randomString}`;
      console.log(`Checking image in bucket: ${bucket}, URL: ${imageUrl}`);
  
      this.http.get(imageUrl, { responseType: 'blob', observe: 'response' })
        .subscribe({
          next: (response: any) => {
            if (response.status === 200) {
              console.log(`Image found in bucket: ${bucket}`);
              this.existingImage = imageUrl;
              this.notifier.loading(false);
            } else {
              console.log(`Image not found in bucket: ${bucket}`);
              if (bucket === primaryBucket) {
                checkImage(fallbackBucket); // Try fallback bucket
              } else {
                this.existingImage = ''; // Clear if not found in either bucket
                this.notifier.loading(false);
              }
            }
          },
          error: (error) => {
            console.log(`Error retrieving image from bucket ${bucket}:`, error);
            if (bucket === primaryBucket) {
              checkImage(fallbackBucket); // Try fallback bucket on error
            } else {
              this.existingImage = ''; // Clear if not found in either bucket
              this.notifier.loading(false);
            }
          }
        });
    };
  
    // Start by checking the primary bucket
    checkImage(primaryBucket);
  }
  

  onRaceTypeChange(): void {
    if (this.selectedFile) {
      const fileName = this.selectedFile.name;
      this.checkForExistingImage(fileName); // Recheck for the image based on the new race type
    }
  }
  
  async onSubmit(): Promise<void> {
    if (this.selectedFile) {
      this.notifier.loading(true);
      let apiURL: string = `${this.config[getENV()].raceAPI}/flask-operations`;
      const prefix = this.racingTypeDict[this.selectedRaceType];
      let payload: any = {
        action: 'upload_silk',
        file_name: this.selectedFile.name,
        folder_name:prefix
      };
      console.log(apiURL,payload);
      let result: any = await this.apiService.postDataPromis(apiURL, payload, {});
      if (result.status == '1' || result.s == '1') {
        this.meta.uploadStatus = 'PRESIGNED_URL_GENERATED';
        this.meta.presigned_url = result.url || result.response || result.presign_url;
        await this.uploadFile(this.meta.file, result);
      } else {
        this.meta.uploadStatus = null;
        this.meta.fileError =
          result.error_message || 'Failed to Upload File. Please try again';
        this.notifier.alert("File Upload Error","",this.meta.fileError,"error",5000);
      }
      // this.notifier.alert("File Upload Error","",this.meta.fileError,"error",5000);
      console.log(apiURL,payload);
      this.notifier.loading(false);
    }
  }
  
  async uploadFile(file: File, result: any) {
    await this.uploadFileAPI(result, file);
  }

  async uploadFileAPI(result: any, file: any) {
    let metadata = result.meta_data || {};
    return new Promise(async (resolve: any, reject: any) => {
      this.fileUploadService
        .uploadFile(this.meta.presigned_url, this.selectedFile, metadata)
        .subscribe(async (event: HttpEvent<any>) => {
          switch (event.type) {
            case HttpEventType.Sent:
              break;
            case HttpEventType.ResponseHeader:
              break;
            case HttpEventType.UploadProgress:
              this.meta.uploadStatus = 'UPLOADING';
              let progress = Math.round(
                (event.loaded / (event.total || 0)) * 100
              );
              this.meta.uploaded = event.loaded;
              this.meta.percentage = progress;
              break;
            case HttpEventType.Response:
              if (event.status === 200) {
                this.notifier.alert('Success', '', 'Successfully added silk image', 'success', 5000);
                resolve(true);
              } else {
                this.meta.uploadStatus = 'ERROR';
                this.meta.fileError = `Upload failed with status ${event.status}`;
                this.notifier.alert("File Upload Error","",this.meta.fileError,"error",5000);
                reject(new Error(`Upload failed with status ${event.status}`));
              }
              break;
            case HttpEventType.User:
              break;
          }
        });
    });
  }
}
