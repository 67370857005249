<div class="card">
  <h5 class="card-header fw-bold">Harness Reports</h5>
  <div class="card-body">
    <div class="grid-3">
      <div class="card">
        <div class="card-header flex-between">
          <div>Victorian Harness Racing</div>
          <div>
            <span class="small-text">
              {{ locations["HRV"] || 0 }} event(s)
            </span>
            <img src="./assets/img/harness/hrv.jpeg" alt="" />
          </div>
        </div>
        <div class="card-body flex-start">
          <a
            routerLink="/dash/post-race"
            [queryParams]="{ state: 'HRV', type: 'Harness Racing' }"
            fragment="eod-report"
            >EOD Report</a
          >
          <a
            routerLink="/dash/post-race"
            [queryParams]="{ state: 'HRV', type: 'Harness Racing' }"
            fragment="race-management"
            >Race Management</a
          >
        </div>
      </div>
      <div class="card">
        <div class="card-header flex-between">
          <div>Queensland Harness Racing</div>
          <div>
            <span class="small-text">
              {{ locations["HRQ"] || 0 }} event(s)
            </span>
            <img src="./assets/img/harness/RQ_logo.png" alt="" />
          </div>
        </div>
        <div class="card-body flex-start">
          <a
            routerLink="/dash/post-race"
            [queryParams]="{ state: 'HRQ', type: 'Harness Racing' }"
            fragment="eod-report"
            >EOD Report</a
          >
          <a
            routerLink="/dash/post-race"
            [queryParams]="{ state: 'HRQ', type: 'Harness Racing' }"
            fragment="race-management"
            >Race Management</a
          >
        </div>
      </div>
      <div class="card">
        <div class="card-header flex-between">
          <div>NSW Harness Racing</div>
          <div>
            <span class="small-text">
              {{ locations["HNSW"] || 0 }} event(s)
            </span>
            <img src="./assets/img/harness/hrnsw.jpeg" alt="" />
          </div>
        </div>
        <div class="card-body flex-start">
          <a
            routerLink="/dash/post-race"
            [queryParams]="{ state: 'HNSW', type: 'Harness Racing' }"
            fragment="eod-report"
            >EOD Report</a
          >
          <a
            routerLink="/dash/post-race"
            [queryParams]="{ state: 'HNSW', type: 'Harness Racing' }"
            fragment="race-management"
            >Race Management</a
          >
        </div>
      </div>
    </div>
  </div>
</div>
<div class="card">
  <h5 class="card-header fw-bold">Thoroughbred Reports</h5>
  <div class="card-body">
    <div class="grid-3">
      <div class="card">
        <div class="card-header flex-between">
          <div>Australian Turf Club (ATC)</div>
          <div>
            <span class="small-text">
              {{ locations["ATC"] || 0 }} event(s)
            </span>
            <img src="./assets/img/harness/atc.jpeg" alt="" />
          </div>
        </div>
        <div class="card-body">
          <div class="flex-start">
            <a
              routerLink="/dash/post-race"
              [queryParams]="{ state: 'ATC', type: 'Thoroughbred Racing' }"
              fragment="australian-turb-club-eod-report"
              >EOD Report</a
            >
            <a
              routerLink="/dash/post-race"
              [queryParams]="{ state: 'ATC', type: 'Thoroughbred Racing' }"
              fragment="race-management"
              >Race Management</a
            >
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header flex-between">
          <div>Racing Victoria Club</div>
          <div>
            <span class="small-text">
              {{ locations["RVC"] || 0 }} event(s)
            </span>
            <img src="./assets/img/harness/RVC.jpeg" alt="" />
          </div>
        </div>
        <div class="card-body">
          <div class="flex-start">
            <a
              routerLink="/dash/post-race"
              [queryParams]="{ state: 'RVC', type: 'Thoroughbred Racing' }"
              fragment="racing-victoria-club-eod-report"
              >EOD Report</a
            >
            <a
              routerLink="/dash/post-race"
              [queryParams]="{ state: 'RVC', type: 'Thoroughbred Racing' }"
              fragment="race-management"
              >Race Management</a
            >
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header flex-between">
          <div>Brisbane Racing Club</div>
          <div>
            <span class="small-text">
              {{ locations["BRC"] || 0 }} event(s)
            </span>
            <img src="./assets/img/harness/brc.jpg" alt="" />
          </div>
        </div>
        <div class="card-body">
          <div class="flex-start">
            <a
              routerLink="/dash/post-race"
              [queryParams]="{ state: 'BRC', type: 'Thoroughbred Racing' }"
              fragment="brisbane-racing-club-eod-report"
              >EOD Report</a
            >
            <a
              routerLink="/dash/post-race"
              [queryParams]="{ state: 'BRC', type: 'Thoroughbred Racing' }"
              fragment="race-management"
              >Race Management</a
            >
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header flex-between">
          <div>Queensland Racing Club</div>
          <div>
            <span class="small-text">
              {{ locations["QRC"] || 0 }} event(s)
            </span>
            <img src="./assets/img/harness/RQ_logo.png" alt="" />
          </div>
        </div>
        <div class="card-body">
          <div class="flex-start">
            <a
              routerLink="/dash/post-race"
              [queryParams]="{ state: 'QRC', type: 'Thoroughbred Racing' }"
              fragment="queensland-racing-club-eod-report"
              >EOD Report</a
            >
            <a
              routerLink="/dash/post-race"
              [queryParams]="{ state: 'QRC', type: 'Thoroughbred Racing' }"
              fragment="race-management"
              >Race Management</a
            >
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header flex-between">
          <div>Singapore Tracks</div>
          <div>
            <span class="small-text">
              {{ locations["STC"] || 0 }} event(s)
            </span>
            <img src="./assets/img/harness/stc.jpg" alt="" />
          </div>
        </div>
        <div class="card-body">
          <div class="flex-start">
            <a
              routerLink="/dash/post-race"
              [queryParams]="{ state: 'STC', type: 'Thoroughbred Racing' }"
              fragment="singapore-tracks-eod-report"
              >EOD Report</a
            >
            <a
              routerLink="/dash/post-race"
              [queryParams]="{ state: 'STC', type: 'Thoroughbred Racing' }"
              fragment="race-management"
              >Race Management</a
            >
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header flex-between">
          <div>New Zealand Tracks</div>
          <div>
            <span class="small-text">
              {{ locations["ARC"] || 0 }} event(s)
            </span>
            <img src="" alt="" />
          </div>
        </div>
        <div class="card-body">
          <div class="flex-start">
            <a
              routerLink="/dash/post-race"
              [queryParams]="{ state: 'ARC', type: 'Thoroughbred Racing' }"
              fragment="new-zealand-tracks-eod-report"
              >EOD Report</a
            >
            <a
              routerLink="/dash/post-race"
              [queryParams]="{ state: 'ARC', type: 'Thoroughbred Racing' }"
              fragment="race-management"
              >Race Management</a
            >
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header flex-between">
          <div>Racing South Australia Track</div>
          <div>
            <span class="small-text">
              {{ locations["RSA"] || 0 }} event(s)
            </span>
            <img
              src="./assets/img/harness/rsa.png"
              alt="Racing South Australia Tracks"
            />
          </div>
        </div>
        <div class="card-body">
          <div class="flex-start">
            <a
              routerLink="/dash/post-race"
              [queryParams]="{ state: 'RSA', type: 'Thoroughbred Racing' }"
              fragment="racing-south-australia-track-eod-report"
              >EOD Report</a
            >
            <a
              routerLink="/dash/post-race"
              [queryParams]="{ state: 'RSA', type: 'Thoroughbred Racing' }"
              fragment="race-management"
              >Race Management</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
