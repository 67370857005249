import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthComponent } from './modules/auth/components/auth/auth.component';
import { DashComponent } from './modules/globals/dash/dash.component';
import { SuperadminComponent } from './modules/globals/superadmin/superadmin.component';
import { ConfigResolver } from './services/config.resolver';
import { ForgotPasswordComponent } from './modules/auth/components/forgot-password/forgot.component';
import { LoginComponent } from './modules/auth/components/login/login.component';
import { RegisterComponent } from './modules/auth/components/register/register.component';
import { ClientsComponent } from './modules/globals/superadmin/components/clients/clients.component';
import { NewMenusComponent } from './modules/globals/superadmin/components/new-menus/new-menus.component';
import { PoliciesComponenet } from './shared/components/policies/manage.policies.component';
import { feedbackComponent } from './modules/globals/dash/components/feedback/feedback.component';
import { HomeComponent } from './modules/globals/dash/modules/pages/components/home/home.component';
import { PreRaceComponent } from './modules/globals/dash/modules/pages/components/pre-race/app.component';
import { DeputyComponent } from './modules/globals/dash/modules/pages/components/pre-race/deputy/deputy.component';
import { DeviceListComponent } from './modules/globals/dash/modules/pages/components/pre-race/device-list/device-list.component';
import { RaceDetailsComponent } from './modules/globals/dash/modules/pages/components/race-details/race-details.component';
import { RaceOverviewComponent } from './modules/globals/dash/modules/pages/components/race-overview/race-overview.component';
import { VenueDetailsComponent } from './modules/globals/dash/modules/pages/components/venve-details/venve-details.component';
import { AuthGuard, PreventUnsavedChangesGuard } from './services/auth.guard';
import { UserProfileComponent } from './modules/globals/dash/modules/settings/components/user-profile/user-profile.component';
import { UsersComponent } from './modules/globals/dash/modules/settings/components/users/users.component';
import { HarnessReportsComponent } from './modules/globals/dash/modules/pages/components/post-race/harness-reports/harness-reports.component';
import { UpdateUserComponent } from './modules/globals/dash/modules/settings/components/users/components/update-user/update-user.component';
import { RaceReportsComponent } from './modules/globals/dash/modules/pages/components/post-race/harness-reports/components/race-reports/race-reports.component';
import { PcStatusComponent } from './modules/globals/dash/modules/pages/components/pc-status/pc-status.component';
import { VenueEmailsComponent } from './modules/globals/dash/modules/pages/components/venue-emails/venue-emails.component';
import { OperatorKitManagementComponent } from './modules/globals/dash/modules/pages/components/operator-kit-management/operator-kit-management.component';
import { OperatorKitDetailsPageComponent } from './modules/globals/dash/modules/pages/components/operator-kit-details-page/operator-kit-details-page.component';
import { KitManagementComponent } from './modules/globals/dash/modules/pages/components/kit-management/kit-management.component';
import { KitManagementDetailsComponent } from './modules/globals/dash/modules/pages/components/kit-management-details/kit-management-details.component';
import { DevicesComponent } from './modules/globals/dash/modules/pages/components/devices/devices.component';
import { RaceMonitoringComponent } from './modules/globals/dash/modules/pages/components/race-monitoring/race-monitoring.component';
import { RaceStatusComponent } from './modules/globals/dash/modules/pages/components/race-status/race-status.component';
import { RaceRunnerComponent } from './modules/globals/dash/modules/pages/components/race-runner/race-runner.component';
import { RaceRunnerMapComponent } from './modules/globals/dash/modules/pages/components/race-runner-map/race-runner-map.component';
import { OnCourseOpsRaceReportsComponent } from './modules/globals/dash/modules/pages/components/post-race/harness-reports/components/on-course-ops-race-reports/on-course-ops-race-reports.component';
import { OnCourseOpsJudgesComponent } from './modules/globals/dash/modules/pages/components/post-race/harness-reports/components/on-course-ops-judges/on-course-ops-judges.component';
import { ManualRaceCardsComponent } from './modules/globals/dash/modules/pages/components/manual-race-cards/manual-race-cards.component';
import { OnCourseOpsSectionalsComponent } from './modules/globals/dash/modules/pages/components/post-race/harness-reports/components/on-course-ops-sectionals/on-course-ops-sectionals.component';
const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'auth/login',
  },
  {
    path: 'auth',
    component: AuthComponent,
    children: [
      {
        path: 'login',
        component: LoginComponent,
        title: 'Login',
        resolve: { config: ConfigResolver },
      },
      {
        path: 'register',
        component: RegisterComponent,
        title: 'Register',
        resolve: { config: ConfigResolver },
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
        title: 'Forgot Password',
        resolve: { config: ConfigResolver },
      },
    ],
  },
  {
    path: 'superadmin',
    component: SuperadminComponent,
    resolve: { config: ConfigResolver },
    children: [
      {
        path: 'users/:type',
        component: ClientsComponent,
        title: 'Users',
        resolve: { config: ConfigResolver },
      },
      {
        path: 'policies',
        component: PoliciesComponenet,
        title: 'Policies',
        resolve: { config: ConfigResolver },
      },
      {
        path: 'menus',
        component: NewMenusComponent,
        title: 'Menus',
        resolve: { config: ConfigResolver },
      },
    ],
  },
  {
    path: 'dash',
    component: DashComponent,
    resolve: { config: ConfigResolver },
    canActivate: [AuthGuard],
    children: [
      {
        path: 'feedback',
        component: feedbackComponent,
        title: 'Feedback',
      },
      {
        path: 'home',
        component: HomeComponent,
        title: 'Dashboard',
        resolve: { config: ConfigResolver },
      },
      {
        path: 'venue-details',
        component: VenueDetailsComponent,
        canDeactivate: [PreventUnsavedChangesGuard],
        title: 'Event Details',
        resolve: { config: ConfigResolver },
      },
      {
        path: 'pc-status',
        component: PcStatusComponent,
        title: 'PC Status',
        resolve: { config: ConfigResolver },
      },
      {
        path: 'venue-configuration',
        component: VenueEmailsComponent,
        title: 'Venue Email Configuration',
        resolve: { config: ConfigResolver },
      },
      {
        path: 'race-details',
        component: RaceDetailsComponent,
        title: 'Race Details',
        resolve: { config: ConfigResolver },
      },
      {
        path: 'race-overview',
        title: 'Race Overview',
        component: RaceOverviewComponent,
        resolve: { config: ConfigResolver },
      },
      {
        path: 'pre-race',
        title: 'Prepare for Race',
        component: PreRaceComponent,
        resolve: { config: ConfigResolver },
      },
      {
        path: 'device-list',
        component: DeviceListComponent,
        resolve: { config: ConfigResolver },
      },
      {
        path: 'deputy',
        component: DeputyComponent,
        resolve: { config: ConfigResolver },
      },
      {
        path: 'post-race',
        title: 'Post Race',
        component: HarnessReportsComponent,
        resolve: { config: ConfigResolver },
      },
      {
        path: 'post-race/race-reports',
        title: 'Race Reports',
        component: RaceReportsComponent,
        resolve: { config: ConfigResolver },
      },
      {
        path: 'on-course-ops/race-reports',
        title: 'Race Reports',
        component: OnCourseOpsRaceReportsComponent,
        resolve: { config: ConfigResolver },
      },
      {
        path: 'on-course-ops/judges',
        title: 'Judges',
        component: OnCourseOpsJudgesComponent,
        resolve: { config: ConfigResolver },
      },
      {
        path: 'on-course-ops/sectionals',
        title: 'Sectionals',
        component: OnCourseOpsSectionalsComponent,
        resolve: { config: ConfigResolver },
      },
      {
        path: 'operator-kit-management',
        title: 'Operator Kit Management',
        component: OperatorKitManagementComponent,
        resolve: {
          config: ConfigResolver,
        },
      },
      {
        path: 'manual-race-cards',
        title: 'Manual Race Cards',
        component: ManualRaceCardsComponent,
        resolve: {
          config: ConfigResolver,
        },
      },
      {
        path: 'operator-kit-details/:kitId',
        title: 'Operator Kit Details',
        component: OperatorKitDetailsPageComponent,
        resolve: {
          config: ConfigResolver,
        },
      },
      {
        path: 'kit-management',
        title: 'Kit Management',
        component: KitManagementComponent,
        resolve: {
          config: ConfigResolver,
        },
      },
      {
        path: 'kit-details-management/:codeType/:venueState/:kitId',
        title: 'Kit Details Management',
        component: KitManagementDetailsComponent,
        resolve: {
          config: ConfigResolver,
        },
      },
      {
        path: 'devices',
        title: 'Devices',
        component: DevicesComponent,
        resolve: {
          config: ConfigResolver,
        },
      },
      {
        path: 'monitoring',
        title: 'Monitoring',
        component: RaceMonitoringComponent,
        resolve: {
          config: ConfigResolver,
        },
      },
      {
        path: 'race-status',
        title: 'Race Status',
        component: RaceStatusComponent,
        resolve: {
          config: ConfigResolver,
        },
      },
      {
        path: 'race-runner/:venue_id/:event_id/:race_id',
        title: 'Race Runner Status',
        component: RaceRunnerComponent,
        resolve: {
          config: ConfigResolver,
        },
      },
      {
        path: 'race-runner-map/:venue_id/:event_id/:race_id',
        title: 'Race Runner Status',
        component: RaceRunnerMapComponent,
        resolve: {
          config: ConfigResolver,
        },
      },
      {
        path: 'settings',
        children: [
          {
            path: 'users',
            component: UsersComponent,
            title: 'Settings - Users',
            resolve: { config: ConfigResolver },
          },
          {
            path: 'profile',
            component: UserProfileComponent,
            title: 'Settings - Profile',
            resolve: { config: ConfigResolver },
          },
          {
            path: 'policies',
            component: PoliciesComponenet,
            title: 'Settings - Policies',
            resolve: { config: ConfigResolver },
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
