// graphql.service.ts
import { Injectable } from '@angular/core';
import { ApolloClient, InMemoryCache, createHttpLink, DocumentNode, QueryOptions, TypedDocumentNode } from '@apollo/client/core';
import { ApolloQueryResult } from '@apollo/client';
import { Observable } from 'rxjs';
import { gql } from '@apollo/client';
import { InitialQuery, InitialResponse, NextPageQuery, generateDeviceQuery, extractItems, generateBarrierQuery, barrierItems, generateTagQuery, generateKitIDOptionsQuery, SettingsQuery, extractSN } from './graphql-queries';
@Injectable({
  providedIn: 'root',
})
export class GraphqlService {
  private client: ApolloClient<any>; // Use the correct type for ApolloClient

  constructor() {
    const api_token = "eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjI4NDA0MzM5OSwiYWFpIjoxMSwidWlkIjo0OTMyMTU1MCwiaWFkIjoiMjAyMy0wOS0yNVQwNzozOTozMy4wMDBaIiwicGVyIjoibWU6d3JpdGUiLCJhY3RpZCI6MTAyNDkyNTIsInJnbiI6InVzZTEifQ.3M-cp2lQckgqi16_neTS-fCpLgK5EeDNtrLVBEsaCmI";

    const httpLink = createHttpLink({
      uri: "https://api.monday.com/v2",
      headers: {
        Authorization: api_token,
        "API-Version": "2023-10",
      },
    });


    // Create an Apollo Client instance
    this.client = new ApolloClient({
      link: httpLink,
      cache: new InMemoryCache(),
    });
  }

  async query<T>(graphqlQuery: DocumentNode, variables?: Record<string, any>): Promise<ApolloQueryResult<T>> {
    try {
      const result = await this.client.query<T>({
        query: graphqlQuery,
        variables,
      });
      return result;
    } catch (error) {
      throw error;
    }
  }

  codeTypeDict: Record<string, number> = {
    "HARNESS": 0,
    "GALOPPS": 1,
    "GALLOPS":1
  };

  venueStateDict: Record<string, number> =
  {
    "NSW": 0,
    "VIC": 1,
    "QLD": 2,
    "SA": 3,
    "NZ": 4,
    "": 5,
    "STC": 6,
    "SG":6
  }
  async queryAvailableKits(venueState: any, codeType: any) {
    const columnIds = '["kit_id"]';

    const venueStateIndex = this.venueStateDict[venueState];
    const codeTypeIndex = this.codeTypeDict[codeType]
    const venueStateQ = `[${venueStateIndex}]`
    const codeTypeQ = `[${codeTypeIndex}]`
    console.log(codeType)
    console.log(venueStateQ)
    let cursor: any
    let kitIds = new Set<any>();

    let kitQuery = generateDeviceQuery(venueStateQ, codeTypeQ);


    try {
      let responseData: ApolloQueryResult<InitialQuery> = await this.query<InitialQuery>(kitQuery);
      cursor = responseData.data.boards[0].items_page.cursor
      let items = extractItems(responseData.data.boards[0].items_page.items);
      kitIds = new Set([...kitIds, ...items])

      while (cursor != null) {
        kitQuery = generateDeviceQuery(venueStateQ, codeTypeQ, cursor = cursor)
        let nextResponseData: ApolloQueryResult<NextPageQuery> = await this.query<NextPageQuery>(kitQuery);
        cursor = nextResponseData.data.next_items_page.cursor;
        let items = extractItems(nextResponseData.data.next_items_page.items);
        kitIds = new Set([...kitIds, ...items])
        const kitList = Array.from(kitIds);
        const kitListString = JSON.stringify(kitList);

        console.log(kitListString);
      }


    } catch (error) {
      console.error('GraphQL query error', error);
      throw error;
    }
    const stringList = [...kitIds].filter(item => item !== '');
    return stringList
  }
  async queryAvailableTags(kitID: any) {
    console.log(kitID)
    const columnIds = '["kit_id"]';
    const kitIdQ = `[${kitID}]`
    console.log(kitIdQ)
    let cursor: any
    let kitIds = new Set<any>();

    let kitQuery = generateTagQuery(kitIdQ);
    let dict: Record<string, string> = {};

    try {
      let responseData: ApolloQueryResult<InitialQuery> = await this.query<InitialQuery>(kitQuery);
      cursor = responseData.data.boards[0].items_page.cursor
      let items = extractSN(responseData.data.boards[0].items_page.items);
      dict = {...dict, ...items}

      while (cursor != null) {
        kitQuery = generateTagQuery(kitIdQ, cursor = cursor)
        let nextResponseData: ApolloQueryResult<NextPageQuery> = await this.query<NextPageQuery>(kitQuery);
        cursor = nextResponseData.data.next_items_page.cursor;
        let items = extractSN(nextResponseData.data.next_items_page.items);
        items = {...items, ...items}
        // kitIds = new Set([...kitIds, ...items])
        // const kitList = Array.from(kitIds);
        // const kitListString = JSON.stringify(kitList);

        //console.log(kitListString);
      }


    } catch (error) {
      console.error('GraphQL query error', error);
      throw error;
    }
    return dict
  }
  async queryKitOptions(){
    const kit_options_query = generateKitIDOptionsQuery();
    try {
      let responseData: ApolloQueryResult<SettingsQuery> = await this.query<SettingsQuery>(kit_options_query);
      return responseData
    }catch (error) {
      console.error('GraphQL query error', error);
      throw error;
    }
  }
  async queryBarrierKits(venueState: any, codeType: any) {
    const columnIds = '["kit_id"]';

    const venueStateIndex = this.venueStateDict[venueState];
    const codeTypeIndex = this.codeTypeDict[codeType]
    const venueStateQ = `[${venueStateIndex}]`
    const codeTypeQ = `[${codeTypeIndex}]`
    console.log(codeType)
    console.log(venueStateQ)
    let cursor: any
    let kitIds = new Set<any>();

    let kitQuery = generateBarrierQuery(venueStateQ, codeTypeQ);


    try {
      let responseData: ApolloQueryResult<InitialQuery> = await this.query<InitialQuery>(kitQuery);
      cursor = responseData.data.boards[0].items_page.cursor
      let items = extractItems(responseData.data.boards[0].items_page.items);
      kitIds = new Set([...kitIds, ...items])

      while (cursor != null) {
        kitQuery = generateBarrierQuery(venueStateQ, codeTypeQ, cursor = cursor)
        let nextResponseData: ApolloQueryResult<NextPageQuery> = await this.query<NextPageQuery>(kitQuery);
        cursor = nextResponseData.data.next_items_page.cursor;
        let items = extractItems(nextResponseData.data.next_items_page.items);
        kitIds = new Set([...kitIds, ...items])
        const kitList = Array.from(kitIds);
        const kitListString = JSON.stringify(kitList);

        console.log(kitListString);
      }


    } catch (error) {
      console.error('GraphQL query error', error);
      throw error;
    }
    const stringList = [...kitIds].filter(item => item !== '');
    return stringList
  }

}
