<div class="alarm_screen alarm_screen_box">
  <div class="alarm_container">
    <div class="alarm_header flex-between">
      <h4 class="alarm_header_text">View Policy</h4>
      <div>
        <i class="fa fa-times" (click)="hideModel.emit(false)"></i>
      </div>
    </div>
    <div class="alarm_body alarm_padding">
      <div class="row">
        <label>Policy Name</label>
        <div>{{ policyDetails['policyName'] }}</div>
      </div>
      <div class="row">
        <label>Services</label>
        <menu-selector [edit]="false" [menus]="policyDetails['mList']"></menu-selector>
      </div>
      <div class="row">
        <label>Venues</label>
        <div>{{venueList}}</div>
      </div>
      <div class="row">
        <label>Race Types</label>
        <div>{{raceTypes}}</div>
      </div>
      <!-- <div class="row" [ngStyle]="{display: urlPrefix == 'client'? 'block' : 'none'}">
        <label for="exampleInputEmail1">AWS Account List</label>
        <div *ngIf="getAccounts().length > 0">
          {{ getAccounts() }}
        </div>
        <div *ngIf="getAccounts().length == 0">
          -
        </div>
      </div>
      <div class="row">
        <label>Tags</label>
        <div *ngIf="!policyDetails.tagsEnabled">
          All Tags
        </div>
      </div>
      <div class="tags-container form-group">
        <div class="container-body" [ngStyle]="{display: policyDetails.tagsEnabled ? 'block' : 'none'}">
          <div class="account-conntainers" *ngFor="let account of policyDetails['accountDetailsList']">
            <div class="account-header">
              {{account.accountId}}
            </div>
            <div class="account-body">
              <ng-container *ngIf="getObjectKeys(policyDetails.tagsList).length > 0">
                <div class="account-conntainers"
                  *ngFor="let region of getObjectKeys(policyDetails.tagsList[account['accountId']])">
                  <div class="account-header flex-between">
                    <div>
                      {{region}}
                    </div>
                  </div>
                  <div class="account-body"
                    *ngIf="getObjectKeys(policyDetails.tagsList[account['accountId']][region]).length > 0">
                    <ng-container
                      *ngFor="let key of getObjectKeys(policyDetails.tagsList[account['accountId']][region])">
                      <div class="flex-start">
                        <div class="text-bold">{{ key }}</div>
                        <div>
                          {{getSelectedTagValues(account['accountId'], region, key)}}
                        </div>
                      </div>
                    </ng-container>
                  </div>
                  <div class="account-body center"
                    *ngIf="getObjectKeys(policyDetails.tagsList[account['accountId']][region]).length == 0">
                    No Tags Selected
                  </div>
                </div>
              </ng-container>

            </div>
          </div>
        </div>
      </div> -->
      <div class="row">
        <label>Access Type</label>
        <div *ngIf="policyDetails.accessType == 'readOnly'">
          Read Only
        </div>
        <div *ngIf="policyDetails.accessType == 'readOnly(startstop)'">
          Read Only (Start/Stop)
        </div>
        <div *ngIf="policyDetails.accessType == 'readandwrite'">
          Read and Write
        </div>
      </div>
      <!-- <div class="alarm_actions">
        <div class="alarm_div">
          <button class="btn alarm_btn" (click)="updateEntry()" [ngClass]="{'click-disabled': !writeAccess}"
            [attr.disabled]="!writeAccess ? true : null">
            {{action == 'add' ? 'Create' : 'Update'}}
          </button>
          <button class="btn btn-light" (click)="close()">Cancel</button>
        </div>
      </div> -->
    </div>
  </div>
</div>
