<div id="popup" class="popup">
  <div class="popup-container">
    <div class="popup-header">Delete User</div>
    <div class="popup-content">
      <p>
        Are you sure you want to delete the user
        <strong>{{ user["userName"] }} ({{ user["email"] }})</strong>.
      </p>
      <div class="popup-buttons flex-end">
        <button
          id="continueBtn"
          class="btn btn-danger-soft"
          (click)="deleteUser()"
        >
          Delete
        </button>
        <button
          id="logoutBtn"
          class="btn btn-light"
          (click)="hideModel.emit(false)"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>
