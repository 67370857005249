import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment-timezone';
import { APIService } from 'src/app/services/api.service';
import { NotifierService } from 'src/app/services/notifier.service';
import { locationMappers, typeBasedLocations } from '../race-reports/locations';
import { Subscription } from 'rxjs';

@Component({
  selector: 'post-race-eod-report',
  templateUrl: './eod-report.component.html',
  styleUrls: ['./eod-report.component.css'],
})
export class EodReportComponent {
  @Input('event') event: any;
  @Output('hideModel') hideModel: any = new EventEmitter<boolean>();

  constructor(
    private notifier: NotifierService,
  ) {
  }
}
