import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  Input,
} from '@angular/core';
import { APIService } from 'src/app/services/api.service';
import * as moment from 'moment';
import { NotifierService } from 'src/app/services/notifier.service';
import { ActivatedRoute } from '@angular/router';
import {
  checkForVenuePermission,
  convertTimestamp,
  getENV,
  getRaceType,
} from '../../../../../../../_helpers/helpers';
import {
  buttonActions,
  typeBasedLocations,
} from '../post-race/harness-reports/components/race-reports/locations';

import { MqttService } from '../../../../../../../services/mqtt.service';
import { Subscription } from 'rxjs';
// declares
declare let $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit, AfterViewInit, OnDestroy {
  races: any = [];
  loading: boolean = false;
  interval: any = null;
  mqtt_heartbeat_topic = 'EQTraCe/HeartBeat';
  advance_live_data_topic = 'TRACKING/AUS/+/+/+/AdvanceLiveData';
  runner_status_topic = 'TRACKING/AUS/+/+/+/RunnerStatus';
  barrier_status_topic = 'TRACKING/AUS/+/+/+/BarrierLoading';
  subscriptions: { data: any }[] = [];
  filters: any = {
    venue: { selected: ['ALL'] },
    status: { selected: ['ALL'] },
    time: { selected: ['ALL'] },
  };
  @Input() useMqqt: boolean = false;
  headers: any = [
    {
      id: 'MinutesLeft',
      name: 'Time to Race',
      style: {
        width: '100px',
      },
      type: 'component',
      component: {
        name: 'MinutesLeft',
      },
      show: this.useMqqt,
    },
    {
      id: 'SquentialRaceOrderNumber',
      name: 'Race Number',
      style: {
        width: '100px',
      },
      type: 'string',
      filter: true,
      show: true,
    },
    {
      id: 'eventName',
      name: 'Venue',
      type: 'string',
      align: 'left',
      show: true,
      filter: true,
      link: {
        href: '/dash/venue-details',
        queryParamsKey: 'queryParamsVenue',
      },
    },
    {
      id: 'ExternalRaceId',
      name: 'Race ID',
      type: 'string',
      show: false,
      filter: true,
    },
    {
      id: 'RaceName',
      name: 'Race Name',
      align: 'left',
      type: 'string',
      // filter: true,
      show: true,
      link: {
        href: '/dash/race-details',
        queryParamsKey: 'queryParamsRace',
      },
    },
    {
      id: 'status',
      name: 'Race Status',
      type: 'component',
      // filter: true,
      component: {
        name: 'HorseStatusViewComponent',
      },
      show: true,
    },
    {
      id: 'MqttState',
      name: 'MQTT / Race State',
      type: 'component',
      show: this.useMqqt,
      component: {
        name: 'MqttStateComponent',
      },
    },
    {
      id: 'AdvanceLiveData',
      name: 'Advance Live Data',
      type: 'component',
      show: this.useMqqt,
      component: {
        name: 'span',
      },
    },
    {
      id: 'RunnerStatus',
      name: 'Runner Status Feed',
      type: 'component',
      show: this.useMqqt,
      component: {
        name: 'span',
      },
    },
    {
      id: 'BarrierLoading',
      name: 'Barrier Loading Feed',
      type: 'component',
      show: this.useMqqt,
      component: {
        name: 'span',
      },
    },
    {
      id: 'buttonActions',
      name: 'Report Status',
      type: 'component',
      // filter: true,
      component: {
        name: 'RaceStatusViewComponent',
      },
      show: true,
    },
    {
      id: 'RaceTime',
      name: 'Race Time',
      type: 'string',
      show: true,
      datepicker: {
        class: 'datetimepicker',
      },
    },
    {
      id: 'DataQualityClass',
      name: 'Good/Bad Data',
      click: 'toggleGoodorBadData',
      type: 'component',
      component: {
        name: 'clickspan',
      },
    },
    {
      id: 'Operator',
      name: 'Operator',
      type: 'string',
      show: false,
    },
  ];

  config: any;

  constructor(
    private apiService: APIService,
    private notifier: NotifierService,
    private router: ActivatedRoute,
    private mqttService: MqttService
  ) {
    this.config = this.router.snapshot.data['config'];
  }

  default(event: Event) {
    // event.preventDefault();
    event.stopPropagation();
  }

  hideMenu(event: Event, h: any) {
    event.stopPropagation();
    h.show = !h.show;
  }

  setHeadersToShowTrue(headerIds: string[]): void {
    this.headers.forEach((header) => {
      if (headerIds.includes(header.id)) {
        header.show = true;
      }
    });
  }

  async ngOnInit(): Promise<void> {
    if (this.useMqqt) {
      const headerIds = [
        'MinutesLeft',
        'SquentialRaceOrderNumber',
        'eventName',
        'status',
        'MqttState',
        'AdvanceLiveData',
        'BarrierLoading',
        'RunnerStatus',
        'buttonActions',
        'RaceTime',
        'Operator',
      ];
      this.headers.forEach((header) => {
        if (headerIds.includes(header.id)) {
          header.show = true;
        }
      });
      const excludedHeaderIds = ['ExternalRaceId', 'RaceName'];
      this.headers.forEach((header) => {
        if (excludedHeaderIds.includes(header.id)) {
          header.show = false;
        }
      });
    }
  }

  handleClicks(event: any) {
    switch (event.type) {
      case 'toggleGoodorBadData':
        this.toggleGoodorBadData(event.item);
        break;
    }
  }

  async toggleGoodorBadData(race) {
    let apiURL: string = `${this.config[getENV()].raceAPI}/dataquality`;
    let payload: any = {
      ExternalVenueId: race.ExternalVenueId,
      ExternalEventId: race.ExternalEventId,
      ExternalRaceId: race.ExternalRaceId,
      is_data_good: !race.DataQuality,
    };
    let header: any = {
      'X-Api-Key': 'e2bvuAoslY1VJvFMqhr5x7nxoEhFVRii9jRngS3U',
    };
    race.DataQuality = !race.DataQuality;
    race.DataQualityClass = race.DataQuality ? 'green-circle': 'red-circle';
    let result: any = await this.apiService.postDataPromis(
      apiURL,
      payload,
      header
    );
    if (result.status == '1' || result.s == '2') {
      this.notifier.alert(
        'Success',
        '',
        'Data Saved Successfully',
        'success',
        5000
      );
    } else {
      race.DataQuality = !race.DataQuality;
      race.DataQualityClass = race.DataQuality ? 'green-circle' : 'red-circle';
      this.notifier.alert('Error', '', result.error_message, 'error', 5000);
    }
  }

  times: { name: string; id: string }[] = [];
  async loadData(): Promise<void> {
    this.times = [];
    this.times.push({
      name: '10 mins',
      id: '10 mins',
    });
    this.times.push({
      name: '30 mins',
      id: '30 mins',
    });
    this.times.push({
      name: '1 Hour',
      id: '1 Hour',
    });
    this.loading = true;
    this.notifier.loading(true);

    this.races = [];
    let date: string = moment(
      $('.datetimepicker').val(),
      'DD MMMM YYYY'
    ).format('DD-MM-YYYY');
    if (date) {
      await this.loadEventsBasedOnDates(
        moment(date, 'DD-MM-YYYY').format('YYYY-MM-DD')
      );
    }
    if (this.races && this.races.length > 0) {
      this.races = this.races.sort((a: any, b: any): number => {
        return a['MinutesLeft'] - b['MinutesLeft'];
      });
    }
    if (this.useMqqt) {
      this.races = this.races.filter((race) => race['MinutesLeft'] >= -5);
    }

    this.loading = false;
    this.notifier.loading(false);
  }

  async ngAfterViewInit(): Promise<void> {
    let interval_seconds = 5 * 50 * 1000;
    if (this.useMqqt === true) {
      interval_seconds = 30000;
    }
    $('.datetimepicker').val(moment().format('DD MMMM YYYY'));
    $('.datetimepicker')
      .datepicker({
        autoclose: true,
        // minViewMode: 1,
        format: 'dd MM yyyy',
        orientation: 'bottom auto',
      })
      .on('changeDate', (selected: any) => {
        this.subscriptions.forEach((data: any) => {
          this.mqttService.unsubscribe(data.data);
        });
        this.subscriptions = [];
        setTimeout(() => {
          this.loadData();
        }, 10);
        if (this.interval) {
          clearInterval(this.interval);
          this.interval = null;
        }
        this.interval = setInterval(() => {
          this.loadData();
        }, interval_seconds);
      });
    setTimeout(() => {
      this.loadData();
      if (this.interval) {
        clearInterval(this.interval);
        this.interval = null;
      }
      this.interval = setInterval(() => {
        this.loadData();
      }, interval_seconds);
    }, 10);
    this.connect();
  }

  events: any = [];
  statuses: { id: string; name: string }[] = [
    { name: 'PLANNED', id: 'PLANNED' },
    { name: 'DNS', id: 'DNS' },
    { name: 'DNT', id: 'DNT' },
    { name: 'DNF', id: 'DNF' },
    { name: 'DSQ', id: 'DSQ' },
    { name: 'OFFICIAL', id: 'OFFICIAL' },
    { name: 'FINISHED', id: 'FINISHED' },
    { name: 'PUBLISHED', id: 'PUBLISHED' },
    { name: 'ABANDONED', id: 'ABANDONED' },
  ];

  async loadEventsBasedOnDates(date: string): Promise<boolean> {
    return new Promise(async (resolve: any) => {
      let apiURL: string = `${
        this.config[getENV()].raceAPI
      }/event-list?formatted_date=${date}`;

      let result: any = await this.apiService.getDataPromis(apiURL, {}, {});

      this.events = [];

      if (result.eventNames && result.eventNames.length > 0) {
        result.eventNames = result.eventNames.filter((event: any) => {
          return checkForVenuePermission(event['external_venue_id']);
        });
        this.events = result.eventNames.map(
          (event: any): { id: string; name: string } => {
            let raceType = getRaceType(event['external_venue_id'])[
              'raceTypeStr'
            ];
            if (
              event['venue_name'] == 'Cranbourne' &&
              raceType == 'Thoroughbred Racing'
            ) {
              event['venue_name'] = 'Cranbourne Turf';
            }
            return {
              id: event['external_event_id'],
              name: event['venue_name'],
            };
          }
        );
        let promises: any = [];
        result.eventNames.forEach((event: any) => {
          let raceType = getRaceType(event['external_venue_id']);
          event['raceType'] = raceType['raceTypeStr'];
          if (raceType['raceTypeStr'] == 'Thoroughbred Racing') {
            if (event['venue_name'] == 'Cranbourne') {
              event['venue_name'] = 'Cranbourne Turf';
            }
          }
          const jurisdiction = typeBasedLocations[event['raceType']]?.find(
            (jurisdiction: any) =>
              jurisdiction.Locations.some(
                (location: any) =>
                  location.Name.toLowerCase() ===
                  event['venue_name'].toLowerCase()
              )
          );
          if (jurisdiction) {
            event['jurisdictionCode'] = jurisdiction.JurisdictionCode;
            event['stateCode'] = jurisdiction.State;
          }
          event['name'] = event['venue_name'];
          // console.log(event);
          promises.push(this.loadRacesBasedOnEvent(event));
        });
        if (promises.length > 0) {
          Promise.all(promises).then((values: any) => {
            resolve(true);
          });
        } else {
          resolve(true);
        }
      } else {
        resolve(true);
      }
    });
  }

  async loadRacesBasedOnEvent(event: any): Promise<boolean> {
    return new Promise(async (resolve: any) => {
      let apiURL: string = `${
        this.config[getENV()].raceAPI
      }/getracedetails?ExternalVenueId=${
        event['external_venue_id']
      }&ExternalEventId=${event['external_event_id']}`;

      let result: any = await this.apiService.getDataPromis(apiURL, {}, {});
      let raceDate = $('.datetimepicker').val();
      if (result && Array.isArray(result) && result.length > 0) {
        let raceType = getRaceType(event['external_venue_id'])['raceTypeStr'];
        result.forEach((race: any) => {
          race.DataQualityClass = race.DataQuality
            ? 'green-circle'
            : 'red-circle';
          // const runnerTopic: string = `TRACKING/AUS/${race['ExternalVenueId']}/${race['ExternalEventId']}/${race['ExternalRaceId']}/RunnerStatus`;
          // const barrierTopic: string = `TRACKING/AUS/${race['ExternalVenueId']}/${race['ExternalEventId']}/${race['ExternalRaceId']}/BarrierLoading`;
          // let check = this.subscriptions.find(
          //   (subscription: any) => subscription.data == runnerTopic
          // );
          // if (!check) {
          //   this.mqttService.subscribe(runnerTopic);
          //   this.mqttService.subscribe(barrierTopic);
          //   this.subscriptions.push({ data: runnerTopic });
          //   this.subscriptions.push({ data: barrierTopic });
          // }
          race['status'] = {
            PLANNED: 0,
            DNS: 0,
            DNT: 0,
            DNF: 0,
            DSQ: 0,
            OFFICIAL: 0,
            FINISHED: 0,
            PUBLISHED: 0,
            ABANDONED: 0,
          };
          race['raceStatus'] = race['RaceState'] || 'PLANNED';
          if (race['raceStatus'].toUpperCase() == 'ABANDONED') {
            race['status']['ABANDONED'] = race['AnimalDetail'].length;
          } else {
            race['AnimalDetail'].forEach((animal: any) => {
              animal['RaceState'] = animal['RaceState'] || 'PLANNED';
              let status = 'PLANNED';
              if (animal['RaceState'].toUpperCase() == 'PLANNED') {
                if (race['raceStatus'].toUpperCase() == 'PLANNED') {
                  status = 'PLANNED';
                } else if (race['raceStatus'].toUpperCase() == 'FINISHED') {
                  status = 'FINISHED';
                }
              } else {
                status = animal['RaceState'].toUpperCase();
              }
              race['status'][status] += 1;
            });
          }
          race['buttonActions'] = [
            {
              slug: 'OR',
              status: 'PLANNED',
              actions: [],
            },
            {
              slug: 'PDF',
              status: 'PLANNED',
              actions: [],
            },
            {
              slug: 'CUST',
              status: 'PLANNED',
              actions: [],
            },
            {
              slug: 'TSD',
              status: 'PLANNED',
              actions: [],
            },
          ];
          let buttonActionsJSON = JSON.parse(JSON.stringify(buttonActions));
          buttonActionsJSON
            .filter((report: any) => {
              if (report.conditions) {
                const condition = report.conditions.find(
                  (c: any) => c.state === event['jurisdictionCode']
                );
                if (condition) {
                  const { key, equation, value } = condition.condition;
                  const raceValue = race[key];
                  switch (equation) {
                    case 'equals':
                      return raceValue === value;
                    case 'not equals':
                      return raceValue !== value;
                    default:
                      return true;
                  }
                }
                return true;
              }
              return true;
            })
            .forEach((report: any) => {
              if (
                (report.states == 'ALL' ||
                  report.states.includes(event['jurisdictionCode']) ||
                  report.states.includes(event['stateCode'])) &&
                (!report.type || report.type == raceType)
              ) {
                let reportStatus = race['ReportStatus'].find((report_: any) => {
                  return report_['StepType'] == report['action'];
                });
                let action = race['buttonActions'].find(
                  (buttonAction: any) => buttonAction.slug == report['slug']
                );

                if (reportStatus) {
                  action['actions'].push({
                    name: report['name'],
                    status: reportStatus['StepCompletionStatus'].toUpperCase(),
                    comment: reportStatus['Comments'] || '',
                  });
                } else {
                  action['actions'].push({
                    name: report['name'],
                    status: 'PLANNED',
                    comment: '',
                  });
                }
              }
            });

          race['buttonActions'].forEach((action: any) => {
            let success = action['actions'].find((action_: any) => {
              return action_['status'] == 'COMPLETED';
            });
            let failed = action['actions'].find((action_: any) => {
              return action_['status'] == 'FAILED';
            });
            if (failed) {
              action['status'] = 'FAILED';
            } else if (success) {
              action['status'] = 'COMPLETED';
            } else {
              action['status'] = 'PLANNED';
            }
          });

          race['eventName'] = event['name'];
          race['eventDate'] = $('.datetimepicker').val();
          race['RaceTimeStr'] = race['RaceTime'];
          race['RaceTime'] = convertTimestamp(
            race['RaceTime'],
            event['venue_state'],
            this.useMqqt
          );
          race['queryParamsVenue'] = {
            event_id: race['ExternalEventId'],
            venue_id: race['ExternalVenueId'],
            date: race['eventDate'],
            event_name: race['eventName'],
          };
          race['queryParamsRace'] = {
            race_id: race['ExternalRaceId'],
            event_id: race['ExternalEventId'],
          };
          race['MqttState'] = {
            state: (function () {
              race['MQTTState'] = race['MQTTState']?.toString();
              if (race['MQTTState'] == '4') {
                return 'green-circle';
              } else if (race['MQTTState'] == '5') {
                return 'checkered-flag';
              } else {
                return 'red-circle';
              }
            })(),
            pc_number: race['MQTTPC'] || 'No PC',
          };
          race['AdvanceLiveData'] = 'red-circle';
          race['RunnerStatus'] = 'red-circle';
          race['BarrierLoading'] = 'red-circle';
          race['MinutesLeft'] = this.calculateMinutesLeft(
            raceDate,
            race['RaceTime']
          );
          race['Operator'] = 'Not Assigned';
        });
        this.races = [...this.races, ...result];
        // await this.getRaceResult(
        //   event['external_event_id'],
        //   event['external_venue_id'],
        //   result
        // );
        await this.getOperators();
        // this.races = [...this.races, ...result];
      }
      resolve(true);
    });
  }

  // async getRaceResult(event_id: string, venue_id: string, races: any) {
  //   return new Promise(async (resolve: any) => {
  //     let apiURL: string = `${
  //       this.config[getENV()].raceAPI
  //     }/getraceresults?ExternalEventId=${event_id}&ExternalVenueId=${venue_id}&${races
  //       .map((race: any) => {
  //         return `raceIds%5B%5D=${race['ExternalRaceId']}`;
  //       })
  //       .join('&')}`;

  //     let result: any = await this.apiService.getDataPromis(apiURL, {}, {});

  //     if (result && typeof result == 'object') {
  //       races.forEach((race: any) => {
  //         if (result[race['ExternalRaceId']] != undefined) {
  //           race['raceStatus'] = result[race['ExternalRaceId']];
  //         } else {
  //           race['raceStatus'] = race['RaceState'].toUpperCase() || 'PLANNED';
  //         }
  //         race['status'] = {
  //           PLANNED: 0,
  //           DNS: 0,
  //           DNT: 0,
  //           DNF: 0,
  //           DSQ: 0,
  //           OFFICIAL: 0,
  //           FINISHED: 0,
  //           PUBLISHED: 0,
  //           ABANDONED: 0,
  //         };
  //         if (race['raceStatus'].toUpperCase() == 'ABANDONED') {
  //           race['status']['ABANDONED'] = race['AnimalDetail'].length;
  //         } else {
  //           race['AnimalDetail'].forEach((animal: any) => {
  //             let status = 'PLANNED';
  //             if (animal['RaceState'].toUpperCase() == 'PLANNED') {
  //               if (race['raceStatus'].toUpperCase() == 'PLANNED') {
  //                 status = 'PLANNED';
  //               } else if (race['raceStatus'].toUpperCase() == 'FINISHED') {
  //                 status = 'FINISHED';
  //               }
  //             } else {
  //               status = animal['RaceState'].toUpperCase();
  //             }
  //             race['status'][status] += 1;
  //           });
  //         }
  //       });
  //     }
  //     this.races = [...this.races, ...races];
  //     resolve(true);
  //   });
  // }

  async getOperators(): Promise<boolean> {
    return new Promise(async (resolve: any) => {
      let apiURL: string = `${
        this.config[getENV()].raceAPI
      }/flask-operations?action=get_assigned_pcs`;
      try {
        let result: any = await this.apiService.getDataPromis(apiURL, {}, {});
        if (result.status === '1') {
          result.pcs.forEach((pc: any) => {
            this.races.forEach((event) => {
              if (event.ExternalEventId === pc.ExternalEventID) {
                event.Operator = pc.AssignedOperatorFullName;
              }
            });
          });
        }

        resolve(true);
      } catch (error) {
        console.error('Error fetching operators', error);
        resolve(false);
      }
    });
  }
  ngOnDestroy(): void {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
    if (this.useMqqt) {
      this.subscriptions.forEach((data: any) => {
        this.mqttService.unsubscribe(data.data);
      });
    }
  }
  calculateMinutesLeft(raceDate, raceTimeStr) {
    let raceHour = parseInt(raceTimeStr.substring(0, 2));
    let raceMinute = parseInt(raceTimeStr.substring(3, 5));
    // Combine race date and time
    let raceDateTime = moment(raceDate, 'DD MMMM YYYY').set({
      hour: raceHour,
      minute: raceMinute,
      second: 0,
      millisecond: 0,
    });

    // Get the current browser time
    let currentTime = moment();

    // Calculate the difference in minutes
    let minutesLeft = raceDateTime.diff(currentTime, 'minutes');

    return minutesLeft;
  }
  connect(): void {
    this.mqttService.subscribe(this.mqtt_heartbeat_topic);
    this.mqttService.subscribe(this.advance_live_data_topic);
    this.mqttService.subscribe(this.runner_status_topic);
    this.mqttService.subscribe(this.barrier_status_topic);
    this.subscriptions.push({ data: this.mqtt_heartbeat_topic });
    this.subscriptions.push({ data: this.advance_live_data_topic });
    this.subscriptions.push({ data: this.runner_status_topic });
    this.subscriptions.push({ data: this.barrier_status_topic });

    this.mqttService.client.on('message', (topic: string, payload: Buffer) => {
      const message = JSON.parse(payload.toString());
      if (topic.includes('AdvanceLiveData')) {
        let live_data = this.mqttService.parseLiveDataMessage(topic);
        this.races.forEach((event) => {
          if (event.ExternalRaceId === live_data) {
            event.AdvanceLiveData = 'green-circle';
          }
        });
      } else if (topic.includes('RunnerStatus')) {
        this.races.forEach((event) => {
          if (event.ExternalRaceId === message.ExternalRaceId) {
            event.RunnerStatus = 'green-circle';
          }
        });
      } else if (topic.includes('BarrierLoading')) {
        this.races.forEach((event) => {
          if (event.ExternalRaceId === message.ExternalRaceId) {
            event.BarrierLoading = 'green-circle';
          }
        });
      } else {
        let heartbeat = this.mqttService.parseHeartbeatMessage(message);
        if (heartbeat !== null) {
          this.races.forEach((event) => {
            if (event.ExternalEventId === heartbeat.event_id) {
              event.MqttState.pc_number = heartbeat.pc_number;

              // Assign state based on race_id match
              if (
                heartbeat.race_id &&
                heartbeat.race_id === event.ExternalRaceId
              ) {
                event.MqttState.state = heartbeat.state;
              }
            }
          });
        }
      }
    });
  }
}
