<div class="screen">
  <div class="screen-container">
    <div class="screen-header">Generating Rail Table</div>
    <div class="screen-body">
      <p>Please Assign Tracks First!!</p>
      <div class="flex-start">
        <div class="flex-center">
          <input
            type="radio"
            id="entire-cource"
            name="option"
            [(ngModel)]="selectedOption"
            value="entireCourse"
            (change)="updateSelection()"
          />
          <label for="entire-cource">Entire Course</label>
        </div>
        <div class="flex-center">
          <input
            type="radio"
            id="split-cource"
            name="option"
            [(ngModel)]="selectedOption"
            value="split"
            (change)="updateSelection()"
          />
          <label for="split-cource">Split</label>
        </div>
      </div>
      <br />
      <div *ngIf="selectedOption === 'entireCourse'">
        <label>Enter entire course details:</label>
        <br />
        <div>
          <label>Rail</label>
          <div class="flex-start">
            <input
              type="number"
              min="0"
              class="form-control"
              style="width: 100px"
              [(ngModel)]="entireCourseDetails"
              placeholder="Rail"
            /><strong>m Entire Course</strong>
          </div>
        </div>
      </div>

      <div *ngIf="selectedOption === 'split'">
        <label>Enter details for split:</label>
        <br />
        <div>
          <div class="flex-start width-auto pb-1">
            <div class="flex-start">
              <div>
                <label></label>
                <strong>+</strong>
              </div>
              <div>
                <label>Rail</label>
                <div class="flex-start">
                  <input
                    type="number"
                    class="form-control"
                    style="width: 100px"
                    [(ngModel)]="splitDetails1"
                    placeholder="Rail"
                  /><strong>m in</strong>
                </div>
              </div>
            </div>
            <div>
              <label>Split Start</label>
              <div class="flex-start">
                <input
                  type="number"
                  min="0"
                  class="form-control"
                  style="width: 200px"
                  [(ngModel)]="splitDetails2"
                  placeholder="Split Start"
                /><strong>m -</strong>
              </div>
            </div>
            <div>
              <label>Split End</label>
              <div class="flex-start">
                <input
                  type="number"
                  min="0"
                  class="form-control"
                  style="width: 200px"
                  [(ngModel)]="splitDetails3"
                  placeholder="Split End"
                /><strong>m,</strong>
              </div>
            </div>
          </div>
          <div class="pt-2 flex-start">
            <strong>+</strong>
            <div class="flex-start">
              <input
                type="number"
                min="0"
                class="form-control"
                style="width: 100px"
                [(ngModel)]="splitDetails4"
                placeholder="Rail"
              /><strong>Reminder</strong>
            </div>
          </div>
          <div class="pt-2 flex-start">
            <strong>+</strong>
            <input
              type="number"
              min="0"
              class="form-control"
              style="width: 100px"
              [(ngModel)]="splitDetails5"
              (keyup)="onSplitDetails5Change()"
              (change)="onSplitDetails5Change()"
              placeholder="Trans"
            /><strong>Transition</strong>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div *ngIf="errorMessage != ''">
        <div class="alert alert-danger flex-start" role="role">
          <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
          {{ errorMessage }}
        </div>
      </div>
      <div *ngIf="generating">
        <div *ngFor="let tracker of tableResult; let trackerIndex = index">
          <strong
            >Race {{ trackerIndex + 1 }} - TrackLength:
            {{ trackLengths[trackerIndex][0] }} - RaceLength:
            {{ data.racelengths[trackerIndex] }}</strong
          >
          <table class="table table-bordered table-hover">
            <thead>
              <tr>
                <th class="col-1">Rail Position</th>
                <th class="col-1">Start</th>
                <th class="col-1">End</th>
                <th class="col-1">Transition</th>
                <th class="col-1">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let track of tracker; let rowIndex = index">
                <td class="col-1">
                  <input
                    type="text"
                    name="railPosition{{ rowIndex }}"
                    [(ngModel)]="track.add"
                    class="form-control"
                  />
                </td>
                <td class="col-1">
                  <input
                    type="text"
                    name="start{{ rowIndex }}"
                    [(ngModel)]="track.start"
                    class="form-control"
                  />
                </td>
                <td class="col-1">
                  <input
                    type="text"
                    name="end{{ rowIndex }}"
                    [(ngModel)]="track.end"
                    class="form-control"
                  />
                </td>
                <td class="col-1">
                  <input
                    type="text"
                    name="transition{{ rowIndex }}"
                    [(ngModel)]="track.transition"
                    class="form-control"
                  />
                </td>
                <td class="col-1">
                  <!-- Delete Row Button -->
                  <button
                    type="button"
                    class="btn btn-danger-soft"
                    (click)="deleteRow(trackerIndex, rowIndex)"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <!-- Add Row Button -->
          <button
            type="button"
            class="btn btn-primary-soft"
            (click)="addRow(trackerIndex)"
          >
            Add Row
          </button>
          <br /><br />
        </div>
      </div>
    </div>
    <div class="screen-actions flex-end">
      <button
        type="button"
        class="btn btn-primary-soft"
        (click)="onGenerate()"
        [disabled]="isButtonDisabled"
      >
        Calculate
      </button>
      <button
        type="button"
        class="btn btn-success-soft"
        (click)="onSubmit()"
        [disabled]="!tableResult || tableResult.length === 0 || !generating"
      >
        Submit
      </button>
      <button type="button̥" class="btn btn-danger-soft" (click)="onNoClick()">
        Close
      </button>
    </div>
  </div>
</div>
