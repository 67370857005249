<div class="card" *ngIf="!selectedUser">
  <div class="card-header flex-between">
    <h5>Users</h5>
    <div class="flex-start">
      <span class="relative" *ngIf="ut == 'admin' && urlPrefix == 'client'">
        <label class="switch">
          <input
            autocomplete="off"
            type="checkbox"
            [(ngModel)]="groupByAdmins"
            (change)="showSuperAdmin()"
          />
          <span class="slider round"></span>
        </label>
      </span>
      <div class="no-wrap" *ngIf="ut == 'admin' && urlPrefix == 'client'">
        Group By Admin
      </div>
      <input
        autocomplete="off"
        type="text"
        class="form-control"
        placeholder="Search..."
        [(ngModel)]="filterText"
      />
      <select class="form-select" [(ngModel)]="statusFilter">
        <option value="ALL">All</option>
        <option value="ACTIVE">Active</option>
        <option value="DISABLED">Disabled</option>
      </select>
      <button class="btn btn-light" (click)="load()">Refresh</button>
      <button
        class="btn btn-success-soft"
        *ngIf="urlPrefix == 'client' && userList.length > 0"
        (click)="export()"
      >
        Export
      </button>
      <button
        class="btn btn-primary-soft"
        *ngIf="writeAccess || isUserAdmin"
        (click)="createUser()"
      >
        Create
      </button>
    </div>
  </div>
  <div class="card-body">
    <table class="table table-docs table-hover" id="users-table">
      <thead>
        <tr>
          <th>S.N.</th>
          <th
            *ngFor="
              let h of urlPrefix == 'client' ? userHeaders : adminUsersheader
            "
          >
            {{ h.name }}
          </th>
          <th>User Type</th>
          <th>Created By</th>
        </tr>
      </thead>

      <tbody *ngIf="filteredUserList().length > 0">
        <tr *ngFor="let i of filteredUserList(); let j = index">
          <td>{{ j + 1 }}</td>
          <td
            *ngFor="
              let h of urlPrefix == 'client' ? userHeaders : adminUsersheader
            "
          >
            <span
              *ngIf="h.click"
              class="nav-link"
              (click)="callFunction(h.click, i)"
            >
              {{ i[h.id] }}
            </span>
            <span *ngIf="!h.click">
              {{ i[h.id] }}
            </span>
          </td>

          <td>
            {{ userTypes[i["userType"]] || "-" }}
          </td>
          <td>
            {{ i["masterClientId"] || "-" }}
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="loading">
        <tr>
          <td
            class="text-center"
            [attr.colspan]="
              (urlPrefix == 'client'
                ? userHeaders.length
                : adminUsersheader.length) + 1
            "
          >
            Looking for Users...
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="!loading && filteredUserList().length == 0">
        <tr>
          <td
            class="text-center"
            [attr.colspan]="
              (urlPrefix == 'client'
                ? userHeaders.length
                : adminUsersheader.length) +
              1 +
              (urlPrefix == 'client' && ut == 'admin' ? 1 : 0)
            "
          >
            No Users Found
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<!-- <app-user-details *ngIf="selectedUser" [user]="selectedUser" (hideModal)="this.load()"></app-user-details> -->

<update-user
  *ngIf="writeAccess && updateUser"
  [action]="userAction"
  [venues]="venues"
  [userDetails]="updateUser"
  (hideModel)="hideUpdateUser($event)"
></update-user>

<app-user-details
  *ngIf="selectedUser"
  [user]="selectedUser"
  [venues]="venues"
  (hideModal)="this.load()"
></app-user-details>
