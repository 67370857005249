import { Component, AfterViewInit, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { MqttService } from '../../../../../../../services/mqtt.service';
import * as L from 'leaflet';
import { fromEvent } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

import { getENV } from 'src/app/_helpers/helpers';

interface RaceEntrant {
  ExternalSilkID: string;
  AnimalName: string;
  TrainerName: string;
  JockeyName: string;
  Latitude: number;
  Longitude: number;
  ExternalAnimalID: string;
  StartNumber: number;
}

interface RaceResponse {
  entrants: RaceEntrant[];
  code_type: string;
}

interface AdvanceLiveDataSet {
  ExternalAnimalId: string;
  Latitude: number;
  Longitude: number;
}

interface HeartBeatMessage {
  AdvanceLiveDataSets: AdvanceLiveDataSet[];
}

@Component({
  selector: 'app-race-runner-map',
  templateUrl: './race-runner-map.component.html',
  styleUrls: ['./race-runner-map.component.css']
})
export class RaceRunnerMapComponent implements OnInit, AfterViewInit {
  private map!: L.Map;
  private markers: L.Marker[] = [];
  advance_live_data_topic = '';
  venue_id = '';
  event_id = '';
  race_id = '';
  code_type = '';
  private entrantsMap: { [key: string]: RaceEntrant } = {};
  private initialMessageReceived = false;
  private lastMessageTime: number = 0;
  config: any;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private mqttService: MqttService
  ) {
    this.config = this.route.snapshot.data['config'];
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.race_id = params['race_id'];
      this.event_id = params['event_id'];
      this.venue_id = params['venue_id'];
      const raceDetailsUrl = `${this.config[getENV()].raceAPI}/race-entrant-details?ExternalRaceId=${this.race_id}&ExternalEventId=${this.event_id}`;
      this.advance_live_data_topic = `TRACKING/AUS/${this.venue_id}/${this.event_id}/${this.race_id}/AdvanceLiveData`;
      console.log(this.advance_live_data_topic);
      // Fetch race details

      this.http.get<RaceResponse>(raceDetailsUrl).subscribe(
        (data: RaceResponse) => {
          this.mapEntrants(data.entrants);
          this.addMarkers(data.entrants);
          this.code_type = data.code_type;
        },
        (error) => {
          console.error('Error fetching race details:', error);
        }
      );
    });
    this.connect();
  }

  ngAfterViewInit(): void {}

  private initMap(lat: number, long: number): void {
    this.map = L.map('map', {
      center: [lat, long],
      zoom: 17
    });

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(this.map);
  }

  private addMarkers(entrants: RaceEntrant[]): void {
    if (this.map) {
      for (const entrant of entrants) {
        const silkUrl = this.getSilkUrl(entrant);
        const icon = L.icon({
          iconUrl: silkUrl,
          iconSize: [16, 16], // Adjust the size as needed
          iconAnchor: [8, 16], // The point of the icon which will correspond to marker's location
          popupAnchor: [0, -16] // The point from which the popup should open relative to the iconAnchor
        });

        const marker = L.marker([entrant.Latitude, entrant.Longitude], { icon }).addTo(this.map);
        marker.bindPopup(`<b>${entrant.AnimalName}</b><br>Trainer: ${entrant.TrainerName}<br>Jockey: ${entrant.JockeyName}`);
        this.markers.push(marker);
      }
    }
  }

  private addBarrierMarker(barrierDataSet: AdvanceLiveDataSet): void {
    const barrierIcon = L.icon({
      iconUrl: './assets/img/marker-icon.png', // Update this with the actual icon URL for the barrier
      iconSize: [32, 32], // Adjust the size as needed
      iconAnchor: [16, 32], // The point of the icon which will correspond to marker's location
      popupAnchor: [0, -32] // The point from which the popup should open relative to the iconAnchor
    });

    const marker = L.marker([barrierDataSet.Latitude, barrierDataSet.Longitude], { icon: barrierIcon }).addTo(this.map);
    marker.bindPopup(`<b>Barrier</b>`);
    this.markers.push(marker);
  }

  private getSilkUrl(entrant: RaceEntrant): string {
    if (this.code_type === 'GALLOPS') {
      return `https://tsd-prd01-silks.s3.ap-southeast-2.amazonaws.com/Racing_Australia/${entrant.ExternalSilkID}.png`;
    } else {
      return `https://tsd-prd01-silks.s3.ap-southeast-2.amazonaws.com/Harness_Racing_Australia/${entrant.StartNumber}.png`;
    }
  }

  private mapEntrants(entrants: RaceEntrant[]): void {
    this.entrantsMap = {};
    for (const entrant of entrants) {
      this.entrantsMap[entrant.ExternalAnimalID] = entrant;
    }
  }

  private updateMarkers(advanceLiveDataSets: AdvanceLiveDataSet[]): void {
    if (this.map) {
      // Clear existing markers
      this.markers.forEach(marker => this.map.removeLayer(marker));
      this.markers = [];

      // Add new markers
      for (const dataSet of advanceLiveDataSets) {
        if (dataSet.ExternalAnimalId === 'Barrier') {
          this.addBarrierMarker(dataSet);
          continue;
        }

        const entrant = this.entrantsMap[dataSet.ExternalAnimalId];
        if (entrant) {
          const icon = L.icon({
            iconUrl: this.getSilkUrl(entrant),
            iconSize: [32, 32], // Adjust the size as needed
            iconAnchor: [16, 32], // The point of the icon which will correspond to marker's location
            popupAnchor: [0, -32] // The point from which the popup should open relative to the iconAnchor
          });

          const marker = L.marker([dataSet.Latitude, dataSet.Longitude], { icon }).addTo(this.map);
          marker.bindPopup(`<b>${entrant.AnimalName}</b><br>Trainer: ${entrant.TrainerName}<br>Jockey: ${entrant.JockeyName}`);
          this.markers.push(marker);
        } else {
          console.warn(`No entrant found for ExternalAnimalId: ${dataSet.ExternalAnimalId}`);
        }
      }
    }
  }

  connect(): void {
    this.mqttService.subscribe(this.advance_live_data_topic);
    fromEvent(this.mqttService.client, 'message').pipe(
      throttleTime(1000) // Throttle to once every second
    ).subscribe((event: any) => {
      const [topic, payload] = event;
      const message: HeartBeatMessage = JSON.parse(payload.toString());

      if (!this.initialMessageReceived) {
        this.initialMessageReceived = true;
        if (message.AdvanceLiveDataSets.length > 0) {
          const firstDataSet = message.AdvanceLiveDataSets.find(dataSet => dataSet.ExternalAnimalId === 'Barrier') || message.AdvanceLiveDataSets[0];
          this.initMap(firstDataSet.Latitude, firstDataSet.Longitude);
        }
      }

      this.updateMarkers(message.AdvanceLiveDataSets);
    });
  }
}
