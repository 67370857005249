<div class="popup-screen">
  <div class="popup-container">
    <div class="popup-header danger" *ngIf="action_type">Delete Data</div>
    <div class="popup-header"  *ngIf="!action_type">Copy Data</div>
    <div class="popup-body">
      <div class="alert alert-danger" role="alert" *ngIf="action_type">
        Submitting this form will result in the deletion of the entire Race Day record. This action cannot be undone.
      </div>
      <div class="dropdown-container">
        <ng-container *ngIf="!action_type">
          <select disabled class="form-select">
            <option value="production">Production</option>
          </select>
          <div class="arrow">→</div>
        </ng-container>
        <select class="form-select" [(ngModel)]="destination">
          <option value="" disabled>Select Destination</option>
          <option value="dev">Dev</option>
          <option value="staging">Staging</option>
        </select>
      </div>
      <div class="checkbox-container" *ngIf="!action_type">
        <label>Select file types to copy:</label>
        <div class="checkbox-list">
          <div class="checkbox-item flex-start">
            <input
              type="radio"
              id="tol"
              name="file_type"
              value="tol"
              [(ngModel)]="fileType"
            />
            <label for="tol">Tol Files</label>
          </div>
          <!-- <div class="checkbox-item flex-start">
            <input
              type="radio"
              id="xml"
              name="file_type"
              value="xml"
              [(ngModel)]="fileType"
            />
            <label for="xml">XML Files</label>
          </div> -->
        </div>
      </div>
    </div>
    <div class="button-container flex-end">
      <ng-container *ngIf="!loading; else showLoading">
        <button class="btn btn-light" (click)="hideModal.emit()">Cancel</button>
        <button class="btn btn-primary-soft" *ngIf="!action_type" [disabled]="!destination || !fileType" (click)="submit()">
          Copy Data
        </button>
        <button class="btn btn-danger-soft" *ngIf="action_type" [disabled]="!destination" (click)="submit()">
          Delete Data
        </button>
      </ng-container>
      <ng-template #showLoading>
        <div class="loading-container flex-start">
          <div class="spinner-border spinner-border-sm text-secondary mr-2"></div>
          <div class="loading-text" *ngIf="!action_type">Copying Data...</div>
          <div class="loading-text" *ngIf="action_type">Deleting Data...</div>
        </div>
      </ng-template>

    </div>
  </div>
</div>
