interface condition {
  state: string,
  condition: {
    key: string,
    equation: string,
    value: string
  }
}

export interface Task {
  id: string;
  label: string;
  value: string;
  loading: boolean;
  completed: boolean;
  state?: string;
  type?: string;
  comments?: string;
  failed?: boolean;
  error?: boolean;
  conditions?: condition[];
  preview?: boolean;
}

export const tasks: Task[] = [
  {
    id: 'update-pdf-to-tsd-bucket',
    label: 'Upload Race files to S3',
    value: 'race_check_hrv',
    loading: false,
    completed: false,
    state: 'HRV',
    type: 'Harness Racing',
  },
  {
    id: 'upload-all-files-to-tsd-s3',
    label: 'Upload Race files to S3',
    value: 'upload_files',
    loading: false,
    completed: false,
    state: 'ALL',
    type: 'Thoroughbred Racing',
  },
  {
    id: 'create-pdf',
    label: 'Generate PDF',
    value: 'generate_pdf',
    loading: false,
    completed: false,
    preview: false,
    state: 'ALL',
    conditions: [
      {
        state: 'HRQ',
        condition: {
          'key': 'RaceLength',
          'equation': 'not equals',
          'value': "947"
        }
      }
    ],
    type: 'Harness Racing',
  },
  {
    id: 'create-pdf',
    label: 'Generate PDF',
    value: 'generate_pdf_redcliffe',
    loading: false,
    completed: false,
    preview: false,
    state: 'HRQ',
    conditions: [
      {
        state: 'HRQ',
        condition: {
          'key': 'RaceLength',
          'equation': 'equals',
          'value': "947"
        }
      }
    ],
    type: 'Harness Racing',
  },
  {
    id: 'generate-pdf',
    label: 'Generate PDF',
    value: 'generate_pdf',
    loading: false,
    completed: false,
    preview: false,
    state: 'RSA',
    type: 'Thoroughbred Racing',
  },
  {
    id: 'generate-pdf',
    label: 'Generate PDF',
    value: 'generate_pdf',
    loading: false,
    completed: false,
    preview: false,
    state: 'ARC',
    type: 'Thoroughbred Racing',
  },
  {
    id: 'upload-files-to-champion-data',
    label: 'Publish to Champion Data',
    value: 'upload_cd',
    loading: false,
    completed: false,
    state: 'RVC',
    type: 'Thoroughbred Racing',
  },
  {
    id: 'upload-files-to-champion-data',
    label: 'Publish to Champion Data',
    value: 'upload_cd',
    loading: false,
    completed: false,
    state: 'RSA',
    type: 'Thoroughbred Racing',
  },
  {
    id: 'upload-pdf-to-hrv-s3-bucket',
    label: 'Publish to Customer',
    value: 'send_to_hrv',
    loading: false,
    completed: false,
    state: 'HRV',
    type: 'Harness Racing',
  },
  {
    id: 'upload-hrq-files-to-ftp-server',
    label: 'Publish to Customer',
    value: 'upload_files',
    loading: false,
    completed: false,
    state: 'HRQ',
    type: 'Harness Racing',
  },
  {
    id: 'upload-all-files-to-qr-ftr-server',
    label: 'Publish to Customer',
    value: 'upload_files',
    loading: false,
    completed: false,
    state: 'BRC',
    type: 'Thoroughbred Racing',
  },
  {
    id: 'send-data-to-qr-ftr-server',
    label: 'Publish to Customer',
    value: 'upload_files',
    loading: false,
    completed: false,
    state: 'QRC',
    type: 'Thoroughbred Racing',
  },
  {
    id: 'upload-files-to-atc-web-page',
    label: 'Publish to Customer',
    value: 'upload_to_web_page',
    loading: false,
    completed: false,
    state: 'ATC',
    type: 'Thoroughbred Racing',
  },
  {
    id: 'send-mail',
    label: 'Send Mail',
    value: 'send_tol_mail',
    loading: false,
    completed: false,
    state: 'ARC',
    type: 'Thoroughbred Racing',
  },
];
