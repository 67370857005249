import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortByRankDnsDnf',
  pure: false,
})
export class SortByRankDnsDnfPipe implements PipeTransform {
  transform(entrants: any[]): any[] {
    if (!entrants) return [];
    return entrants.sort((a: any, b: any) => {
      const aState = a['RaceState'].toUpperCase();
      const bState = b['RaceState'].toUpperCase();

      if (aState === 'DNS' && bState !== 'DNS') return 1;
      if (aState !== 'DNS' && bState === 'DNS') return -1;

      const aRank = a['Rank'] || 100;
      const bRank = b['Rank'] || 100;
      return aRank - bRank;
    });
  }
}
