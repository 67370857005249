<nav>
  <div class="nav nav-tabs" id="nav-tab" role="tablist">
    <button
      class="nav-link tab active"
      id="breeze-tab"
      data-bs-toggle="tab"
      data-bs-target="#breeze"
      type="button"
      role="tab"
      aria-controls="breeze"
      aria-selected="true"
    >
      Breeze
    </button>
  </div>
</nav>
<div class="tab-content" id="nav-tabContent">
  <div
    class="tab-pane show active"
    id="breeze"
    role="tabpanel"
    aria-labelledby="breeze-tab"
  >
    <div class="steps-container">
      <div class="step-container">
        <div class="step-header">
          <div>
            {{ step["title"] || "STEP HEADER" }}
          </div>
        </div>
        <div class="step-body">
          <ng-container *ngIf="currentStep == 1">
            <div>
              <!-- <div class="form-group">
                <label for="eventName">Event Name</label>
                <input
                  type="text"
                  [disabled]="meta.loading"
                  class="form-control"
                  [(ngModel)]="meta.eventName"
                />
              </div> -->
              <div class="form-group">
                <label for="eventName">Date of Event</label>
                <input
                  type="date"
                  [disabled]="meta.loading"
                  class="form-control"
                  [(ngModel)]="meta.eventDate"
                />
              </div>
              <div class="form-group">
                <label for="eventName">Venue</label>
                <select
                  class="form-select"
                  [(ngModel)]="meta.venue"
                  [disabled]="meta.loading"
                >
                  <option [ngValue]="null" disabled selected>
                    Select Venue
                  </option>
                  <option [ngValue]="venue" *ngFor="let venue of venues">
                    {{ venue.name }} ({{ venue.id }})
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label for="eventName">Start of First Race</label>
                <input
                  type="time"
                  [disabled]="meta.loading"
                  class="form-control"
                  [(ngModel)]="meta.firstRaceTime"
                />
              </div>
              <!-- <div class="form-group">
                <label>Kit</label>
                <select
                  class="form-select"
                  [(ngModel)]="meta.kit"
                  [disabled]="!meta.venue"
                >
                  <option value="" disabled>Select Kit</option>
                  <option value="{{ kit }}" *ngFor="let kit of kits">
                    {{ kit }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label for="eventName">Race Distance</label>
                <input
                  type="number"
                  [disabled]="meta.loading"
                  class="form-control"
                  [(ngModel)]="meta.raceDistance"
                />
              </div>
              <div class="form-group">
                <label for="eventName">Tracks</label>
                <form (submit)="addTrack()">
                  <div class="flex-start">
                    <input
                      name="trackName"
                      type="text"
                      [(ngModel)]="meta.track"
                      class="form-control"
                      placeholder="Track Name"
                    />
                    <button type="submit" class="btn btn-primary-soft">
                      Add
                    </button>
                  </div>
                </form>
                <div class="flex-start pt-2">
                  <span
                    class="flex-start small-card"
                    *ngFor="let track of meta.tracks; let i = index"
                  >
                    {{ track.name }}
                    <i class="fa-solid fa-xmark" (click)="removeTrack(i)"></i>
                  </span>
                </div>
              </div> -->
            </div>
          </ng-container>
          <ng-container *ngIf="currentStep == 0">
            <div *ngIf="meta.fileError" class="error flex-start">
              <i class="fa-solid fa-circle-exclamation"></i>
              <span>{{ meta.fileError }}</span>
            </div>
            <div *ngIf="meta.fileSuccess" class="success flex-start mb-0">
              <i class="fa-solid fa-check-circle"></i>
              <span>{{ meta.fileSuccess }}</span>
            </div>
            <div
              *ngIf="meta.uploadStatus == 'PROCESSING'"
              class="secondary flex-between mb-0"
            >
              <div class="flex-start">
                <i class="fa-solid fa-clock"></i>
                <span>Processing...</span>
              </div>
              <div>
                <span class="spinner-border spinner-border-sm ml-2"></span>
              </div>
            </div>
            <div
              class="upload-section"
              *ngIf="!meta.file; else showFile"
              id="uploadSection"
              [class.active]="dragging"
              (click)="uploadSection.click()"
              (dragover)="onDragOver($event)"
              (dragleave)="onDragLeave($event)"
              (drop)="onDrop($event)"
            >
              {{
                dragging
                  ? "Drop here"
                  : "Click here to upload or drag and drop the XLSX File"
              }}
              <input
                #uploadSection
                type="file"
                id="tolFileInput"
                [attr.accept]="'.xlsx'"
                style="display: none"
                (change)="onFileSelected($event)"
              />
            </div>
            <ng-template #showFile>
              <div class="flex-between p-1">
                <div class="flex-1 flex-start file">
                  <div class="pr-1">
                    <i class="fa fa-file-text" aria-hidden="true"></i>
                  </div>
                  <div class="flex-1">
                    <div>
                      <strong>{{ meta.file.name }}</strong>
                    </div>
                    <div class="mt-1">
                      <span
                        *ngIf="
                          !meta.uploadStatus ||
                            meta.uploadStatus == 'COMPLETED' ||
                            meta.uploadStatus == 'PROCESSING';
                          else showUploadStatus
                        "
                        class="flex-start"
                      >
                        {{ formatFileSize(meta.file.size) }}
                        <ng-container
                          *ngIf="
                            meta.uploadStatus == 'COMPLETED' ||
                            meta.uploadStatus == 'PROCESSING'
                          "
                        >
                          <span> | </span>
                          <span class="flex-start text-success fw-bold"
                            ><i class="fa fa-check-circle color-inherit"></i>
                            Uploaded</span
                          >
                        </ng-container>
                      </span>
                      <ng-template #showUploadStatus>
                        <div>
                          <span
                            >{{ formatFileSize(meta.uploaded || 0) }} /
                            {{ formatFileSize(meta.file.size) }} ({{
                              meta.percentage || 0
                            }}%)</span
                          >
                        </div>
                        <div class="progress-bar">
                          <div
                            class="progress"
                            [style.width.%]="meta.percentage || 0"
                          ></div>
                        </div>
                      </ng-template>
                    </div>
                  </div>
                </div>
                <div class="flex-start">
                  <ng-container
                    *ngIf="
                      !meta.uploadStatus || meta.uploadStatus == 'COMPLETED';
                      else showLoader
                    "
                  >
                    <button
                      class="flex-start btn btn-danger-soft"
                      (click)="cancelPDF()"
                      *ngIf="
                        meta.uploadStatus != 'COMPLETED';
                        else showCloseButton
                      "
                    >
                      <i class="fa fa-times color-inherit"></i>
                      Cancel
                    </button>
                    <ng-template #showCloseButton>
                      <button
                        class="flex-start btn btn-danger-soft"
                        (click)="cancelPDF()"
                      >
                        <i class="fa fa-times color-inherit"></i>
                        Close
                      </button>
                    </ng-template>
                  </ng-container>
                  <ng-template #showLoader>
                    <span class="spinner-border spinner-border-sm ml-2"></span>
                  </ng-template>
                </div>
              </div>
            </ng-template>
          </ng-container>
        </div>
        <div class="dropdown-divider"></div>
        <div class="step-footer">
          <div class="flex-between">
            <div>
              <span
                class="btn btn-light"
                [class.disabled]="meta.loading"
                *ngFor="let action of step?.actions?.left"
                (click)="handleAction(action.action)"
              >
                {{ action["label"] }}
              </span>
            </div>
            <div>
              <span
                class="btn btn-primary-soft flex-start"
                [class.disabled]="meta.loading"
                *ngFor="let action of step?.actions?.right"
                (click)="handleAction(action.action)"
              >
                {{ action["label"] }}
                <span
                  class="spinner-border spinner-border-sm ml-2"
                  *ngIf="meta.loading"
                ></span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
