import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment-timezone';
import { locationMappers, typeBasedLocations } from '../race-reports/locations';
import {
  checkForVenuePermission,
  convertTimestamp,
  getENV,
  getRaceType,
} from 'src/app/_helpers/helpers';
import { APIService } from 'src/app/services/api.service';
import { NotifierService } from 'src/app/services/notifier.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { RacesObject } from '../../../../home/interfaces';

declare var $: any;

@Component({
  selector: 'app-on-course-ops-judges',
  templateUrl: './on-course-ops-judges.component.html',
  styleUrls: [
    './on-course-ops-judges.component.css',
    './../race-reports/race-reports.component.css',
  ],
})
export class OnCourseOpsJudgesComponent {
  date: string = null;
  race_states: string[] = [
    'Scheduled',
    'Arm',
    'Running',
    'PreFinished',
    'Finished',
    'Canceled',
  ];
  horse_race_states = [
    { name: 'PLANNED', value: 'PLANNED' },
    { name: 'DNS', value: 'DNS' },
    { name: 'DNT', value: 'DNT' },
    { name: 'DNF', value: 'DNF' },
    { name: 'DSQ', value: 'DSQ' },
    { name: 'OFFICIAL', value: 'OFFICIAL' },
    { name: 'FINISHED', value: 'FINISHED' },
    { name: 'PUBLISHED', value: 'PUBLISHED' },
    { name: 'ABANDONED', value: 'ABANDONED' },
  ];
  async ngAfterViewInit(): Promise<void> {
    this.date = this.date || moment().format('DD MMMM YYYY');
    $('.datetimepicker').val(this.date);
    $('.datetimepicker')
      .datepicker({
        autoclose: true,
        // minViewMode: 1,
        format: 'dd MM yyyy',
        orientation: 'bottom auto',
        endDate: moment().add(2, 'days').toDate(),
      })
      .on('changeDate', (selected: any) => {
        this.date = $('.datetimepicker').val();
        this.fetchEvents();
      });

    setTimeout(() => {
      this.fetchEvents();
    }, 100);
  }

  uploadFileObj: any = null;
  uploadFile(event: any, race: any, type: string) {
    event.stopPropagation();
    this.uploadFileObj = {
      type: type,
      race: race,
      raceCource: this.selected,
    };
  }

  querySubscription: Subscription;
  params: any = {};
  ngOnInit(): void {
    this.querySubscription = this.activeRoute.queryParams.subscribe(
      (params: any) => {
        if (params.event_id) {
          this.params['event_id'] = params.event_id;
        }
        if (params.race_id) {
          this.params['race_id'] = params.race_id;
        }
        if (params.date) {
          this.date = params.date;
        }
      }
    );
  }

  config: any;
  constructor(
    private apiService: APIService,
    private notifier: NotifierService,
    private activeRoute: ActivatedRoute,
    private router: Router
  ) {
    this.config = this.activeRoute.snapshot.data['config'];
  }

  selected: any = null;
  locations: any = [];
  races: any = [];
  async fetchEvents() {
    return new Promise(async (resolve: any) => {
      this.notifier.loading(true);
      this.selected = null;
      this.locations = [];
      this.races = [];
      const date = this.date
        ? moment(this.date, 'DD MMMM YYYY').format('YYYY-MM-DD')
        : moment().format('YYYY-MM-DD');
      let raceTypes: any = localStorage.getItem('raceTypes');
      if (raceTypes) {
        raceTypes = JSON.parse(raceTypes);
      }
      let apiURL: string = `${
        this.config[getENV()].raceAPI
      }/event-list?formatted_date=${date}`;

      if (raceTypes && raceTypes.length > 0) {
        apiURL += `&race_types=${raceTypes.join(',')}`;
      }

      let result: any = await this.apiService.getDataPromis(apiURL, {}, {});

      if (result.eventNames && result.eventNames.length > 0) {
        result.eventNames = result.eventNames.filter((event: any) => {
          return checkForVenuePermission(event['external_venue_id']);
        });
        let resultEventNames = result.eventNames;

        resultEventNames.forEach((event: any, index: number) => {
          event['operator'] = null;
          let raceType = getRaceType(event['external_venue_id'])['raceTypeStr'];

          if (
            raceType == 'Thoroughbred Racing' &&
            event['venue_name'] == 'Cranbourne'
          ) {
            event['venue_name'] = 'Cranbourne Turf';
          }

          const jurisdiction = (typeBasedLocations[raceType] as any).find(
            (j: any) => {
              let location: any = j.Locations.find((location: any) => {
                return (
                  location.Name.toLowerCase() ==
                  event['venue_name'].toLowerCase()
                );
              });
              if (location) {
                event['type'] = location.type;
              }
              return location;
            }
          );

          if (jurisdiction) {
            event['State'] = jurisdiction.State;
            event['JurisdictionCode'] = jurisdiction.JurisdictionCode;
            event['Jurisdiction'] = jurisdiction.Jurisdiction;
            event['RaceType'] = raceType;
            event['date'] = date;
          }

          event['name'] = event['venue_name'];
          event['id'] = event['external_event_id'];
          // this.loadEvent(event, index);
        });

        this.locations = resultEventNames;
        if (this.params.event_id) {
          const selectedEvent = this.locations.find(
            (event: any) => event.external_event_id === this.params.event_id
          );
          if (selectedEvent) {
            this.selectEvent(selectedEvent);
          } else {
            this.notifier.loading(false);
          }
        } else {
          this.notifier.loading(false);
        }
      } else {
        this.locations = [];
        this.notifier.loading(false);
      }
      resolve(true);
    });
  }

  stopPropagation(event: any) {
    event.stopPropagation();
  }

  selectedRace: any = {
    race: null,
    action: null,
  };
  confirmPopup(race: any, action: string = null) {
    if (!action) {
      action = race['statusSelected'];
    }
    if (action == 'ARM') {
      this.submitGrayHound(race, action);
      return;
    }
    this.selectedRace = {
      race: race,
      action: action,
    };
  }

  async closePopup(event: boolean) {
    if (event) {
      let race = this.races.find(
        (race: any) =>
          race['ExternalRaceId'] === this.selectedRace['race']['ExternalRaceId']
      );
      let action: string = this.selectedRace['action'];
      this.submitGrayHound(race, action);
    }
    this.selectedRace = {
      race: null,
      action: null,
    };
  }

  private async submitGrayHound(
    race: any,
    action: string = null
  ): Promise<void> {
    race['loading'] = true;
    let apiURL: string = `${this.config[getENV()].raceAPI}/flask-operations`;
    let payload: any = {
      action: 'greyhounds_server_trigger',
      operation: action,
      race_id: race['ExternalRaceId'],
    };

    let result: any = await this.apiService.postDataPromis(apiURL, payload, {});

    if (result.status == '1' || result.s == '1') {
      this.notifier.alert('Success', '', 'Success', 'success', 5000);
    } else {
      this.notifier.alert('Info', '', result.error_message, 'info', 5000);
    }
    race['loading'] = false;
  }

  async reviewPDF(event: any, race: any, type: string = '.pdf') {
    event.stopPropagation();
    let apiURL: string = `${this.config[getENV()].raceAPI}/flask-operations`;

    let payload: any = {
      action: 'review_files',
      location:
        locationMappers[this.selected.venue_name] || this.selected.venue_name,
      race_number: race['SquentialRaceOrderNumber'],
      race_date: this.selected['date'],
      type: this.selected['RaceType'],
      event_id: this.selected['external_event_id'],
      race_id: race['ExternalRaceId'],
      file_extension: type,
    };

    if (type == '.csv') {
      payload['file_folder'] = 'tol';
    }

    let result: any = await this.apiService.postDataPromis(apiURL, payload, {});

    if (result.status === '1' || result.s === '1') {
      let a: HTMLAnchorElement = document.createElement('a');
      a.target = '_blank';
      a.download = result.presigned_url;
      a.href = result.presigned_url;
      a.click();
    } else {
      this.notifier.alert('Info', '', result.error_message, 'info', 5000);
    }
  }

  async selectEvent(event: any) {
    this.notifier.loading(true);

    this.router.navigate(['/dash/on-course-ops/judges'], {
      queryParams: { event_id: event['external_event_id'], date: this.date },
    });
    this.selected = event;

    // this.loadEvent(event.external_event_id);

    const apiURL = `${
      this.config[getENV()].raceAPI
    }/getracedetails?ExternalVenueId=${
      event.external_venue_id
    }&ExternalEventId=${event.external_event_id}`;

    const result = await this.apiService.getDataPromis(apiURL, {}, {});

    if (result && Array.isArray(result) && result.length > 0) {
      result.sort(
        (a, b) => a.SquentialRaceOrderNumber - b.SquentialRaceOrderNumber
      );

      result.forEach((race: any) => {
        race['statusSelected'] = '';
        race['RaceState'] = 'Not Imported';
        race['disabled'] = true;
        race['eventName'] = event.name;
        race['raceStatus'] = race['RaceState']?.toUpperCase() || 'PLANNED';
        race['RaceTimeStr'] = race['RaceTime'];
        race['RaceTime'] = convertTimestamp(
          race['RaceTime'],
          event['venue_state']
        );

        race['queryParamsVenue'] = {
          event_id: race['ExternalEventId'],
          venue_id: race['ExternalVenueId'],
          date: race['eventDate'],
          event_name: race['eventName'],
        };

        race['queryParamsRace'] = {
          race_id: race['ExternalRaceId'],
        };

        race[
          'name'
        ] = `#${race['SquentialRaceOrderNumber']} ${race['RaceName']}`;
        race['id'] = race['SquentialRaceOrderNumber'];
      });

      this.races = result;

      await this.fetchRaceStatuses();

      this.notifier.loading(false);
    }
  }

  async fetchRaceStatuses() {
    const apiURL = 'https://50b3p4zwm3.execute-api.ap-southeast-2.amazonaws.com/tsd-prod-api/greyhound-monitor?action=races';
    try {
      const response = await this.apiService.getDataPromis(apiURL, {}, {});
      if (response.status == '1' || response.s == '1') {
        const races = response.races || [];
        this.races.forEach((race: any) => {
          let raceStatus = races.find((r: any) => r.raceid == race.ExternalRaceId);
          if (raceStatus) {
            race.RaceState = raceStatus.status;
            race['disabled'] = false;
          }
        });
      }
    } catch (error) {
      console.error('Error fetching race statuses:', error);
    }
  }

  closeUploadLIF() {
    if(this.uploadFileObj.uploadStatus == 'COMPLETED') {
      this.uploadFileObj.race.RaceState = 'OFFICIAL';
    }
    this.uploadFileObj = null;
  }

  async loadEntrants(race: any) {
    if (race.expanded) {
      race.expanded = false;
      return;
    }
    race.expanded = true;
    if (race.entrants && race.entrants.length > 0) {
      return;
    }
    race.loading = true;
    let apiURL: string = `${
      this.config[getENV()].raceAPI
    }/race-entrant-details?ExternalRaceId=${
      race.ExternalRaceId
    }&ExternalEventId=${race.ExternalEventId}`;

    let result: any = await this.apiService.getDataPromis(apiURL, {}, {});

    if (typeof result == 'string') {
      race.error = result;
      race.entrants = [];
      return;
    }
    race.entrants = result.entrants || [];
    race.entrants.forEach((horse: any) => {
      horse['RaceState'] = horse['RaceState']
        ? horse['RaceState'].toUpperCase()
        : 'PLANNED';
      horse['RaceStateNew'] = horse['RaceState'];
      horse['imagePath'] = (() => {
        if (result.code_type === 'HARNESS') {
          return `https://tsd-prd01-silks.s3.ap-southeast-2.amazonaws.com/Harness_Racing_Australia/${horse.StartNumber}.png`;
        } else if (this.selected.external_venue_id.includes('STC')) {
          return `https://tsd-prd01-silks.s3.ap-southeast-2.amazonaws.com/singapore/${horse.ExternalSilkID}.png`;
        } else if (this.selected.external_venue_id.startsWith('GR')) {
          return `https://tsd-prd01-silks.s3.ap-southeast-2.amazonaws.com/Greyhounds/${horse.StartNumber}.png`;
        } else {
          return `https://tsd-prd01-silks.s3.ap-southeast-2.amazonaws.com/Racing_Australia/${horse.ExternalSilkID}.png`;
        }
      })();
    });
    race.loading = false;
  }

  async saveHorseStatus(race: any) {
    return new Promise(async (resolve: any, reject: any) => {
      let eventData: any = {
        external_race_id: race.ExternalRaceId,
        track_id: race['TrackID'],
        track_name: race['Track'],
        race_state: race['RaceState'].toUpperCase(),
        update_data: {},
      };

      // Track used tag IDs to check for uniqueness
      const usedTagIds: Set<any> = new Set();
      let isValidationFailed = false;
      race.entrants.forEach((race_entrant: any) => {
        race_entrant.RaceState = race_entrant.RaceStateNew;
        let tag_sn = race_entrant.DeviceNumber || null;
        let trainer_name = race_entrant.ExternalTrainerID || null;
        let dns_id = race_entrant?.RaceState?.toUpperCase() == 'DNS' || false;
        let jockey_name = race_entrant?.ExternalJockeyID || null;
        let tag_id = race_entrant?.DeviceID || null;
        eventData['update_data'][race_entrant.ExternalAnimalID] = {
          tag: tag_id ? Number(tag_id) : null,
          tag_sn: tag_sn,
          trainer: trainer_name,
          jockey: jockey_name,
          is_dns: dns_id,
          barrier: race_entrant.BarrierNumber || '',
          state: race_entrant?.RaceState,
        };
      });
      const apiUrl = `${this.config[getENV()].raceAPI}/update-race-details`;
      const apiKey = 'U7RoenAJeFp21d07UQMY7Y1TAitCuR76nuXQJ8pg';
      const headers = { 'x-api-key': apiKey };
      this.notifier.loading(true);
      let result: any = await this.apiService.postDataPromis(
        apiUrl,
        eventData,
        headers
      );

      this.notifier.loading(false);
      resolve(true);
    });
  }

  ngOnDestroy(): void {
    this.querySubscription.unsubscribe();
  }
}
