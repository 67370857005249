<div class="card">
  <div class="card-header flex-between">
    <div class="flex-start">
      <h5 class="mb-0">Judges</h5>
    </div>
    <input type="text" class="datetimepicker" />
  </div>
  <div class="card-body">
    <div>
      <div class="form-group mt-2">
        <label>Select your Event</label>
        <post-race-location-picker
          [locations]="locations"
          [selected]="selected"
          (selectedLocation)="selectEvent($event)"
          type="event"
        ></post-race-location-picker>
        <div *ngIf="locations.length == 0">
          No Events Found on <b>{{ date }}</b>
        </div>
      </div>

      <div class="form-group mt-2">
        <div class="flex-between">
          <label class="mb-0">Races</label>
        </div>
        <div
          class="race form-group"
          [class.pending]="race.RaceState != 'PUBLISHED'"
          *ngFor="let race of races"
        >
          <div class="card-view race" (click)="loadEntrants(race)">
            <div class="flex-start">
              <div class="flex-1">
                <div class="flex-between">
                  <div class="flex-start flex-1">
                    <div class="fw-bold fs-5">
                      #{{ race["SquentialRaceOrderNumber"] }} {{ race.RaceName }}
                    </div>
                    <div class="flex-end flex-1" *ngIf="selected.RaceType == 'Greyhound Racing'" (click)="stopPropagation($event)">
                      <ng-container *ngIf="!race.loading; else showSpinner">
                        <button
                          class="btn flex-start"
                          [ngClass]="{
                            'btn-primary-soft': race.RaceState === 'Scheduled' || race.RaceState === 'Not Imported',
                            'btn-success-soft': race.RaceState === 'Arm',
                            'btn-light': race.RaceState === 'Running' || race.RaceState === 'PreFinished' || race.RaceState === 'Finished' || race.RaceState === 'Cancelled'
                          }"
                          [disabled]="race.RaceState === 'Running' || race.RaceState === 'PreFinished' || race.RaceState === 'Finished' || race.RaceState === 'Cancelled' || race['disabled'] ? true : null"
                          (click)="confirmPopup(race, 'ARM')">
                            <i class="fa fa-check-circle color-inherit" *ngIf="race.RaceState !== 'Scheduled' && race.RaceState !== 'Not Imported'"></i>
                          ARM
                        </button>
                        <button class="btn btn-primary-soft" (click)="confirmPopup(race, 'RESET')">RESET</button>
                        <select class="form-select max-width-200px" [(ngModel)]="race.statusSelected">
                          <option value="" selected disabled>Status</option>
                          <option [value]="status" *ngFor="let status of race_states">{{ status }}</option>
                        </select>
                        <button class="btn btn-primary-soft" *ngIf="race.statusSelected" (click)="confirmPopup(race)">Submit</button>
                      </ng-container>
                      <ng-template #showSpinner>
                        <span class="spinner-border spinner-border-sm ml-2"></span>
                      </ng-template>
                    </div>
                  </div>
                  <div class="flex-start">
                    <div class="flex-start">
                      <button
                        class="btn flex-start"
                        [ngClass]="{
                          'btn-success-soft': race.RaceState == 'OFFICIAL' || race.RaceState == 'PUBLISHED',
                          'btn-primary-soft': race.RaceState != 'OFFICIAL' && race.RaceState != 'PUBLISHED'
                        }"
                        (click)="uploadFile($event, race, 'normal_lip')"
                      >
                        <i
                          *ngIf="race.RaceState == 'OFFICIAL' || race.RaceState == 'PUBLISHED'"
                          class="fa fa-check-circle color-inherit"
                          aria-hidden="true"
                        ></i>
                        Upload LIF
                      </button>
                    </div>
                    <span
                      class="status-text-container alert inline-flex-start"
                      [ngClass]="{
                        'alert-success': race.RaceState == 'PUBLISHED',
                        'alert-dark': race.RaceState != 'PUBLISHED'
                      }"
                    >
                      <i
                        class="fa fa-circle color-inherit"
                        aria-hidden="true"
                      ></i>
                      <span class="color-inherit">
                        <span *ngIf="race.RaceState == 'PUBLISHED'"
                          >PUBLISHED</span
                        >
                        <span *ngIf="race.RaceState != 'PUBLISHED'"
                          >NOT YET PUBLISHED</span
                        >
                      </span>
                    </span>
                    <i class="fa-solid fa-angle-up" *ngIf="race.expanded"></i>
                    <i
                      class="fa-solid fa-angle-down"
                      *ngIf="!race.expanded"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="race.expanded">
            <div class="flex-center p-2" *ngIf="race.loading">
              <span class="spinner-border spinner-border-sm ml-2"></span>
            </div>
            <div *ngIf="!race.loading">
              <table class="table table-docs">
                <thead>
                  <tr>
                    <th>SCN</th>
                    <th>Barrier/Box</th>
                    <th>Image</th>
                    <th>Animal Name</th>
                    <th>State</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let entrant of race.entrants">
                    <td>{{ entrant.StartNumber }}</td>
                    <td>{{ entrant.BarrierNumber }}</td>
                    <td>
                      <img
                        [src]="entrant.imagePath"
                        alt="Image"
                        style="height: 40px"
                      />
                    </td>
                    <td>
                      {{ entrant.AnimalName }}
                    </td>
                    <td>
                      <div class="flex-center ml-4">
                        <select [(ngModel)]="entrant.RaceStateNew">
                          <option
                            [value]="status.value"
                            *ngFor="let status of horse_race_states"
                          >
                            {{ status.name }}
                          </option>
                        </select>
                        <button
                          [ngStyle]="{
                            visibility:
                              entrant.RaceState != entrant.RaceStateNew
                                ? 'visible'
                                : 'hidden'
                          }"
                          (click)="saveHorseStatus(race)"
                          class="btn btn-danger-soft"
                        >
                          Submit
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<upload-file-lip *ngIf="uploadFileObj" [config]="config" [meta]="uploadFileObj">
  <button
    ngProjectAs="closePopup"
    class="btn btn-light"
    [disabled]="
      uploadFileObj.uploadStatus && uploadFileObj.uploadStatus != 'COMPLETED'
        ? true
        : null
    "
    (click)="closeUploadLIF()"
  >
    Close
  </button>
</upload-file-lip>
<eod-confirm-box
  *ngIf="selectedRace['race']"
  title="Confirm Submission"
  message=""
  (hideModel)="closePopup($event)"
  [buttons]="['confirm', 'close']"
>
  <div ngProjectAs="main-body">
    <p>Click 'Confirm' to proceed with the submission.</p>
  </div>
</eod-confirm-box>
