<style>
  :host {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
      "Segoe UI Symbol";
    font-size: 14px;
    color: #333;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 8px 0;
  }

  p {
    margin: 0;
  }

  .spacer {
    flex: 1;
  }

  .toolbar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 60px;
    display: flex;
    align-items: center;
    background-color: #1976d2;
    color: white;
    font-weight: 600;
  }

  .toolbar img {
    margin: 0 16px;
  }

  .toolbar #twitter-logo {
    height: 40px;
    margin: 0 8px;
  }

  .toolbar #youtube-logo {
    height: 40px;
    margin: 0 16px;
  }

  .toolbar #twitter-logo:hover,
  .toolbar #youtube-logo:hover {
    opacity: 0.8;
  }

  .content {
    display: flex;
    margin: 82px auto 32px;
    padding: 0 16px;
    max-width: 960px;
    flex-direction: column;
    align-items: center;
  }

  svg.material-icons {
    height: 24px;
    width: auto;
  }

  svg.material-icons:not(:last-child) {
    margin-right: 8px;
  }

  .card svg.material-icons path {
    fill: #888;
  }

  .card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 16px;
  }

  .card {
    all: unset;
    border-radius: 4px;
    border: 1px solid #eee;
    background-color: #fafafa;
    height: 40px;
    width: 200px;
    margin: 0 8px 16px;
    padding: 16px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    line-height: 24px;
  }

  .card-container .card:not(:last-child) {
    margin-right: 0;
  }

  .card.card-small {
    height: 16px;
    width: 168px;
  }

  .card-container .card:not(.highlight-card) {
    cursor: pointer;
  }

  .card-container .card:not(.highlight-card):hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 17px rgba(0, 0, 0, 0.35);
  }

  .card-container .card:not(.highlight-card):hover .material-icons path {
    fill: rgb(105, 103, 103);
  }

  .card.highlight-card {
    background-color: #1976d2;
    color: white;
    font-weight: 600;
    border: none;
    width: auto;
    min-width: 30%;
    position: relative;
  }

  .card.card.highlight-card span {
    margin-left: 60px;
  }

  svg#rocket {
    width: 80px;
    position: absolute;
    left: -10px;
    top: -24px;
  }

  svg#rocket-smoke {
    height: calc(100vh - 95px);
    position: absolute;
    top: 10px;
    right: 180px;
    z-index: -10;
  }

  a,
  a:visited,
  a:hover {
    color: #1976d2;
    text-decoration: none;
  }

  a:hover {
    color: #125699;
  }

  .terminal {
    position: relative;
    width: 80%;
    max-width: 600px;
    border-radius: 6px;
    padding-top: 45px;
    margin-top: 8px;
    overflow: hidden;
    background-color: rgb(15, 15, 16);
  }

  .terminal::before {
    content: "\2022 \2022 \2022";
    position: absolute;
    top: 0;
    left: 0;
    height: 4px;
    background: rgb(58, 58, 58);
    color: #c2c3c4;
    width: 100%;
    font-size: 2rem;
    line-height: 0;
    padding: 14px 0;
    text-indent: 4px;
  }

  .terminal pre {
    font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
    color: white;
    padding: 0 1rem 1rem;
    margin: 0;
  }

  .circle-link {
    height: 40px;
    width: 40px;
    border-radius: 40px;
    margin: 8px;
    background-color: white;
    border: 1px solid #eeeeee;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: 1s ease-out;
  }

  .circle-link:hover {
    transform: translateY(-0.25rem);
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
  }

  footer {
    margin-top: 8px;
    display: flex;
    align-items: center;
    line-height: 20px;
  }

  footer a {
    display: flex;
    align-items: center;
  }

  .github-star-badge {
    color: #24292e;
    display: flex;
    align-items: center;
    font-size: 12px;
    padding: 3px 10px;
    border: 1px solid rgba(27, 31, 35, 0.2);
    border-radius: 3px;
    background-image: linear-gradient(-180deg, #fafbfc, #eff3f6 90%);
    margin-left: 4px;
    font-weight: 600;
  }

  .github-star-badge:hover {
    background-image: linear-gradient(-180deg, #f0f3f6, #e6ebf1 90%);
    border-color: rgba(27, 31, 35, 0.35);
    background-position: -0.5em;
  }

  .github-star-badge .material-icons {
    height: 16px;
    width: 16px;
    margin-right: 4px;
  }

  svg#clouds {
    position: fixed;
    bottom: -160px;
    left: -230px;
    z-index: -10;
    width: 1920px;
  }

  /* Responsive Styles */
  @media screen and (max-width: 767px) {
    .card-container > *:not(.circle-link),
    .terminal {
      width: 100%;
    }

    .card:not(.highlight-card) {
      height: 16px;
      margin: 8px 0;
    }

    .card.highlight-card span {
      margin-left: 72px;
    }

    svg#rocket-smoke {
      right: 120px;
      transform: rotate(-5deg);
    }
  }

  @media screen and (max-width: 575px) {
    svg#rocket-smoke {
      display: none;
      visibility: hidden;
    }
  }
</style>

<div class="screen">
  <div class="screen-contaienr mt-2" role="main">
    <div class="screen-header">
      <div class="header-big">
        {{ type == "login" || type == "manual_login" ? "Prepare System" : "Log Out" }}
      </div>
    </div>
    <ng-container *ngIf="!prepare">
      <div class="screen-body">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
          <div class="grid-4">
            <div class="form-group">
              <label>Location</label>
              <div class="camelcase value-text" *ngIf="location">
                {{ location || "-" }}
              </div>
              <mat-form-field appearance="fill" *ngIf="!location">
                <mat-label>Choose Location</mat-label>
                <mat-select
                  [ngClass]="{
                    'is-invalid': submitted && f['location']['errors']
                  }"
                  formControlName="location"
                  name="location"
                  (selectionChange)="changeLocation($event)"
                >
                  <mat-option *ngFor="let l of locations" [value]="l['value']">
                    {{ l["value"] }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <div
                *ngIf="submitted && f['location']['errors']"
                class="invalid-feedback"
              >
                <div *ngIf="f['location']['errors']['required']">
                  Location is required
                </div>
              </div>
            </div>

            <div class="form-group">
              <label>Operator</label>
              <div class="value-text" *ngIf="ut != 'admin'; else list_operators">
                {{ type == "login" || type == "manual_login" ? un : operator }}
              </div>
              <ng-template #list_operators>
                <mat-form-field appearance="fill">
                  <mat-label>Choose the operator</mat-label>
                  <mat-select
                    [ngClass]="{
                      'is-invalid': submitted && f['operator']['errors']
                    }"
                    formControlName="operator"
                    name="operator"
                    (selectionChange)="onOperatorChange($event)"
                  >
                    <mat-option *ngFor="let user of userList" [value]="user.username">
                      {{ user.username }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </ng-template>
              <div
                *ngIf="submitted && f['operator']['errors']"
                class="invalid-feedback"
              >
                <div *ngIf="f['operator']['errors']['required']">
                  Your name is required
                </div>
              </div>
            </div>

            <div class="form-group">
              <label>Kit</label>
              <mat-form-field appearance="fill">
                <mat-label>Choose Kit</mat-label>
                <mat-select
                  [ngClass]="{ 'is-invalid': submitted && f['kit'].errors }"
                  formControlName="kit"
                  (selectionChange)="onKitSelection($event)"
                  name="kit"
                >
                  <mat-option *ngFor="let k of kits" [value]="k.value">
                    {{ k.value }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <div
                *ngIf="submitted && f['kit'].errors"
                class="invalid-feedback"
              >
                <div *ngIf="f['kit']['errors']['required']">
                  Kit is required
                </div>
              </div>
            </div>
            <div class="form-group">
              <label>PC Number</label>
              <div class="camelcase value-text" *ngIf="pc">{{ pc || "-" }}</div>
              <mat-form-field appearance="fill" *ngIf="!pc">
                <mat-label>Choose PC</mat-label>
                <mat-select
                  [ngClass]="{ 'is-invalid': submitted && f['PC']['errors'] }"
                  formControlName="PC"
                  name="PC"
                  (selectionChange)="checkPCSelected($event)"
                >
                  <mat-option
                    *ngFor="let p of PCs"
                    [value]="p.value"
                    [disabled]="p.disabled ? true : null"
                  >
                    {{ p.value }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <div *ngIf="pcError" class="text-danger">
                {{ pcError.AssignedPCName }} is already being used by
                <a
                  routerLink="/dash/venue-details"
                  [queryParams]="{
                    event_id: pcError['ExternalEventID'],
                    venue_id: pcError['ExternalVenueID'],
                    date: pcError['MeetingDate'],
                    event_name: pcError['VenueName']
                  }"
                  class="nav-link"
                  target="_blank"
                  >{{ pcError["VenueName"] }}
                  <i
                    class="fa fa-external-link color-inherit"
                    aria-hidden="true"
                  ></i
                ></a>
              </div>
              <div
                *ngIf="submitted && f['PC']['errors']"
                class="invalid-feedback"
              >
                <div *ngIf="f['PC']['errors']['required']">
                  PC number is required
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="form-group">
            <label style="font-size: larger; color: #1976d2">Password</label>
            <input
              type="password"
              formControlName="password"
              class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f['password']['errors'] }"
            />
            <div
              *ngIf="submitted && f['password']['errors']"
              class="invalid-feedback"
            >
              <div *ngIf="f['password']['errors']['required']">
                Password is required
              </div>
            </div>
          </div> -->
        </form>
      </div>

      <div class="container-actions flex-between">
        <div>
          <span class="text-danger">
            *Please make sure that EQTraCe is not open
          </span>
        </div>
        <div class="flex-start">
          <ng-container *ngIf="loading">
            <img class="image_loader" src="./assets/img/loading_2.svg" alt="" />
          </ng-container>
          <ng-container *ngIf="!loading">
            <button
              class="btn btn-primary-soft"
              *ngIf="type == 'logout'"
              (click)="faultyPopup = true"
            >
              Yes there's faulty Tags
            </button>
            <button
              type="submit"
              class="btn btn-primary-soft"
              (click)="onSubmit()"
              *ngIf="!pcError"
            >
              {{ type == "login" ? "Prepare" : type == "manual_login" ? "Manual Login" : "Logout" }}
            </button>
            <button
              type="submit"
              class="btn btn-light"
              (click)="hideModel.emit(false)"
            >
              Cancel
            </button>
          </ng-container>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="prepare">
      <div class="screen-body">
        <div
          role="alert"
          class="alert alert-danger"
          *ngIf="prepare.failedCount > 0 && prepare.status == 'FAILED'"
        >
          Unable to
          {{ prepare.payload.type == "login" ? "prepare the race" : "logout" }}
          as {{ prepare.failedCount }} step(s) failed, please rerun or close
          this window and recheck
        </div>
        <div
          role="alert"
          class="alert alert-success"
          *ngIf="prepare.failedCount <= 0 && prepare.status == 'COMPLETED'"
        >
          {{ prepare.payload.type == "login" ? "Prepare" : "Logout" }} progress
          done! Click OK to dismiss the
          {{ prepare.payload.type == "login" ? "prepare" : "logout" }} dailog
        </div>
        <div
          role="alert"
          class="alert alert-primary"
          *ngIf="prepare.status == 'IN_PROGRESS'"
        >
          Do not refresh or close this page, it will cause serious problem!
        </div>
        <div
          role="alert"
          class="alert alert-danger"
          *ngIf="
            prepare.payload.type == 'logout' &&
            prepare.finish &&
            prepare.small_file == true
          "
        >
          The database file is too small! thus I didn't upload it. But you are
          still logged out.<br />
          If You know about it you are good to go, otherwise please tell the
          manager
        </div>
        <div
          [ngStyle]="{
            'grid-template-columns':
              'repeat(' +
              prepare.payload.steps[prepare.payload.type].length +
              ', 1fr)'
          }"
          class="progress-bar-container"
        >
          <div
            class="step-progress"
            [ngStyle]="{
              backgroundColor:
                step['status'] == 'COMPLETED'
                  ? 'green'
                  : step['status'] == 'FAILED'
                  ? 'red'
                  : ''
            }"
            *ngFor="let step of prepare.payload.steps[prepare.payload.type]"
          ></div>
        </div>
        <div class="steps-container">
          <div
            class="step-container flex-between"
            *ngFor="
              let step of prepare.payload.steps[prepare.payload.type];
              let i = index
            "
          >
            <span
              class="step_name"
              [ngStyle]="{
                color:
                  step['status'] == 'FAILED'
                    ? 'red'
                    : step['status'] == 'COMPLETED'
                    ? 'green'
                    : ''
              }"
              >{{ step["name"] }}</span
            >
            <span class="flex-start">
              <span class="pending-text" *ngIf="step['status'] == 'PENDING'">
                PENDING
              </span>
              <ng-container *ngIf="step['status'] == 'COMPLETED'">
                <span class="tooltip-container" *ngIf="step['comment']">
                  <i
                    class="fa fa-exclamation-triangle text-warning"
                    aria-hidden="true"
                  ></i>
                  <span class="tooltip-absolute">
                    <span>{{ step["comment"] }} </span>
                  </span>
                </span>
                <span class="completed-text"> COMPLETED </span>
              </ng-container>
              <ng-container *ngIf="step['status'] == 'FAILED'">
                <!-- <span class="relative" *ngIf="step['comment']">
                  <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                  <span class="tooltip">
                    {{ step["comment"] }}
                  </span>
                </span> -->
                <span class="failed-text tooltip-container">
                  FAILED
                  <span class="tooltip-absolute" *ngIf="step['comment']">
                    <span>{{ step["comment"] }} </span>
                  </span>
                </span>
              </ng-container>
              <img
                class="image_loader"
                src="./assets/img/loading_2.svg"
                alt=""
                *ngIf="
                  step['status'] == 'IN_PROGRESS' || step['status'] == 'STARTED'
                "
              />
            </span>
          </div>
        </div>
      </div>
      <div class="container-actions flex-between" *ngIf="prepare.finish">
        <div>
          <span class="text-danger" *ngIf="prepare.failedCount > 0">
            *Please make sure that EQTraCe is not open
          </span>
        </div>
        <div class="flex-start">
          <ng-container>
            <!-- <button
              type="submit"
              class="btn btn-primary-soft"
              *ngIf="prepare.failedCount <= 0"
              (click)="onSubmit(false)"
            >
              {{ prepare.payload.type == "login" ? "Prepare" : "Logout" }}
            </button> -->
            <button type="submit" class="btn btn-light" (click)="reLogin()">
              Re-{{ type }}
            </button>
            <button
              type="submit"
              class="btn btn-primary-soft"
              *ngIf="prepare.failedCount > 0"
              (click)="onSubmit(true)"
            >
              Rerun
            </button>
            <button
              type="submit"
              class="btn btn-light"
              (click)="hideModel.emit(false)"
              *ngIf="prepare.finish && prepare.failedCount > 0"
            >
              Cancel
            </button>
            <button
              type="submit"
              class="btn btn-light"
              (click)="close(true)"
              *ngIf="prepare.finish && prepare.failedCount <= 0"
            >
              Ok
            </button>
          </ng-container>
        </div>
      </div>
      <div class="container-actions" *ngIf="!prepare.finish">
        <div class="flex-end">
          <ng-container>
            <img class="image_loader" src="./assets/img/loading_2.svg" alt="" />
          </ng-container>
          <button
            type="submit"
            class="btn btn-primary-soft"
            (click)="hideModel.emit(false)"
          >
            Hide
          </button>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<eod-confirm-box
  *ngIf="faultyPopup"
  title="Faulty Tags"
  message="Please confirm you have filled out the incident report for all faulty tags today."
  [buttons]="['yes']"
  (hideModel)="hideFaultPopup($event)"
></eod-confirm-box>
