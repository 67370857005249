<div id="popup" class="popup">
  <div class="popup-container">
    <div class="popup-header">Edit Venue Emails - {{ venue["VenueName"] }}</div>
    <div class="popup-content">
      <div class="popup-body">
        <div class="form-group">
          <label>Start List Email</label>
          <input
            type="email"
            class="form-control"
            [ngClass]="{ 'error-text': errors['startlist_id'] }"
            placeholder="Email"
            [(ngModel)]="venue['StartlistEmailDL']"
          />
          <div
            class="flex-start"
            *ngIf="errors['startlist_id']"
            class="error-message"
          >
            <i
              aria-hidden="true"
              class="fa fa-exclamation-triangle color-inherit"
            ></i>
            {{ errors["startlist_id"] }}
          </div>
        </div>
        <div class="form-group">
          <label>Official Race Reports Email</label>
          <input
            type="email"
            class="form-control"
            [ngClass]="{ 'error-text': errors['racereports_id'] }"
            placeholder="Email"
            [(ngModel)]="venue['RaceReportsEmailDL']"
          />
          <div
            class="flex-start"
            *ngIf="errors['racereports_id']"
            class="error-message"
          >
            <i
              aria-hidden="true"
              class="fa fa-exclamation-triangle color-inherit"
            ></i>
            {{ errors["racereports_id"] }}
          </div>
        </div>
        <div class="form-group">
          <label>Unofficial Race Reports Email</label>
          <input
            type="email"
            class="form-control"
            [ngClass]="{ 'error-text': errors['unofficialracereports_id'] }"
            placeholder="Email"
            [(ngModel)]="venue['UnofficialEmailDL']"
          />
          <div
            class="flex-start"
            *ngIf="errors['unofficialracereports_id']"
            class="error-message"
          >
            <i
              aria-hidden="true"
              class="fa fa-exclamation-triangle color-inherit"
            ></i>
            {{ errors["unofficialracereports_id"] }}
          </div>
        </div>
        <div class="form-group">
          <label>EOD Email</label>
          <input
            type="email"
            class="form-control"
            [ngClass]="{ 'error-text': errors['eod_id'] }"
            placeholder="Email"
            [(ngModel)]="venue['EODEmailDL']"
          />
          <div
            class="flex-start"
            *ngIf="errors['eod_id']"
            class="error-message"
          >
            <i
              aria-hidden="true"
              class="fa fa-exclamation-triangle color-inherit"
            ></i>
            {{ errors["eod_id"] }}
          </div>
        </div>
      </div>
      <div class="popup-buttons flex-end">
        <button
          id="continueBtn"
          class="btn btn-primary-soft flex-start"
          (click)="saveVenue()"
          [attr.disabled]="loading ? true : null"
        >
          <div
            *ngIf="loading"
            class="spinner-border spinner-border-sm text-secondary mr-2"
          ></div>

          Update
        </button>
        <button
          id="logoutBtn"
          class="btn btn-light"
          (click)="hideModel.emit(false)"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>
