<div class="status-container" *ngIf="data">
  <table>
    <tbody>
      <tr>
        <td *ngIf="data.PLANNED">
          <div class="PLANNED_status">PLANNED: {{ data.PLANNED }}</div>
        </td>
        <td *ngIf="data.OFFICIAL">
          <div class="PLANNED_status">OFFICIAL: {{ data.OFFICIAL }}</div>
        </td>
        <td *ngIf="data.FINISHED">
          <div class="FINISHED_status">FINISHED: {{ data.FINISHED }}</div>
        </td>
        <td *ngIf="data.PUBLISHED">
          <div class="FINISHED_status">PUBLISHED: {{ data.PUBLISHED }}</div>
        </td>
        <td *ngIf="data.DNS">
          <div class="DNS_status">DNS: {{ data.DNS }}</div>
        </td>
        <td *ngIf="data.ABANDONED">
          <div class="ABANDONED_status">ABANDONED: {{ data.ABANDONED }}</div>
        </td>
        <td *ngIf="data.DNT">
          <div class="DNT_status">DNT: {{ data.DNT }}</div>
        </td>
        <td *ngIf="data.DNF">
          <div class="DNT_status">DNF: {{ data.DNF }}</div>
        </td>
        <td *ngIf="data.DSQ">
          <div class="DNT_status">DNQ: {{ data.DSQ }}</div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
