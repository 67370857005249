import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { getENV } from 'src/app/_helpers/helpers';
import { APIService } from 'src/app/services/api.service';
import { NotifierService } from 'src/app/services/notifier.service';
import { locationMappers } from '../post-race/harness-reports/components/race-reports/locations';

@Component({
  selector: 'data-transfer',
  templateUrl: './data-transfer.component.html',
  styleUrls: ['./data-transfer.component.css'],
})
export class DataTransferComponent implements OnInit {
  @Input('race') race: any;
  @Input('action_type') action_type: string = undefined;
  @Output() hideModal = new EventEmitter<void>();

  destination: string = '';
  fileType: string = '';
  loading: boolean = false;

  config: any;
  constructor(
    private activeRoute: ActivatedRoute,
    private apiService: APIService,
    private notifier: NotifierService
  ) {
    this.config = this.activeRoute.snapshot.data['config'];
  }

  ngOnInit(): void {}

  async submit() {
    this.loading = true;
    let payload = {
      action: 'copy_files_between_env',
      location: locationMappers[this.race['venue']] || this.race['venue'],
      race_number: this.race['race_number'],
      race_id: this.race['race_id'],
      race_date: this.race['event_date'],
      event_id: this.race['event_id'],
      jurisdiction: this.race['eventDetails']['JurisdictionCode'],
      type: this.race['race_type'] + ' Racing',
      venue_id: this.race['venue_id'],
      destination_environment: this.destination,
      file_type: this.fileType,
      action_type: this.action_type,
    };

    let apiURL: string = `${this.config[getENV()].raceAPI}/flask-operations`;

    let result: any = await this.apiService.postDataPromis(apiURL, payload, {});

    if (result.status == '1' || result.s == '1') {
      this.hideModal.emit();
      this.notifier.alert('Success', '', result.response, 'success', 5000);
    } else {
      this.notifier.alert(
        'Info',
        '',
        result.response || result.error_message || result.message,
        'info',
        5000
      );
    }

    this.loading = false;
  }
}
