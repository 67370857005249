<div class="card mt-3">
    <div class="card-body">
      <h1 class="upcoming-races">Racing Operations Center - Kit Management</h1>
  
      <!-- Dynamic Tabs for each CodeType -->
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation" *ngFor="let codeType of groupedKits | keyvalue; let i = index">
          <button class="nav-link" [class.active]="i === 0" id="{{codeType.key}}-tab" data-bs-toggle="tab" [attr.data-bs-target]="'#' + codeType.key" type="button" role="tab" [attr.aria-controls]="codeType.key" [attr.aria-selected]="i === 0 ? 'true' : 'false'">{{ codeType.key }}</button>
        </li>
      </ul>
  
      <!-- Tab Content -->
      <div class="tab-content" id="myTabContent">
        <div *ngFor="let codeType of groupedKits | keyvalue; let i = index" 
             class="tab-pane fade" 
             [ngClass]="{'show': i === 0, 'active': i === 0}" 
             [id]="codeType.key" 
             role="tabpanel" 
             [attr.aria-labelledby]="codeType.key + '-tab'">
          <div *ngFor="let venueGroup of codeType.value | keyvalue">
            <h3>{{ venueGroup.key }}</h3> <!-- VenueState -->
            <div class="row">
              <div class="col-2" *ngFor="let kit of venueGroup.value">
                <a routerLink="/dash/operator-kit-details/{{kit.KitID}}" class="btn btn-custom mb-3">{{ kit.KitID }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  