<div class="card">
  <div class="card-body">
    <ng-container *ngIf="event">
      <div class="flex-between">
        <a
          class="p-1 btn btn-light flex-start d-inline-flex"
          routerLink="/dash/venue-details"
          [queryParams]="{
          event_id: event['event_id'],
          venue_id: event['venue_id'],
          date: event['date'],
          event_name: event['event_name'],
        }"
        >
          <i class="fa fa-angle-left" aria-hidden="true"></i>
          <span>Back to Race Page</span>
        </a>
        <div class="race-paginator flex-start" *ngIf="races.length > 0">
          <a
            class="race-navigator flex-start"
            [class.disabled]="event['race_number'] == 1"
            (click)="prev()"
          >
            <i class="fa fa-angle-left"></i>
            Previous
          </a>
          <a
            class="race-navigator"
            routerLink="/dash/race-details"
            [queryParams]="{
              race_id: race.ExternalRaceId,
              event_id: race.ExternalEventId
            }"
            [class.active]="race.ExternalRaceId == raceId"
            *ngFor="let race of races"
          >
            <span class="race-text">{{ race.SquentialRaceOrderNumber }}</span>
            <div class="tooltip-container">
              <div class="tooltip-content">
                <div class="flex-start">
                  <div class="fw-bold white-space-none">
                    #{{ race["SquentialRaceOrderNumber"] }}
                    {{ race.RaceName }} | {{ race.RaceTime }}
                  </div>
                  <span
                    class="status-text-container alert inline-flex-start"
                    [ngClass]="{
                      'alert-success':
                        race.RaceState == 'FINISHED' ||
                        race.RaceState == 'PUBLISHED',
                      'alert-dark':
                        race.RaceState == 'PLANNED' ||
                        race.RaceState == 'OFFICIAL',
                      'alert-danger':
                        race.RaceState == 'INCOMPLETED' ||
                        race.RaceState == 'CANCELLED' ||
                        race.RaceState == 'DIDNOTTRACK' ||
                        race.RaceState == 'ABANDONED'
                    }"
                  >
                    <i
                      class="fa fa-circle color-inherit"
                      aria-hidden="true"
                    ></i>
                    <span class="color-inherit">{{
                      race["RaceState"] || "-"
                    }}</span>
                  </span>
                </div>
              </div>
            </div>
          </a>
          <a
            class="race-navigator flex-start"
            [class.disabled]="event['race_number'] == races.length"
            (click)="next()"
          >
            Next
            <i class="fa fa-angle-right"></i>
          </a>
        </div>
      </div>
      <div class="flex-center head-container">
        <div>
          <img
            src="https://tsd-prd01-logos.s3.ap-southeast-2.amazonaws.com/venues/{{
              event['venue_id']
            }}.png"
            alt=""
          />
        </div>
        <div class="flex-1 ml-2">
          <h2 class="fw-bold">{{ event["venue"] }}</h2>
          <h3 class="fw-bold mb-2">
            Race {{ event["race_number"] }}: {{ event["race_name"] }} -
            {{ event["race_length"] }}m
          </h3>
          <h4 class="fw-bold mb-2">
            {{ convertTimestamp(event["race_time"], this.event.venue_state) }}
          </h4>
          <div class="flex-start mb-2">
            <label>Race Status: </label>
            <span *ngIf="event['race_status_edit']" class="flex-start">
              <select
                [(ngModel)]="event['race_status_edit']"
                class="form-select"
              >
                <option *ngFor="let item of race_states" [value]="item">
                  {{ item }}
                </option>
              </select>
              <span class="flex-start">
                <i
                  class="fa fa-check-circle text-success pointer"
                  (click)="selectRaceStatus()"
                ></i>
                <i
                  class="fa fa-times-circle text-danger pointer"
                  (click)="event.race_status_edit = undefined"
                ></i>
              </span>
            </span>
            <span *ngIf="!event['race_status_edit']">
              <div class="flex-start">
                <span
                  class="status-text-container alert inline-flex-start"
                  [ngClass]="{
                    'alert-success': event.race_status == 'FINISHED',
                    'alert-dark':
                      event.race_status == 'PLANNED' ||
                      event.race_status == 'PUBLISHED' ||
                      event.race_status == 'OFFICIAL',
                    'alert-danger':
                      event.race_status == 'INCOMPLETED' ||
                      event.race_status == 'CANCELLED' ||
                      event.race_status == 'DIDNOTTRACK' ||
                      event.race_status == 'ABANDONED'
                  }"
                >
                  <i class="fa fa-circle color-inherit" aria-hidden="true"></i>
                  <span class="color-inherit">{{
                    event["race_status"] || "-"
                  }}</span>
                </span>
                <i
                  class="fa fa-pencil pointer"
                  (click)="event.race_status_edit = event.race_status || ''"
                ></i>
              </div>
            </span>
          </div>
          <div class="flex-start mb-2">
            <label>Weather: </label>
            <span *ngIf="event['weather_edit'] !== undefined" class="flex-start">
              <select [(ngModel)]="event['weather_edit']" class="form-select">
                <option *ngFor="let item of weather_options" [value]="item">
                  {{ item }}
                </option>
              </select>
              <span class="flex-start">
                <i
                  class="fa fa-check-circle text-success pointer"
                  (click)="selectWeatherStatus()"
                ></i>
                <i
                  class="fa fa-times-circle text-danger pointer"
                  (click)="event.weather_edit = undefined"
                ></i>
              </span>
            </span>
            <span *ngIf="event['weather_edit'] === undefined">
              <div class="flex-start">
                <span class="status-text-container alert inline-flex-start">
                  <i class="fa fa-circle color-inherit" aria-hidden="true"></i>
                  <span class="color-inherit">{{
                    event["weather"] || "-"
                  }}</span>
                </span>
                <i
                  class="fa fa-pencil pointer"
                  (click)="event.weather_edit = event.weather || ''"
                ></i>
              </div>
            </span>
          </div>
          <div class="flex-start mb-2">
            <label>Track Condition: </label>
            <span *ngIf="event['track_condition_edit'] !== undefined" class="flex-start">
              <select
                [(ngModel)]="event['track_condition_edit']"
                class="form-select"
              >
                <option
                  *ngFor="let item of track_condition_options"
                  [value]="item"
                >
                  {{ item }}
                </option>
              </select>
              <input
                *ngIf="event.weather === 'custom'"
                type="text"
                id="custom_weather"
                name="custom_weather"
                class="form-control"
                placeholder="Enter custom split text"
                [(ngModel)]="event.custom_weather"
              />
              <span class="flex-start">
                <i
                  class="fa fa-check-circle text-success pointer"
                  (click)="selectTrackConditionStatus()"
                ></i>
                <i
                  class="fa fa-times-circle text-danger pointer"
                  (click)="event.track_condition_edit = undefined"
                ></i>
              </span>
            </span>
            <span *ngIf="event['track_condition_edit'] === undefined">
              <div class="flex-start">
                <span class="status-text-container alert inline-flex-start">
                  <i class="fa fa-circle color-inherit" aria-hidden="true"></i>
                  <span class="color-inherit">{{
                    event["track_condition"] || "-"
                  }}</span>
                </span>
                <i
                  class="fa fa-pencil pointer"
                  (click)="
                    event.track_condition_edit = event.track_condition || ''
                  "
                ></i>
              </div>
            </span>
          </div>
          <div class="flex-start">
            <a
              href="dash/race-runner/{{ event['venue_id'] }}/{{
                event['event_id']
              }}/{{ raceId }}"
            >
              Runner Status
            </a>
          </div>
          <div class="flex-start">
            <a
              href="dash/race-runner-map/{{ event['venue_id'] }}/{{
                event['event_id']
              }}/{{ raceId }}"
            >
              Runner Status Map
            </a>
          </div>
          <!-- <div class="flex-center p-2 pb-3">
            <h5>Current EQTrace Track:</h5>
            <div>
              <select
                class="form-select"
                [(ngModel)]="event['current_eq_trace_track']"
              >
                <option
                  value="{{ trace['value'] }}"
                  *ngFor="let trace of traceList"
                >
                  {{ trace["name"] }}
                </option>
              </select>
            </div>
          </div>
          <h5>Current Kit - {{ event["tag_id"] }}</h5> -->
        </div>
      </div>

      <div *ngIf="event">
        <div class="flex-end">
          <ng-container
            *ngIf="event?.pdf_status != 'GENERATING'; else elseBlock"
          >
            <button class="btn btn-primary-soft" (click)="load()">
              Refresh
            </button>
            <button class="btn btn-primary-soft" (click)="submitRace($event)">
              Submit
            </button>
            <button
              class="btn btn-primary-soft flex-start"
              (click)="updateScratchings()"
              [disabled]="event.loadingScrating"
            >
              <img
                src="./assets/img/loading_2.svg"
                class="img-18"
                alt=""
                *ngIf="event.loadingScrating"
              />
              Update Scratchings
            </button>
            <ng-container *ngIf="ut == 'admin'">
              <button
                class="btn btn-primary-soft"
                (click)="dataTransfer = event"
              >
                Copy Data
              </button>
              <button
                class="btn btn-danger-soft"
                (click)="deleteData()"
              >
                Delete Data
              </button>
            </ng-container>
            <!-- <button
              class="btn btn-primary-soft"
              (click)="submitRace($event, true)"
            >
              Submit and Generate Start List
            </button> -->
          </ng-container>
          <ng-template #elseBlock>
            <div
              class="spinner-border spinner-border-sm text-secondary mr-2"
            ></div>
          </ng-template>
          <ng-container *ngIf="event && event['eventDetails']">
            <a
              routerLink="/dash/post-race/race-reports"
              [attr.disabled]="
                !this.eventDetails || !this.eventDetails['pc'] ? true : null
              "
              [queryParams]="{
                event_id: this.event['event_id'],
                race_id: this.raceId,
                date: event['date']
              }"
              class="btn btn-primary-soft"
              >Race Reports</a
            >
          </ng-container>
        </div>
        <table-view
          [headers]="headers"
          [data]="event.entrants"
          [loading]="loading"
          (sendEvent)="captureEvent($event)"
        ></table-view>
      </div>
    </ng-container>
    <ng-container *ngIf="event == null">
      <h2 class="center">{{ error }}</h2>
    </ng-container>
  </div>
</div>
<data-transfer *ngIf="dataTransfer" [race]="dataTransfer" [action_type]="dataTransfer['action_type'] || undefined" (hideModal)="dataTransfer = null"></data-transfer>
