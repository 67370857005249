<div id="pdfPopup" class="popup">
  <div class="popup-content">
    <div class="popup-header">
      #{{ meta.race.SquentialRaceOrderNumber }} {{ meta.race.RaceName }} -
      {{ metaData[meta.type]?.title || "Upload LIF File" }}
    </div>
    <div class="popup-body">
      <div>
        <div *ngIf="meta.fileError" class="error flex-start">
          <i class="fa-solid fa-circle-exclamation"></i>
          <span>{{ meta.fileError }}</span>
        </div>
        <div *ngIf="meta.fileSuccess" class="success flex-start mb-0">
          <i class="fa-solid fa-check-circle"></i>
          <span>{{ meta.fileSuccess }}</span>
        </div>
        <div
          *ngIf="meta.uploadStatus == 'PROCESSING'"
          class="secondary flex-between mb-0"
        >
          <div class="flex-start">
            <i class="fa-solid fa-clock"></i>
            <span>Processing...</span>
          </div>
          <div>
            <span class="spinner-border spinner-border-sm ml-2"></span>
          </div>
        </div>
        <div
          class="upload-section"
          *ngIf="!meta.file; else showFile"
          id="uploadSection"
          [class.active]="dragging"
          (click)="uploadSection.click()"
          (dragover)="onDragOver($event)"
          (dragleave)="onDragLeave($event)"
          (drop)="onDrop($event)"
        >
          {{
            dragging
              ? "Drop here"
              : "Click here to upload or drag and drop the " +
                metaData[meta.type]["name"] +
                " File"
          }}
          <input
            #uploadSection
            type="file"
            id="tolFileInput"
            [attr.accept]="metaData[meta.type].allowed"
            style="display: none"
            (change)="onFileSelected($event)"
          />
        </div>
        <ng-template #showFile>
          <div class="flex-between p-1">
            <div class="flex-1 flex-start file">
              <div class="pr-1">
                <i class="fa fa-file-text" aria-hidden="true"></i>
              </div>
              <div class="flex-1">
                <div>
                  <strong>{{ meta.file.name }}</strong>
                </div>
                <div class="mt-1">
                  <span
                    *ngIf="
                      !meta.uploadStatus ||
                        meta.uploadStatus == 'COMPLETED' ||
                        meta.uploadStatus == 'PROCESSING';
                      else showUploadStatus
                    "
                    class="flex-start"
                  >
                    {{ formatFileSize(meta.file.size) }}
                    <ng-container
                      *ngIf="
                        meta.uploadStatus == 'COMPLETED' ||
                        meta.uploadStatus == 'PROCESSING'
                      "
                    >
                      <span> | </span>
                      <span class="flex-start text-success fw-bold"
                        ><i class="fa fa-check-circle color-inherit"></i>
                        Uploaded</span
                      >
                    </ng-container>
                  </span>
                  <ng-template #showUploadStatus>
                    <div>
                      <span
                        >{{ formatFileSize(meta.uploaded || 0) }} /
                        {{ formatFileSize(meta.file.size) }} ({{
                          meta.percentage || 0
                        }}%)</span
                      >
                    </div>
                    <div class="progress-bar">
                      <div
                        class="progress"
                        [style.width.%]="meta.percentage || 0"
                      ></div>
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>
            <div class="flex-start">
              <ng-container
                *ngIf="
                  !meta.uploadStatus || meta.uploadStatus == 'COMPLETED';
                  else showLoader
                "
              >
                <button
                  class="flex-start btn btn-primary-soft"
                  (click)="viewContent()"
                >
                  <i class="fa fa-eye color-inherit"></i>
                  View
                </button>
                <button
                  class="flex-start btn btn-success-soft"
                  (click)="handleFile()"
                  *ngIf="!meta.uploadStatus"
                >
                  <i class="fa-solid fa-upload color-inherit"></i>
                  Upload
                </button>
                <button
                  class="flex-start btn btn-danger-soft"
                  (click)="cancelPDF()"
                  *ngIf="meta.uploadStatus != 'COMPLETED'; else showCloseButton"
                >
                  <i class="fa fa-times color-inherit"></i>
                  Cancel
                </button>
                <ng-template #showCloseButton>
                  <button
                    class="flex-start btn btn-danger-soft"
                    (click)="cancelPDF()"
                  >
                    <i class="fa fa-times color-inherit"></i>
                    Close
                  </button>
                </ng-template>
              </ng-container>
              <ng-template #showLoader>
                <span class="spinner-border spinner-border-sm ml-2"></span>
              </ng-template>
            </div>
          </div>
          <div *ngIf="meta.type === 'normal_lip'" class="alert alert-warning flex-start warning-alert">
            <i class="fa-solid fa-exclamation-triangle"></i>
            <span class="color-inherit">File will be uploaded as <span class="color-inherit fw-bold">{{ getFileName }}</span></span>
          </div>
        </ng-template>
      </div>
    </div>
    <div class="popup-actions flex-end">
      <ng-content select="closePopup"></ng-content>
    </div>
  </div>
</div>
