<div id="popup" class="popup">
  <div class="popup-container">
    <div class="popup-header">Change Password</div>
    <div class="popup-content">
      <div class="form-group">
        <label>Password</label>
        <div class="relative">
          <input
            [attr.type]="showPassword ? 'text' : 'password'"
            class="form-control"
            placeholder="Password"
            [(ngModel)]="password"
          />
          <i
            class="fa"
            [ngClass]="{
              'fa-eye-slash': showPassword,
              'fa-eye': !showPassword
            }"
            (click)="showPassword = !showPassword"
          ></i>
        </div>
        <div class="password-policy-container">
          <div
            *ngFor="
              let policy_key of getKeys(
                passwordChecker(this.password)['policies']
              );
              let i = index
            "
            [ngClass]="{
              correct: passwordChecker(this.password)['policies'][policy_key]
            }"
            class="password-policy flex-start flex-start-center"
          >
            <i
              *ngIf="!passwordChecker(this.password)['policies'][policy_key]"
              aria-hidden="true"
              class="fa fa-times-circle"
            ></i>
            <i
              *ngIf="passwordChecker(this.password)['policies'][policy_key]"
              aria-hidden="true"
              class="fa fa-check-circle"
            ></i>
            <span>
              {{ policy_key }}
            </span>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label>Confirm Password</label>
        <div class="relative">
          <input
            [attr.type]="showPassword ? 'text' : 'password'"
            class="form-control"
            placeholder="Confirm Password"
            [(ngModel)]="confPassword"
          />
          <i
            class="fa"
            [ngClass]="{
              'fa-eye-slash': showPassword,
              'fa-eye': !showPassword
            }"
            (click)="showPassword = !showPassword"
          ></i>
        </div>
      </div>
      <div class="popup-buttons flex-end">
        <button
          id="continueBtn"
          class="btn btn-primary-soft"
          (click)="changePassword()"
          [attr.disabled]="
            password != confPassword || password == '' || confPassword == ''
              ? true
              : null
          "
        >
          Change
        </button>
        <button
          id="logoutBtn"
          class="btn btn-light"
          (click)="hideModel.emit(false)"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>
