<div class="card">
  <div class="card-header flex-between">
    <div class="flex-start">
      <h5 class="mb-0">Sectionals</h5>
    </div>
    <input type="text" class="datetimepicker" />
  </div>
  <div class="card-body">
    <div>
      <div class="form-group mt-2">
        <label>Select your Event</label>
        <post-race-location-picker
          [locations]="locations"
          [selected]="selected"
          (selectedLocation)="selectEvent($event)"
          type="event"
        ></post-race-location-picker>
        <div *ngIf="locations.length == 0">
          No Events Found on <b>{{ date }}</b>
        </div>
      </div>

      <div class="form-group mt-2">
        <div class="flex-between">
          <label class="mb-0">Races</label>
        </div>
        <div
          class="race form-group"
          [class.pending]="race.RaceState != 'PUBLISHED'"
          *ngFor="let race of races"
        >
          <div class="card-view race" (click)="loadEntrants(race)">
            <div class="flex-start">
              <div class="flex-1">
                <div class="flex-between">
                  <div class="flex-start flex-1">
                    <div class="fw-bold fs-5">
                      #{{ race["SquentialRaceOrderNumber"] }}
                      {{ race.RaceName }} | {{ race.RaceLength }}m
                    </div>
                  </div>
                  <div class="flex-start">
                    <span class="fw-bold" *ngIf="race['RaceState'] == 'PLANNED' && race['RaceTimeMs'] > currentTimeMs">
                      Starts in {{ (race['RaceTimeMs'] - currentTimeMs) | date:'HH:mm.ss':'UTC' }}
                    </span>
                    <span
                      class="status-text-container alert inline-flex-start"
                      [ngClass]="{
                        'alert-success':
                          race.RaceState == 'FINISHED' ||
                          race.RaceState == 'RUNNING' ||
                          race.RaceState == 'PUBLISHED',
                        'alert-dark':
                          race.RaceState == 'PLANNED' ||
                          race.RaceState == 'OFFICIAL',
                        'alert-danger':
                          race.RaceState == 'INCOMPLETED' ||
                          race.RaceState == 'CANCELLED' ||
                          race.RaceState == 'DIDNOTTRACK' ||
                          race.RaceState == 'ABANDONED'
                      }"
                    >
                      <i
                        class="fa fa-circle color-inherit"
                        aria-hidden="true"
                      ></i>
                      <span class="color-inherit">
                        {{ race.RaceState }}
                      </span>
                    </span>
                    <button
                      class="btn btn-success-soft"
                      (click)="export($event, race)"
                      *ngIf="race.raceHeaders"
                    >
                      Export
                    </button>
                    <i class="fa-solid fa-angle-up" *ngIf="race.expanded"></i>
                    <i
                      class="fa-solid fa-angle-down"
                      *ngIf="!race.expanded"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="race.expanded">
            <div class="flex-center p-2" *ngIf="race.loading">
              <span class="spinner-border spinner-border-sm ml-2"></span>
            </div>
            <div *ngIf="!race.loading" class="overflow-auto">
              <table class="table table-docs sticky-columns">
                <thead>
                  <tr>
                    <th>Rank</th>
                    <th>SCN</th>
                    <th>Barrier/Box</th>
                    <th>Image</th>
                    <th>Animal Name</th>
                    <th *ngFor="let section of race['raceHeadersMain']">
                      <div class="center">
                        <div claas="mb-2">{{ section["sectionName"] }}</div>
                        <div>{{ section["intermediateTime"] }}</div>
                        <div>({{ section["sectionTime"] }})</div>
                      </div>
                    </th>
                    <th>Overall Time</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="let entrant of race.entrants"
                    [class.dns]="entrant.RaceState == 'DNS'"
                  >
                    <td>{{ entrant.Rank || "-" }}</td>
                    <td>{{ entrant.StartNumber }}</td>
                    <td>{{ entrant.BarrierNumber }}</td>
                    <td>
                      <img
                        [src]="entrant.imagePath"
                        alt="Image"
                        style="height: 40px"
                      />
                    </td>
                    <td>
                      {{ entrant.AnimalName }}
                    </td>
                    <ng-container
                      *ngIf="entrant['sections']; else show_no_sections"
                    >
                      <td *ngFor="let section of entrant['sections']">
                        {{
                          entrant.RaceState.toUpperCase() != "DNS"
                            ? section["sectionTime"] || "-"
                            : "-"
                        }}
                      </td>
                    </ng-container>
                    <ng-template #show_no_sections>
                      <td *ngFor="let section_name of race['raceHeaders']">
                        -
                      </td>
                    </ng-template>
                    <td>
                      {{
                        entrant.RaceState.toUpperCase() != "DNS"
                          ? getOverallTime(race, entrant) || "-"
                          : "-"
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
