import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ActivatedRoute, Params } from '@angular/router';
import { APIService } from 'src/app/services/api.service';

import { getENV } from 'src/app/_helpers/helpers';

@Component({
  selector: 'app-kit-management',
  templateUrl: './kit-management.component.html',
  styleUrls: ['./kit-management.component.css']
})
export class KitManagementComponent implements OnInit {
  kits: any[] = [];
  selectedDevice: any = null; // Currently selected kit for the modal
  showModal: boolean = false; // Controls visibility of the modal

  constructor(
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private apiService: APIService
  ){
    this.config = this.route.snapshot.data['config'];
  }

  loading:boolean = false;
  config: any;
  ngOnInit(): void {
    this.fetchKits();
  }
  async fetchKits(): Promise<void>  {
    this.loading = true;
    // this.http.get<{data: any}>('https://4otldh9um5.execute-api.ap-southeast-2.amazonaws.com/tsd-prd-api/devices/kit_ids').subscribe(response => {
    //   this.kits = response.data;
    // });
    let apiURL: string = `${this.config[getENV()].raceAPI}/devices/kit_ids`;
    let response = await this.apiService.getDataPromis(apiURL, {}, {});
    console.log(response);
    this.kits = response.data;
    this.loading = false;
  }
  navigateToKit(kitId: string): void {
    this.router.navigate(['/kits', kitId]);
  }
  openDeviceDetails(device:any): void {
    this.selectedDevice = device;
    this.showModal = true;
  }

  openAddKit():void {
    this.selectedDevice = null;
    this.showModal = true;
  }

  closeModal(): void {
    this.showModal = false;
    window.location.reload();
  }
  customEncodeURIComponent(value: string): string {
    return encodeURIComponent(value);
  }
}
