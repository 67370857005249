import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { getENV, checkForVenuePermission, getRaceType, convertTimestamp } from 'src/app/_helpers/helpers';
import { APIService } from 'src/app/services/api.service';
import { NotifierService } from 'src/app/services/notifier.service';
import { typeBasedLocations, buttonActions } from '../race-reports/locations';

declare let $: any;
@Component({
  selector: 'app-on-course-ops-race-reports',
  templateUrl: './on-course-ops-race-reports.component.html',
  styleUrls: [
    './on-course-ops-race-reports.component.css',
    './../race-reports/race-reports.component.css',
  ],
})
export class OnCourseOpsRaceReportsComponent {
  races: any = [];
  loading: boolean = false;
  interval: any = null;
  filters: any = {
    venue: { selected: ['ALL'] },
    status: { selected: ['ALL'] },
    time: { selected: ['ALL'] },
  };
  headers: any = [
    {
      id: 'SquentialRaceOrderNumber',
      name: 'Race Number',
      style: {
        width: '100px',
      },
      type: 'string',
      filter: true,
      show: true,
    },
    {
      id: 'eventName',
      name: 'Venue',
      type: 'string',
      align: 'left',
      show: true,
      filter: true,
    },
    {
      id: 'RaceName',
      name: 'Race Name',
      align: 'left',
      type: 'string',
      // filter: true,
      show: true,
    },
    {
      id: 'status',
      name: 'Race Status',
      type: 'component',
      // filter: true,
      align: 'center',
      component: {
        name: 'HorseStatusViewComponent',
      },
      show: true,
    },
    {
      id: 'buttonActions',
      name: 'Report Status',
      type: 'component',
      // filter: true,
      component: {
        name: 'ReprotsdownloadComponent',
      },
      show: true,
    },
  ];

  config: any;

  constructor(
    private apiService: APIService,
    private notifier: NotifierService,
    private router: ActivatedRoute
  ) {
    this.config = this.router.snapshot.data['config'];
  }

  default(event: Event) {
    // event.preventDefault();
    event.stopPropagation();
  }

  hideMenu(event: Event, h: any) {
    event.stopPropagation();
    h.show = !h.show;
  }

  setHeadersToShowTrue(headerIds: string[]): void {
    this.headers.forEach((header) => {
      if (headerIds.includes(header.id)) {
        header.show = true;
      }
    });
  }

  async ngOnInit(): Promise<void> {}

  times: { name: string; id: string }[] = [];
  async loadData(): Promise<void> {
    this.times = [];
    this.times.push({
      name: '10 mins',
      id: '10 mins',
    });
    this.times.push({
      name: '30 mins',
      id: '30 mins',
    });
    this.times.push({
      name: '1 Hour',
      id: '1 Hour',
    });
    this.loading = true;
    this.notifier.loading(true);

    this.races = [];
    let date: string = moment(
      $('.datetimepicker').val(),
      'DD MMMM YYYY'
    ).format('DD-MM-YYYY');
    if (date) {
      await this.loadEventsBasedOnDates(
        moment(date, 'DD-MM-YYYY').format('YYYY-MM-DD')
      );
    }
    if (this.races && this.races.length > 0) {
      this.races = this.races.sort((a: any, b: any): number => {
        return a['MinutesLeft'] - b['MinutesLeft'];
      });
    }

    this.loading = false;
    this.notifier.loading(false);
  }

  async ngAfterViewInit(): Promise<void> {
    let interval_seconds = 5 * 50 * 1000;
    $('.datetimepicker').val(moment().format('DD MMMM YYYY'));
    $('.datetimepicker')
      .datepicker({
        autoclose: true,
        // minViewMode: 1,
        format: 'dd MM yyyy',
        orientation: 'bottom auto',
      })
      .on('changeDate', (selected: any) => {
        setTimeout(() => {
          this.loadData();
        }, 10);
        if (this.interval) {
          clearInterval(this.interval);
          this.interval = null;
        }
        this.interval = setInterval(() => {
          this.loadData();
        }, interval_seconds);
      });
    setTimeout(() => {
      this.loadData();
      if (this.interval) {
        clearInterval(this.interval);
        this.interval = null;
      }
      this.interval = setInterval(() => {
        this.loadData();
      }, interval_seconds);
    }, 10);
  }

  events: any = [];
  statuses: { id: string; name: string }[] = [
    { name: 'PLANNED', id: 'PLANNED' },
    { name: 'DNS', id: 'DNS' },
    { name: 'DNT', id: 'DNT' },
    { name: 'DNF', id: 'DNF' },
    { name: 'DSQ', id: 'DSQ' },
    { name: 'OFFICIAL', id: 'OFFICIAL' },
    { name: 'FINISHED', id: 'FINISHED' },
    { name: 'PUBLISHED', id: 'PUBLISHED' },
    { name: 'ABANDONED', id: 'ABANDONED' },
  ];

  async loadEventsBasedOnDates(date: string): Promise<boolean> {
    return new Promise(async (resolve: any) => {
      let raceTypes: any = localStorage.getItem('raceTypes');
      if (raceTypes) {
        raceTypes = JSON.parse(raceTypes);
      }
      let apiURL: string = `${
        this.config[getENV()].raceAPI
      }/event-list?formatted_date=${date}`;

      if (raceTypes && raceTypes.length > 0) {
        apiURL += `&race_types=${raceTypes.join(',')}`;
      }

      let result: any = await this.apiService.getDataPromis(apiURL, {}, {});

      this.events = [];

      if (result.eventNames && result.eventNames.length > 0) {
        result.eventNames = result.eventNames.filter((event: any) => {
          return checkForVenuePermission(event['external_venue_id']);
        });
        this.events = result.eventNames.map(
          (event: any): { id: string; name: string } => {
            let raceType = getRaceType(event['external_venue_id'])[
              'raceTypeStr'
            ];
            if (
              event['venue_name'] == 'Cranbourne' &&
              raceType == 'Thoroughbred Racing'
            ) {
              event['venue_name'] = 'Cranbourne Turf';
            }
            return {
              id: event['external_event_id'],
              name: event['venue_name'],
            };
          }
        );
        let promises: any = [];
        result.eventNames.forEach((event: any) => {
          let raceType = getRaceType(event['external_venue_id']);
          event['raceType'] = raceType['raceTypeStr'];
          if (raceType['raceTypeStr'] == 'Thoroughbred Racing') {
            if (event['venue_name'] == 'Cranbourne') {
              event['venue_name'] = 'Cranbourne Turf';
            }
          }
          const jurisdiction = typeBasedLocations[event['raceType']]?.find(
            (jurisdiction: any) =>
              jurisdiction.Locations.some(
                (location: any) =>
                  location.Name.toLowerCase() ===
                  event['venue_name'].toLowerCase()
              )
          );
          if (jurisdiction) {
            event['jurisdictionCode'] = jurisdiction.JurisdictionCode;
            event['stateCode'] = jurisdiction.State;
          }
          event['name'] = event['venue_name'];
          // console.log(event);
          promises.push(this.loadRacesBasedOnEvent(event));
        });
        if (promises.length > 0) {
          Promise.all(promises).then((values: any) => {
            resolve(true);
          });
        } else {
          resolve(true);
        }
      } else {
        resolve(true);
      }
    });
  }

  async loadRacesBasedOnEvent(event: any): Promise<boolean> {
    return new Promise(async (resolve: any) => {
      let apiURL: string = `${
        this.config[getENV()].raceAPI
      }/getracedetails?ExternalVenueId=${
        event['external_venue_id']
      }&ExternalEventId=${event['external_event_id']}`;

      let result: any = await this.apiService.getDataPromis(apiURL, {}, {});
      let raceDate = $('.datetimepicker').val();
      if (result && Array.isArray(result) && result.length > 0) {
        let raceType = getRaceType(event['external_venue_id'])['raceTypeStr'];
        result.forEach((race: any) => {
          race['status'] = {
            PLANNED: 0,
            DNS: 0,
            DNT: 0,
            DNF: 0,
            DSQ: 0,
            OFFICIAL: 0,
            FINISHED: 0,
            PUBLISHED: 0,
            ABANDONED: 0,
          };
          race['raceStatus'] = race['RaceState'] || 'PLANNED';
          if (race['raceStatus'].toUpperCase() == 'ABANDONED') {
            race['status']['ABANDONED'] = race['AnimalDetail'].length;
          } else {
            race['AnimalDetail'].forEach((animal: any) => {
              animal['RaceState'] = animal['RaceState'] || 'PLANNED';
              let status = 'PLANNED';
              if (animal['RaceState'].toUpperCase() == 'PLANNED') {
                if (race['raceStatus'].toUpperCase() == 'PLANNED') {
                  status = 'PLANNED';
                } else if (race['raceStatus'].toUpperCase() == 'FINISHED') {
                  status = 'FINISHED';
                }
              } else {
                status = animal['RaceState'].toUpperCase();
              }
              race['status'][status] += 1;
            });
          }
          race['buttonActions'] = [
            {
              slug: 'OR',
              status: 'PLANNED',
              actions: [],
            },
            {
              slug: 'PDF',
              status: 'PLANNED',
              actions: [],
            },
            {
              slug: 'CUST',
              status: 'PLANNED',
              actions: [],
            },
            {
              slug: 'TSD',
              status: 'PLANNED',
              actions: [],
            },
          ];
          let buttonActionsJSON = JSON.parse(JSON.stringify(buttonActions));
          buttonActionsJSON
            .filter((report: any) => {
              if (report.conditions) {
                const condition = report.conditions.find(
                  (c: any) => c.state === event['jurisdictionCode']
                );
                if (condition) {
                  const { key, equation, value } = condition.condition;
                  const raceValue = race[key];
                  switch (equation) {
                    case 'equals':
                      return raceValue === value;
                    case 'not equals':
                      return raceValue !== value;
                    default:
                      return true;
                  }
                }
                return true;
              }
              return true;
            })
            .forEach((report: any) => {
              if (
                (report.states == 'ALL' ||
                  report.states.includes(event['jurisdictionCode']) ||
                  report.states.includes(event['stateCode'])) &&
                (!report.type || report.type == raceType)
              ) {
                let reportStatus = race['ReportStatus'].find((report_: any) => {
                  return report_['StepType'] == report['action'];
                });
                let action = race['buttonActions'].find(
                  (buttonAction: any) => buttonAction.slug == report['slug']
                );

                if (reportStatus) {
                  action['actions'].push({
                    name: report['name'],
                    status: reportStatus['StepCompletionStatus'].toUpperCase(),
                    comment: reportStatus['Comments'] || '',
                  });
                } else {
                  action['actions'].push({
                    name: report['name'],
                    status: 'PLANNED',
                    comment: '',
                  });
                }
              }
            });

          race['buttonActions'].forEach((action: any) => {
            let success = action['actions'].find((action_: any) => {
              return action_['status'] == 'COMPLETED';
            });
            let failed = action['actions'].find((action_: any) => {
              return action_['status'] == 'FAILED';
            });
            if (failed) {
              action['status'] = 'FAILED';
            } else if (success) {
              action['status'] = 'COMPLETED';
            } else {
              action['status'] = 'PLANNED';
            }
          });

          race['eventName'] = event['name'];
          race['eventDate'] = $('.datetimepicker').val();
          race['RaceTimeStr'] = race['RaceTime'];
          race['RaceTime'] = convertTimestamp(
            race['RaceTime'],
            event['venue_state']
          );
          race['MinutesLeft'] = this.calculateMinutesLeft(
            raceDate,
            race['RaceTime']
          );
        });
        this.races = [...this.races, ...result];
        // await this.getRaceResult(
        //   event['external_event_id'],
        //   event['external_venue_id'],
        //   result
        // );
        // this.races = [...this.races, ...result];
      }
      resolve(true);
    });
  }

  calculateMinutesLeft(raceDate, raceTimeStr) {
    let raceHour = parseInt(raceTimeStr.substring(0, 2));
    let raceMinute = parseInt(raceTimeStr.substring(3, 5));
    // Combine race date and time
    let raceDateTime = moment(raceDate, 'DD MMMM YYYY').set({
      hour: raceHour,
      minute: raceMinute,
      second: 0,
      millisecond: 0,
    });

    // Get the current browser time
    let currentTime = moment();

    // Calculate the difference in minutes
    let minutesLeft = raceDateTime.diff(currentTime, 'minutes');

    return minutesLeft;
  }
  ngOnDestroy(): void {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }
}
