import { Component } from '@angular/core';

@Component({
  selector: 'app-race-monitoring-report',
  templateUrl: './race-monitoring.component.html',
  styleUrls: ['./race-monitoring.component.css']
})
export class RaceMonitoringComponent{

}
