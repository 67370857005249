<div id="popup">
  <div class="popup-container">
    <div class="popup-header">SSM PC Control - {{ pc.name }}</div>
    <div class="popup-content">
      <nav class="relative">
        <div class="nav nav-tabs" role="tablist">
          <button
            class="nav-link tab"
            [class.active]="selectedTab === 'system'"
            (click)="selectTab('system')"
          >
            System
          </button>
          <button
            class="nav-link tab"
            [class.active]="selectedTab === 'applications'"
            (click)="selectTab('applications')"
          >
            Applications
          </button>
          <button
            class="nav-link tab"
            [class.active]="selectedTab === 'processes'"
            (click)="selectTab('processes')"
          >
            Processes
          </button>
          <button
            class="nav-link tab"
            [class.active]="selectedTab === 'remote'"
            (click)="selectTab('remote')"
          >
            Remote PC
          </button>
          <button
            class="nav-link tab"
            [class.active]="selectedTab === 'details'"
            (click)="selectTab('details')"
          >
            Details
          </button>
        </div>
      </nav>
      <div class="tab-content">
        <div *ngIf="selectedTab === 'system'" class="tab-pane active">
          <div class="form-section">
            <div class="form-section-header">Date and Time</div>
            <div class="form-section-body">
              <div class="form-group full-width">
                <label for="timeZone">Set Time Zone:</label>
                <select
                  id="timeZone"
                  [(ngModel)]="selectedTimeZone"
                  class="form-select"
                  [disabled]="isLoadingTimeZone || isSettingTimeZone"
                >
                  <option *ngFor="let time of times" [value]="time.name">
                    {{ time.name }}
                  </option>
                </select>
                <button
                  (click)="setTimeZone()"
                  class="btn btn-primary-soft mt-1"
                  [disabled]="isSettingTimeZone"
                >
                  <span
                    *ngIf="isSettingTimeZone"
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Set Timezone
                </button>
                <div *ngIf="isLoadingTimeZone" class="text-center flex-start">
                  <span
                    class="spinner-border"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Loading time zone...
                </div>
              </div>
              <div class="flex-start full-width">
                <div class="form-group flex-1">
                  <label for="date">Set Date:</label>
                  <input
                    type="date"
                    id="date"
                    class="form-control"
                    [(ngModel)]="selectedDate"
                    [disabled]="isLoadingDateTime || isSettingDateTime"
                  />
                </div>
                <div class="form-group flex-1">
                  <label for="time">Set Time:</label>
                  <div class="flex-start">
                    <select
                      id="hour"
                      class="form-select"
                      [(ngModel)]="selectedHour"
                      [disabled]="isLoadingDateTime || isSettingDateTime"
                    >
                      <option *ngFor="let hour of hours" [value]="hour">
                        {{ hour }}
                      </option>
                    </select>
                    <select
                      id="minute"
                      class="form-select"
                      [(ngModel)]="selectedMinute"
                      [disabled]="isLoadingDateTime || isSettingDateTime"
                    >
                      <option *ngFor="let minute of minutes" [value]="minute">
                        {{ minute }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="form-group full-width">
                <button
                  (click)="setDateTime()"
                  class="btn btn-primary-soft"
                  [disabled]="isSettingDateTime"
                >
                  <span
                    *ngIf="isSettingDateTime"
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Set Date and Time
                </button>
                <div *ngIf="isLoadingDateTime" class="text-center flex-start">
                  <span
                    class="spinner-border"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Loading date and time...
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="form-section">
            <div class="form-section-header">System Status</div>
            <div class="form-section-body">
              <div class="flex-start">
                <button (click)="startPC()" class="btn btn-success-soft">
                  Start PC
                </button>
                <button (click)="stopPC()" class="btn btn-danger-soft">
                  Stop PC
                </button>
              </div>
            </div>
          </div> -->
          <div class="form-section">
            <div class="form-section-header">PC Status</div>
            <div class="form-section-body">
              <div class="pc-status">
                <span class="status-label">Status:</span>
                <div class="status-indicator">
                  <div class="outer-circle"></div>
                  <div class="inner-circle"></div>
                </div>
                <span class="status-value">Running</span>
                <button
                  (click)="stopPC()"
                  class="btn btn-danger-soft"
                  [disabled]="isStoppingPC"
                >
                  <span
                    *ngIf="isStoppingPC"
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Stop PC
                </button>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="selectedTab === 'applications'" class="tab-pane active">
          <div class="form-group full-width sticky-top">
            <input
              type="text"
              class="form-control"
              placeholder="Search applications..."
              [(ngModel)]="searchTerm"
            />
          </div>
          <div *ngIf="isLoadingApplications" class="text-center flex-start">
            <span
              class="spinner-border"
              role="status"
              aria-hidden="true"
            ></span>
            Loading applications...
          </div>
          <div *ngIf="!isLoadingApplications">
            <div
              *ngFor="let app of filteredApplications()"
              class="application-card"
            >
              <div class="flex-between">
                <div class="flex-start">
                  <div>
                    <div class="text-bold">{{ app.DisplayName }}</div>
                    <div class="small-text">{{ app.PathName }}</div>
                  </div>
                </div>
                <div class="flex-center">
                  <div
                    class="flex-start"
                    [class.text-success]="app.State === 'Running'"
                    [class.text-danger]="app.State === 'Stopped'"
                  >
                    {{ app.State }}
                  </div>
                  <button
                    *ngIf="app.State === 'Stopped'"
                    (click)="startApplication(app)"
                    class="btn btn-success-soft flex-start"
                    [disabled]="isStartingApplication[app.name]"
                  >
                    <span
                      *ngIf="isStartingApplication[app.name]"
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Start Application
                  </button>
                  <button
                    *ngIf="app.State === 'Running'"
                    (click)="stopApplication(app)"
                    class="btn btn-danger-soft flex-start"
                    [disabled]="isStoppingApplication[app.name]"
                  >
                    <span
                      *ngIf="isStoppingApplication[app.name]"
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Stop Application
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="selectedTab === 'processes'" class="tab-pane active">
          <div class="form-group full-width sticky-top">
            <input
              type="text"
              class="form-control"
              placeholder="Search processes..."
              [(ngModel)]="searchTermProcesses"
            />
          </div>
          <div *ngIf="isLoadingProcesses" class="text-center flex-start">
            <span
              class="spinner-border"
              role="status"
              aria-hidden="true"
            ></span>
            Loading processes...
          </div>
          <div *ngIf="!isLoadingProcesses">
            <div
              *ngFor="let process of filteredProcesses()"
              class="application-card"
            >
              <div class="flex-between">
                <div class="flex-start">
                  <div>
                    <div class="text-bold">{{ process.Name }}</div>
                    <div class="flex-start process-details">
                      <div class="small-text">
                        <span class="text-bold">PID:</span> {{ process.Id }}
                      </div>
                      <div class="small-text">
                        <span class="text-bold">Memory:</span>
                        {{ process.WorkingSet | number }} bytes
                      </div>
                      <div class="small-text">
                        <span class="text-bold">CPU:</span>
                        {{ process.CPU }}%
                      </div>
                      <div class="small-text">
                        <span class="text-bold">Path:</span>
                        {{ process.Path || '-' }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex-center">
                  <button
                    (click)="killProcess(process.Id)"
                    class="btn btn-danger-soft flex-start"
                    [disabled]="isKillingProcess[process.Id]"
                  >
                    <span
                      *ngIf="isKillingProcess[process.Id]"
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Kill Process
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="selectedTab === 'remote'" class="tab-pane active">
          <h3>Remote PC</h3>
          <p>Remote Desktop functionality can be added here.</p>
        </div>
        <div *ngIf="selectedTab === 'details'" class="tab-pane active">
          <div class="grid-3">
            <div class="validation-method">
              <div class="text-bold font-size-1">PC Number</div>
              <div class="small-text font-size-1">{{ pc.name || "-" }}</div>
            </div>
            <div class="validation-method">
              <div class="text-bold font-size-1">IP Address</div>
              <div class="small-text font-size-1">
                {{ pc.ipaddress || "-" }}
              </div>
            </div>
            <div class="validation-method">
              <div class="text-bold font-size-1">Port</div>
              <div class="small-text font-size-1">{{ pc.port || "-" }}</div>
            </div>
            <div class="validation-method">
              <div class="text-bold font-size-1">Status</div>
              <div class="small-text font-size-1">
                {{ pc.found ? "In Use" : "Not In Use" }}
              </div>
            </div>
            <div class="validation-method">
              <div class="text-bold font-size-1">Operator</div>
              <div class="small-text font-size-1">
                {{ pc?.found?.AssignedOperatorFullName || "-" }}
              </div>
            </div>
            <div class="validation-method">
              <div class="text-bold font-size-1">Kit</div>
              <div class="small-text font-size-1">
                {{ pc?.found?.AssignedKit || "-" }}
              </div>
            </div>
            <div class="validation-method">
              <div class="text-bold font-size-1">Assigned PC Name</div>
              <div class="small-text font-size-1">
                {{ pc?.found?.AssignedPCName || "-" }}
              </div>
            </div>
            <div class="validation-method">
              <div class="text-bold font-size-1">External Event ID</div>
              <div class="small-text font-size-1">
                {{ pc?.found?.ExternalEventID || "-" }}
              </div>
            </div>
            <div class="validation-method">
              <div class="text-bold font-size-1">Venue Name</div>
              <div class="small-text font-size-1">
                {{ pc?.found?.VenueName || "-" }}
              </div>
            </div>
            <div class="validation-method">
              <div class="text-bold font-size-1">External Venue ID</div>
              <div class="small-text font-size-1">
                {{ pc?.found?.ExternalVenueID || "-" }}
              </div>
            </div>
            <div class="validation-method">
              <div class="text-bold font-size-1">Meeting Date</div>
              <div class="small-text font-size-1">
                {{ pc?.found?.MeetingDate || "-" }}
              </div>
            </div>
            <div class="validation-method">
              <div class="text-bold font-size-1">Event Name</div>
              <div class="small-text font-size-1">
                {{ pc?.found?.EventName || "-" }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="popup-buttons flex-end">
      <button (click)="hideModel.emit()" class="btn btn-light">Close</button>
    </div>
  </div>
</div>
