<div id="pdfPopup" class="popup">
  <div class="popup-content">
    <div class="popup-header">
      #{{ meta.race.SquentialRaceOrderNumber }} {{ meta.race.RaceName }} -
      Generate LIF File
    </div>
    <div class="popup-body">
      <div>
        <div *ngIf="meta.error" class="error flex-start mb-0">
          <i class="fa-solid fa-circle-exclamation"></i>
          <span>{{ meta.error }}</span>
        </div>
        <div *ngIf="meta.completed" class="success flex-start mb-0">
          <i class="fa-solid fa-check-circle"></i>
          <span>LIF File Generated Successfully</span>
        </div>
        <div
          *ngIf="meta.loadingHorses; else showHorses"
          class="flex-center p-2"
        >
          <span class="spinner-border spinner-border-sm ml-2"></span>
        </div>
        <ng-template #showHorses>
          <div>
            <table class="table table-docs table-hover">
              <thead>
                <tr>
                  <th>SCN</th>
                  <th>Horse Name</th>
                  <th>State</th>
                  <th>Rank</th>
                  <th>Official Finish Time</th>
                  <th>Margin</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let horse of horses">
                  <td><span [class.line-through]="horse.RaceState == 'DNS'">{{ horse.StartNumber }}</span></td>
                  <td class="whitespace-none"><span [class.line-through]="horse.RaceState == 'DNS'">{{ horse.AnimalName }}</span></td>
                  <td>
                    <select
                      class="form-select"
                      [(ngModel)]="horse.RaceState"
                      [disabled]="meta.generateStatus == 'GENERATING'"
                    >
                      <option *ngFor="let state of horseStates" [value]="state">{{ state }}</option>
                    </select>
                  </td>
                  <td>
                    <div>
                      <select
                        class="form-select"
                        [disabled]="meta.generateStatus == 'GENERATING' || horse.RaceState == 'DNS' ? true : null"
                        [(ngModel)]="horse.Rank"
                      >
                      <option value="" disabled selected>Rank</option>
                      <option [value]="rank" *ngFor="let rank of ranks">{{ rank }}</option>
                    </select>
                    </div>
                    <div class="flex-start alert-danger mt-1 p-1 fw-bold" *ngIf="horse.RankError">
                      <i class="fa fa-exclamation-triangle"></i>
                      {{ horse.RankError }}
                    </div>
                  </td>
                  <td>
                    <div class="input-container">
                      <input
                        class="form-control"
                        [disabled]="meta.generateStatus == 'GENERATING' || horse.RaceState == 'DNS' ? true : null"
                        type="text"
                        placeholder="MM:SS.MS / MM.SS.MSS / SS.MSS"
                        [(ngModel)]="horse.OfficialFinishTime"
                      />
                      <span class="converted-time" *ngIf="horse.OfficialFinishTime">
                        {{ convertTimeToSeconds(horse.OfficialFinishTime || '') }}s
                      </span>
                    </div>
                    <div class="flex-start alert-danger mt-1 p-1 fw-bold" *ngIf="horse.TimeError">
                      <i class="fa fa-exclamation-triangle"></i>
                      {{ horse.TimeError }}
                    </div>
                  </td>
                  <td>
                    <input
                      class="form-control"
                      [disabled]="meta.generateStatus == 'GENERATING' || horse.RaceState == 'DNS' ? true : null"
                      type="number"
                      step="0.01"
                      min="0"
                      [(ngModel)]="horse.Margin"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </ng-template>
      </div>
    </div>
    <div class="popup-actions flex-between">
      <span class="small"><strong>Note:</strong> The "MM:SS.MSS" format indicates minutes (MM), seconds (SS), and milliseconds (MSS).</span>
      <div class="flex-start">
        <button
          *ngIf="!meta.loadingHorses"
          class="btn btn-success-soft flex-start"
          (click)="generateLIF()"
          [disabled]="meta.generateStatus == 'GENERATING'"
        >
          <span
            class="spinner-border spinner-border-sm ml-2"
            *ngIf="meta.generateStatus == 'GENERATING'"
          ></span>
          Generate
        </button>
        <ng-content select="closePopup"></ng-content>
      </div>
    </div>
  </div>
</div>
