<div id="popup" class="popup">
  <div class="popup-container">
    <div class="popup-header">Select PC</div>
    <div class="popup-content">
      <div class="popup-body">
        <div class="alert alert-warning" role="alert">
          PC is required to trigger this action
        </div>
        <div class="flex-start-top">
          <div class="form-group">
            <label>Operator</label>
            <span>{{username}}</span>
          </div>
          <div class="form-group">
            <label>PC Number</label>
            <mat-form-field appearance="fill">
              <mat-label>Choose PC</mat-label>
              <mat-select
                formControlName="PC"
                name="PC"
                (selectionChange)="selectPC($event)"
              >
                <mat-option
                  *ngFor="let p of PCs"
                  [value]="p.value"
                >
                  {{ p.value }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="popup-buttons flex-end">
        <button
          id="continueBtn"
          class="btn btn-primary-soft flex-start"
          (click)="hideModel.emit(pc)"
          [attr.disabled]="loading || !pc ? true : null"
        >
          <div
            *ngIf="loading"
            class="spinner-border spinner-border-sm text-secondary mr-2"
          ></div>

          Trigger
        </button>
        <button
          id="logoutBtn"
          class="btn btn-light"
          (click)="hideModel.emit(null)"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>
