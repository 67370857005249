import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment-timezone';
import { Subscription } from 'rxjs';
import { APIService } from 'src/app/services/api.service';
import { NotifierService } from 'src/app/services/notifier.service';
import { locationMappers, typeBasedLocations } from '../race-reports/locations';
import { tasks, Task } from './tasks';
import { checkForVenuePermission, convertTimestamp, getENV, getRaceType } from 'src/app/_helpers/helpers';


@Component({
  selector: 'post-race-race-management',
  templateUrl: './race-management.component.html',
  styleUrls: ['./race-management.component.css'],
})
export class RaceManagementComponent implements OnInit, OnDestroy {
  tasks: Task[] = [];
  config: any;
  locations: any = [];
  selected: any = null;
  selectedTasks: any = [];
  type: string = null;
  date: any;
  state: string = null;
  username: string = localStorage.getItem('un')
  routerSubscription: Subscription;
  constructor(
    private route: ActivatedRoute,
    private apiService: APIService,
    private notifier: NotifierService,
    private router: ActivatedRoute
  ) {
    this.config = this.route.snapshot.data['config'];
    this.routerSubscription = this.router.queryParams.subscribe(
      (params: any) => {
        this.state = params['state'];
        this.type = params['type'];
      }
    );
  }

  ngOnInit(): void {
    this.date = moment().format('DD MMMM YYYY');
    this.fetchEvents();
  }

  onclick(type: boolean, button: any) {
    this.handleClicks(type, button);
  }


  // selectPC: boolean = false;
  // selectedReport: any = null;
  // hideSelectPC(event: any) {
  //   if (event) {
  //     this.selected['pc'] = event.value;
  //     this.handleClicks(true, this.selectedReport);
  //   }
  //   this.selectPC = false;
  // }

  async fetchEvents() {
    return new Promise(async (resolve: any) => {
      this.notifier.loading(true);
      const date = moment().format('YYYY-MM-DD');
      let apiURL: string = `${this.config[getENV()].raceAPI}/event-list?formatted_date=${date}`;

      let result: any = await this.apiService.getDataPromis(apiURL, {}, {});

      if (result.eventNames && result.eventNames.length > 0) {
        result.eventNames = result.eventNames.filter((event: any) => {
          return checkForVenuePermission(event['external_venue_id']);
        });
        let locations: any = [];
        let jurisdiction = typeBasedLocations[this.type].find(
          (jurisdiction: any) => {
            return (
              jurisdiction.State == this.state ||
              jurisdiction.JurisdictionCode == this.state
            );
          }
        );
        if (jurisdiction) {
          locations = jurisdiction.Locations.map((location: any) => {
            return location.Name.toLowerCase();
          });
        }
        if (locations && locations.length > 0) {
          result.eventNames = result.eventNames.filter((event: any) => {
            let raceType = getRaceType(event['external_event_id'])['raceTypeStr'];
            return (
              locations.includes(event['venue_name'].toLowerCase()) &&
              raceType == this.type
            );
          });
        } else {
          result.eventNames = [];
        }
        result.eventNames.forEach((event: any, index: number) => {
          event['name'] = event['venue_name'];
          event['id'] = event['external_event_id'];
          this.loadEvent(event, index);
        });
        this.locations = result.eventNames || [];
      } else {
        this.locations = [];
      }
      this.notifier.loading(false);
      resolve(true);
    });
  }

  async loadEvent(event: any, index: number) {
    let apiURL: string = `${this.config[getENV()].raceAPI}/getevent?ExternalEventId=${event.external_event_id}`;

    let header: any = {
      'X-Api-Key': 'e2bvuAoslY1VJvFMqhr5x7nxoEhFVRii9jRngS3U',
    };

    let result: any = await this.apiService.getDataPromis(apiURL, {}, header);

    this.locations[index] = { ...event, ...result.event };
  }

  async loadRacesBasedOnEvent(
    event_id: string,
    venue_id: string,
    event_name: string
  ): Promise<boolean> {
    return new Promise(async (resolve: any) => {
      this.notifier.loading(true);
      let apiURL: string = `${this.config[getENV()].raceAPI}/getracedetails?ExternalVenueId=${venue_id}&ExternalEventId=${event_id}`;

      let result: any = await this.apiService.getDataPromis(apiURL, {}, {});

      if (result && Array.isArray(result) && result.length > 0) {
        result.forEach((race: any) => {
          race['eventName'] = event_name;
          race['RaceTime'] = convertTimestamp(race['RaceTime'], this.selected.venue_state);
          race['raceStatus'] = race['RaceState']?.toUpperCase() || 'PLANNED';
          race[
            'name'
          ] = `#${race['SquentialRaceOrderNumber']} ${race['RaceName']}`;
          race['id'] = race['SquentialRaceOrderNumber'];
          race['eventDate'] = moment().format('YYYY-MM-DD');
          race['RaceTimeStr'] = race['RaceTime'];
        });
        this.races = result.sort((a: any, b: any) => {
          return a['SquentialRaceOrderNumber'] - b['SquentialRaceOrderNumber'];
        });
        await this.getRaceResult(event_id, venue_id, result);
      }

      this.notifier.loading(false);
      resolve(true);
    });
  }

  races: any = [];
  selectedRace: any;
  async getRaceResult(event_id: string, venue_id: string, races: any) {
    return new Promise(async (resolve: any) => {
      this.notifier.loading(true);
      let apiURL: string = `${this.config[getENV()].raceAPI
        }/getraceresults?ExternalEventId=${event_id}&ExternalVenueId=${venue_id}&${races
          .map((race: any) => {
            return `raceIds%5B%5D=${race['ExternalRaceId']}`;
          })
          .join('&')}`;

      let result: any = await this.apiService.getDataPromis(apiURL, {}, {});

      if (result && typeof result == 'object') {
        races.forEach((race: any) => {
          if (result[race['ExternalRaceId']] != undefined) {
            race['raceStatus'] = result[race['ExternalRaceId']];
          }
        });
      }

      this.notifier.loading(false);
      resolve(true);
    });
  }

  selectLocation(event: any) {
    this.selected = event;
    this.selectedRace = null;
    this.races = [];
    this.loadRacesBasedOnEvent(
      event['external_event_id'],
      event['external_venue_id'],
      event['name']
    );
  }

  selectRace(event: any) {
    this.selectedRace = event;
    this.tasks = tasks.filter((task: Task) => {
      if (
        task.type === this.type &&
        task.state === this.state
      ) {
        if (task.conditions) {
          const conditions = task.conditions.filter((condition: any) => condition.state === this.state);
          if (conditions.length > 0) {
            const conditionMet = conditions.some((condition: any) => {
              const { key, equation, value } = condition.condition;
              const raceValue = this.selectedRace[key];
              switch (equation) {
                case 'equals':
                  return raceValue === value;
                case 'not equals':
                  return raceValue !== value;
                default:
                  return false;
              }
            });
            return conditionMet;
          }
        }
      };
      return task.state === 'ALL';
    });

    this.tasks.forEach((task: Task) => {
      this.checkButtonCondition(task);
    });
  }

  async checkButtonAPIStatus(button: any, errorHide: boolean = false) {
    return new Promise(async (resolve: any, reject: any) => {
      button.error = '';
      let payload: any = {
        action: 'action_status',
        event_id: this.selected['external_event_id'],
        button_action: button.value,
        race_number: this.selectedRace['SquentialRaceOrderNumber'],
        race_id: this.selectedRace['ExternalRaceId'],
        operator_name: this.selected['operator'] || this.username,
        pc_number: this.selected['pc'],
        venue_id: this.selected['external_venue_id'],
      };

      let apiURL: string = `${this.config[getENV()].raceAPI}/flask-operations`;

      let result: any = await this.apiService.postDataPromis(
        apiURL,
        payload,
        {}
      );

      if (result.status == '1' || result.s == '1') {
        if (result?.response?.StepCompletionStatus?.toUpperCase() == 'FAILED') {
          button.completed = false;
          button.error =
            result?.response?.Comments ||
            'Something went wrong. Please try again!';
          if (!errorHide && button.loading) {
            this.notifier.alert(
              'Info',
              '',
              result?.response?.Comments ||
              'Something went wrong. Please try again!',
              'info',
              5000
            );
          }
        } else if (
          result?.response?.StepCompletionStatus?.toUpperCase() == 'COMPLETED'
        ) {
          button.error = '';
          button.loading = false;
          button.completed = true;
        } else if (!result?.response?.StepCompletionStatus) {
          button.error = '';
          button.completed = false;
        }
        resolve(
          result?.response?.StepCompletionStatus?.toUpperCase() || 'PENDING'
        );
      } else {
        reject('FAILED');
      }
    });
  }

  async checkButtonCondition(button: any) {
    let event_id = this.selected['external_event_id'];
    let race_id = this.selectedRace['ExternalRaceId'];
    button.loading = true;
    let status = await this.checkButtonAPIStatus(button, true);
    if (status == 'PENDING') {
      button.loading = false;
    } else if (status == 'IN PROGRESS') {
      button.loading = true;
      setTimeout(() => {
        if (
          event_id == this.selected['external_event_id'] &&
          race_id == this.selectedRace['ExternalRaceId']
        ) {
          this.checkButtonCondition(button);
        } else {
          return;
        }
      }, 5000);
    } else if (status == 'COMPLETED') {
      button.loading = false;
      button.completed = true;
    } else {
      button.loading = false;
    }
  }

  async handleClicks(event: boolean = false, params: any) {
    // if (params.completed) {
    //   if (event) {
    //     this.notifier.alert(
    //       'Info',
    //       '',
    //       'Action is already completed!',
    //       'info',
    //       5000
    //     );
    //   }
    //   return;
    // }
    // if (!this.selected['pc']) {
    //   this.selectPC = true;
    //   this.selectedReport = params;
    //   return;
    // } else {
    //   this.selectPC = false;
    //   this.selectedReport = null;
    // }

    if (params.loading && event) {
      this.notifier.alert(
        'Info',
        '',
        'Action is already in progress!',
        'info',
        5000
      );
      return;
    }

    params.loading = true;
    let data: any = {
      action: params['value'],
      location: locationMappers[this.selected.venue_name] || this.selected.venue_name,
      race_number: this.selectedRace.SquentialRaceOrderNumber,
      race_id: this.selectedRace.ExternalRaceId,
      race_date: moment(this.date, 'DD MMMM YYYY').format('YYYY-MM-DD'),
      event_id: this.selected['external_event_id'],
      jurisdiction: this.state,
      type: this.type,
      operator_name: this.selected['operator'] || this.username,
      pc_number: this.selected['pc'],
      venue_id: this.selected['external_venue_id'],
    };

    let apiURL: string = `${this.config[getENV()].raceAPI}/flask-operations`;
    let result: any = await this.apiService.postDataPromis(apiURL, data, {});

    if (result.status == '1' || result.s == '1') {
      this.notifier.alert(
        'Success',
        '',
        result.msg || result.message || result.success,
        'success',
        5000
      );
      setTimeout(() => {
        this.checkButtonCondition(params);
      }, 5000);
    } else {
      this.notifier.alert(
        'Info',
        '',
        result.error_message || result.error || result.message,
        'info',
        5000
      );
    }

    this.notifier.loading(false);
  }

  async reviewPDF(button: any) {
    button.loading = true;
    let apiURL: string = `${this.config[getENV()].raceAPI}/flask-operations`;

    let payload: any = {
      "action": "review_files",
      "location": locationMappers[this.selected.venue_name] || this.selected.venue_name,
      "race_number": this.selectedRace['SquentialRaceOrderNumber'],
      race_date: this.selected['date'],
      type: this.selected['RaceType'],
    }

    let result: any = await this.apiService.postDataPromis(apiURL, payload, {});

    if (result.status === '1' || result.s === '1') {
      let a: HTMLAnchorElement = document.createElement('a');
      a.target = '_blank';
      a.download = result.presigned_url;
      a.href = result.presigned_url;
      a.click();
    } else {
      this.notifier.alert('Info', '', result.error_message, 'info', 5000);
    }
    button.loading = false;
  }

  destroyed: boolean = false;
  ngOnDestroy(): void {
    this.destroyed = true;
    this.routerSubscription.unsubscribe();
  }
}
