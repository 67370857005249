<div class="card">
  <div class="card-header flex-between">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a href="">Home</a></li>
      <li class="breadcrumb-item">
        <a href="/dash/operator-kit-management">Operator Kit Management</a>
      </li>
      <li class="breadcrumb-item active" aria-current="page">{{ kitId }}</li>
    </ol>
    <div class="flex-start">
      <button-status
        [filter]="filters['kit']"
        title="Tag"
        [multiple]="true"
        [items]="statuses"
      ></button-status>
      <button
        type="button"
        (click)="submitForm()"
        class="btn btn-primary-soft mr-0"
      >
        Submit Changes
      </button>
    </div>
  </div>
  <div class="card-body">
    <div class="overview-section">
      <div class="overview-field cursor-pointer" (click)="filters['kit']['selected'] = ['ALL']">
        <div class="lg-text">{{ overview["total"] }}</div>
        <div>Total</div>
      </div>
      <div class="overview-field cursor-pointer" (click)="filters['kit']['selected'] = ['In operation']">
        <div class="text-success lg-text">{{ overview["in_operation"] }}</div>
        <div class="text-success">In Operation</div>
      </div>
      <div class="overview-field cursor-pointer" (click)="filters['kit']['selected'] = ['Out of Operation']">
        <div class="text-danger lg-text">
          {{ overview["out_of_operation"] }}
        </div>
        <div class="text-danger">Out of Operation</div>
      </div>
    </div>
    <table class="table table-striped w-100">
      <thead>
        <tr>
          <th scope="col" class="text-center">Serial Number</th>
          <th scope="col">Tag ID</th>
          <th scope="col">Status</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="
            let kit of kitsData | KitFilter : filters['kit']['selected'];
            let i = index
          "
        >
          <td class="text-center serial-number">{{ kit.SerialNumber }}</td>
          <td>
            <input
              type="text"
              class="form-control"
              [(ngModel)]="kit.TagID"
              name="tagId-{{ i }}"
              readonly
              disabled
            />
          </td>
          <td>
            <select
              class="form-control"
              [(ngModel)]="kit.Status"
              name="status-{{ i }}"
              [ngStyle]="{
                'background-color': getSelectedOptionColor(kit.Status),
                'border-color': getSelectedOptionBorderColor(kit.Status),
                color: getTextColor(getSelectedOptionColor(kit.Status))
              }"
            >
              <option
                *ngFor="let status of objectKeys(labels)"
                [value]="labels[status]"
                [ngStyle]="{
                  'background-color': getColor(status),
                  'border-color': getBorderColor(status),
                  color: getTextColor(getColor(status))
                }"
              >
                {{ labels[status] }}
              </option>
            </select>
          </td>
        </tr>
        <tr
          *ngIf="
            kitsData.length > 0 &&
            (kitsData | KitFilter : filters['kit']['selected']).length == 0
          "
        >
          <td colspan="3" class="center">
            No tags found matching the applied filters. Please try a different
            filter.
          </td>
        </tr>
        <tr *ngIf="kitsData.length == 0">
          <td colspan="3" class="center">No tags found.</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<app-device-history
  [showModal]="showHistoryModal"
  [SerialNumber]="selectedKit"
  (modalClose)="closeHistoryModal()"
></app-device-history>
