import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment-timezone';
import { formatFileSize, getENV } from 'src/app/_helpers/helpers';
import { APIService } from 'src/app/services/api.service';
import { FileUploadService } from 'src/app/services/fileupload.service';
import { NotifierService } from 'src/app/services/notifier.service';

@Component({
  selector: 'app-manual-race-cards',
  templateUrl: './manual-race-cards.component.html',
  styleUrls: ['./manual-race-cards.component.css'],
})
export class ManualRaceCardsComponent {
  config: any;
  formatFileSize: any = formatFileSize;
  @Output('hideModel') hideModel: any = new EventEmitter<boolean>();

  constructor(
    private route: ActivatedRoute,
    private apiService: APIService,
    private fileUploadService: FileUploadService,
    private notifier: NotifierService
  ) {
    this.config = this.route.snapshot.data['config'];
  }
  meta: any = {};
  kits: string[] = [
    'RQLD Kit 1 (BRC)',
    'RQLD Kit 2',
    'RQLD Kit 3',
    'HRV Kit 2',
    'HRNSW Kit 2',
  ];
  steps = [
    {
      id: 1,
      title: 'Upload Excel File',
      description:
        'Select and upload your Excel file containing race card data.',
      fields: [
        {
          type: 'file',
          name: 'excelFile',
          label: 'Select Excel File',
          accept: '.xlsx, .xls',
          required: true,
        },
      ],
      actions: {
        left: [],
        right: [
          {
            type: 'button',
            label: 'Next',
            action: 'proceedToNextStep',
            icon: 'fas fa-arrow-right',
          },
        ],
      },
    },
    {
      id: 2,
      title: 'Add Event Details',
      description: 'Provide information about the event.',
      fields: [
        {
          type: 'select',
          name: 'venue',
          label: 'Select Venue',
          options: ['Venue 1', 'Venue 2', 'Venue 3'],
          required: true,
        },
        {
          type: 'date',
          name: 'eventDate',
          label: 'Date of Event',
          required: true,
        },
        {
          type: 'time',
          name: 'firstRaceTime',
          label: 'Start of First Race',
          required: true,
        },
        {
          type: 'select',
          name: 'track',
          label: 'Select Track',
          options: ['Track 1', 'Track 2', 'Track 3'],
          required: true,
        },
      ],
      actions: {
        left: [
          {
            type: 'button',
            label: 'Back',
            action: 'goToPreviousStep',
            icon: 'fas fa-arrow-left',
          },
        ],
        right: [
          {
            type: 'button',
            label: 'Create Event',
            action: 'uploadAndProceed',
            icon: 'fas fa-arrow-right',
          },
        ],
      },
    },
  ];
  currentStep: number = 0;

  venues: any = [
    {
      id: '301',
      name: 'Newcastle',
    },
    {
      id: '355',
      name: 'Seymour',
    },
    {
      id: '957',
      name: 'Gold Coast Poly Track',
    },
  ];

  get step() {
    return this.steps[this.currentStep];
  }

  handleAction(action: string) {
    if (action == 'goToPreviousStep') {
      this.currentStep--;
    } else if (action == 'proceedToNextStep') {
      this.currentStep++;
    } else if (action == 'uploadAndProceed') {
      this.handleFile();
    }
  }

  dragging: boolean = false;
  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.dragging = true;
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.dragging = false;
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.dragging = false;

    if (!event.dataTransfer.types.includes('Files')) {
      this.meta.file.fileError = 'Not a valid file';
      return;
    }

    this.validateFile(event.dataTransfer.files);
  }

  validateFile(files: FileList): boolean {
    this.meta.fileError = null;
    const allowed = '.xlsx';
    if (files && files.length == 0) {
      this.meta.fileError = 'Missing file';
      return false;
    } else if (files.length > 1) {
      this.meta.fileError = 'Multiple files are not allowed';
      return false;
    } else if (!files[0].name.toLowerCase().endsWith(allowed)) {
      this.meta.fileError = `Only ${allowed} file is allowed`;
      return false;
    }
    this.meta.fileError = '';
    this.meta.file = files[0];
    // this.meta.file.uploadStatus = 'COMPLETED';
    return true;
  }

  onFileSelected(event: Event) {
    const element = event.target as HTMLInputElement;
    const files = element.files;
    let status: boolean = this.validateFile(files);
    if (!status) {
      element.value = '';
    }
  }

  cancelPDF() {
    this.meta.uploadStatus = null;
    this.meta.file = null;
  }

  async handleFile() {
    // Process the file here (e.g., upload to server, parse XML, etc.)
    if (!this.meta.file) {
      this.notifier.alert('Info', '', 'FIle is missing', 'info', 5000);
      return;
    }
    this.meta.loading = true;
    this.meta.uploadStatus = 'GETTING_PRESIGNED_URL';
    let apiURL: string = `${this.config[getENV()].raceAPI}/breez`;

    let payload: any = {
      action: 'generate_presign_url',
      file_name: this.meta.file.name,
      method: 'post',
      event_date: moment(this.meta.eventDate, 'YYYY-MM-DD').format(
        'DD-MM-YYYY'
      ),
    };

    let result: any = await this.apiService.postDataPromis(apiURL, payload, {});

    if (result.status == '1' || result.s == '1') {
      this.meta.uploadStatus = 'PRESIGNED_URL_GENERATED';
      this.meta.presigned_url =
        result.url || result.response || result.presign_url;
      this.meta.object_file_name = result.file_name;
      this.uploadFile(this.meta.file, result);
    } else {
      this.meta.loading = false;
      this.currentStep = 0;
      this.meta.uploadStatus = null;
      this.meta.fileError =
        result.error_message || 'Failed to Upload File. Please try again';
    }
  }

  async uploadFile(file: File, result: any) {
    await this.uploadFileAPI(result, file);
  }

  async uploadFileAPI(result: any, file: any) {
    return new Promise(async (resolve: any, reject: any) => {
      this.fileUploadService
        .uploadFile(this.meta.presigned_url, file)
        .subscribe(async (event: HttpEvent<any>) => {
          switch (event.type) {
            case HttpEventType.Sent:
              break;
            case HttpEventType.ResponseHeader:
              break;
            case HttpEventType.UploadProgress:
              this.meta.uploadStatus = 'UPLOADING';
              let progress = Math.round(
                (event.loaded / (event.total || 0)) * 100
              );
              this.meta.uploaded = event.loaded;
              this.meta.percentage = progress;
              break;
            case HttpEventType.Response:
              if (event.status === 200) {
                this.meta.uploadStatus = 'COMPLETED';
                this.proceedFile();
                resolve(true);
              } else {
                this.meta.loading = false;
                this.meta.uploadStatus = 'ERROR';
                this.meta.fileError = `Upload failed with status ${event.status}`;
                this.currentStep = 0;
                reject(new Error(`Upload failed with status ${event.status}`));
              }
              break;
            case HttpEventType.User:
              break;
          }
        });
    });
  }

  async proceedFile() {
    let apiURL: string = `${this.config[getENV()].raceAPI}/breez`;
    let payload: any = {
      // event_name: this.meta.eventName,
      event_date: moment(this.meta.eventDate, 'YYYY-MM-DD').format(
        'DD-MM-YYYY'
      ),
      first_race_start_time: this.meta.firstRaceTime,
      venue_id: this.meta.venue.id,
      venue_name: this.meta.venue.name,
      action: 'process_file',
      file_name: this.meta.object_file_name,
      // kit: this.meta.kit
    };

    let result: any = await this.apiService.postDataPromis(apiURL, payload, {});

    if (result.status == '1' || result.s == '1') {
      this.meta.completed = true;
      this.meta.failed = false;
      this.notifier.alert(
        'Success',
        '',
        'Event Created Successfully',
        'success',
        5000
      );
      this.hideModel.emit(true);
    } else {
      this.meta.error =
        result.error_message || 'Failed to Injest Data. Please try again';
      this.meta.failed = true;
      this.meta.completed = false;
      this.notifier.alert('Info', '', this.meta.error, 'info', 5000);
    }

    this.meta.loading = false;
  }

  removeTrack(index: number) {
    this.meta.tracks.splice(index, 1);
  }

  addTrack() {
    if (!this.meta.tracks) {
      this.meta.tracks = [];
    }
    this.meta.tracks.push({
      name: this.meta.track,
    });
    this.meta.track = '';
  }
}
