<div id="popup" class="popup">
  <div class="popup-container">
    <div class="popup-header flex-between">
      <span class="color-inherit">
        {{ title }}
      </span>
      <span
        class="cursor-pointer color-inherit"
        (click)="hideModel.emit(meta.notes)"
      >
        <i class="fa fa-times color-inherit"></i>
      </span>
    </div>
    <div class="popup-content">
      <div class="popup-body">
        <ng-container *ngIf="!edit">
          <div>
            <div class="flex-end">
              <span
                class="nav-link flex-start font-size-0_8"
                (click)="editNotesToggle()"
              >
                <i class="fa fa-pencil"></i>
                Edit Notes
              </span>
            </div>
            <div class="wrap notes-container">
              {{ meta.notes || "No notes available" }}
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="edit">
          <textarea
            class="form-control"
            rows="3"
            [(ngModel)]="meta.notes_edit"
            [class.error]="meta.notes_edit.length > limits[meta.action]"
            [maxlength]="limits[meta.action]"
          ></textarea>
          <div class="flex-end light-text">
            {{ meta.notes_edit.length }} characters ({{
              limits[meta.action] - meta.notes_edit.length
            }}
            remaining)
          </div>
        </ng-container>
      </div>
      <div class="popup-buttons flex-center">
        <ng-container *ngIf="!edit">
          <button class="btn btn-light" (click)="hideModel.emit(meta.notes)">
            Close
          </button>
        </ng-container>
        <ng-container *ngIf="edit">
          <button class="btn btn-primary-soft" (click)="saveNotes()">
            Save Notes
          </button>
          <button class="btn btn-light" (click)="edit = false">Cancel`</button>
        </ng-container>
      </div>
    </div>
  </div>
</div>
