<div class="grid-4">
  <div
    class="location-container flex-between-column"
    [ngClass]="{
      active:
        location.id == selected?.id ||
        (multiple && selected.includes(location.id)),
      disabled: location.show == false
    }"
    (click)="selectedLocation.emit(location)"
    *ngFor="let location of locations"
  >
    <div class="flex-between">
      <span>
        {{ location.name }}
      </span>
      <span *ngIf="type == 'event' && location.operator" class="flex-start">
        <i class="fa fa-user-circle" aria-hidden="true"></i>
        {{ location.operator }}
      </span>
    </div>
    <div *ngIf="type == 'race'">
      <div class="flex-start">
        <span
          class="status-text-container alert inline-flex-start"
          [ngClass]="{
            'alert-success': location.RaceState == 'FINISHED' || location.RaceState == 'PUBLISHED',
            'alert-primary': location.RaceState == 'RUNNING',
            'alert-dark':
              location.RaceState == 'PLANNED' ||
              location.RaceState == 'OFFICIAL',
            'alert-danger':
              location.RaceState == 'INCOMPLETED' ||
              location.RaceState == 'CANCELLED' ||
              location.RaceState == 'ABANDONED'
          }"
        >
          <i class="fa fa-circle color-inherit" aria-hidden="true"></i>
          <span class="color-inherit">{{ location["RaceState"] || "-" }}</span>
        </span>
      </div>
    </div>
  </div>
</div>
