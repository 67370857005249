import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';

import { NotifierService } from 'src/app/services/notifier.service';
import { tokenExpired, APIService } from 'src/app/services/api.service';

import { getENV } from 'src/app/_helpers/helpers';

@Component({
  selector: 'forgot-password',
  templateUrl: './forgot.component.html',
  styleUrls: [
    './../register/register.component.css',
    './../login/login.component.css',
  ],
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  tokenExpired: any = tokenExpired;
  @Output() hideModel = new EventEmitter<boolean>();
  otpRequired: boolean = false;

  loading: any = {
    forgot: false,
    otp: false,
  };
  validations: any = {
    username: {
      policy: {
        check: {
          key: 'otpSent',
          value: false,
        },
        required: true,
        regex: (text: string) => {
          let regexFormat: RegExp =
            /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;
          return regexFormat.test(text);
        },
      },
      name: 'Email ID',
    },
    otp: {
      policy: {
        required: true,
        check: {
          key: 'otpSent',
          value: true,
        },
      },
      name: 'OTP',
    },
    newPassword: {
      policy: {
        required: true,
        check: {
          key: 'otpSent',
          value: true,
        },
      },
      name: 'New Password',
    },
    newConfirmPassword: {
      policy: {
        required: true,
        check: {
          key: 'otpSent',
          value: true,
        },
      },
      name: 'Confirm New Password',
    },
  };
  errors: any = {
    username: {
      error: false,
      message: '',
    },
    otp: {
      error: false,
      message: '',
    },
    newPassword: {
      error: false,
      message: '',
    },
    newConfirmPassword: {
      error: false,
      message: '',
    },
  };
  showPassword: boolean = false;
  passwordInterval: any = null;
  validating: boolean = false;

  config: any;

  validate(): boolean {
    let ref: any = this;
    let check: boolean = true;
    Object.keys(this.validations).forEach((key: string) => {
      this.errors[key]['error'] = false;
      if (
        (this.validations[key]['policy']['check'] &&
          ref[this.validations[key]['policy']['check']['key']] ==
          this.validations[key]['policy']['check']['value']) ||
        !this.validations[key]['policy']['check']
      ) {
        if (
          this.validations[key]['policy']['required'] &&
          (!ref[key].trim() || ref[key].trim() == '')
        ) {
          this.errors[key]['error'] = true;
          this.errors[key][
            'message'
          ] = `${this.validations[key]['name']} is a mandatory field`;
          check = false;
        } else if (
          this.validations[key]['policy']['regex'] &&
          this.validations[key]['policy']['regex'](ref[key]) === false
        ) {
          this.errors[key]['error'] = true;
          this.errors[key][
            'message'
          ] = `Entered ${this.validations[key]['name']} is not valid`;
          check = false;
        } else {
          this.errors[key]['error'] = false;
        }
      } else {
        this.errors[key]['error'] = false;
      }
    });

    return check;
  }

  alterPassword() {
    this.showPassword = !this.showPassword;
    if (this.showPassword) {
      this.passwordInterval = setTimeout(() => {
        this.showPassword = false;
        clearInterval(this.passwordInterval);
        this.passwordInterval = null;
      }, 5000);
    } else if (this.passwordInterval) {
      clearInterval(this.passwordInterval);
      this.passwordInterval = null;
    }
  }

  public userNameForm = new UntypedFormGroup({
    username: new UntypedFormControl('addet0260@gmail.com', []),
  });

  public newPasswordForm = new UntypedFormGroup({
    otp: new UntypedFormControl('1234', []),
    new_password: new UntypedFormControl('1234', []),
    confirm_new_password: new UntypedFormControl('1234', []),
  });

  get username_form() {
    return this.userNameForm.controls;
  }

  get new_password_form() {
    return this.newPasswordForm.controls;
  }

  otpSent: boolean = false;

  username: string = '';
  otp: string = '';
  newPassword: string = '';
  newConfirmPassword: string = '';
  role: string = 'Client';
  hideRoleSelection: any = false;
  CBSPortal: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiServer: APIService,
    private notifier: NotifierService
  ) {
    this.config = this.route.snapshot.data['config'];
  }

  ngOnInit(): void {
    this.tokenExpired['checking'] = false;

    if (window.location.href.indexOf('dev.swayam.ai') != -1 || this.CBSPortal) {
      this.role = 'Admin';
      this.hideRoleSelection = true;
    } else {
      this.role = 'Client';
      this.hideRoleSelection = true;
    }
  }

  passwordChecker(password: string) {
    let passwordObj: any = {
      strength: 0,
      policies: {
        'Must have atleast 8 characters': password.length > 7,
        'Must have atleast 1 uppercase letter, 1 lowercase letter, and 1 number':
          false,
        'Must have atleast 1 Special Character': false,
        'Longer Password': false,
      },
    };
    if (passwordObj.policies['Must have atleast 8 characters']) {
      passwordObj.strength += 1;
    }
    if (
      password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{0,}$/gm)
    ) {
      passwordObj.strength += 1;
      passwordObj.policies[
        'Must have atleast 1 uppercase letter, 1 lowercase letter, and 1 number'
      ] = true;
    }
    if (
      password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{10,}$/gm)
    ) {
      passwordObj.strength += 1;
      passwordObj.policies['Longer Password'] = true;
    }
    if (password.match(/[^A-z\s\d][\\\^]?/gm)) {
      passwordObj.strength += 1;
      passwordObj.policies['Must have atleast 1 Special Character'] = true;
    }
    return passwordObj;
  }

  resend: string = '';
  async sendOTP() {
    if (this.tokenExpired['checking']) {
      return false;
    }
    if (!this.otpSent && !this.validate()) {
      return false;
    }
    this.loading['forgot'] = true;
    let data = { a: 'forgotpassword', email: this.username };
    let apiURL = `${this.config[getENV()].apiURL}/${this.role == 'Admin' ? 'admin' : 'client'
      }/globals/user`;

    let result = await this.apiServer.postDataPromis(apiURL, data, {});

    if (result.status == '1' || result.s == '1') {
      this.resend = result.otpMail || 'no-reply@swayam.cloud';
      this.otpSent = true;
      // this.notifier.alert("Success", "", result.msg, "success", 5000);
    } else {
      if (result.error.toLowerCase().includes('email')) {
        this.errors['username'] = {
          error: true,
          message: result.error,
        };
      } else {
        this.notifier.alert(
          'Info',
          '',
          result.error || result.msg,
          'info',
          5000
        );
      }
    }
    this.loading['forgot'] = false;

    return true;
  }

  getKeys(obj: any): any {
    return Object.keys(obj);
  }

  async resetPassword() {
    if (this.tokenExpired['checking']) {
      return false;
    }
    if (!this.validate()) {
      return false;
    }
    if (this.passwordChecker(this.newPassword)['strength'] < 3) {
      this.errors['newPassword'] = {
        error: true,
        message: "Entered Password doesn't meet the minimum password policy",
      };
      return false;
    } else {
      this.errors['newPassword'] = {
        error: false,
        message: '',
      };
    }
    if (this.newPassword != this.newConfirmPassword) {
      this.errors['newConfirmPassword'] = {
        error: true,
        message: 'Passwords do not match',
      };
      // this.notifier.alert("Error", "", "Passwords do not match", "error", 5000);
      return false;
    } else {
      this.errors['newConfirmPassword'] = {
        error: false,
        message: '',
      };
    }
    this.loading['otp'] = true;
    let data = {
      a: 'validateForgotPassword',
      email: this.username,
      otp: this.otp.toString(),
      password: this.newPassword,
    };
    let apiURL = `${this.config[getENV()].apiURL}/${this.role == 'Admin' ? 'admin' : 'client'
      }/globals/user`;

    let result = await this.apiServer.postDataPromis(apiURL, data, {});

    if (result.status == '1' || result.s == '1') {
      this.router.navigate(['/auth/login'], { queryParams: { pass: true } });
    } else {
      if (result.error.toLowerCase().includes('otp')) {
        this.errors['otp'] = {
          error: true,
          message: result.error,
        };
      } else if (result.error.toLowerCase().includes('password')) {
        this.errors['newPassword'] = {
          error: true,
          message: result.error,
        };
      } else {
        this.notifier.alert('Info', '', result.error, 'info', 5000);
      }
    }
    this.loading['forgot'] = false;
    this.loading['otp'] = false;
    return true;
  }

  ngOnDestroy() { }
}
