import * as moment from 'moment-timezone';
import { DashService } from '../modules/globals/dash/dash.service';
import { timer } from './globals';

declare let window: any;
export let uuid = window.uuidv4();

export class Acticity {
  timerCount: number = 7200000;
  constructor(private dashService: DashService) {
    uuid = window.uuidv4();
  }

  bc = new BroadcastChannel('triplesdata');
  setLastActivityTime(message: boolean = true) {
    if (message) {
      this.bc.postMessage(JSON.stringify({ id: uuid, message: 'RESET_TIMER' }));
    }
    localStorage.setItem('lastActivityTime', moment().valueOf().toString());
    this.startTimer();
  }

  startTimer() {
    if (timer['timer'] != null) {
      clearTimeout(timer['timer']);
      timer['timer'] = null;
    }
    if (localStorage.getItem('t') == null) {
      return;
    }
    timer['timer'] = setTimeout(() => {
      this.dashService.sendMessage('SHOW_INACTIVITY_MODAL');
      clearTimeout(timer['timer']);
      timer['timer'] = null;
    }, this.timerCount);
  }
}
