import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { RaceApiService } from '../../../../../../../services/race-api.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { MqttService } from '../../../../../../../services/mqtt.service';
import { NotifierService } from 'src/app/services/notifier.service';
import { ActivatedRoute, Params } from '@angular/router';
import { Router } from '@angular/router';
import { APIService } from 'src/app/services/api.service';

import { getENV } from 'src/app/_helpers/helpers';

@Component({
  selector: 'app-race-status-report',
  templateUrl: './race-status.component.html',
  styleUrls: ['./race-status.component.css']
})
export class RaceStatusComponent implements OnInit, OnDestroy {
  private stopFetching = false;
  currentTimeStamp: Date;
  completedRaceData: any = [];
  currentDate: string;
  inputDate: Date;
  report: any;
  all_report: any;
  races: any;
  unmappedOperators: any;
  filterForm: FormGroup;
  selectedLocation: string = '';
  dropdownList: { item_id: number; item_text: string; }[] = [];
  selectedItems: { item_id: number; item_text: string; }[] = [];
  dropdownSettings: IDropdownSettings = {};
  mqtt_heartbeat: { [key: string]: { pc_number: string; state: string; } } = {};
  advance_live_data: { [key: string]: { pc_number: string; state: string; } } = {};
  mqtt_heartbeat_topic = 'EQTraCe/HeartBeat';
  advance_live_data_topic = 'TRACKING/AUS/+/+/+/AdvanceLiveData';
  config: any;
  constructor(
    private http: HttpClient,
    private race_api: RaceApiService,
    private fb: FormBuilder,
    private mqttService: MqttService,
    private notifier: NotifierService,
    private router: Router,
    private route: ActivatedRoute,
    private apiService: APIService
  ) {
    this.config = this.route.snapshot.data['config'];
    this.currentTimeStamp = this.race_api.getTimeStamp();
    this.inputDate = this.race_api.getTimeStamp();
    this.currentDate = this.race_api.getTodayDate();
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.filterForm = this.fb.group({
      locationFilter: []
    });
  }

  async ngOnInit() {
    await this.fetchReportData();
    this.getUniqueLocations();
    this.selectedItems = this.dropdownList;
    this.connect();
  }

  async setDate(inputDate: Date) {
    this.currentDate = this.race_api.getStringFromDate(inputDate);
    this.notifier.loading(true);
    await this.fetchReportData();
    this.getUniqueLocations();
    this.selectedItems = this.dropdownList;
    this.notifier.loading(false);
  }

  ngOnDestroy() {
    this.stopFetching = true;
    this.mqttService.unsubscribe(this.advance_live_data_topic);
    this.mqttService.unsubscribe(this.mqtt_heartbeat_topic);
  }

  async fetchReportData(): Promise<void> {
    let apiURL: string = `${this.config[getENV()].raceAPI}/report-status-date?target_date=${this.currentDate}`;

    try {
      // Using await to fetch data asynchronously
      const response: any = await this.apiService.getDataPromis(apiURL, {}, {});

      // Process the response
      if (Object.keys(this.mqtt_heartbeat).length === 0 && response.report !== undefined) {
        for (const race of response.report) {
          this.mqtt_heartbeat[race.ExternalRaceID] = {
            pc_number: 'No PC',
            state: 'red-circle',
          };
        }
      }

      if (Object.keys(this.advance_live_data).length === 0 && response.report !== undefined) {
        for (const race of response.report) {
          const current_state = 'red';
          this.advance_live_data[race.ExternalRaceID] = {
            pc_number: 'No PC',
            state: current_state,
          };
        }
      }

      this.all_report = response.report;
      this.report = response.report;
    } catch (error) {
      console.error('Error:', error);
      throw error; // Rethrow the error to reject the Promise
    }
  }


  isObjectEmpty(obj: object): boolean {
    return Object.keys(obj).length === 0;
  }

  getFirstKey(obj: any): string {
    return obj ? Object.keys(obj)[0] : '';
  }

  getRaceStateStyle(raceState: any): any {
    let backgroundColor = '';

    if (raceState === 'FINISHED') {
      backgroundColor = '#28a745';
    } else if (raceState === 'DNS') {
      backgroundColor = '#ffc107';
    } else if (raceState === 'Planned') {
      backgroundColor = '#007bff';
    } else if (raceState === 'PLANNED') {
        backgroundColor = '#007bff';
    } else if (raceState === 'OFFICIAL') {
          backgroundColor = '#ff7f50';
    } else {
      backgroundColor = '#999999';
    }

    return { 'background-color': backgroundColor, 'color': 'white' };
  }

  updateLocationFilter() {
    this.selectedLocation = this.filterForm.value.locationFilter;
    if (this.selectedLocation === 'All') {
      this.report = this.all_report;
    } else {
      this.report = this.all_report.filter((race: any) => race.VenueName === this.selectedLocation);
    }
  }

  filterReport() {
    const itemTextArray: string[] = this.selectedItems.map(item => item.item_text);
    this.report = this.all_report.filter((race: any) => itemTextArray.includes(race.VenueName));
  }

  getUniqueLocations() {
    if (!this.all_report) {
      this.dropdownList = [];
    }

    const locations = this.all_report.map((race: any) => race.VenueName as string);
    const locations_array = [...new Set<string>(locations)];
    this.dropdownList = [];
    locations_array.forEach((location, index) => {
      this.dropdownList.push({
        item_id: index + 1,
        item_text: location,
      });
    });
  }

  onItemSelect(item: any) {
    this.filterReport();
  }

  OnItemDeSelect(item: any) {
    this.filterReport();
  }

  onSelectAll(items: any) {
    const itemTextArray: string[] = items.map((item: any) => item.item_text);
    this.report = this.all_report.filter((race: any) => itemTextArray.includes(race.VenueName));
  }

  onDeSelectAll(items: any) {
    const itemTextArray: string[] = items.map((item: any) => item.item_text);
    this.report = this.all_report.filter((race: any) => itemTextArray.includes(race.VenueName));
  }

  connect(): void {
    this.mqttService.subscribe(this.mqtt_heartbeat_topic);
    this.mqttService.subscribe(this.advance_live_data_topic);
    this.mqttService.client.on('message', (topic: string, payload: Buffer) => {
      const message = JSON.parse(payload.toString());
      if (topic.includes('AdvanceLiveData')) {
        // Handle advance live data messages
        this.handleAdvanceLiveDataMessage(topic, message);
      } else {
        // Handle heartbeat messages
        this.handleHeartbeatMessage(message);
      }
    });
  }

  private handleHeartbeatMessage(message: any): void {
    Object.keys(message).forEach(pcNumber => {
      if (pcNumber !== 'Timestamp' && pcNumber.includes('PC')) {
        const raceId = message[pcNumber].ExternalRaceId;
        const state = message[pcNumber].State;
        const eventId = message[pcNumber].ExternalEventId;

        let color_state = 'red-circle';
        if (state === 1) {
          color_state = 'blue-circle'
        } else if (state === 2) {
          color_state = 'yellow-circle'
        } else if (state === 3) {
          color_state = 'orange-circle'
        } else if (state === 4) {
          color_state = 'green-circle'
        } else if (state === 5) {
          color_state = 'checkered-flag'
        } else {
          color_state = 'white-circle'
        }

        for (const race of this.report) {
          if (eventId === race.ExternalEventID) {
            this.mqtt_heartbeat[race.ExternalRaceID]['pc_number'] = pcNumber;
          }
          if (raceId !== race.ExternalEventID) {
            this.mqtt_heartbeat[race.ExternalRaceID]['state'] = 'white-circle';
          }
        }
        if (this.mqtt_heartbeat[raceId] === undefined) {
          return;
        }
        this.mqtt_heartbeat[raceId]['state'] = color_state;
      }
    });
  }

  private handleAdvanceLiveDataMessage(topic: string, message: any): void {
    const race_id = this.extractRaceId(topic);
    if (!this.advance_live_data[race_id]) {
      this.advance_live_data[race_id] = { pc_number: 'No PC', state: 'red' };
    }
    this.advance_live_data[race_id].state = 'green';
  }

  private extractRaceId(path: string): string {
    const parts = path.split('/');
    const raceIdIndex = parts.indexOf('AdvanceLiveData') - 1;
    return raceIdIndex >= 0 && raceIdIndex < parts.length ? parts[raceIdIndex] : '';
  }
}
