<div class="card mt-3">
    <div class="card-header d-flex justify-content-between align-items-center">
      <h2>Kits</h2>
      <div>
        <button type="button" class="btn btn-success" (click)="openAddKit()">Add Kit</button>
        &nbsp;&nbsp;
        <a routerLink="/dash/devices" class="btn btn-primary">All Devices</a>
      </div>
    </div>

    <div class="card-body">
      <table class="table table-striped">
        <thead>
          <tr>
            <th colspan="4">Thoroughbred Kits</th>
          </tr>
          <tr>
            <th>Kit ID</th>
            <th>Venue State</th>
            <th>Code Type</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let kit of kits | filterRaceType: 'GALLOPS'">
            <td>
              <a [routerLink]="['/dash/kit-details-management', kit.CodeType, kit.VenueState, customEncodeURIComponent(kit.KitID)]">
                {{ kit.KitID }}
              </a>
            </td>
            <td class="uniform-width">{{ kit.VenueState }}</td>
            <td class="uniform-width">{{ kit.CodeType }}</td>
            <td class="uniform-width">
              <button type="button" class="btn btn-primary btn-sm" (click)="openDeviceDetails(kit)">Update Kit</button>
            </td>
          </tr>
        </tbody>
      </table>

      <table class="table table-striped">
        <thead>
          <tr>
            <th colspan="4">Harness Kits</th>
          </tr>
          <tr>
            <th>Kit ID</th>
            <th>Venue State</th>
            <th>Code Type</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let kit of kits | filterRaceType: 'HARNESS'">
            <td>
              <a [routerLink]="['/dash/kit-details-management', kit.CodeType, kit.VenueState, customEncodeURIComponent(kit.KitID)]">
                {{ kit.KitID }}
              </a>
            </td>
            <td class="uniform-width">{{ kit.VenueState }}</td>
            <td class="uniform-width">{{ kit.CodeType }}</td>
            <td class="uniform-width">
              <button type="button" class="btn btn-primary btn-sm" (click)="openDeviceDetails(kit)">Update Device</button>
            </td>
          </tr>
        </tbody>
      </table>
      <table class="table table-striped">
        <thead>
          <tr>
            <th colspan="4">Greyhounds Kits</th>
          </tr>
          <tr>
            <th>Kit ID</th>
            <th>Venue State</th>
            <th>Code Type</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let kit of kits | filterRaceType: 'GREYHOUNDS'">
            <td>
              <a [routerLink]="['/dash/kit-details-management', kit.CodeType, kit.VenueState, customEncodeURIComponent(kit.KitID)]">
                {{ kit.KitID }}
              </a>
            </td>
            <td class="uniform-width">{{ kit.VenueState }}</td>
            <td class="uniform-width">{{ kit.CodeType }}</td>
            <td class="uniform-width">
              <button type="button" class="btn btn-primary btn-sm" (click)="openDeviceDetails(kit)">Update Device</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <!-- Modal Component for Add/Edit Kit Details -->
  <app-device-form-modal [selectedDevice]="selectedDevice" [showModal]="showModal" (modalClose)="closeModal()"></app-device-form-modal>
