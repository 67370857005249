import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-horsestatus',
  templateUrl: './horsestatus.component.html',
  styleUrls: ['./horsestatus.component.css'],
})
export class HorsestatusComponent implements OnChanges {
  @Input('data') data: any;

  ngOnChanges(changes: SimpleChanges): void {
      
  }
}
