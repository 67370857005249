import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { getENV, getRaceType } from 'src/app/_helpers/helpers';
import { locationMappers } from 'src/app/modules/globals/dash/modules/pages/components/post-race/harness-reports/components/race-reports/locations';
import { APIService } from 'src/app/services/api.service';
import { NotifierService } from 'src/app/services/notifier.service';

@Component({
  selector: 'app-reprotsdownload',
  templateUrl: './reprotsdownload.component.html',
  styleUrls: ['./reprotsdownload.component.css'],
})
export class ReprotsdownloadComponent implements OnChanges, OnInit {
  @Input('data') data: any;
  ngOnChanges() {}

  ngOnInit(): void {
    (this.data.ReportStatus || []).forEach((report: any) => {
      if (
        (report.EPCSWSID.includes('generate_pdf') ||
          report.StepType == 'generate_pdf') &&
        report.StepCompletionStatus == 'Completed'
      ) {
        this.data.download = true;
      }
    });
  }

  config: any;
  constructor(
    private activeRoute: ActivatedRoute,
    private apiService: APIService,
    private notifier: NotifierService
  ) {
    this.config = this.activeRoute.snapshot.data['config'];
  }

  async reviewPDF(event: any, type: string = '.pdf') {
    event.stopPropagation();
    let apiURL: string = `${this.config[getENV()].raceAPI}/flask-operations`;

    let eventDate = moment(this.data.eventDate, 'DD MMMM YYYY').format(
      'YYYY-MM-DD'
    );

    let payload: any = {
      action: 'review_files',
      location: locationMappers[this.data.eventName] || this.data.eventName,
      race_number: this.data['SquentialRaceOrderNumber'],
      race_date: eventDate,
      type: getRaceType(this.data['ExternalVenueId'])['raceTypeStr'],
      event_id: this.data['ExternalEventId'],
      race_id: this.data['ExternalRaceId'],
      file_extension: type,
    };

    let result: any = await this.apiService.postDataPromis(apiURL, payload, {});

    if (result.status === '1' || result.s === '1') {
      let a: HTMLAnchorElement = document.createElement('a');
      a.target = '_blank';
      a.download = result.presigned_url;
      a.href = result.presigned_url;
      a.click();
    } else {
      this.notifier.alert('Info', '', result.error_message, 'info', 5000);
    }
  }
}
