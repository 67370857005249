import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  ActivatedRoute,
  CanDeactivate,
} from '@angular/router';
import { Observable } from 'rxjs';
import { VenueDetailsComponent } from '../modules/globals/dash/modules/pages/components/venve-details/venve-details.component';

declare let window: any;

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private route: ActivatedRoute) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    //const currentUser = this.authenticationService.currentUserValue;

    //this.route.queryParams
    //  .subscribe(params => {
    //    console.log(params);
    //  else {

    //    }
    //  }
    //);

    //let queryString:any = {};
    //location.search.substr(1).split("&").forEach(function (pair) {
    //  if (pair === "") return;
    //  var parts = pair.split("=");
    //  queryString[parts[0]] = parts[1] &&
    //    decodeURIComponent(parts[1].replace(/\+/g, " "));
    //});

    //console.log("queryString", queryString);
    //if (queryString.t) {
    //  localStorage.setItem('t', queryString.t);
    //  localStorage.setItem('t', queryString.t);
    //  localStorage.setItem('t', queryString.uid);

    //  localStorage.setItem('accountId', queryString.accountId);
    //  localStorage.setItem('region', queryString.region);

    //}
    const token: string | null = localStorage.getItem('t');
    if (!token) {
      this.router.navigate(['/auth/login']);
      return false;
    } else {
      return true;
    }
    const currentUser = localStorage.getItem('t');
    if (currentUser) {
      // logged in so return true
    }

    if (localStorage.getItem('accountStatus') != 'Inactive') {
      // not logged in so redirect to login page with the return url
      if (!window.location.pathname.includes('login')) {
        let redirect: string =
          window.location.pathname + window.location.search;
        window.location.href = `/auth/login?redirectURL=${redirect}`;
      }

      return false;
    } else {
      return true;
    }
  }

  qs(key: string) {
    key = key.replace(/[*+?^$.\[\]{}()|\\\/]/g, '\\$&'); // escape RegEx meta chars
    var match = location.search.match(
      new RegExp('[?&]' + key + '=([^&]+)(&|$)')
    );
    return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
  }
}

export interface CanDeactivateComponent {
  canDeactivate: () => Observable<boolean> | boolean;
}

@Injectable({
  providedIn: 'root',
})
export class PreventUnsavedChangesGuard
  implements CanDeactivate<VenueDetailsComponent>
{
  canDeactivate(component: VenueDetailsComponent): boolean {
    if (component.hasUnsavedChanges) {
      return confirm(
        'You have unsaved changes. Are you sure you want to leave?'
      );
    }
    return true;
  }
}

@Injectable()
export class canDeactivateGuard
  implements CanDeactivate<CanDeactivateComponent>
{
  canDeactivate(component: CanDeactivateComponent) {
    return component.canDeactivate ? component.canDeactivate() : true;
  }
}
