<div id="popup" class="popup">
  <div class="popup-container">
    <div class="popup-header">Email Sending Cooldown</div>
    <div class="popup-content">
      <div class="popup-body">
        <div class="alert alert-warning center fw-bold" role="alert">
          To prevent excessive emails, there's a 5-minute cooldown period for
          each race after sending.<br />
          Please wait before sending another email to the same race.
        </div>
        <div class="center">
          Are you sure you want to send another email to this race? It hasn't
          been 5 minutes since your last email was sent
        </div>
      </div>
      <div class="popup-buttons flex-center">
        <button
          id="continueBtn"
          class="btn btn-primary-soft flex-start"
          (click)="hideModel.emit(true)"
        >
          Send Mail
        </button>
        <button
          id="logoutBtn"
          class="btn btn-light"
          (click)="hideModel.emit(false)"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>
