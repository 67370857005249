import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ActivatedRoute, Params } from '@angular/router';
import { APIService } from 'src/app/services/api.service';

import { NotifierService } from 'src/app/services/notifier.service';
import { getENV } from 'src/app/_helpers/helpers';

@Component({
  selector: 'app-device-form-modal',
  templateUrl: './device-form-modal.component.html',
  styleUrls: ['./device-form-modal.component.css']
})
export class DeviceFormModalComponent implements OnChanges {
  @Input() selectedDevice: any;
  @Input() showModal: boolean = false;
  @Output() modalClose = new EventEmitter<void>(); // Used to notify when the modal is closed
  form: { [key: string]: any } = {};
  kitDetails: any; // Variable to hold kit details
  isEditMode: boolean = false;

  config: any;
  error = '';
  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private apiService: APIService,
    private notifier: NotifierService
  ) {
    this.config = this.route.snapshot.data['config'];
  }
  loading: boolean = false;
  ngOnChanges(): void {
    if (this.showModal) {
      if (this.selectedDevice) {
        this.isEditMode = true
        this.form = {...this.selectedDevice}
        console.log(this.form)
      } else {
        this.form = {'id':null};
        this.isEditMode = false
      }


    }
  }

  toggleModal(): void {
    this.showModal = !this.showModal; // Toggle the internal flag
    if (!this.showModal) {
      this.modalClose.emit(); // Emit an event if the modal is being closed
    }
  }

  async submitForm() {
    console.log(this.form);

    const headers = new HttpHeaders()
      .set('accept', 'application/json')

    const httpOptions = {
      headers: headers
    };
    this.loading = true;
    //const apiUrl = 'https://4otldh9um5.execute-api.ap-southeast-2.amazonaws.com/tsd-prd-api/devices/kit_ids';
    const apiUrl: string = `${this.config[getENV()].raceAPI}/devices/kit_ids`;
    let filteredForm = { ...this.form };
    delete filteredForm['code_type'];
    let result = await this.apiService.postDataPromis(apiUrl, filteredForm).then((data) => {
      alert("Submitted Data!");
      this.loading = false;
      this.toggleModal();
    },
    (error) => {
      console.log(error);
      alert(error);
      this.loading = false;
    });
    console.log(result);
    // this.http.post(apiUrl, filteredForm, httpOptions)
    //   .subscribe(
    //     (data) => {
    //       alert("Submitted Data!");
    //       this.loading = false;
    //       this.toggleModal();
    //       // window.location.reload();
    //     },
    //     (error) => {
    //       console.log(error)
    //       // Handle any errors that occurred during the HTTP request
    //       alert(error);
    //       this.loading = false;
    //     }
    //   );
  }
}
