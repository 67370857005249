<button class="btn btn-light filter-button" #parentcontainer (click)="toggleDropdown($event)">
  <div class="flex-start">
    <i class="fa fa-filter filter-icon"></i>
    <span class="color-inherit">
      <span class="font-size-0-8rem">{{ title }} </span>
      <span class="fw-bold">{{ getSelected || 'NA' }}</span>
    </span>
  </div>
  <div class="absolute-floating" [class.active]="isDropdownOpen" (click)="stopPropogation($event)">
    <div>
      <input type="text" placeholder="Search..." class="form-control" [(ngModel)]="filterText">
    </div>
    <div>
      <div class="button-text flex-start" (click)="filter['selected'] = ['ALL']">
        <i class="fa fa-check-square" aria-hidden="true" [class.selected]="filter['selected'] == 'ALL' || (data | filter : filterText).length == filter['selected'].length"></i>
        <span class="white-space-none">All</span>
      </div>
      <ng-container *ngIf="(data | filter:filterText).length > 0; else nodata">
        <div class="button-text flex-start" *ngFor="let item of data | filter:filterText" (click)="updateSelected(item['name'])">
          <i class="fa fa-check-square" aria-hidden="true" [class.selected]="isSelected(item['name'])"></i>
          <span class="white-space-none">{{ item['name'] }}</span>
        </div>
      </ng-container>
      <ng-template #nodata>No Data Found</ng-template>
    </div>
  </div>
</button>