import { Pipe, PipeTransform } from '@angular/core';
import { race } from 'rxjs';

@Pipe({
  name: 'filterRaceType'
})
export class FilterRaceTypePipe implements PipeTransform {

  transform(races: any[], type: string): any[] {
    return races.filter(race => race.code_type === type);
  }

}
