import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment-timezone';
import { formatFileSize, getENV } from 'src/app/_helpers/helpers';
import { APIService } from 'src/app/services/api.service';
import { FileUploadService } from 'src/app/services/fileupload.service';
import { NotifierService } from 'src/app/services/notifier.service';
import * as pako from 'pako';
import VenueEmails from '../services/emails';

@Component({
  selector: 'app-manual-race-cards',
  templateUrl: './manual-race-cards.component.html',
  styleUrls: ['./manual-race-cards.component.css'],
})
export class ManualRaceCardsComponent implements OnInit {
  config: any;
  formatFileSize: any = formatFileSize;
  selectedLocation: any = null;
  @Output('hideModel') hideModel: any = new EventEmitter<boolean>();

  constructor(
    private route: ActivatedRoute,
    private apiService: APIService,
    private fileUploadService: FileUploadService,
    private notifier: NotifierService
  ) {
    this.config = this.route.snapshot.data['config'];
  }

  locations: any = [];
  async ngOnInit() {
    const venueEmailObj = new VenueEmails(
      this.apiService,
      this.config[getENV()],
      this.notifier
    );

    let result: any = await venueEmailObj.loadEmails();
    if (result.status == '1') {
      this.locations = result.data;
    } else {
      this.locations = [];
      this.notifier.alert('Info', '', result.error_message, 'info', 5000);
    }
  }

  meta: any = {};
  kits: string[] = [
    'RQLD Kit 1 (BRC)',
    'RQLD Kit 2',
    'RQLD Kit 3',
    'HRV Kit 2',
    'HRNSW Kit 2',
  ];
  steps = [
    {
      id: 1,
      title: 'Upload Excel File',
      description:
        'Select and upload your Excel file containing race card data.',
      fields: [
        {
          type: 'file',
          name: 'excelFile',
          label: 'Select Excel File',
          accept: '.xlsx, .xls',
          required: true,
        },
      ],
      actions: {
        left: [],
        right: [
          {
            type: 'button',
            label: 'Next',
            action: 'proceedToNextStep',
            icon: 'fas fa-arrow-right',
          },
        ],
      },
    },
    {
      id: 2,
      title: 'Add Event Details',
      description: 'Provide information about the event.',
      fields: [
        {
          type: 'select',
          name: 'venue',
          label: 'Select Venue',
          options: ['Venue 1', 'Venue 2', 'Venue 3'],
          required: true,
        },
        {
          type: 'date',
          name: 'eventDate',
          label: 'Date of Event',
          required: true,
        },
        {
          type: 'time',
          name: 'firstRaceTime',
          label: 'Start of First Race',
          required: true,
        },
        {
          type: 'select',
          name: 'track',
          label: 'Select Track',
          options: ['Track 1', 'Track 2', 'Track 3'],
          required: true,
        },
      ],
      actions: {
        left: [
          {
            type: 'button',
            label: 'Back',
            action: 'goToPreviousStep',
            icon: 'fas fa-arrow-left',
          },
        ],
        right: [
          {
            type: 'button',
            label: 'Create Event',
            action: 'uploadAndProceed',
            icon: 'fas fa-arrow-right',
          },
        ],
      },
    },
  ];
  currentStep: number = 0;

  venues: any = [
    {
      id: '301',
      name: 'Newcastle',
    },
    {
      id: '355',
      name: 'Seymour',
    },
    {
      id: '957',
      name: 'Gold Coast Poly Track',
    },
  ];

  get step() {
    return this.steps[this.currentStep];
  }

  handleAction(action: string) {
    if (action == 'goToPreviousStep') {
      this.currentStep--;
    } else if (action == 'proceedToNextStep') {
      this.currentStep++;
    } else if (action == 'uploadAndProceed') {
      this.handleFile();
    }
  }

  dragging: boolean = false;
  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.dragging = true;
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.dragging = false;
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.dragging = false;

    if (!event.dataTransfer.types.includes('Files')) {
      this.meta.file.fileError = 'Not a valid file';
      return;
    }

    this.validateFile(event.dataTransfer.files);
  }

  activeTab: string = 'breeze';
  changeTab(tab: string) {
    this.activeTab = tab;
    this.meta = {};
  }

  onVenueSelect(event: any) {
    // this.loadFiles();
  }

  async loadFiles() {
    const apiUrl = `${
      this.config[getENV()].raceAPI
    }/get-latest-events?external_venue_id=${
      this.selectedLocation[0].ExternalVenueID
    }`;

    let result: any = await this.apiService.getDataPromis(apiUrl, {}, {});

    try {
      this.meta.files = JSON.parse(result.response);
    } catch (error) {
      this.notifier.alert(
        'Info',
        '',
        result.error_message || 'FAILED',
        'info',
        5000
      );
    }
  }

  viewContent() {
    const reader = new FileReader();
    reader.onload = (e) => {
      this.previewXML(e.target.result);
    };
    reader.readAsText(this.meta.file);
  }

  previewXML(xmlContent) {
    // Create a new window
    const newWindow = window.open('', '_blank');

    // Create a pre element to preserve formatting
    const preElement = newWindow.document.createElement('pre');

    // Format the XML content
    const formattedXML = this.formatXML(xmlContent);

    // Set the formatted XML as the text content of the pre element
    preElement.textContent = formattedXML;

    // Append the pre element to the new window's body
    newWindow.document.body.appendChild(preElement);

    // Add some basic styling
    newWindow.document.head.innerHTML = `
      <style>
          body { font-family: monospace; background-color: #f0f0f0; }
          pre { white-space: pre-wrap; word-wrap: break-word; }
      </style>
  `;
  }

  formatXML(xml) {
    let formatted = '';
    let indent = '';
    const tab = '  '; // 2 spaces for indentation

    xml.split(/>\s*</).forEach(function (node) {
      if (node.match(/^\/\w/)) {
        indent = indent.substring(tab.length); // Decrease indent
      }
      formatted += indent + '<' + node + '>\r\n';
      if (node.match(/^<?\w[^>]*[^\/]$/)) {
        indent += tab; // Increase indent
      }
    });

    return formatted.substring(1, formatted.length - 3);
  }

  validateFile(files: FileList): boolean {
    this.meta.fileError = null;
    let allowed: string[] = ['.xlsx'];
    if (this.activeTab == 'breeze') {
      allowed = ['.xlsx'];
    } else if (this.activeTab == 'final-fields') {
      allowed = ['.xml', '.csv'];
    }
    if (files && files.length == 0) {
      this.meta.fileError = 'Missing file';
      return false;
    } else if (files.length > 1) {
      this.meta.fileError = 'Multiple files are not allowed';
      return false;
    } else if (
      !allowed.some((ext) => files[0].name.toLowerCase().endsWith(ext))
    ) {
      this.meta.fileError = `Only ${allowed.join(', ')} files are allowed`;
      return false;
    }
    this.meta.fileError = '';
    this.meta.file = files[0];
    return true;
  }

  onFileSelected(event: Event) {
    const element = event.target as HTMLInputElement;
    const files = element.files;
    let status: boolean = this.validateFile(files);
    if (!status) {
      element.value = '';
    }
  }

  cancelPDF() {
    this.meta.uploadStatus = null;
    this.meta.file = null;
  }

  async handleFile() {
    // Process the file here (e.g., upload to server, parse XML, etc.)
    if (!this.meta.file) {
      this.notifier.alert('Info', '', 'FIle is missing', 'info', 5000);
      return;
    }
    this.meta.loading = true;
    this.meta.uploadStatus = 'GETTING_PRESIGNED_URL';
    let apiURL: string = `${this.config[getENV()].raceAPI}/breez`;

    let payload: any = {
      action: 'generate_presign_url',
      file_name: this.meta.file.name,
      method: 'post',
      event_date: moment(this.meta.eventDate, 'YYYY-MM-DD').format(
        'DD-MM-YYYY'
      ),
    };

    let result: any = await this.apiService.postDataPromis(apiURL, payload, {});

    if (result.status == '1' || result.s == '1') {
      this.meta.uploadStatus = 'PRESIGNED_URL_GENERATED';
      this.meta.presigned_url =
        result.url || result.response || result.presign_url;
      this.meta.object_file_name = result.file_name;
      this.uploadFile(this.meta.file, result);
    } else {
      this.meta.loading = false;
      this.currentStep = 0;
      this.meta.uploadStatus = null;
      this.meta.fileError =
        result.error_message || 'Failed to Upload File. Please try again';
    }
  }

  async uploadFile(file: File, result: any) {
    await this.uploadFileAPI(result, file);
  }

  async uploadFileAPI(result: any, file: any) {
    return new Promise(async (resolve: any, reject: any) => {
      this.fileUploadService
        .uploadFile(this.meta.presigned_url, file)
        .subscribe(async (event: HttpEvent<any>) => {
          switch (event.type) {
            case HttpEventType.Sent:
              break;
            case HttpEventType.ResponseHeader:
              break;
            case HttpEventType.UploadProgress:
              this.meta.uploadStatus = 'UPLOADING';
              let progress = Math.round(
                (event.loaded / (event.total || 0)) * 100
              );
              this.meta.uploaded = event.loaded;
              this.meta.percentage = progress;
              break;
            case HttpEventType.Response:
              if (event.status === 200) {
                this.meta.uploadStatus = 'COMPLETED';
                this.proceedFile();
                resolve(true);
              } else {
                this.meta.loading = false;
                this.meta.uploadStatus = 'ERROR';
                this.meta.fileError = `Upload failed with status ${event.status}`;
                this.currentStep = 0;
                reject(new Error(`Upload failed with status ${event.status}`));
              }
              break;
            case HttpEventType.User:
              break;
          }
        });
    });
  }

  async proceedFile() {
    let apiURL: string = `${this.config[getENV()].raceAPI}/breez`;
    let payload: any = {
      // event_name: this.meta.eventName,
      event_date: moment(this.meta.eventDate, 'YYYY-MM-DD').format(
        'DD-MM-YYYY'
      ),
      first_race_start_time: this.meta.firstRaceTime,
      venue_id: this.meta.venue.id,
      venue_name: this.meta.venue.name,
      action: 'process_file',
      file_name: this.meta.object_file_name,
      // kit: this.meta.kit
    };

    let result: any = await this.apiService.postDataPromis(apiURL, payload, {});

    if (result.status == '1' || result.s == '1') {
      this.meta.completed = true;
      this.meta.failed = false;
      this.notifier.alert(
        'Success',
        '',
        'Event Created Successfully',
        'success',
        5000
      );
      this.hideModel.emit(true);
    } else {
      this.meta.error =
        result.error_message || 'Failed to Injest Data. Please try again';
      this.meta.failed = true;
      this.meta.completed = false;
      this.notifier.alert('Info', '', this.meta.error, 'info', 5000);
    }

    this.meta.loading = false;
  }

  readFileContents(): Promise<boolean> {
    return new Promise((resolve: any, reject: any) => {
      if (!this.meta.file) {
        console.error('No file selected.');
        resolve(false);
      }

      const reader = new FileReader();

      reader.onload = (e) => {
        const originalUint8Array = new Uint8Array(reader.result as ArrayBuffer);

        // Gzip the Uint8Array with pako
        const compressedUint8Array = pako.gzip(originalUint8Array);

        // Convert the compressed data to a string of hexadecimal values with \x notation
        const compressedHexString =
          this.uint8ArrayToHexString(compressedUint8Array);
        this.meta.compressed = compressedHexString;
        resolve(true);
      };

      reader.readAsArrayBuffer(this.meta.file);
    });
  }

  uint8ArrayToHexString(uint8Array: Uint8Array): any {
    return Array.from(uint8Array);
  }

  async finalFieldsUpload() {
    try {
      let response: boolean = await this.readFileContents();
    } catch (error) {
      return;
    }

    this.meta.fileSuccess = null;
    this.meta.fileError = null;

    const title = this.meta.file ? this.meta.file.name : '';

    this.meta.uploadStatus = 'PROCESSING';

    let headers = {
      'Content-Type': 'application/xml',
    };

    let apiURL: string = `${
      this.config[getENV()].raceAPI
    }/finalfield-csv-upload?title=${encodeURIComponent(title)}&venue_id=${
      this.selectedLocation[0].ExternalVenueID
    }`;

    console.log(apiURL, headers, this.meta.compressed);

    let result: any = await this.apiService.postDataPromis(
      apiURL,
      this.meta.compressed,
      headers
    );

    if (result.status == '1' || result.s == '1') {
      this.meta.fileSuccess = result.message || 'File Uploaded Successfully...';
      this.meta.uploadStatus = 'COMPLETED';
    } else {
      this.meta.fileError =
        result.error_message || 'Failed to Upload File. please contact admin';
      this.meta.uploadStatus = 'FAILED';
    }
  }

  removeTrack(index: number) {
    this.meta.tracks.splice(index, 1);
  }

  addTrack() {
    if (!this.meta.tracks) {
      this.meta.tracks = [];
    }
    this.meta.tracks.push({
      name: this.meta.track,
    });
    this.meta.track = '';
  }
}
