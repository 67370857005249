<ng-container *ngIf="data">
  <table>
    <tbody>
      <tr>
        <ng-container *ngFor="let button of data">
          <td *ngIf="button.actions && button.actions.length > 0">
            <div class="relative">
              <div
                class="PLANNED_status status-container"
                *ngIf="button.status == 'PLANNED'"
              >
                {{ button.slug }}
              </div>
              <div
                class="COMPLETED_status status-container"
                *ngIf="button.status == 'COMPLETED'"
              >
                {{ button.slug }}
              </div>
              <div
                class="CANCELLED_status status-container"
                *ngIf="button.status == 'FAILED'"
              >
                {{ button.slug }}
              </div>
              <div class="absolute">
                <div>
                  <ng-container
                    *ngFor="let report of button['actions']; let i = index"
                  >
                    <div>
                      <ng-container *ngIf="report.name">
                        {{ report.name }} -
                      </ng-container>
                      {{ report.status }}
                    </div>
                    <div *ngIf="report.comment">
                      {{ report.comment }}
                    </div>
                    <div
                      class="divider"
                      *ngIf="button['actions'].length > i + 1"
                    ></div>
                  </ng-container>
                </div>
              </div>
            </div>
          </td>
        </ng-container>
      </tr>
    </tbody>
  </table>
</ng-container>
