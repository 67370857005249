<div class="card mt-4">
    <div class="card-body">
      <div class="row mb-3">
        <div class="col">
          <input type="text" class="form-control" placeholder="Filter by Kit ID" [(ngModel)]="filters.KitID" (input)="onFilterChange()">
        </div>
        <div class="col">
          <input type="text" class="form-control" placeholder="Filter by Original Kit ID" [(ngModel)]="filters.OriginalKit" (input)="onFilterChange()">
        </div>
        <div class="col">
          <input type="text" class="form-control" placeholder="Filter by Serial Number" [(ngModel)]="filters.SerialNumber" (input)="onFilterChange()">
        </div>
        <div class="col">
          <input type="text" class="form-control" placeholder="Filter by Device ID" [(ngModel)]="filters.DeviceID" (input)="onFilterChange()">
        </div>
      </div>
  
      <table class="table table-striped table-equal-width">
        <thead>
          <tr>
            <th (click)="onSortChange('SerialNumber')" [ngClass]="getSortClass('SerialNumber')">Serial Number</th>
            <th (click)="onSortChange('DeviceID')" [ngClass]="getSortClass('DeviceID')">Device ID</th>
            <th (click)="onSortChange('KitID')" [ngClass]="getSortClass('KitID')">Kit ID</th>
            <th (click)="onSortChange('OriginalKit')" [ngClass]="getSortClass('OriginalKit')">Original Kit ID</th>
            <th>Warranty Status</th>
            <th>Remaining Warranty Months</th>
            <th>Actions</th>
            <th>Issues</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let device of devices">
            <td>{{ device.SerialNumber }}</td>
            <td>{{ device.DeviceID }}</td>
            <td>{{ device.KitID }}</td>
            <td>{{ device.OriginalKit }}</td>
            <td>{{ device.WarrantyStatus }}</td>
            <td>{{ device.RemainingWarrantyMonths }}</td>
            <td><button class="btn btn-primary btn-sm" (click)="openKitDetails(device)">Details</button></td>
            <td><button class="btn btn-primary btn-sm" (click)="openKitHistory(device)">Add Issue</button></td>
          </tr>
        </tbody>
      </table>
  
      <nav>
        <ul class="pagination justify-content-center">
          <li class="page-item" [class.disabled]="currentPage <= 1">
            <a class="page-link" (click)="onPageChange(currentPage - 1)">Previous</a>
          </li>
          <ng-container *ngFor="let page of getPages()">
            <li class="page-item" [class.active]="currentPage === page" [class.disabled]="page === '...'">
              <a class="page-link" (click)="onPageChange(page)" *ngIf="page !== '...'">{{ page }}</a>
              <span class="page-link" *ngIf="page === '...'">...</span>
            </li>
          </ng-container>
          <li class="page-item" [class.disabled]="currentPage >= totalPages">
            <a class="page-link" (click)="onPageChange(currentPage + 1)">Next</a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
  
  <!-- Modal Components -->
  <app-device-history [showModal]="showHistoryModal" [SerialNumber]="selectedKit" (modalClose)="closeHistoryModal()"></app-device-history>
  <app-kit-details-modal [selectedKit]="selectedKit" [kitId]="selectedKit" [showModal]="showModal" (modalClose)="closeModal()"></app-kit-details-modal>
  