import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-harness-reports',
  templateUrl: './harness-reports.component.html',
  styleUrls: ['./harness-reports.component.css'],
})
export class HarnessReportsComponent implements AfterViewInit, OnDestroy {
  constructor(private route: ActivatedRoute, private router: Router) {}
  selected: string = 'home';
  subscription: Subscription;
  ngAfterViewInit(): void {
    this.subscription = this.route.fragment.subscribe((params: string) => {
      if (!params) {
        this.changeFragment('home');
      } else {
        this.selected = params;
      }
    });
  }

  changeFragment(selected: string) {
    this.router.navigate(['/dash/post-race'], {
      fragment: selected,
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
