import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DevicesService {

  private baseUrl = 'https://4otldh9um5.execute-api.ap-southeast-2.amazonaws.com/tsd-prd-api/devices';

  constructor(private http: HttpClient) { }

  getKitIds(url): Observable<string[]> {
    
    return this.http.get<{data: any[]}>(url).pipe(
      map(response => response.data.map(item => item.KitID))
    );
  }

  getTagIdsForKit(kitId: string): Observable<string[]> {
    const url = `${this.baseUrl}/kits?kit_id=${encodeURIComponent(kitId)}`;
    return this.http.get<any[]>(url).pipe(
      map(response => response.filter(item => item.Status === 'In operation').map(item => item.TagID))
    );
  }
}
