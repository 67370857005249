<div class="screen">
  <div class="screen-container">
    <div class="screen-header flex-between">
      <div class="header-big">EOD Report Inputs</div>
    </div>
    <div class="screen-body">
      <div class="flex-column-center p-0">
        <div class="flex-1 overflow-auto">
          <div class="grid-2">
            <div class="form-group">
              <label for="general-detail"
                >General
                <span class="small-text"
                  >Only use this section IF you have any additional comments to
                  capture.</span
                ></label
              >
              <textarea
                class="form-control"
                [(ngModel)]="event['general']"
                id="general-detail"
                rows="3"
                placeholder="No Issue"
              ></textarea>
            </div>
            <div class="form-group">
              <label for="software-detail">Software</label>
              <textarea
                class="form-control"
                [(ngModel)]="event['software']"
                id="software-detail"
                rows="3"
                placeholder="No Issue"
              ></textarea>
            </div>
            <div class="form-group">
              <label for="Infrastructure-detail">Infrastructure</label>
              <textarea
                class="form-control"
                [(ngModel)]="event['infrastructure']"
                id="Infrastructure-detail"
                rows="3"
                placeholder="No Issue"
              ></textarea>
            </div>
            <div class="form-group">
              <label for="3rd-party-graphics-detail">3rd Party Graphics</label>
              <textarea
                class="form-control"
                [(ngModel)]="event['graphics']"
                id="3rd-party-graphics-detail"
                rows="3"
                placeholder="No Issue"
              ></textarea>
            </div>
            <div class="form-group">
              <label for="starting-pulse-detail">Starting Pulse</label>
              <textarea
                class="form-control"
                [(ngModel)]="event['pulse']"
                id="starting-pulse-detail"
                rows="3"
                placeholder="No Issue"
              ></textarea>
            </div>
            <div class="form-group" *ngIf="event['jurisdictionCode'] == 'RVC'">
              <label for="barrier_load_performance">Barrier Load Performance</label>
              <textarea
                class="form-control"
                [(ngModel)]="event['barrier_load_performance']"
                id="barrier_load_performance"
                rows="3"
                placeholder="No Issue"
              ></textarea>
            </div>
            <div class="form-group">
              <label for="race-reports-detail">Race Reports</label>
              <textarea
                id="race-reports-detail"
                [(ngModel)]="event['reports']"
                rows="3"
                class="form-control"
                placeholder="No Issue"
              ></textarea>
            </div>
          </div>
          <hr />
          <div class="grid-2">
            <div class="form-group">
              <label for="tags-detail" class="flex-between">
                <span> Tags </span>
                <span class="light-text"
                  >Race < race #>, horse < horse #>, tag < tag #>, set to
                  DidNotTrack</span
                >
              </label>
              <textarea
                id="tags-detail"
                rows="3"
                [(ngModel)]="event['tags']"
                class="form-control"
                placeholder="No Issue"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="screen-buttons flex-end">
      <button class="btn btn-primary-soft" (click)="hideModel.emit(true)">Submit</button>
      <button class="btn btn-light" (click)="hideModel.emit(false)">Close</button>
    </div>
  </div>
</div>
