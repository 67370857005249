<div class="card">
  <div class="card-header flex-between">
    <h5>
      Races
    </h5>
    <div class="flex-start">
      <button-status [filter]="filters['venue']" title="Venue" [multiple]="true" [items]="events"></button-status>
      <button-status [filter]="filters['status']" title="Status" [multiple]="true" [items]="statuses"></button-status>
      <button-status [filter]="filters['time']" title="Starts in" [multiple]="false" [items]="times"></button-status>
    </div>
  </div>
  <div class="card-body">
    <table-view [headers]="headers" [data]="races | raceFilter:filters['venue']['selected']:filters['status']['selected']:filters['time']['selected']" [loading]="loading" (sendEvent)="handleClicks($event)"></table-view>
  </div>
</div>
