<div class="screen">
  <div class="screen-container mt-2" role="main">
    <div class="screen-header flex-between">
      <div class="header-big">EOD Report</div>
      <div>
        <i class="fa fa-times text-white p-1 cursor-pointer" (click)="hideModel.emit(this.publishReportButton.loading)"></i>
      </div>
    </div>
    <div class="screen-body">
      <div
        class="race form-group"
        [class.completed]="
          generateReportButton.completed && publishReportButton.completed
        "
        [class.error]="generateReportButton.error || publishReportButton.error"
      >
        <div class="card-view">
          <div class="flex-between justify-content-flex-start">
            <div class="flex-1">
              <div class="flex-start justify-content-flex-start">
                <div class="fw-bold fs-5">{{event['venue']}}</div>
                <!-- <span class="nav-link flex-start" (click)="showEventNotes()">
                  <i class="fa fa-sticky-note" aria-hidden="true"></i>
                  Event Notes
                </span> -->
              </div>
            </div>
            <div class="flex-start justify-content-flex-start">
              <div class="flex-column-stretch">
                <button
                  class="btn flex-grow-1 position-relative"
                  [ngClass]="{
                    'btn-success': generateReportButton.completed,
                    'btn-danger': generateReportButton.error,
                    'btn-secondary':
                      !generateReportButton.completed &&
                      !generateReportButton.error
                  }"
                  [disabled]="generateReportButton.loading"
                >
                  <div
                    class="color-inherit cursor-pointer"
                    (click)="generatePDF()"
                  >
                    Generate Report
                    <span
                      *ngIf="generateReportButton.loading"
                      class="spinner-border spinner-border-sm ml-2"
                    ></span>
                  </div>
                  <div class="tooltip-container">
                    <!-- Tooltip content for Generate Report button -->
                    <div
                      class="tooltip-content"
                      *ngIf="generateReportButton.error"
                    >
                      <div class="failed p-2">
                        <i class="fa fa-exclamation-circle text-danger"></i>
                        {{ generateReportButton.message }}
                      </div>
                    </div>
                    <div
                      class="tooltip-content"
                      *ngIf="
                        generateReportButton.completed &&
                        !generateReportButton.error
                      "
                    >
                      <i class="fa fa-check-circle text-success"></i>
                      Completed
                    </div>
                    <div
                      class="tooltip-content"
                      *ngIf="generateReportButton.pending"
                    >
                      <i class="fa-regular fa-clock"></i> Pending
                    </div>
                  </div>
                </button>
                <button
                  type="button"
                  class="btn btn-outline-secondary flex-start btn-dropdown"
                  [attr.disabled]="
                    !generateReportButton.completed || generateReportButton.pdf_loading ? true : null
                  "
                >
                  Review / Download
                  <span
                    *ngIf="generateReportButton.pdf_loading"
                    class="spinner-border spinner-border-sm ml-2"
                  ></span>
                  <i
                    *ngIf="!generateReportButton.pdf_loading && generateReportButton.files && generateReportButton.files.length > 0"
                    class="fa fa-angle-down color-inherit"
                    aria-hidden="true"
                  ></i>
                  <div class="dropdown-menu" *ngIf="generateReportButton.files && generateReportButton.files.length > 0">
                    <span
                      *ngFor="let file of generateReportButton.files"
                      class="dropdown-item"
                      href="#"
                      (click)="reviewPDF(file.filetype)"
                    >
                      {{ file.filetype }} File
                    </span>
                  </div>
                </button>

              </div>

              <div class="flex-column-stretch">
                <button
                  class="btn flex-grow-1 position-relative"
                  [ngClass]="{
                    'btn-success': publishReportButton.completed,
                    'btn-danger': publishReportButton.error,
                    'btn-secondary':
                      !publishReportButton.completed &&
                      !publishReportButton.error
                  }"
                  [disabled]="publishReportButton.loading"
                >
                  <div
                    class="color-inherit cursor-pointer"
                    (click)="checkForPublish()"
                  >
                    Publish to Customer
                    <span
                      *ngIf="publishReportButton.loading"
                      class="spinner-border spinner-border-sm ml-2"
                    ></span>
                  </div>
                  <div class="tooltip-container">
                    <!-- Tooltip content for Publish button -->
                     <div class="tooltip-content">
                       <div
                         *ngIf="
                           publishReportButton.steps &&
                           publishReportButton.steps.length > 0; else showSingleError
                         "
                         class="steps-container"
                       >
                         <div
                           class="step-container"
                           *ngFor="let step of publishReportButton.steps"
                         >
                           <div
                             class="step-status"
                             [ngClass]="{
                               completed:
                                 step.StepCompletionStatus.toUpperCase() ==
                                 'COMPLETED',
                               'in-progress':
                                 step.StepCompletionStatus.toUpperCase() ==
                                 'IN PROGRESS',
                               failed:
                                 step.StepCompletionStatus.toUpperCase() ==
                                 'FAILED'
                             }"
                           >
                             <i
                               class="fa"
                               [ngClass]="{
                                 'fa-check-circle':
                                   step.StepCompletionStatus.toUpperCase() ==
                                   'COMPLETED',
                                 'fa-clock-o':
                                   step.StepCompletionStatus.toUpperCase() ==
                                   'IN PROGRESS',
                                 'fa-exclamation-circle':
                                   step.StepCompletionStatus.toUpperCase() ==
                                   'FAILED'
                               }"
                             ></i>
                             <strong>
                               {{ step.StepType }}:
                               {{ step.StepCompletionStatus }}
                             </strong>
                             <ng-container
                               *ngIf="
                                 step.StepCompletionStatus.toUpperCase() ==
                                 'FAILED'
                               "
                             >
                               <br />
                               {{ step.Comments }}
                             </ng-container>
                           </div>
                         </div>
                       </div>
                       <ng-template #showSingleError>
                         <div *ngIf="publishReportButton.error" class="alert alert-danger">
                          <i class="fa fa-exclamation-circle text-danger"></i>
                           {{ publishReportButton.message }}
                         </div>
                       </ng-template>
                       <div *ngIf="publishReportButton.pending">
                         <i class="fa-regular fa-clock"></i> Pending
                       </div>
                       <div *ngIf="venueEmail">
                         <hr>
                         <div>
                           <i class="fa-regular fa-envelope"></i>
                           <span class="email-text"
                             >Email will be sent to:
                             <strong>{{ venueEmail || "-" }}</strong></span
                           >
                         </div>
                       </div>

                     </div>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<confirm-box
  *ngIf="mailCoolDown"
  (hideModel)="hideCooldownAlert($event)"
></confirm-box>

<eod-confirm-box
  *ngIf="confirmPublishCheck"
  title="Publish Report"
  message="Are you sure you want to publish this report?"
  (hideModel)="this.hideConfirmPublish($event)"
>
  <div
    class="center alert alert-primary p-1"
    role="alert"
    ngProjectAs="main-body"
    *ngIf="venueEmail"
  >
    Email will be sent to:
    <strong>{{ venueEmail || "-" }}</strong>
  </div>
</eod-confirm-box>
<post-race-eod-report *ngIf="publishInputs" [event]="event" (hideModel)="hidePublishInputs($event)"></post-race-eod-report>
