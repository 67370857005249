import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment-timezone';
import { APIService } from 'src/app/services/api.service';
import { typeBasedLocations } from '../race-reports/locations';

import { checkForVenuePermission, getENV, getRaceType } from 'src/app/_helpers/helpers';

@Component({
  selector: 'post-race-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomePostRaceComponent implements OnInit {
  config: any;
  constructor(private apiService: APIService, private router: ActivatedRoute) {
    this.config = this.router.snapshot.data['config'];
  }

  ngOnInit(): void {
    this.loadTodaysEvents();
  }

  locations: any = {};
  async loadTodaysEvents() {
    const date = moment().format('YYYY-MM-DD');
    let apiURL: string = `${this.config[getENV()].raceAPI}/event-list?formatted_date=${date}`;
    let raceTypes: any = localStorage.getItem('raceTypes');
    if (raceTypes) {
      raceTypes = JSON.parse(raceTypes);
    }
    if (raceTypes && raceTypes.length > 0) {
      apiURL += `&race_types=${raceTypes.join(',')}`;
    }

    let result: any = await this.apiService.getDataPromis(apiURL, {}, {});

    if (result.eventNames && result.eventNames.length > 0) {
      result.eventNames = result.eventNames.filter((event: any) => {
        return checkForVenuePermission(event['external_venue_id']);
      });
      result.eventNames.forEach((event: any) => {
        let raceType = getRaceType(event['external_venue_id'])[
          'raceTypeShortStr'
        ];
        typeBasedLocations[raceType + ' Racing'].forEach(
          (jurisdiction: any) => {
            if (this.locations[jurisdiction.JurisdictionCode] == undefined) {
              this.locations[jurisdiction.JurisdictionCode] = 0;
            }
            jurisdiction.Locations.forEach((location: any) => {
              if (
                event['venue_name']
                  .toLowerCase()
                  .includes(location.Name.toLowerCase())
              ) {
                this.locations[jurisdiction.JurisdictionCode] += 1;
              }
            });
          }
        );
      });
    } else {
      this.locations = {};
    }
  }
}
