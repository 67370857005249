import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { utcToZonedTime, format as tzFormat } from 'date-fns-tz';
@Injectable({
  providedIn: 'root'
})
export class RaceApiService {
  timezone: string = 'Australia/Sydney';
  constructor(private http: HttpClient) {
  }
  async getAllRaces(currentTimeStamp:Date, today_date:string, url:string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const apiKey = 'e2bvuAoslY1VJvFMqhr5x7nxoEhFVRii9jRngS3U';
      const headers = new HttpHeaders()
        .set('accept', 'application/json');

      const params = new HttpParams()
        .set('formatted_date', today_date);

      this.http.get(url, { headers, params }).subscribe(
        (response: any) => {
          const raceData = response.raceData;
          const operatorJsonResponse = response.getOperatorJsonResponse;
          const updatedRaceData = this.updateRaceDataWithEventType(raceData, currentTimeStamp, today_date);
          const completedRaceData = this.updateRaceDataWithDisplayName(operatorJsonResponse, updatedRaceData);
          // console.log(completedRaceData)
          resolve(completedRaceData);
        },
        (error) => {
          console.error('Error:', error);
          alert("Error Encountered!")
          reject(error); // Reject the promise in case of an error.
        }
      );
    });
  }
  getTimeStamp(): Date {
    const currentDate = new Date();
    const currentTimeStamp = utcToZonedTime(currentDate, this.timezone);
    return currentTimeStamp
  }
  getTodayDate(): string {
    const currentDate = new Date();
    const zonedCurrentDate = utcToZonedTime(currentDate, this.timezone);
    return tzFormat(zonedCurrentDate, 'yyyy-MM-dd', { timeZone: this.timezone });
  }
  getStringFromDate(inputDate:Date):string {
    const zonedCurrentDate = utcToZonedTime(inputDate, this.timezone);
    return tzFormat(zonedCurrentDate, 'yyyy-MM-dd', { timeZone: this.timezone });
  }
  updateRaceDataWithEventType(raceData: any, currentTimeStamp:Date, today_date:string) {
    type UnitNameMapping = {
      [key: string]: string;
    };
    const unitNameMapping: UnitNameMapping = {
      'Redcliffe': 'HARNESS',
      'Albion Park': 'HARNESS',
      'Ballarat': 'HARNESS',
      'Bendigo': 'HARNESS',
      'Cranbourne': 'HARNESS',
      'Geelong': 'HARNESS',
      'Kilmore': 'HARNESS',
      'Melton': 'HARNESS',
      'Mildura': 'HARNESS',
      'Shepparton': 'HARNESS',
      'Penrith': 'HARNESS',
      'Tabcorp Park Menangle': 'HARNESS',
      'Ipswich': 'THOROUGHBRED',
      'Aquis Park Gold Coast Poly': 'THOROUGHBRED',
      'Toowoomba': 'THOROUGHBRED',
      'Sunshine Coast': 'THOROUGHBRED',
      'Mackay': 'THOROUGHBRED',
      'Townsville': 'THOROUGHBRED',
      'Ladbrokes Cannon Park': 'THOROUGHBRED',
      'Doomben': 'THOROUGHBRED',
      'Eagle Farm': 'THOROUGHBRED',
      'Rosehill Gardens': 'THOROUGHBRED',
      'Royal Randwick': 'THOROUGHBRED',
      'Kensington': 'THOROUGHBRED',
      'Singapore Turf Club': 'THOROUGHBRED',
      'Flemington': 'THOROUGHBRED',
      'Ladbrokes Park Hillside': 'THOROUGHBRED',
      'Ladbrokes Park Lakeside': 'THOROUGHBRED',
      'Sportsbet Pakenham Synthetic': 'THOROUGHBRED',
      'Sportsbet Pakenham': 'THOROUGHBRED',
      'The Valley': 'THOROUGHBRED',
      'Caulfield': 'THOROUGHBRED'
      // 'Cranbourne': 'THOROUGHBRED'
    };

    const updatedRaceData = raceData.map((item: any) => {
      const { eventName } = item;
      const middleString = eventName.split(" ").slice(1, -1).join(" ");
      // console.log(middleString);
      const event_type = unitNameMapping[middleString] || '';
      const raceTimeParts = item.external_race_time.split(":");
      
      // let race_time_obj = new Date();
      const race_date_time = today_date + ' ' + item.external_race_time
      const race_time_obj = new Date(race_date_time);
      // race_time_obj.setHours(parseInt(raceTimeParts[0], 10));
      
      // race_time_obj.setMinutes(parseInt(raceTimeParts[1], 10));
      // race_time_obj.setSeconds(parseInt(raceTimeParts[2], 10));

      const timeDiff = race_time_obj.getTime() - currentTimeStamp.getTime();
      const minutes = Math.floor(timeDiff / (1000 * 60));
      const time_to_race = minutes;
      const external_event_id = item.external_event_id;
      const external_venue_id = item.external_venue_id
      let logo = ""
      // console.log(event_type)
      if (event_type === "HARNESS") {
        logo = "harness.png";
      } else if (event_type === "THOROUGHBRED") {
        logo = "thoroughbred.png";
      }
      return {
        ...item, // Spread the properties from the original item
        event_type,
        time_to_race,
        race_time_obj,
        logo,
        external_event_id,
        external_venue_id
      };
    });

    const sortedRaceData = updatedRaceData.sort((a: { time_to_race: number }, b: { time_to_race: number }) => a.time_to_race - b.time_to_race);
    return sortedRaceData;
  }

  updateRaceDataWithDisplayName(operatorJsonResponse: any, raceData: any) {
    type UnitNameMapping = {
      [key: string]: string;
    };
    const unitNameMapping: UnitNameMapping = {
      // 'REDC-N': 'Redcliffe',
      // 'REDC-D': 'Redcliffe',
      'REDC': 'Redcliffe',
      // 'ALBI': 'Albion Park',
      // 'ALBI-D': 'Albion Park',
      'ALBI-N': 'Albion Park',
      'IPSW': 'Ipswich',
      'GCST': 'Aquis Park Gold Coast Poly',
      // 'TWBA-N': 'Toowoomba',
      'TWBA': 'Toowoomba',
      'SCST': 'Sunshine Coast',
      'SCST-N': 'Sunshine Coast',
      'MKAY': 'Mackay',
      'TOWN': 'Townsville',
      'DOOM': 'Doomben',
      'EAGL': 'Eagle Farm',
      'CRNS': 'Ladbrokes Cannon Park',
      'BALL': 'Ballarat',
      // 'Ballarat-N': 'Ballarat',
      'Bendigo-N': 'Bendigo',
      'Bendigo-D': 'Bendigo',
      // 'CRAN-HR': 'Cranbourne',
      // 'Cranbourne-N': 'Cranbourne',
      'Geelong-N': 'Geelong',
      // 'Geelong-N': 'Geelong',
      // 'KILM': 'Kilmore',
      'Kilmore-N': 'Kilmore',
      // 'Kilmore-D': 'Kilmore',
      // 'MELT': 'Melton',
      'MELT-N': 'Melton',
      // 'MELT-D': 'Melton',
      'Mildura-D': 'Mildura',
      'Mildura-N': 'Mildura',
      'Shepparton-N': 'Shepparton',
      'Shepparton-D': 'Shepparton',
      'PENR': 'Penrith',
      // 'PENR-N': 'Penrith',
      // 'PENR-D': 'Penrith',
      'MENA': 'Tabcorp Park Menangle',
      // 'MENA-N': 'Tabcorp Park Menangle',
      'RHIL': 'Rosehill Gardens',
      'RAND': 'Royal Randwick',
      'KENS': 'Kensington',
      'STC': 'Singapore Turf Club',
      // 'STC-D': 'Singapore Turf Club',
      'FLEM': 'Flemington',
      'CAUL': 'Caulfield',
      'PAKE': 'Sportsbet-Pakenham Synthetic',
      // 'PAKE': 'Sportsbet-Pakenham',
      'CRAN-TH': 'Cranbourne',
      'MOON-D': 'The Valley',
      'MOON-N': 'The Valley',
      'SAND': 'Ladbrokes Park Hillside'
      // 'SAND': 'Ladbrokes Park Hillside',
      // 'SAND': 'Ladbrokes Park Lakeside'
    };

    const displayNames: Record<string, string> = {};
    operatorJsonResponse.forEach((item: any) => {
      const operationalUnitName = item['_DPMetaData']['OperationalUnitInfo']['OperationalUnitName'];
      const displayName: string = item['_DPMetaData']['EmployeeInfo']['DisplayName'];
      displayNames[operationalUnitName] = displayName;
    });
    const raceDataWithDisplayName = raceData.map((race: any) => {
      const eventName = race['eventName'];
      const unitName = Object.keys(unitNameMapping).find(key => unitNameMapping[key] === eventName);
      // console.log('eventName:', eventName);
      // console.log('unitName:', unitName);
      const displayName = unitName ? displayNames[unitName] : 'N/A';
      // console.log('displayName:', displayName);
      race['display_name'] = displayName;
      return race;
    });
    return raceDataWithDisplayName;
  }
}
