<div id="pdfPopup" class="popup">
  <div class="popup-content">
    <div class="popup-header">PDF Generator</div>
    <div class="popup-body">
      <div class="flex-start" style="align-items: stretch">
        <div class="flex-1">
          <div class="flex-column">
            <div class="option-group">
              <div class="option-group-header">Create PDF</div>
              <div *ngIf="lps.fileError" class="error flex-start">
                <i class="fa-solid fa-circle-exclamation"></i>
                <span>{{ lps.fileError }}</span>
              </div>
              <div *ngIf="lps.fileSuccess" class="success flex-start mb-0">
                <i class="fa-solid fa-check-circle"></i>
                <span>{{ lps.fileSuccess }}</span>
              </div>
              <div
                *ngIf="lps.uploadStatus == 'PROCESSING'"
                class="secondary flex-between mb-0"
              >
                <div class="flex-start">
                  <i class="fa-solid fa-clock"></i>
                  <span>Processing...</span>
                </div>
                <div>
                  <span class="spinner-border spinner-border-sm ml-2"></span>
                </div>
              </div>
              <div
                class="upload-section"
                *ngIf="!lps.file; else showFile"
                id="uploadSection"
                [class.active]="dragging"
                (click)="uploadSection.click()"
                (dragover)="onDragOver($event)"
                (dragleave)="onDragLeave($event)"
                (drop)="onDrop($event)"
              >
                {{
                  dragging
                    ? "Drop here"
                    : "Click here to upload or drag and drop the TOL File"
                }}
                <input
                  #uploadSection
                  type="file"
                  id="tolFileInput"
                  [attr.accept]="'.xml'"
                  style="display: none"
                  (change)="onFileSelected($event)"
                />
              </div>
              <ng-template #showFile>
                <div class="flex-between p-1">
                  <div class="flex-1 flex-start file">
                    <div class="pr-1">
                      <i class="fa fa-file-text" aria-hidden="true"></i>
                    </div>
                    <div class="flex-1">
                      <div>
                        <strong>{{ lps.file.name }}</strong>
                      </div>
                      <div class="mt-1">
                        <span
                          *ngIf="
                            !lps.uploadStatus ||
                              lps.uploadStatus == 'COMPLETED' ||
                              lps.uploadStatus == 'PROCESSING';
                            else showUploadStatus
                          "
                          class="flex-start"
                        >
                          {{ formatFileSize(lps.file.size) }}
                          <ng-container
                            *ngIf="
                              lps.uploadStatus == 'COMPLETED' ||
                              lps.uploadStatus == 'PROCESSING'
                            "
                          >
                            <span> | </span>
                            <span class="flex-start text-success fw-bold"
                              ><i class="fa fa-check-circle color-inherit"></i>
                              Uploaded</span
                            >
                          </ng-container>
                        </span>
                        <ng-template #showUploadStatus>
                          <div>
                            <span
                              >{{ formatFileSize(lps.uploaded || 0) }} /
                              {{ formatFileSize(lps.file.size) }} ({{
                                lps.percentage || 0
                              }}%)</span
                            >
                          </div>
                          <div class="progress-bar">
                            <div
                              class="progress"
                              [style.width.%]="lps.percentage || 0"
                            ></div>
                          </div>
                        </ng-template>
                      </div>
                    </div>
                  </div>
                  <div class="flex-start">
                    <ng-container
                      *ngIf="
                        !lps.uploadStatus || lps.uploadStatus == 'COMPLETED';
                        else showLoader
                      "
                    >
                      <button
                        class="flex-start btn btn-primary-soft"
                        (click)="viewContent()"
                      >
                        <i class="fa fa-eye color-inherit"></i>
                        View
                      </button>
                      <button
                        class="flex-start btn btn-success-soft"
                        (click)="handleFile()"
                        *ngIf="!lps.uploadStatus"
                      >
                        <i class="fa-solid fa-upload color-inherit"></i>
                        Upload
                      </button>
                      <button
                        class="flex-start btn btn-danger-soft"
                        (click)="cancelPDF()"
                        *ngIf="
                          lps.uploadStatus != 'COMPLETED';
                          else showCloseButton
                        "
                      >
                        <i class="fa fa-times color-inherit"></i>
                        Cancel
                      </button>
                      <ng-template #showCloseButton>
                        <button
                          class="flex-start btn btn-danger-soft"
                          (click)="cancelPDF()"
                        >
                          <i class="fa fa-times color-inherit"></i>
                          Close
                        </button>
                      </ng-template>
                    </ng-container>
                    <ng-template #showLoader>
                      <span
                        class="spinner-border spinner-border-sm ml-2"
                      ></span>
                    </ng-template>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
          <div class="option-group">
            <div class="option-group-header">Create PDF</div>
            <div *ngIf="lps.params.steps.length > 0 || lps.params?.error">
              <div class="flex-start flex-wrap" *ngIf="lps.params.steps.length > 0">
                <ng-container *ngFor="let step of lps.params.steps">
                  <div
                    class="flex-start success"
                    *ngIf="
                      step.StepCompletionStatus == 'Completed'
                    "
                  >
                    <i class="fa fa-check-circle"></i>
                    <span class="color-inherit">
                      {{ step.StepType }} : PDF Generated Successfully
                    </span>
                  </div>
                </ng-container>
              </div>
              <div *ngIf="lps.params?.error" class="mb-0">
                <div
                  class="flex-start error"
                  *ngIf="
                    !lps.params.steps || lps.params.steps.length == 0;
                    else showError
                  "
                >
                  <i class="fa-solid fa-circle-exclamation"></i>
                  <span>{{ lps.params?.error }}</span>
                </div>
                <ng-template #showError>
                  <ng-container *ngFor="let step of lps.params.steps">
                    <div
                      class="flex-start error"
                      *ngIf="
                        step.StepCompletionStatus == 'Failed'
                      "
                    >
                      <i class="fa-solid fa-circle-exclamation"></i>
                      <span class="color-inherit">
                        {{ step.StepType }} : {{ step.Comments || 'PDF Generation Failed' }}
                      </span>
                    </div>
                  </ng-container>
                </ng-template>
              </div>
            </div>
            <div class="flex-start">
              <select
                class="mr-0"
                id="pdfType"
                [(ngModel)]="lps.reportType"
                placeholder="Report Type"
              >
                <option value="" disabled>Report Type</option>
                <option value="official">Official PDF</option>
                <option value="unofficial">Unofficial PDF</option>
              </select>
              <select
                class="mr-0"
                id="xmlType"
                [(ngModel)]="lps.sourceType"
                placeholder="XML Type"
              >
                <option value="" disabled>XML Type</option>
                <option [value]="file" *ngFor="let file of files">
                  {{ file }}
                </option>
              </select>

              <button
                *ngIf="!lps.loadingXMLs; else showLoader"
                class="btn btn-primary-soft"
                (click)="loadXMLs()"
              >
                <i class="fa fa-refresh color-inherit"></i>
              </button>
              <ng-template #showLoader>
                <span class="spinner-border spinner-border-sm ml-2"></span>
              </ng-template>
              <ng-content select="generatePDF"></ng-content>
            </div>
          </div>

          <div class="option-group">
            <div class="option-group-header">Download {{ lps.params.lastUpdatedStepType + ' ' }}PDF</div>
            <div>
              <ng-content select="downloadPDF"></ng-content>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="popup-actions flex-end">
      <ng-content select="closePopup"></ng-content>
    </div>
  </div>
</div>
