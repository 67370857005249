import { NgModule, isDevMode } from '@angular/core';

import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { DashComponent } from './modules/globals/dash/dash.component';
import { SuperadminComponent } from './modules/globals/superadmin/superadmin.component';
import { feedbackComponent } from './modules/globals/dash/components/feedback/feedback.component';
import { HomeComponent } from './modules/globals/dash/modules/pages/components/home/home.component';
import { PreRaceComponent } from './modules/globals/dash/modules/pages/components/pre-race/app.component';
import {
  steps_dialog,
  invalid_dialog,
  DeputyComponent,
} from './modules/globals/dash/modules/pages/components/pre-race/deputy/deputy.component';
import { DeviceListComponent } from './modules/globals/dash/modules/pages/components/pre-race/device-list/device-list.component';
import { RaceDetailsComponent } from './modules/globals/dash/modules/pages/components/race-details/race-details.component';
import { RaceOverviewComponent } from './modules/globals/dash/modules/pages/components/race-overview/race-overview.component';
import { VenueDetailsComponent } from './modules/globals/dash/modules/pages/components/venve-details/venve-details.component';
import { UserProfileComponent } from './modules/globals/dash/modules/settings/components/user-profile/user-profile.component';
import { DisableComponentComponent } from './modules/globals/dash/modules/settings/components/users/components/disable-component/disable-component.component';
import { UpdateUserComponent } from './modules/globals/dash/modules/settings/components/users/components/update-user/update-user.component';
import { UserDetailsComponent } from './modules/globals/dash/modules/settings/components/users/components/user-details/user-details.component';
import { UsersComponent } from './modules/globals/dash/modules/settings/components/users/users.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NewMenusComponent } from './modules/globals/superadmin/components/new-menus/new-menus.component';
import { ClientsComponent } from './modules/globals/superadmin/components/clients/clients.component';
import { InviteClientComponent } from './modules/globals/superadmin/components/clients/components/invite-client/invite-client.component';
import { PolicyViewComponent } from './shared/components/policy-view/policy-view.component';
import { UpdatePoliciesComponent } from './shared/components/policies/components/update.policies.component';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { MenuSelectorComponent } from './modules/globals/dash/modules/settings/components/menu-selector/menu-selector.component';
import { PoliciesComponenet } from './shared/components/policies/manage.policies.component';
import { TableViewComponent } from './shared/components/table-view/table-view.component';
import { StatusViewComponent } from './shared/components/status-view/status-view.component';
import { RegisterComponent } from './modules/auth/components/register/register.component';
import { LoginComponent } from './modules/auth/components/login/login.component';
import { ForgotPasswordComponent } from './modules/auth/components/forgot-password/forgot.component';
import { AuthComponent } from './modules/auth/components/auth/auth.component';
import { rail_dialog } from './modules/globals/dash/modules/pages/components/venve-details/components/rail-calculator/rail-calculator.component';
import { HarnessReportsComponent } from './modules/globals/dash/modules/pages/components/post-race/harness-reports/harness-reports.component';
import { RaceManagementComponent } from './modules/globals/dash/modules/pages/components/post-race/harness-reports/components/race-management/race-management.component';
import { EodReportComponent } from './modules/globals/dash/modules/pages/components/post-race/harness-reports/components/eod-report/eod-report.component';
import { HomePostRaceComponent } from './modules/globals/dash/modules/pages/components/post-race/harness-reports/components/home/home.component';
import { LocationPickerComponent } from './modules/globals/dash/modules/pages/components/post-race/harness-reports/subcomponentes/location-picker/location-picker.component';
import { RaceReportsComponent } from './modules/globals/dash/modules/pages/components/post-race/harness-reports/components/race-reports/race-reports.component';
import { RaceEodReportComponent } from './modules/globals/dash/modules/pages/components/eod-report/eod-report.component';
import { ConfirmBoxComponent } from './modules/globals/dash/modules/pages/components/post-race/harness-reports/components/race-reports/components/confirm-box/confirm-box.component';
import { HorsestatusComponent } from './shared/components/horsestatus/horsestatus.component';
import { ReportstatusComponent } from './shared/components/report-status/reportstatus.component';
import { DeletePolicyComponent } from './shared/components/policies/components/delete-policy/delete-policy.component';
import { PcStatusComponent } from './modules/globals/dash/modules/pages/components/pc-status/pc-status.component';
import { DeleteUserComponent } from './modules/globals/dash/modules/settings/components/users/components/delete-policy/delete-policy.component';
import { changePasswordComponent } from './modules/globals/dash/modules/settings/components/users/components/changePassword/change-password.component';
import { EditVenueEmailComponent } from './modules/globals/dash/modules/pages/components/venue-emails/components/edit-venue-email/edit-venue-email.component';
import { VenueEmailsComponent } from './modules/globals/dash/modules/pages/components/venue-emails/venue-emails.component';
import { PcSelectorComponent } from './modules/globals/dash/modules/pages/components/post-race/harness-reports/components/race-reports/components/pc-selector/pc-selector.component';
import { OperatorKitManagementComponent } from './modules/globals/dash/modules/pages/components/operator-kit-management/operator-kit-management.component';
import { OperatorKitDetailsPageComponent } from './modules/globals/dash/modules/pages/components/operator-kit-details-page/operator-kit-details-page.component';
import { DeviceHistoryComponent } from './modules/globals/dash/modules/modals/device-history/device-history.component';
import { KitManagementComponent } from './modules/globals/dash/modules/pages/components/kit-management/kit-management.component';
import { KitManagementDetailsComponent } from './modules/globals/dash/modules/pages/components/kit-management-details/kit-management-details.component';
import { DeviceFormModalComponent } from './modules/globals/dash/modules/modals/device-form-modal/device-form-modal.component';
import { KitDetailsModalComponent } from './modules/globals/dash/modules/modals/kit-details-modal/kit-details-modal.component';
import { FilterRaceTypePipe } from './pipes/filter-race-type.pipe';
import { DevicesComponent } from './modules/globals/dash/modules/pages/components/devices/devices.component';
import { RaceMonitoringComponent } from './modules/globals/dash/modules/pages/components/race-monitoring/race-monitoring.component';
import { RaceStatusComponent } from './modules/globals/dash/modules/pages/components/race-status/race-status.component';
import { RaceRunnerComponent } from './modules/globals/dash/modules/pages/components/race-runner/race-runner.component';
import { MqttStateComponent } from './shared/components/mqttstate/mqttstate.component';
import { ButtonStatusComponent } from './modules/globals/dash/modules/pages/components/home/button-status/button-status.component';
import { FilterPipe } from './modules/globals/dash/modules/pages/components/home/button-status/filter.pipe';
import { RacesPipe } from './modules/globals/dash/modules/pages/components/home/filter.pipe';
import { EODConfirmBoxComponent } from './modules/globals/dash/modules/pages/components/post-race/harness-reports/components/race-reports/components/confirm-box copy/confirm-box.component';
import { RaceRunnerMapComponent } from './modules/globals/dash/modules/pages/components/race-runner-map/race-runner-map.component';
import { LpsGenerateReportComponent } from './modules/globals/dash/modules/pages/components/post-race/harness-reports/components/race-reports/components/lps-generate-report/lps-generate-report.component';
import { GenerateLipComponent } from './modules/globals/dash/modules/pages/components/post-race/harness-reports/components/race-reports/components/generate-lip/generate-lip.component';
import { UploadFileLipComponent } from './modules/globals/dash/modules/pages/components/post-race/harness-reports/components/race-reports/components/upload-file-lip/upload-file-lip.component';
import { OnCourseOpsRaceReportsComponent } from './modules/globals/dash/modules/pages/components/post-race/harness-reports/components/on-course-ops-race-reports/on-course-ops-race-reports.component';
import { OnCourseOpsJudgesComponent } from './modules/globals/dash/modules/pages/components/post-race/harness-reports/components/on-course-ops-judges/on-course-ops-judges.component';
import { ReprotsdownloadComponent } from './shared/components/reprotsdownload/reprotsdownload.component';
import { ManualRaceCardsComponent } from './modules/globals/dash/modules/pages/components/manual-race-cards/manual-race-cards.component';
import { Step1Component } from './modules/globals/dash/modules/pages/components/manual-race-cards/components/step-1/step-1.component';
import { OnCourseOpsSectionalsComponent } from './modules/globals/dash/modules/pages/components/post-race/harness-reports/components/on-course-ops-sectionals/on-course-ops-sectionals.component';
import { NotesComponent } from './modules/globals/dash/modules/pages/components/post-race/harness-reports/components/race-reports/components/notes/notes.component';
import { KitsPipe } from './modules/globals/dash/modules/pages/components/operator-kit-details-page/kit.pipe';
import { DataTransferComponent } from './modules/globals/dash/modules/pages/components/data-transfer/data-transfer.component';
import { JiraTrackerComponent } from './modules/globals/dash/modules/pages/components/jira-tracker/jira-tracker.component';
import { StagingManagementComponent } from './modules/globals/dash/modules/pages/components/staging-management/staging-management.component';
@NgModule({
  declarations: [
    AppComponent,
    FilterRaceTypePipe,
    DashComponent,
    SuperadminComponent,
    feedbackComponent,
    HomeComponent,
    steps_dialog,
    invalid_dialog,
    DeviceListComponent,
    DeputyComponent,
    UsersComponent,
    UpdateUserComponent,
    UserProfileComponent,
    UserDetailsComponent,
    DisableComponentComponent,
    NewMenusComponent,
    ClientsComponent,
    UpdateUserComponent,
    InviteClientComponent,
    PolicyViewComponent,
    UpdatePoliciesComponent,
    MenuSelectorComponent,
    UpdatePoliciesComponent,
    PoliciesComponenet,
    VenueDetailsComponent,
    RaceDetailsComponent,
    RaceOverviewComponent,
    PreRaceComponent,
    TableViewComponent,
    StatusViewComponent,
    RegisterComponent,
    LoginComponent,
    ForgotPasswordComponent,
    AuthComponent,
    rail_dialog,
    HarnessReportsComponent,
    RaceManagementComponent,
    EodReportComponent,
    HomePostRaceComponent,
    LocationPickerComponent,
    RaceReportsComponent,
    RaceEodReportComponent,
    ConfirmBoxComponent,
    HorsestatusComponent,
    MqttStateComponent,
    ReportstatusComponent,
    DeletePolicyComponent,
    DeleteUserComponent,
    changePasswordComponent,
    PcStatusComponent,
    VenueEmailsComponent,
    EditVenueEmailComponent,
    PcSelectorComponent,
    OperatorKitManagementComponent,
    OperatorKitDetailsPageComponent,
    DeviceHistoryComponent,
    KitManagementComponent,
    KitManagementDetailsComponent,
    DeviceFormModalComponent,
    KitDetailsModalComponent,
    DevicesComponent,
    RaceMonitoringComponent,
    RaceStatusComponent,
    RaceRunnerComponent,
    RaceRunnerMapComponent,
    ButtonStatusComponent,
    EODConfirmBoxComponent,
    FilterPipe,
    RacesPipe,
    LpsGenerateReportComponent,
    GenerateLipComponent,
    UploadFileLipComponent,
    OnCourseOpsRaceReportsComponent,
    OnCourseOpsJudgesComponent,
    ReprotsdownloadComponent,
    ManualRaceCardsComponent,
    Step1Component,
    OnCourseOpsSectionalsComponent,
    NotesComponent,
    KitsPipe,
    DataTransferComponent,
    JiraTrackerComponent,
    StagingManagementComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    NgSelectModule,
    RouterModule,
    MatSelectModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatProgressBarModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    NgMultiSelectDropDownModule.forRoot(),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
