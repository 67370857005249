import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DashService {
  private dashSource = new BehaviorSubject('');
  dashObervable: Observable<string> = this.dashSource.asObservable();

  constructor() {}

  sendMessage(string: string) {
    this.dashSource.next(string);
  }
}
