import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { APIService } from 'src/app/services/api.service';

import { NotifierService } from 'src/app/services/notifier.service';
import { getENV } from 'src/app/_helpers/helpers';

interface Labels {
  [key: string]: string;
}

interface ColorInfo {
  color: string;
  border: string;
}

interface LabelsColors {
  [key: string]: ColorInfo;
}

@Component({
  selector: 'app-operator-kit-details-page',
  templateUrl: './operator-kit-details-page.component.html',
  styleUrls: ['./operator-kit-details-page.component.css'],
})
export class OperatorKitDetailsPageComponent implements OnInit {
  kitsData: any[] = [];
  kitId: string = '';

  labels: Labels = {
    '0': 'Spare',
    '1': 'In operation',
    '2': 'Out of Operation',
    '3': 'New',
    '4': 'Returning to Track',
    '6': 'Returning to TSD',
    '7': 'Oncourse test required',
    '8': 'In Transit',
    '9': 'TSD Testing',
    '10': 'On Track',
    '11': 'At Swiss Timing',
    '12': 'OnCourse Spare',
  };
  labelsColors: LabelsColors = {
    '0': { color: '#fdab3d', border: '#e99729' },
    '1': { color: '#00c875', border: '#00b461' },
    '2': { color: '#df2f4a', border: '#ce3048' },
    '3': { color: '#007eb5', border: '#3db0df' },
    '4': { color: '#9d50dd', border: '#9238af' },
    '6': { color: '#cab641', border: '#c0ab31' },
    '7': { color: '#579bfc', border: '#4387e8' },
    '8': { color: '#9cd326', border: '#89b921' },
    '9': { color: '#ffcb00', border: '#c0ab1b' },
    '10': { color: '#ff007f', border: '#e01279' },
    '11': { color: '#bb3354', border: '#a42d4a' },
    '12': { color: '#401694', border: '#401694' },
  };
  selectedKit: any = null; // Currently selected kit for the modal
  showHistoryModal: boolean = false;
  loading: boolean = false;
  config: any;
  error = '';
  overview: any = { total: 0, in_operation: 0, out_of_operation: 0 };
  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private apiService: APIService,
    private notifier: NotifierService
  ) {
    this.config = this.route.snapshot.data['config'];
  }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.kitId = params['kitId'];
      this.fetchKitDetails(this.kitId);
    });
  }

  statuses: { name: string; id: string }[] = [
    { name: 'Spare', id: 'Spare' },
    { name: 'In operation', id: 'In operation' },
    { name: 'Out of Operation', id: 'Out of Operation' },
    { name: 'New', id: 'New' },
    { name: 'Returning to Track', id: 'Returning to Track' },
    { name: 'Returning to TSD', id: 'Returning to TSD' },
    { name: 'Oncourse test required', id: 'Oncourse test required' },
    { name: 'In Transit', id: 'In Transit' },
    { name: 'TSD Testing', id: 'TSD Testing' },
    { name: 'On Track', id: 'On Track' },
    { name: 'At Swiss Timing', id: 'At Swiss Timing' },
    { name: 'OnCourse Spare', id: 'OnCourse Spare' },
  ];

  filters: any = {
    kit: {
      selected: ['ALL'],
    },
  };
  async fetchKitDetails(kitId: string): Promise<void> {
    this.notifier.loading(true);
    let apiURL: string = `${
      this.config[getENV()].raceAPI
    }/devices/kits?kit_id=${kitId}`;
    let response = await this.apiService.getDataPromis(apiURL, {}, {});
    this.kitsData = response
      .map(({ VenueState, Kit, ...keepAttrs }) => keepAttrs)
      .sort((a, b) => a.TagID - b.TagID);
    this.overview['total'] = this.kitsData.length;
    this.overview['in_operation'] = 0;
    this.overview['out_of_operation'] = 0;
    this.kitsData.forEach((kit) => {
      this.overview['in_operation'] += kit.Status == 'In operation';
      this.overview['out_of_operation'] += kit.Status == 'Out of Operation';
    });
    this.notifier.loading(false);

    //                           .sort((a, b) => a.TagID - b.TagID);
    // const apiUrl = `https://4otldh9um5.execute-api.ap-southeast-2.amazonaws.com/tsd-prd-api/devices/kits?kit_id=${kitId}`;
    // this.http.get<any[]>(apiUrl).subscribe(response => {
    //   this.kitsData = response.map(({ VenueState, Kit, ...keepAttrs }) => keepAttrs)
    //                           .sort((a, b) => a.TagID - b.TagID);
    //   console.log(this.kitsData);
    // });
  }

  getColor(status: string): string {
    return this.labelsColors[status]?.color || 'transparent';
  }

  getSelectedOptionColor(statusText: string): string {
    const statusKey = this.findStatusKeyByText(statusText);
    return this.getColor(statusKey);
  }

  getSelectedOptionBorderColor(statusText: string): string {
    const statusKey = this.findStatusKeyByText(statusText);
    return this.getBorderColor(statusKey);
  }

  getTextColor(color: string): string {
    return color === '#401694' ? 'white' : 'black';
  }

  findStatusKeyByText(statusText: string): string {
    return (
      Object.keys(this.labels).find((key) => this.labels[key] === statusText) ||
      ''
    );
  }

  getBorderColor(status: string): string {
    return this.labelsColors[status]?.border || '#ddd'; // Default border color
  }

  getLabel(status: string): string {
    return this.labels[status] || 'Unknown Status';
  }

  objectKeys(obj: any): string[] {
    return Object.keys(obj);
  }

  async submitForm(): Promise<void> {
    this.notifier.loading(true);
    const dataWithoutTagID = this.kitsData.map(({ TagID, ...rest }) => rest);
    this.loading = true;
    // const apiUrl = 'https://4otldh9um5.execute-api.ap-southeast-2.amazonaws.com/tsd-prd-api/devices/kits';
    const apiUrl: string = `${this.config[getENV()].raceAPI}/devices/kits`;
    let result = await this.apiService
      .postDataPromis(apiUrl, dataWithoutTagID)
      .then(
        (data) => {
          alert('Submitted Data!');
          this.loading = false;
          window.location.reload();
        },
        (error) => {
          console.error('Error:', error);
          alert('Error occurred while submitting data!');
          this.loading = false;
        }
      );

      this.notifier.loading(false);

    // this.http.post(apiUrl, dataWithoutTagID, httpOptions).subscribe(
    //   (data) => {
    //     alert("Submitted Data!");
    //     this.loading = false;
    //     window.location.reload();
    //   },
    //   (error) => {
    //     console.error('Error:', error);
    //     alert("Error occurred while submitting data!");
    //     this.loading = false;
    //   }
    // );
  }

  openKitHistory(kit: any): void {
    this.selectedKit = kit.SerialNumber;
    this.showHistoryModal = true;
  }

  closeHistoryModal(): void {
    this.showHistoryModal = false;
  }
}
