import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
} from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ActivatedRoute, Params } from '@angular/router';
import { APIService } from 'src/app/services/api.service';

import { NotifierService } from 'src/app/services/notifier.service';
import { getENV } from 'src/app/_helpers/helpers';
interface Labels {
  [key: string]: string;
}
interface ColorInfo {
  color: string;
  border: string;
}

interface LabelsColors {
  [key: string]: ColorInfo;
}
@Component({
  selector: 'app-kit-details-modal',
  templateUrl: './kit-details-modal.component.html',
  styleUrls: ['./kit-details-modal.component.css'],
})
export class KitDetailsModalComponent implements OnChanges {
  @Input() selectedKit: any;
  @Input() showModal: boolean = false;
  @Input() kitId: any;
  @Input() venueState: any;
  @Input() codeType: any;
  @Output() modalClose = new EventEmitter<void>(); // Used to notify when the modal is closed
  serialNumber: string = '';
  form: { [key: string]: any } = {};
  kitDetails: any; // Variable to hold kit details
  isEditMode: boolean = false;
  loading: boolean = false;
  labels: Labels = {
    '0': 'Spare',
    '1': 'In operation',
    '2': 'Out of Operation',
    '3': 'New',
    '4': 'Returning to Track',
    '6': 'Returning to TSD',
    '7': 'Oncourse test required',
    '8': 'In Transit',
    '9': 'TSD Testing',
    '10': 'On Track',
    '11': 'At Swiss Timing',
    '12': 'OnCourse Spare',
  };
  labelsColors: LabelsColors = {
    '0': { color: '#fdab3d', border: '#e99729' },
    '1': { color: '#00c875', border: '#00b461' },
    '2': { color: '#df2f4a', border: '#ce3048' },
    '3': { color: '#007eb5', border: '#3db0df' },
    '4': { color: '#9d50dd', border: '#9238af' },
    '6': { color: '#cab641', border: '#c0ab31' },
    '7': { color: '#579bfc', border: '#4387e8' },
    '8': { color: '#9cd326', border: '#89b921' },
    '9': { color: '#ffcb00', border: '#c0ab1b' },
    '10': { color: '#ff007f', border: '#e01279' },
    '11': { color: '#bb3354', border: '#a42d4a' },
    '12': { color: '#401694', border: '#401694' },
  };

  config: any;
  error = '';
  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private apiService: APIService,
    private notifier: NotifierService
  ) {
    this.config = this.route.snapshot.data['config'];
  }

  ngOnChanges(): void {
    if (this.showModal) {
      if (this.selectedKit) {
        this.fetchKitDetails();
        this.isEditMode = true;
      } else {
        this.form = {
          KitID: this.kitId,
          VenueState: this.venueState,
          CodeType: this.codeType,
        };
        this.isEditMode = false;
      }
    }
  }

  toggleModal(): void {
    this.showModal = !this.showModal; // Toggle the internal flag
    if (!this.showModal) {
      this.modalClose.emit(); // Emit an event if the modal is being closed
    }
  }

  async fetchKitDetails(): Promise<void> {
    if (!this.selectedKit) {
      console.error('Serial number is missing.');
      return;
    }

    const serialNumberParam = encodeURIComponent(this.selectedKit);
    // const apiUrl = `https://4otldh9um5.execute-api.ap-southeast-2.amazonaws.com/tsd-prd-api/devices/kit?serial_number=${serialNumberParam}`;
    let apiURL: string = `${
      this.config[getENV()].raceAPI
    }/devices/kit?serial_number=${serialNumberParam}`;
    let response = await this.apiService.getDataPromis(apiURL, {}, {});
    (response) => response.json();
    this.kitDetails = response.data; // Assigning fetched details to kitDetails
    this.form = { ...response.data };
    // this.http.get<any>(apiUrl).subscribe(response => {
    //   console.log(response);
    //   this.kitDetails = response.data; // Assigning fetched details to kitDetails
    //   this.form = {...response.data};
    //   console.log(this.form)
    // }, error => {
    //   console.error('Error fetching kit details:', error);
    //   // Handle any errors
    // });
  }

  async submitForm() {
    let filteredForm = { ...this.form };
    let payload = {
      TransponderID: filteredForm['TransponderID'],
      WarrantyExpDate: filteredForm['WarrantyExpDate'],
      SetNumber: filteredForm['SetNumber'],
      Location: filteredForm['Location'],
      Jurisdiction: filteredForm['Jurisdiction'],
      CodeType: filteredForm['CodeType'],
      KitID: filteredForm['KitID'],
      VenueState: filteredForm['VenueState'],
      Status: filteredForm['Status'],
    };
    // this.showModal = false; // Close modal after submission
    const headers = new HttpHeaders().set('accept', 'application/json');

    const httpOptions = {
      headers: headers,
    };
    this.loading = true;
    // const apiUrl = 'https://4otldh9um5.execute-api.ap-southeast-2.amazonaws.com/tsd-prd-api/devices/kit';

    // this.http.post(apiUrl, filteredForm, httpOptions)
    //   .subscribe(
    //     (data) => {
    //       alert("Submitted Data!");
    //       this.loading = false;
    //       this.showModal = false;
    //       // window.location.reload();
    //       this.toggleModal(); // Close modal after submission
    //     },
    //     (error) => {
    //       console.log(error)
    //       // Handle any errors that occurred during the HTTP request
    //       alert(error);
    //       this.loading = false;
    //       // this.toggleModal(); // Close modal after submission
    //     }
    //   );
    const apiUrl: string = `${this.config[getENV()].raceAPI}/devices/kit`;
    let result = await this.apiService
      .postDataPromis(
        apiUrl,
        filteredForm['CodeType'] == 'GRAYHOUNDS' ? payload : filteredForm
      )
      .then(
        (data) => {
          alert('Submitted Data!');
          this.loading = false;
          window.location.reload();
        },
        (error) => {
          console.error('Error:', error);
          alert('Error occurred while submitting data!');
          this.loading = false;
        }
      );
  }
  getColor(status: string): string {
    return this.labelsColors[status]?.color || 'transparent';
  }
  getSelectedOptionColor(statusText: string): string {
    const statusKey = this.findStatusKeyByText(statusText);
    return this.getColor(statusKey);
  }

  // Method to get the border color for the selected option
  getSelectedOptionBorderColor(statusText: string): string {
    const statusKey = this.findStatusKeyByText(statusText);
    return this.getBorderColor(statusKey);
  }
  getTextColor(color: string): string {
    if (color === '#401694') {
      return 'white';
    } else {
      return 'black';
    }
  }
  // Utility method to find a status key by its text value
  findStatusKeyByText(statusText: string): string {
    return (
      Object.keys(this.labels).find((key) => this.labels[key] === statusText) ||
      ''
    );
  }

  getBorderColor(status: string): string {
    return this.labelsColors[status]?.border || '#ddd'; // Default border color
  }
  getLabel(status: string): string {
    return this.labels[status] || 'Unknown Status';
  }
  objectKeys(obj: any): string[] {
    return Object.keys(obj);
  }
}
