import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'KitFilter',
  pure: true,
})
export class KitsPipe implements PipeTransform {
  transform(items: any[], status_check: any): any[] {
    if (status_check.length == 1) {
      if (status_check[0] == 'ALL') {
        return items;
      }
    }
    return items.filter((item: any) => {
      let status_check_final: boolean = false;

      status_check_final =
        status_check == 'ALL' || status_check.includes(item.Status);

        return status_check_final;
    });
  }
}
