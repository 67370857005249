import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { getENV } from 'src/app/_helpers/helpers';
import { APIService } from 'src/app/services/api.service';
import { NotifierService } from 'src/app/services/notifier.service';
import * as moment from 'moment';

@Component({
  selector: 'generate-lip',
  templateUrl: './generate-lip.component.html',
  styleUrls: [
    './generate-lip.component.css',
    './../lps-generate-report/lps-generate-report.component.css',
  ],
})
export class GenerateLipComponent implements OnInit {
  @Input({ required: true }) meta: any;
  @Input({ required: true }) config: any;

  @Output() onClose: EventEmitter<any> = new EventEmitter();

  constructor(
    private apiService: APIService,
    private notifier: NotifierService
  ) {}

  ngOnInit(): void {
    this.load();
  }

  horses: any = [];
  ranks: number[] = [];
  horseStates: string[] = [
    'PLANNED',
    'DNS',
    'DNT',
    'DNF',
    'DSQ',
    'OFFICIAL',
    'FINISHED',
    'PUBLISHED',
    'ABANDONED',
  ];

  async load() {
    this.meta.loadingHorses = true;
    let apiURL: string = `${
      this.config[getENV()].raceAPI
    }/race-entrant-details?ExternalRaceId=${
      this.meta.race.ExternalRaceId
    }&ExternalEventId=${this.meta.race.ExternalEventId}`;

    let result: any = await this.apiService.getDataPromis(apiURL, {}, {});

    if (typeof result == 'string') {
      this.meta.error = result;
      this.horses = [];
      this.meta.loadingHorses = false;
      return;
    }
    this.horses = result.entrants.map((horse: any) => {
      if(!horse.RaceState) {
        horse.RaceState = 'PLANNED';
      }
      horse.RaceState = horse.RaceState.toUpperCase();
      return horse;
    });
    this.ranks = this.horses.map((horse: any, index: number) => index + 1);
    this.meta.loadingHorses = false;
  }

  validate(event: any = null) {
    let check: boolean = true;
    this.horses.forEach((horse_: any) => {
      if (horse_.RaceState == 'DNS') {
        return;
      }
      if (!horse_.Rank) {
        horse_.RankError = 'Rank is missing';
        check = false;
      } else {
        horse_.RankError = '';
      }
      const timeValidation = this.validateTimeFormat(horse_.OfficialFinishTime);
      if (!timeValidation.isValid) {
        horse_.TimeError = timeValidation.message;
        check = false;
      } else {
        horse_.TimeError = '';
      }
    });
    return check;
  }

  async generateLIF() {
    if (!this.validate()) {
      return;
    }
    this.meta.generateStatus = 'GENERATING';
    let apiURL: string = `${this.config[getENV()].raceAPI}/flask-operations`;

    let payload: any = {
      action: 'generate_lif_file',
      location: this.meta.raceCource.venue_name,
      event_id: this.meta.raceCource.external_event_id,
      race_number: this.meta.race.SquentialRaceOrderNumber,
      race_date: this.meta.raceCource.date,
      jurisdiction: this.meta.raceCource.JurisdictionCode,
      type: this.meta.raceCource.RaceType,
      race_name: this.meta.race.RaceName,
      race_id: this.meta.race.ExternalRaceId,
      race_distance: (this.meta.race.RaceLength || 0) + 'm',
      entrants: this.horses.map((horse: any) => {
        let data = {
          StartNumber: horse.StartNumber,
          AnimalName: horse.AnimalName,
          RaceState: horse.RaceState || 'PLANNED',
          Rank: horse.Rank || 0,
          OfficialFinishTime: this.convertTimeToSeconds(
            horse.OfficialFinishTime
          ),
          Margin: horse.Margin || 0,
        };

        if (horse.RaceState == 'DNS') {
          delete horse.Rank;
          delete horse.OfficialFinishTime;
          delete horse.Margin;
        }
        return data;
      }),
    };

    let result: any = await this.apiService.postDataPromis(apiURL, payload, {});

    if (result.s == '1' || result.status == '1') {
      this.meta.generateStatus = 'COMPLETED';
      this.meta.completed = true;
      this.notifier.alert(
        'Success',
        '',
        'LIF File Generated Successfully',
        'success',
        5000
      );
      this.onClose.emit();
    } else {
      this.meta.generateStatus = 'FAILED';
      this.meta.error = result.error_message;
    }
  }

  validateTimeFormat(time: string): { isValid: boolean; message: string } {
    if(!time) {
      return { isValid: false, message: 'Time is required' };
    }
    if (!time.includes(':')) {
      const parts = time.split('.');
      if (parts.length !== 2) {
        return { isValid: false, message: 'Time must be in MM:SS.000 or SS.000 format' };
      }
      const seconds = parseInt(parts[0], 10);
      const milliseconds = parseInt(parts[1], 10);

      if (seconds < 0) {
        return { isValid: false, message: 'Seconds must be non-negative' };
      }
      if (milliseconds < 0 || milliseconds >= 1000) {
        return {
          isValid: false,
          message: 'Milliseconds must be between 0 and 999',
        };
      }
      return { isValid: true, message: 'Valid time format' };
    }

    const isValidFormat = moment(time.trim(), 'mm:ss.000').isValid();

    if (!isValidFormat) {
      return { isValid: false, message: 'Time must be in MM:SS.000 or SS.000 format' };
    }

    const parts = time.split(':');
    const minutes = parseInt(parts[0], 10);
    const secondsAndMilliseconds = parts[1].split('.');
    const seconds = parseInt(secondsAndMilliseconds[0], 10);
    const milliseconds = parseInt(secondsAndMilliseconds[1], 10);

    if (minutes < 0) {
      return { isValid: false, message: 'Minutes must be non-negative' };
    }
    if (seconds < 0 || seconds >= 60) {
      return { isValid: false, message: 'Seconds must be between 0 and 59' };
    }
    if (milliseconds < 0 || milliseconds >= 1000) {
      return {
        isValid: false,
        message: 'Milliseconds must be between 0 and 999',
      };
    }

    return { isValid: true, message: 'Valid time format' };
  }

  convertTimeToSeconds(time: string): number {
    if (!time) return 0;

    const parts = time.includes(':') ? time.split(':') : ['0', time];
    let seconds = 0;

    if (parts.length === 2) {
      const minutes = parseInt(parts[0], 10);
      const [secs, ms] = parts[1].split('.');
      seconds = minutes * 60 + parseInt(secs, 10);
      if (ms) {
        const msValue = ms.padEnd(3, '0');
        seconds += parseInt(msValue, 10) / 1000;
      }
    } else if (parts.length === 1) {
      seconds = parseFloat(parts[0]);
    }

    return isNaN(seconds) ? 0 : Number(seconds.toFixed(3));
  }
}
