import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { APIService } from 'src/app/services/api.service';
import { RacesObject } from '../home/interfaces';
import { NotifierService } from 'src/app/services/notifier.service';

import {
  checkForVenuePermission,
  convertTimestamp,
  getENV,
  getRaceType,
} from '../../../../../../../_helpers/helpers';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import {
  buttonActions,
  locations,
  typeBasedLocations,
} from '../post-race/harness-reports/components/race-reports/locations';
import { Title } from '@angular/platform-browser';
import events from './events';

import { firstValueFrom } from 'rxjs';
import { DevicesService } from 'src/app/services/devices.service';
import VenueEmails from '../services/emails';

@Component({
  selector: 'app-venve-details',
  templateUrl: './venve-details.component.html',
  styleUrls: ['./venve-details.component.css'],
})
export class VenueDetailsComponent implements OnInit, OnDestroy {
  /*
=====queryparams=====
event_id
venue_id
date(yyyy-mm-dd)
event_name
*/

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (this.hasUnsavedChanges) {
      $event.returnValue = true;
    }
  }
  track_condition_options: string[] = [
    'Firm 1',
    'Firm 2',
    'Good 3',
    'Good 4',
    'Soft 5',
    'Soft 6',
    'Soft 7',
    'Heavy 8',
    'Heavy 9',
    'Heavy 10',
  ];
  weather_options: string[] = [
    'Fine',
    'Hot',
    'Cloudy',
    'Overcast',
    'Raining',
    'Showers',
  ];
  eventId: string | undefined = undefined;
  venueId: string | undefined = undefined;
  date: string | undefined = undefined;
  eventName: string | undefined = undefined;
  event: any = {};
  loading: boolean = false;
  races: RacesObject[] = [];
  traceList: any = [];
  location: any;
  loading_eod: boolean = false;
  today: boolean = false;
  un: string | null = localStorage.getItem('un');

  headers: any = [
    {
      id: 'ExternalRaceId',
      name: 'Race ID',
      type: 'string',
      show: true,
      // filter: true,
      link: {
        href: '/dash/race-details',
        queryParamsKey: 'queryParamsRace',
      },
    },
    {
      id: 'SquentialRaceOrderNumber',
      name: 'Race Number',
      type: 'string',
      show: true,
      // filter: true,
      // link: {
      //   href: '',
      //   queryParamsKey: ''
      // }
    },
    {
      id: 'RaceName',
      name: 'Race Name',
      type: 'string',
      show: true,
      align: 'left',
      // filter: true,
    },
    {
      id: 'RaceTimeHTML',
      name: 'Race Time',
      type: 'string',
      show: true,
      innerHTML: true,
      // filter: true,
    },
    {
      id: 'status',
      name: 'Race Status',
      type: 'component',
      // filter: true,
      component: {
        name: 'HorseStatusViewComponent',
      },
      show: true,
    },
    {
      id: 'buttonActions',
      name: 'Report Status',
      type: 'component',
      // filter: true,
      component: {
        name: 'RaceStatusViewComponent',
      },
      show: true,
    },
    {
      id: 'RaceLength',
      name: 'Race Length',
      type: 'string',
      show: true,
      // filter: true,
    },
    {
      id: 'Track',
      name: 'Current EQTrace Track',
      type: 'string',
      // filter: true,
      or: { type: 'string', text: 'Not assigned' },
      show: true,
    },
    {
      id: 'TrackID',
      name: 'EQTrace Track',
      type: 'dropdown',
      show: true,
      dropdown: {
        getOptions: () => {
          return this.traceList || [];
        },
        key: 'TrackID',
      },
    },
  ];

  config: any;

  tagAssignmentOptionsMapping: any = {
    sequential: 'Sequential',
    set: 'Set',
  };

  constructor(
    private router: ActivatedRoute,
    private apiService: APIService,
    private notifier: NotifierService,
    private deviceService: DevicesService,
    public dialog: MatDialog,
    private title: Title,
    private navigator: Router
  ) {
    this.config = this.router.snapshot.data['config'];
    this.venueEmail = new VenueEmails(
      this.apiService,
      this.config[getENV()],
      this.notifier
    );
  }

  venueEmailData: any;
  venueEmail: VenueEmails;
  async ngOnInit(): Promise<void> {
    this.notifier.loading(true);
    this.router.queryParams.subscribe(async (params: any) => {
      this.eventId = params['event_id'];
      this.venueId = params['venue_id'];
      this.date = params['date'];
      this.today = moment().isSame(this.date, 'day');
      this.eventName = params['event_name'];
      if (this.eventName.toLowerCase().includes('poly')) {
        this.track_condition_options.unshift('Synthetic');
      }

      this.title.setTitle(`${this.eventName} - ${this.eventId}`);
      let formattedEventDate: number = moment(
        this.date,
        'YYYY-MM-DD'
      ).valueOf();
      let formattedCurrentDate: number = moment().valueOf();
      this.formDisabled = formattedEventDate < formattedCurrentDate;
      await this.load();
      this.formDisabled = false;
      this.location = null;
      Object.keys(locations).forEach((location_key: string) => {
        if (this.location) return;
        if (Array.isArray(locations[location_key])) {
          let temp = locations[location_key].find((location: any) => {
            return location.name
              .toLowerCase()
              .includes(this.eventName.toLowerCase());
          });
          if (temp) {
            this.location = temp;
            this.location['type'] = location_key;
          }
        } else {
          Object.keys(locations[location_key]).forEach((location_: string) => {
            if (this.location) return;
            let temp = locations[location_key][location_].find(
              (location: any) => {
                return location.name
                  .toLowerCase()
                  .includes(this.eventName.toLowerCase());
              }
            );
            if (temp) {
              this.location = temp;
              this.location['type'] = location_key;
            }
          });
        }
      });
    });
    // this.loadEODStatus();
  }

  staging: any = null;
  showStaging() {
    this.staging = this.event;
  }

  ut: string = localStorage.getItem('ut');

  kitsLoading: boolean = false;

  checkForChanges(): boolean {
    if (!this.oldEvent) return false;

    const hasKitChanged = this.event.kit !== this.oldEvent.kit;
    const hasTagAssignmentChanged =
      this.event.tagAssignment !== this.oldEvent.tagAssignment;
    const hasWeatherChanged = this.event.weather !== this.oldEvent.weather;
    const hasTrackConditionChanged =
      this.event.track_condition !== this.oldEvent.track_condition;
    const hasEventStatusChanged = this.event.status !== this.oldEvent.status;

    const hasAnyRaceStateChanged = this.races.some(
      (currentRace: any, index: number) => {
        const oldRace = this.oldEvent.races[index];
        return currentRace.TrackID !== oldRace.TrackID;
      }
    );

    return (
      hasKitChanged ||
      hasTagAssignmentChanged ||
      hasWeatherChanged ||
      hasTrackConditionChanged ||
      hasEventStatusChanged ||
      hasAnyRaceStateChanged
    );
  }

  public get hasUnsavedChanges(): boolean {
    return this.checkForChanges();
  }

  async load(): Promise<void> {
    let status: boolean = checkForVenuePermission(this.venueId);
    if (!status) {
      this.notifier.alert(
        'Error',
        '',
        'You do not have permission to access this venue',
        'error',
        5000
      );
      this.notifier.loading(false);
      this.navigator.navigate(['/dash/race-overview']);
      return;
    }
    this.oldEvent = {};
    this.kitsLoading = true;
    this.notifier.loading(true);
    let promises = [];
    // this.getKits();
    await this.getTracts();
    promises.push(this.loadRacesBasedOnEvent());
    promises.push(this.loadEventDetails());
    Promise.all(promises).then(async (values: any) => {
      this.notifier.loading(false);
      const race_type = getRaceType(this.venueId)['raceTypeCode'];
      if (race_type == 'TH') {
        this.loadRailPositions();
      }
      this.venueEmailData = await this.venueEmail.loadVenueEmail(this.venueId);
      this.getPDFDetails();
    });
  }

  async navigate(
    event_id: string,
    venue_id: string,
    date: string,
    event_name: string
  ) {
    let navigateCheck: boolean = false;
    if (this.hasUnsavedChanges) {
      let result: boolean = confirm(
        'You have unsaved changes. Are you sure you want to leave?'
      );
      if (result) {
        navigateCheck = true;
      } else {
        navigateCheck = false;
      }
    } else {
      navigateCheck = true;
    }

    if (navigateCheck) {
      this.navigator.navigate(['/dash/venue-details'], {
        queryParams: {
          event_id: event_id,
          venue_id: venue_id,
          date: date,
          event_name: event_name,
        },
      });
    }
    // let status = await this.submitVenue(false);

    // if (status) {
    //   this.navigator.navigate(['/dash/venue-details'], {
    //     queryParams: {
    //       event_id: event_id,
    //       venue_id: venue_id,
    //       date: date,
    //       event_name: event_name,
    //     },
    //   });
    // }
  }

  hidePrerace(event: any) {
    if (event) {
      this.load();
    }
    this.prepareForRaceVenue = null;
  }

  destroyed: boolean = false;

  async generateStartList() {
    if (!this.event.kit) {
      this.notifier.alert('Info', '', 'No kit assigned to event', 'info', 5000);
      return;
    }
    this.event['pdf_status'] = 'GENERATING';
    await this.updateScratchings(false);
    let apiURL: string = `${
      this.config[getENV()].raceAPI
    }/pdf-task?ExternalEventId=${this.eventId}`;

    let header: any = {
      'X-Api-Key': 'e2bvuAoslY1VJvFMqhr5x7nxoEhFVRii9jRngS3U',
    };

    let result: any = await this.apiService.postDataPromis(apiURL, {}, header);

    if (result.task_id && result.status == '1') {
      this.notifier.alert('Success', '', 'Generating PDF...', 'success', 5000);
      setTimeout(() => {
        this.getPDFDetails();
      }, 5000);
    } else {
      this.event['pdf_status'] = 'FAILED';
    }
    this.notifier.loading(false);
  }

  sendStartListEmail: boolean = false;
  loadingStartListEmail: boolean = false;
  emailStartList() {
    this.sendStartListEmail = true;
  }

  rail_loading: boolean = false;
  apiKey: string = 'e2bvuAoslY1VJvFMqhr5x7nxoEhFVRii9jRngS3U';
  async loadRailPositions() {
    this.rail_loading = true;
    const headers: any = {
      'x-api-key': this.apiKey,
      'Content-Type': 'application/json',
    };
    const apiURL: string = `${
      this.config[getENV()].raceAPI
    }/railpositiondatamessage?ExternalEventId=${this.eventId}`;

    const result: any = await this.apiService.getDataPromis(
      apiURL,
      {},
      headers
    );

    if (
      result &&
      result['rail positions'] &&
      result['rail positions'].length > 0
    ) {
      const railPositions = result['rail positions'];
      const hasRailPositionWithStart = railPositions.some((position: any) => {
        return position.Start != '0';
      });

      if (hasRailPositionWithStart) {
        this.event.rail_green = true;
      } else {
        this.event.rail_green = false;
      }
    }

    this.rail_loading = false;
  }

  async hideEmailStartList(event: any) {
    this.sendStartListEmail = false;
    if (event) {
      this.loadingStartListEmail = true;
      this.event['startlist'] = false;
      let apiURL: string = `${
        this.config[getENV()].raceAPI
      }/generatestartlist?ExternalEventId=${
        this.eventId
      }&send_email=${true}&s3_key=${this.event['pdf_file_path']}`;

      let header: any = {
        'X-Api-Key': 'e2bvuAoslY1VJvFMqhr5x7nxoEhFVRii9jRngS3U',
      };

      let result: any = await this.apiService.getDataPromis(apiURL, {}, header);

      if (result.s == '1' || result.status == '1') {
        this.event['startlist'] = true;
        this.notifier.alert('Success', '', result.message, 'success', 5000);
      } else {
        this.notifier.alert(
          'Error',
          '',
          result.error || result.message || result.error_message,
          'error',
          5000
        );
      }
      this.loadingStartListEmail = false;
    }
  }

  jiraTracker: string = null;

  oldEvent: any = {};

  async loadEventDetails() {
    let apiURL: string = `${
      this.config[getENV()].raceAPI
    }/getevent?ExternalEventId=${this.eventId}`;

    let header: any = {
      'X-Api-Key': 'e2bvuAoslY1VJvFMqhr5x7nxoEhFVRii9jRngS3U',
    };

    let result: any = await this.apiService.getDataPromis(apiURL, {}, header);

    result.event.status = result.event.event_state || 'PLANNED';

    if (
      result.event['weather'] &&
      !this.weather_options.includes(result.event['weather'])
    ) {
      this.weather_options.push(result.event['weather']);
    }
    if (
      result.event['track_condition'] &&
      !this.weather_options.includes(result.event['track_condition'])
    ) {
      this.track_condition_options.push(result.event['track_condition']);
    }
    this.event = result.event;
    this.event.publishCompleted = 0;
    this.oldEvent = {
      ...this.oldEvent,
      ...JSON.parse(JSON.stringify(this.event)),
    };
    if (this.event['raceType'] == 'Thoroughbred Racing') {
      if (this.eventName == 'Cranbourne') {
        this.eventName = 'Cranbourne Turf';
      }
    } else {
      if (!this.event.custom_rail) {
        this.event.rail = this.event.rail || '0';
        this.event.custom_rail = this.event.custom_rail || '0';
      }
    }
    this.event.raceType = getRaceType(this.venueId)['raceTypeStr'];

    const jurisdiction = typeBasedLocations[this.event['raceType']]?.find(
      (jurisdiction: any) =>
        jurisdiction.Locations.some(
          (location: any) =>
            location.Name.toLowerCase() === this.eventName.toLowerCase()
        )
    );
    if (jurisdiction) {
      this.event['jurisdictionCode'] = jurisdiction.JurisdictionCode;
      this.event['Jurisdiction'] = jurisdiction.Jurisdiction;
      this.event['stateCode'] = jurisdiction.State;
    }
    if (!this.event['kit']) {
      this.event['kit'] = this.event['default_kit'];
    }
    this.event['venue_id'] = this.venueId;
    this.event['venue'] = this.eventName;
    this.event['old_kit'] = this.event['kit'];
    this.event['event_id'] = this.eventId;
    this.event['event_date'] = moment(this.date, 'DD MMMM YYYY').format(
      'YYYY-MM-DD'
    );
    this.oldEvent = {
      ...this.oldEvent,
      ...JSON.parse(JSON.stringify(this.event)),
    };
    if (!this.event?.eod_report) {
      this.loadEODStatus();
    }
  }

  selectKit() {
    this.event.kit = this.event.kit_edit;
    this.event.kit_edit = undefined;
  }

  selectRail() {
    if (this.event?.custom_split?.length > 32) {
      this.notifier.alert(
        'Info',
        '',
        'Custom rail length should be less than 32 characters',
        'info',
        5000
      );
      return;
    }
    this.event.rail = this.event.rail_edit;
    this.event.rail_edit = undefined;
  }

  selectTagAssignment() {
    this.event.tag_assignment = this.event.tag_assignment_edit;
    this.event.tag_assignment_edit = undefined;
  }

  prepareForRaceVenue: string = null;
  eodReport: boolean = false;
  type: string = null;

  prepareForRace(type: string = 'login', force: boolean = false) {
    if (
      (type == 'login' || type == 'manual_login') &&
      this.event['pc'] &&
      this.event['logoff'] != '1'
    ) {
      this.notifier.alert(
        'Info',
        '',
        'Prepare for race is already completed, please logout before running again',
        'info',
        5000
      );
      return;
    }
    if (
      type == 'logout' &&
      (this.event['logoff'] == '1' || !this.event['pc'])
    ) {
      this.notifier.alert(
        'Info',
        '',
        'Logout process is already completed',
        'info',
        5000
      );
      return;
    }
    // if (type == 'logout' && this.event['operator'] != this.un) {
    //   this.notifier.alert(
    //     'Info',
    //     '',
    //     'Only assigned operator can perform this action',
    //     'info',
    //     5000
    //   );
    //   return;
    // }
    if (type == 'logout' && !force) {
      this.logoutConfirm = {
        database_backup: false,
        close_the_trace: false,
        issues_logging: false,
        faulty_tags: false,
        pc_start_stop: false,
      };
      return;
    }
    if (type == 'login' && !force) {
      this.loginConfirm = {
        pc_start_stop: false,
      };
      return;
    }
    if (type == 'login') {
      this.event['pc_start_stop'] = this.loginConfirm['pc_start_stop'];
    } else if (type == 'logout') {
      this.event['pc_start_stop'] = this.logoutConfirm['pc_start_stop'];
    }
    this.logoutConfirm = null;
    this.loginConfirm = null;
    this.type = type;
    this.prepareForRaceVenue = this.eventName.replace(' Professional', '');
    this.prepareForRaceVenue = this.eventName.replace(' C CLASS', '');
  }

  logoutConfirm: any = null;
  loginConfirm: any = null;

  hideRailCalculator(event: any) {
    this.railCalculatorData = null;
    if (event) {
      this.load();
    }
  }

  currentDateEvents: any = {};
  currentEventIndex: number = 0;

  async loadEventsBasedOnDates(date: string): Promise<any> {
    let result: any = null;
    if (events[date]) {
      result = {
        eventNames: events[date],
      };
    } else {
      let date = moment(this.date, 'DD MMMM YYYY').format('YYYY-MM-DD');

      let raceTypes: any = localStorage.getItem('raceTypes');
      if (raceTypes) {
        raceTypes = JSON.parse(raceTypes);
      }
      let apiURL: string = `${
        this.config[getENV()].raceAPI
      }/event-list?formatted_date=${date}`;

      if (raceTypes && raceTypes.length > 0) {
        apiURL += `&race_types=${raceTypes.join(',')}`;
      }

      result = await this.apiService.getDataPromis(apiURL, {}, {});
    }

    if (result.eventNames && result.eventNames.length > 0) {
      result.eventNames = result.eventNames.filter((event: any) => {
        return checkForVenuePermission(event['external_venue_id']);
      });
      // events[date] = result.eventNames;
      if (result.eventNames.length > 1) {
        let temp: any = result.eventNames.findIndex((event: any) => {
          return event['external_event_id'] == this.eventId;
        });
        this.currentEventIndex = temp;
        if (temp == result.eventNames.length - 1) {
          this.currentDateEvents['next'] = null;
        } else {
          this.currentDateEvents['next'] = result.eventNames[temp + 1];
        }
        if (temp == 0) {
          this.currentDateEvents['prev'] = null;
        } else {
          this.currentDateEvents['prev'] = result.eventNames[temp - 1];
        }
      } else {
        this.currentEventIndex = 0;
      }
    }
  }

  aggregateTracks(trackList: any) {
    const aggregatedTracks = [];
    const trackGroups = new Map();

    // Aggregate based on TrackID, calculating min/max RaceLength
    for (const track of trackList) {
      const key = track.TrackID;
      if (!trackGroups.has(key)) {
        trackGroups.set(key, {
          ...track,
          MinRaceLength: parseFloat(track.RaceLength),
          MaxRaceLength: parseFloat(track.RaceLength),
        });
      } else {
        const existingTrack = trackGroups.get(key);
        const currentRaceLength = parseFloat(track.RaceLength);
        existingTrack.MinRaceLength = Math.min(
          existingTrack.MinRaceLength,
          currentRaceLength
        );
        existingTrack.MaxRaceLength = Math.max(
          existingTrack.MaxRaceLength,
          currentRaceLength
        );
        trackGroups.set(key, existingTrack);
      }
    }

    // Build aggregatedTracks array from the grouped track information
    for (const [_, track] of trackGroups) {
      aggregatedTracks.push({
        ...track,
        RaceLength: track.MaxRaceLength, // Combine Min and Max RaceLength
      });
    }

    // Sort tracks based on the numerical part of RaceLength (min value)
    const sortedTracks = aggregatedTracks.sort(
      (a, b) => a.RaceLength - b.RaceLength
    );
    return this.modifyRaceLengthsWithUniqueness(sortedTracks);
  }

  modifyRaceLengthsWithUniqueness(tracks: any[]): any[] {
    // Reverse the order of tracks before processing
    tracks = tracks.reverse();

    const modifiedLengths = new Set();
    const modifiedTracks = tracks.map((track) => {
      let baseLength = Math.floor(Number(track.RaceLength) / 100) * 100; // Remove the last two digits
      let newLength = (baseLength + 99).toString() + '.0'; // Add 99 and convert back to string with '.0'

      // Ensure uniqueness of the new RaceLength
      if (!modifiedLengths.has(newLength)) {
        modifiedLengths.add(newLength);
        track.RaceLength = newLength;
        track.MaxRaceLength = newLength;
      }
      return track;
    });

    // Reverse the order back to its original after processing
    return modifiedTracks.reverse();
  }

  async loadRacesBasedOnEvent(): Promise<boolean> {
    return new Promise(async (resolve: any) => {
      let raceType: string = getRaceType(this.venueId)['raceTypeStr'];
      const jurisdiction = typeBasedLocations[raceType]?.find(
        (jurisdiction: any) =>
          jurisdiction.Locations.some(
            (location: any) =>
              location.Name.toLowerCase() === this.eventName.toLowerCase()
          )
      );
      let jurisdictionCode: string = null;
      let stateCode: string = null;
      if (jurisdiction) {
        jurisdictionCode = jurisdiction.JurisdictionCode;
        stateCode = jurisdiction.State;
      }
      let date = moment(this.date, 'DD MMMM YYYY').format('YYYY-MM-DD');
      let result: any = null;
      let check: boolean = false;
      if (events && events[date] != undefined) {
        let temp: any = events[date].find((event: any) => {
          return event['external_event_id'] == this.eventId;
        });
        if (temp && temp['races'] && temp['races'].length > 0) {
          result = temp['races'];
          check = true;
        }
        if (events[date].length > 1) {
          let temp: any = events[date].findIndex((event: any) => {
            return event['external_event_id'] == this.eventId;
          });
          if (temp == events[date].length - 1) {
            this.currentDateEvents['next'] = null;
          } else {
            this.currentDateEvents['next'] = events[date][temp + 1];
          }
          if (temp == 0) {
            this.currentDateEvents['prev'] = null;
          } else {
            this.currentDateEvents['prev'] = events[date][temp - 1];
          }
        }
      }

      if (!check) {
        await this.loadEventsBasedOnDates(date);
        let apiURL: string = `${
          this.config[getENV()].raceAPI
        }/getracedetails?ExternalVenueId=${this.venueId}&ExternalEventId=${
          this.eventId
        }`;

        result = await this.apiService.getDataPromis(apiURL, {}, {});
        // events[date][this.currentEventIndex]['races'] = result;
      }

      if (result && Array.isArray(result) && result.length > 0) {
        this.traceList = this.aggregateTracks(this.traceList);
        result = result
          .map((race: any) => {
            race.rawRaceTime = race.RaceTime;
            if (!race.TrackID) {
              this.event.rail_green = false;
            }
            race['status'] = {
              PLANNED: 0,
              DNS: 0,
              DNT: 0,
              DNF: 0,
              DSQ: 0,
              OFFICIAL: 0,
              FINISHED: 0,
              PUBLISHED: 0,
              ABANDONED: 0,
            };
            if (!race['RaceState']) {
              race['RaceState'] = 'PLANNED';
              race['raceStatus'] = race['RaceState'] || 'PLANNED';
            }
            if (race['RaceState'].toUpperCase() == 'ABANDONED') {
              race['status']['ABANDONED'] = race['AnimalDetail'].length;
            } else {
              race['AnimalDetail'].forEach((animal: any) => {
                let status = 'PLANNED';
                animal['RaceState'] = animal['RaceState'] || 'PLANNED';
                if (animal['RaceState'].toUpperCase() == 'PLANNED') {
                  if (race['RaceState'].toUpperCase() == 'PLANNED') {
                    status = 'PLANNED';
                  } else if (race['RaceState'].toUpperCase() == 'FINISHED') {
                    status = 'FINISHED';
                  }
                } else {
                  status = animal['RaceState'].toUpperCase();
                }
                race['status'][status] += 1;
              });
            }
            race['RaceState'] = race['RaceState'] || 'PLANNED';
            race['AnimalDetail'].forEach((animal: any) => {
              animal['RaceState'] = animal['RaceState'] || 'PLANNED';
            });
            let filteredTracks: any = [];
            if (!race.TrackType) {
              race.TrackType = 'SYNTHETIC';
            }
            race.TrackType =
              race.TrackType.toUpperCase() != 'NONE'
                ? race.TrackType
                : 'SYNTHETIC';
            if (!race.TrackType) {
              filteredTracks = this.traceList;
            } else {
              filteredTracks = this.traceList.filter(
                (track: any) =>
                  track.TrackType.toLowerCase() === race.TrackType.toLowerCase()
              );
            }

            const raceLength: number = parseFloat(race.RaceLength);

            if (this.venueId === '884') {
              for (const track of filteredTracks) {
                const trackRaceLength: number = parseFloat(track.RaceLength);
                const maxTrackRaceLength: number = parseFloat(
                  track.MaxRaceLength
                );
                const minTrackRaceLength: number = parseFloat(
                  track.MinRaceLength
                );
                if (
                  raceLength >= minTrackRaceLength &&
                  raceLength <= maxTrackRaceLength
                ) {
                  race['TrackID'] = track.TrackID;
                  break;
                }
              }
            } else {
              for (const track of filteredTracks) {
                const trackRaceLength: number = parseFloat(track.RaceLength);
                if (trackRaceLength >= raceLength) {
                  race['TrackID'] = track.TrackID;
                  break;
                }
              }
            }
            race['buttonActions'] = [
              {
                slug: 'OR',
                status: 'PLANNED',
                actions: [],
              },
              {
                slug: 'PDF',
                status: 'PLANNED',
                actions: [],
              },
              {
                slug: 'CUST',
                status: 'PLANNED',
                actions: [],
              },
              {
                slug: 'TSD',
                status: 'PLANNED',
                actions: [],
              },
            ];
            let buttonActionsJSON = JSON.parse(JSON.stringify(buttonActions));
            buttonActionsJSON
              .filter((report: any) => {
                if (report.conditions) {
                  const condition = report.conditions.find(
                    (c: any) => c.state === jurisdictionCode
                  );
                  if (condition) {
                    const { key, equation, value } = condition.condition;
                    const raceValue = race[key];
                    switch (equation) {
                      case 'equals':
                        return raceValue === value;
                      case 'not equals':
                        return raceValue !== value;
                      default:
                        return true;
                    }
                  }
                  return true;
                }
                return true;
              })
              .forEach((report: any) => {
                if (
                  (report.states == 'ALL' ||
                    report.states.includes(jurisdictionCode) ||
                    report.states.includes(stateCode)) &&
                  (!report.type || report.type == raceType)
                ) {
                  let reportStatus = race['ReportStatus'].find(
                    (report_: any) => {
                      return report_['StepType'] == report['action'];
                    }
                  );
                  let action = race['buttonActions'].find(
                    (buttonAction: any) => buttonAction.slug == report['slug']
                  );

                  if (reportStatus) {
                    action['actions'].push({
                      name: report['name'],
                      status:
                        reportStatus['StepCompletionStatus'].toUpperCase(),
                      comment: reportStatus['Comments'] || '',
                    });
                  } else {
                    action['actions'].push({
                      name: report['name'],
                      status: 'PLANNED',
                      comment: '',
                    });
                  }
                }
              });

            race['buttonActions'] = race['buttonActions'].filter(
              (action: any) => action['actions'].length > 0
            );

            race['buttonActions'].forEach((action: any, index: number) => {
              let success = action['actions'].find((action_: any) => {
                return action_['status'] == 'COMPLETED';
              });
              let failed = action['actions'].find((action_: any) => {
                return action_['status'] == 'FAILED';
              });
              if (failed) {
                action['status'] = 'FAILED';
              } else if (success) {
                action['status'] = 'COMPLETED';
                if (index == race['buttonActions'].length - 1) {
                  race['publishStatus'] = 'COMPLETED';
                  this.event.publishCompleted += 1;
                }
              } else {
                action['status'] = 'PLANNED';
              }
            });

            race['queryParamsRace'] = {
              race_id: race['ExternalRaceId'],
              event_id: this.eventId,
            };
            if (!check) {
              race['RaceTimeStr'] = race['RaceTime'];
              race['raceStatus'] = race['RaceState'].toUpperCase();
            }
            race['RaceTime'] = convertTimestamp(
              race['RaceTimeStr'],
              this.venueState
            );
            race['RaceTimeHTML'] = race['RaceTime'];
            if (race['Track']) {
              let track = this.traceList.find((track_: any) => {
                return track_['name'] == race['Track'];
              });
              if (track) {
                race['TrackID'] = track['TrackID'];
              }
            }
            return race;
          })
          .sort((a: RacesObject, b: RacesObject) => {
            return (
              a['SquentialRaceOrderNumber'] - b['SquentialRaceOrderNumber']
            );
          });
        this.races = result;
        this.oldEvent['races'] = JSON.parse(JSON.stringify(result));

        // this.getRaceResult(result);
      }

      resolve(true);
    });
  }

  async updateScratchings(refresh: boolean = true) {
    return new Promise(async (resolve: any, reject: any) => {
      this.event.loadingScrating = true;
      let apiURL: string = `${
        this.config[getENV()].raceAPI
      }/scratching?${this.races
        .map((race) => {
          return `raceIds[]=${race.ExternalRaceId}`;
        })
        .join('&')}`;

      let result: any = await this.apiService.postDataPromis(apiURL, {}, {});

      if (result.status == '1' || result.s == '1') {
        if (refresh) {
          this.notifier.alert(
            'Success',
            '',
            'Scratchings updated successfully',
            'success',
            5000
          );
          this.load();
        }
      } else {
        this.notifier.alert('Error', '', result.error_message, 'error', 5000);
      }

      this.event.loadingScrating = false;
      resolve(true);
    });
  }

  eodReportNotReady: boolean = false;
  get canSendEOD(): boolean {
    return this.races.every((race) => {
      return [
        'PUBLISHED',
        'ABANDONED',
        'DIDNOTTRACK',
        'FINISHED',
        'OFFICIAL',
      ].includes(race.raceStatus.toUpperCase());
    });
  }

  get hasFinishedRace(): boolean {
    return this.races.some((race) =>
      ['FINISHED', 'OFFICIAL'].includes(race.raceStatus.toUpperCase())
    );
  }

  hideEODReportWarning(event) {
    if (event) {
      this.showEODReport(true);
    }
    this.eodReportNotReady = false;
  }

  showEODReport(force: boolean = false) {
    if (this.loading_eod) {
      this.notifier.alert(
        'Info',
        '',
        'Report is getting generated!',
        'info',
        5000
      );
      return;
    }
    // if (this.event['eod_report']) {
    //   this.notifier.alert('Info', '', 'Report is already sent!', 'info', 5000);
    //   return;
    // }
    if (this.hasFinishedRace && !force) {
      this.eodReportNotReady = true;
    } else {
      this.eodReport = true;
    }
  }

  async checkButtonAPIStatus(action: string, errorHide: boolean = false) {
    return new Promise(async (resolve: any, reject: any) => {
      let payload: any = {
        action: 'action_status',
        event_state:
          this.event['event_state'] || this.event['status'] || 'PLANNED',
        event_id: this.eventId,
        button_action: action,
        venue_id: this.venueId,
      };

      let apiURL: string = `${this.config[getENV()].raceAPI}/flask-operations`;

      let result: any = await this.apiService.postDataPromis(
        apiURL,
        payload,
        {}
      );

      if (result.status == '1' || result.s == '1') {
        if (
          result?.response?.StepCompletionStatus?.toUpperCase() == 'FAILED' &&
          !errorHide
        ) {
          if (this.loading_eod) {
            this.notifier.alert(
              'Info',
              '',
              result?.response?.Comments ||
                'Something went wrong. Please try again!',
              'info',
              5000
            );
          }
        }
        resolve(
          result?.response?.StepCompletionStatus?.toUpperCase() || 'PENDING'
        );
      } else {
        reject('FAILED');
      }
    });
  }

  async hideEODReport(event: any) {
    this.eodReport = false;
    if (event) {
      this.loading_eod = true;
      this.loadEventDetails();
      this.loadEODStatus();
    }
  }

  async loadEODStatus() {
    let status = await this.checkButtonAPIStatus('publish_eod_report', true);
    if (status == 'COMPLETED') {
      this.event['eod_report'] = 1;
      this.loading_eod = false;
    } else if (status == 'FAILED') {
      this.loading_eod = false;
    } else if (status != 'PENDING') {
      setTimeout(() => {
        this.loadEODStatus();
      }, 5000);
    }
  }

  // async getRaceResult(races: any) {
  //   return new Promise(async (resolve: any) => {
  //     let apiURL: string = `${
  //       this.config[getENV()].raceAPI
  //     }/getraceresults?ExternalEventId=${this.eventId}&ExternalVenueId=${
  //       this.venueId
  //     }&${races
  //       .map((race: any) => {
  //         return `raceIds%5B%5D=${race['ExternalRaceId']}`;
  //       })
  //       .join('&')}`;

  //     let result: any = await this.apiService.getDataPromis(apiURL, {}, {});

  //     if (result && typeof result == 'object') {
  //       races.forEach((race: any) => {
  //         if (result[race['ExternalRaceId']] != undefined) {
  //           race['raceStatus'] = result[race['ExternalRaceId']] || 'PLANNED';
  //         }
  //         race['status'] = {
  //           PLANNED: 0,
  //           DNS: 0,
  //           DNT: 0,
  //           DNF: 0,
  //           DSQ: 0,
  //           OFFICIAL: 0,
  //           FINISHED: 0,
  //           PUBLISHED: 0,
  //           ABANDONED: 0,
  //         };
  //         if (!race['RaceState']) {
  //           race['RaceState'] = race['raceStatus'];
  //         }
  //         if (race['RaceState'].toUpperCase() == 'ABANDONED') {
  //           race['status']['ABANDONED'] = race['AnimalDetail'].length;
  //         } else {
  //           race['AnimalDetail'].forEach((animal: any) => {
  //             let status = 'PLANNED';
  //             if (animal['RaceState'].toUpperCase() == 'PLANNED') {
  //               if (race['RaceState'].toUpperCase() == 'PLANNED') {
  //                 status = 'PLANNED';
  //               } else if (race['RaceState'].toUpperCase() == 'FINISHED') {
  //                 status = 'FINISHED';
  //               }
  //             } else {
  //               status = animal['RaceState'].toUpperCase();
  //             }
  //             race['status'][status] += 1;
  //           });
  //         }
  //       });
  //       this.event.status = 'PLANNED';
  //       if (!this.event.event_state) {
  //         const isToday = moment().isSame(
  //           moment(this.date, 'DD MMMM YYYY'),
  //           'day'
  //         );
  //         let statues = Object.values(result);
  //         if (statues.length == 0) {
  //           statues = ['DNS'];
  //         } else if (statues.length != races.length) {
  //           statues.push('PLANNED');
  //         }
  //         // statues = ['DNS', 'DNS', 'DNS'];
  //         if (statues.includes('DNS') || statues.includes('dns')) {
  //           if (statues.includes('PLANNED') && isToday) {
  //             this.event.status = 'RUNNING';
  //           } else if (!statues.includes('FINISHED')) {
  //             this.event.status = '-';
  //           } else {
  //             this.event.status = 'INCOMPLETED';
  //           }
  //         } else if (statues.includes('PLANNED')) {
  //           this.event.status = 'PLANNED';
  //           if (statues.includes('FINISHED') && isToday) {
  //             this.event.status = 'RUNNING';
  //           }
  //         } else {
  //           if (statues.includes('FINISHED')) {
  //             this.event.status = 'COMPLETED';
  //           }
  //         }
  //       } else {
  //         this.event.status =
  //           this.event.event_state?.toUpperCase() || 'PLANNED';
  //       }
  //     }

  //     // this.races = [...this.races, ...races];
  //     resolve(true);
  //   });
  // }

  async getPDFDetails() {
    return new Promise(async (resolve: any) => {
      let apiURL: string = `${
        this.config[getENV()].raceAPI
      }/pdf-task?ExternalEventId=${this.eventId}`;

      this.event['pdf_status'] = 'LOADING';

      let result: any = await this.apiService.getDataPromis(apiURL, {}, {});

      this.event['old_kit'] = this.event['kit'];

      if (result.status == '1') {
        if (result['pdf_file_path']) {
          this.event['pdf_file_path'] = result['pdf_file_path'];
          this.event['pdf_status'] = 'GENERATED';
        } else if (result['is_running']) {
          this.event['pdf_status'] = 'GENERATING';
        } else if (!result['is_running'] && result['error_status']) {
          this.event['pdf_status'] = 'FAILED';
          this.event['pdf_error'] = result['error_status'];
        }
        if (
          this.event['pdf_error']
            ?.toLowerCase()
            .includes('kit_id cannot be none')
        ) {
          this.event['old_kit'] = null;
        }
        setTimeout(() => {
          if (this.event['pdf_status'] == 'GENERATING') {
            this.getPDFDetails();
          }
        }, 5000);
      } else {
        this.notifier.alert(
          'Info',
          '',
          result.message || result.error_message || result.error,
          'info',
          5000
        );
        this.event['pdf_status'] = '';
      }
      resolve(true);
    });
  }

  kits: any = [];
  // barrierKits: any = [];
  formDisabled: boolean = false;
  codeType: string = '';
  venueState: string = '';

  // async getKits(): Promise<boolean> {
  //   return new Promise(async (resolve: any, reject: any) => {
  //     let apiURL = `${this.config[getENV()].raceAPI}/flask-operations?action=get_kits`;
  //     let data = await this.apiService.getDataPromis(apiURL, {}, {});
  //     var kit_range = data.kits;
  //     this.kits = kit_range
  //       .map((e: any) => {
  //         return e.name;
  //       })
  //       .sort((a: any, b: any) => {
  //         return b > a ? -1 : 1;
  //       });
  //     resolve(true);
  //   });
  // }

  async getTracts() {
    return new Promise(async (resolve: any) => {
      let apiURL: string = `${
        this.config[getENV()].raceAPI
      }/geteqtrack?ExternalVenueId=${this.venueId}`;

      let header: any = {
        'X-Api-Key': 'e2bvuAoslY1VJvFMqhr5x7nxoEhFVRii9jRngS3U',
      };
      let result: any = await this.apiService.getDataPromis(apiURL, {}, header);
      if (result.venue && result.venue.TrackList) {
        this.codeType = result.venue.CodeType;
        this.venueState = result.venue.VenueState;
        if (this.eventId.startsWith('BREEZE')) {
          this.kits = [
            'RQLD Kit 1 (BRC)',
            'RQLD Kit 2',
            'RQLD Kit 3',
            'HRV Kit 2',
            'HRNSW Kit 2',
          ];
        } else {
          let kit_list: any = [];
          const kitsUrl = `${
            this.config[getENV()].raceAPI
          }/devices/kit_ids?venue_state=${encodeURIComponent(
            result.venue.VenueState
          )}&code_type=${encodeURIComponent(result.venue.CodeType)}`;
          try {
            kit_list = await firstValueFrom(
              this.deviceService.getKitIds(kitsUrl)
            );
            if (kit_list.length !== 0) {
              this.kits = kit_list;
            }
          } catch (error) {
            console.error('Failed to load kit IDs', error);
            // Handle any errors here
          }
          this.kits = kit_list.sort((a: any, b: any) => {
            return b > a ? -1 : 1;
          });
        }
        // this.barrierKits = barrierItems.length ? barrierItems : ['Ignore Kit'];
        this.traceList = result.venue.TrackList.map((trace: any) => {
          return {
            name: trace['EQTrackName'],
            value: trace['TrackID'],
            TrackID: trace['EQTrackName'],
            ...trace,
          };
        });
      }
      this.kitsLoading = false;
      resolve(true);
    });
  }

  railCalculatorData: any = null;

  async generateRail() {
    this.railCalculatorData = {
      venueid: this.venueId,
      tracks: this.event,
      racelengths: this.races.map((race) => race.RaceLength),
      races: this.races,
      rail_position: this.event.rail_position,
      rail_start: this.event.rail_start,
      rail_end: this.event.rail_end,
      remainder: this.event.remainder,
      transition: this.event.transition,
      entire_course_rail: this.event.entire_course_rail,
      externalEventId: this.eventId,
    };
  }

  event_states = [
    'PLANNED',
    'RUNNING',
    'COMPLETED',
    'PUBLISHED',
    'DIDNOTTRACK',
    'ABANDONED',
  ];

  selectEventStatus() {
    this.event.status = this.event.status_edit;
    this.event.status_edit = undefined;
  }

  selectWeatherStatus() {
    this.event.weather = this.event.weather_edit;
    this.event.weather_edit = undefined;
  }

  selectTrackConditionStatus() {
    this.event.track_condition = this.event.track_condition_edit;
    this.event.track_condition_edit = undefined;
  }

  async submitVenue(force: boolean = true) {
    return new Promise(async (resolve: any, reject: any) => {
      if (this.event?.custom_split?.length > 32) {
        this.notifier.alert(
          'Info',
          '',
          'Custom split cannot be more than 32 characters',
          'info',
          5000
        );
        resolve(false);
      }
      let event = JSON.parse(JSON.stringify(this.event));
      let status: boolean = true;

      if (event.kit != this.oldEvent.kit) {
        status = false;
      }
      if (event.tag_assignment != this.oldEvent.tag_assignment) {
        status = false;
      }
      event.event_state = event.status || 'PLANNED';

      if (event.event_state != this.oldEvent.event_state) {
        status = false;
      }
      let index: any = this.oldEvent['races'].find(
        (race: any, index_: number) => {
          return race['TrackID'] != this.races[index_]['TrackID'];
        }
      );

      if (index) {
        status = false;
      }
      if (status && !force) {
        resolve(true);
        return;
      }
      if (!event.custom_rail) {
        event.custom_rail = null;
      }
      const apiUrl: string = `${this.config[getENV()].raceAPI}/update-race`;
      const apiKey: string = 'e2bvuAoslY1VJvFMqhr5x7nxoEhFVRii9jRngS3U';
      const trackDict: { [key: string]: string } = {};
      const trackTypeDict: { [key: string]: string } = {};

      this.traceList.forEach((item: any) => {
        trackDict[item.TrackID] = item.EQTrackName;
        trackTypeDict[item.TrackID] = item.TrackType;
      });

      let data: any = {
        kit: this.event['kit'],
        barrier_tag_assignment: this.event['barrier_tag_assignment'],
        tag_assignment: this.event['tag_assignment'],
        rail: this.event['rail'],
        custom_rail: this.event['custom_rail'],
        custom_split: this.event['custom_split'],
        event_state: this.event['status'],
        weather: this.event['weather'] !== '' ? this.event['weather'] : null,
        track_condition:
          this.event['track_condition'] !== ''
            ? this.event['track_condition']
            : null,
        races: this.races.map((race: any) => {
          return race['ExternalRaceId'];
        }),
      };

      data['raceTimes'] = this.races.reduce((acc: any, race: any) => {
        const rawTime = race.rawRaceTime;
        const momentObj = moment(rawTime, 'YYYY-MM-DD-HH:mm:ss');
        if (!race['diffTime']) {
          race['diffTime'] = 0;
        }
        momentObj.add('minutes', race['diffTime']);
        const newTime = momentObj.format('HH:mm');
        acc[race['ExternalRaceId']] = `${newTime}`;
        return acc;
      }, {});

      const trackNameDict: { [key: string]: string } = {};
      const trackTypeAssignmentDict: { [key: string]: string } = {};
      data['eventID'] = this.eventId;
      if (!this.event.kit) {
        this.notifier.alert(
          'Info',
          '',
          'Please select a kit for the event',
          'info',
          5000
        );
        resolve(false);
        return;
      }
      let check: boolean = false;
      for (let i = 0; i < this.races.length; i++) {
        const race = this.races[i];
        if (
          Number(race['TrackID']) < 0 ||
          race['TrackID'] == null ||
          race['TrackID'] == undefined
        ) {
          check = true;
          this.notifier.alert(
            'Info',
            '',
            'Please select a track for the race #' +
              race['SquentialRaceOrderNumber'] +
              ': ' +
              race['RaceName'],
            'info',
            5000
          );
          break;
        }
        const dynamicIdProperty = 'eqt_trace_track_' + race.ExternalRaceId;
        trackNameDict[dynamicIdProperty] = trackDict[race['TrackID']];
        trackTypeAssignmentDict[dynamicIdProperty] =
          trackTypeDict[race['TrackID']];
        data[dynamicIdProperty] = Number(race['TrackID']);
      }
      if (check) {
        resolve(false);
        return;
      }
      data['trackNames'] = trackNameDict;
      data['trackTypes'] = trackTypeAssignmentDict;
      this.notifier.loading(true);
      let headers: any = {
        Authorization: localStorage.getItem('t'),
        'x-api-key': apiKey,
      };
      let result: any = await this.apiService.postDataPromis(
        apiUrl,
        data,
        headers
      );

      this.notifier.loading(false);
      if (force) {
        location.reload();
      }
      resolve(true);
    });
  }

  ngOnDestroy(): void {
    this.destroyed = true;
  }

  showRaceTimePopup: boolean = false;
  selectedRace: any = null;
  newRaceTime: { hour: number; minute: number } = { hour: 0, minute: 0 };
  hours: number[] = Array.from({ length: 24 }, (_, i) => i);
  minutes: number[] = Array.from({ length: 60 }, (_, i) => i);

  openRaceTimePopup(race: any) {
    this.selectedRace = race;
    const [hour, minute] = race.RaceTime.split('H').map(Number);
    this.newRaceTime = { hour, minute };
    this.showRaceTimePopup = true;
  }

  closeRaceTimePopup() {
    this.showRaceTimePopup = false;
    this.selectedRace = null;
    this.newRaceTime = { hour: 0, minute: 0 };
  }

  addMinutes(minutes: number) {
    let time = moment({
      hour: this.newRaceTime.hour,
      minute: this.newRaceTime.minute,
    });
    time.add(minutes, 'minutes');
    this.newRaceTime.hour = time.hour();
    this.newRaceTime.minute = time.minute();
  }

  resetRaceTIme() {
    let raw = this.convertRaceTime(this.selectedRace.rawRaceTime);
    const [hour, minute] = raw.split('H').map(Number);
    this.newRaceTime = { hour, minute };
  }

  updateRaceTime() {
    if (this.selectedRace) {
      let raw = this.convertRaceTime(this.selectedRace.rawRaceTime);
      const [hour, minute] = raw.split('H').map(Number);
      const newRaceTime = `${this.newRaceTime.hour
        .toString()
        .padStart(2, '0')}H${this.newRaceTime.minute
        .toString()
        .padStart(2, '0')}`;
      if (this.newRaceTime.hour == hour && this.newRaceTime.minute == minute) {
        this.selectedRace.RaceTime = newRaceTime;
        this.selectedRace.timeChanged = false;
        this.selectedRace.RaceTimeHTML = newRaceTime;
        this.selectedRace.diffTime = 0;
      } else {
        const originalTime = moment({ hour, minute });
        const newTime = moment({
          hour: this.newRaceTime.hour,
          minute: this.newRaceTime.minute,
        });
        const diff = newTime.diff(originalTime, 'minutes');
        const diffFormatted = diff >= 0 ? `+${diff}m` : `${diff}m`;
        this.selectedRace.RaceTimeHTML = `${newRaceTime} <span class='fw-bold'>${diffFormatted}</span>`;
        this.selectedRace.RaceTime = newRaceTime;
        this.selectedRace.diffTime = diff;
        this.selectedRace.timeChanged = true;
      }
      this.closeRaceTimePopup();
    }
  }

  convertRaceTime(time: string): string {
    return convertTimestamp(time, this.venueState);
  }

  handleTableEvent(event: any) {
    if (event.type === 'editRaceTime') {
      this.openRaceTimePopup(event.item);
    }
  }
}
