import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotifierService } from 'src/app/services/notifier.service';
import { ActivatedRoute } from '@angular/router';
import { APIService } from 'src/app/services/api.service';

import { getENV } from 'src/app/_helpers/helpers';

export interface AnimalDetail {
  AnimalId: string;
  RaceState: string;
}

export interface RaceInterface {
  ExternalEventId: string;
  ExternalVenueId: string;
  ExternalRaceId: string;
  SquentialRaceOrderNumber: number;
  RaceName: string;
  RaceTime: string;
  RaceLength: string;
  Track: string;
  TrackType: string;
  StartType: string;
  AnimalDetail: AnimalDetail[];
  standardTimeMessage: any[]; // You can define a more specific type if needed.
}

export interface DialogData {
  venueid: string;
  tracks: any[];
  racelengths: any[];
  races: RaceInterface[];
  rail_position: string;
  rail_start: string;
  rail_end: string;
  remainder: string;
  transition: string;
  entire_course_rail: string;
  externalEventId: string;
}

export interface apiEQTrack {
  RaceLength: string;
  EQTrackName: string;
  TrackID: string;
  AdditionalStart: string;
  TrackType: string;
  TrackLength: string;
}

@Component({
  selector: 'rail_calculator',
  templateUrl: 'rail-calculator.component.html',
  styleUrls: ['rail-calculator.component.css'],
})
export class rail_dialog implements OnInit {
  @Input('data') data: any;
  @Output('hideModel') hideModel: any = new EventEmitter<boolean>();
  selectedOption: string = 'entireCourse';
  generating: boolean = false;
  entireCourseDetails: string = '';
  splitDetails1: string = '';
  splitDetails2: string = '';
  splitDetails3: string = '';
  splitDetails4: string = '';
  splitDetails5: string = '150';
  errorMessage: string = '';
  trackLengths: any[] = [];
  tableResult: any[] = [];
  tracker: any[] = [];
  apiKey = 'e2bvuAoslY1VJvFMqhr5x7nxoEhFVRii9jRngS3U';
  isButtonDisabled: boolean = false;
  config: any;
  constructor(
    private router: ActivatedRoute,
    private notifier: NotifierService,
    private apiService: APIService
  ) { }

  async ngOnInit(): Promise<void> {
    this.config = this.router.snapshot.data['config'];
    this.splitDetails1 = this.data.rail_position;
    this.splitDetails2 = this.data.rail_start;
    this.splitDetails3 = this.data.rail_end;
    this.splitDetails4 = this.data.remainder;
    this.splitDetails5 = this.data.transition ?? '150';
    this.entireCourseDetails = this.data.entire_course_rail;
    if (
      this.splitDetails1 != '' &&
      this.splitDetails1 !== null &&
      this.splitDetails1 !== '0'
    ) {
      this.selectedOption = 'split';
    } else {
      this.selectedOption = 'entireCourse';
    }
    // if (this.splitDetails1 !=="" || this.entireCourseDetails != ""){
    //   this.onGenerate();
    if (this.entireCourseDetails != '' || !isNaN(Number(this.splitDetails5))) {
      await this.fetchRailPositions();
      if(this.splitDetails5 == '150') {
        this.onSplitDetails5Change();
      }
    } else {
      this.entireCourseDetails = '0';
    }
  }

  onNoClick(): void {
    this.isButtonDisabled = false;
    this.hideModel.emit(false);
  }
  async onGenerate(): Promise<void> {
    this.errorMessage = '';

    if (this.selectedOption === 'entireCourse') {
      if (
        this.entireCourseDetails === null ||
        this.entireCourseDetails === undefined
      ) {
        this.errorMessage = 'Please enter valid entire course details.';
        return;
      }
    } else if (this.selectedOption === 'split') {
      if (
        this.splitDetails1 === null ||
        this.splitDetails1 === undefined ||
        this.splitDetails2 === null ||
        this.splitDetails2 === undefined ||
        this.splitDetails3 === null ||
        this.splitDetails3 === undefined ||
        this.splitDetails4 === null ||
        this.splitDetails4 === undefined ||
        this.splitDetails5 === null ||
        this.splitDetails5 === undefined
      ) {
        this.errorMessage = 'Please enter valid split details.';
        return;
      }
    }

    // Proceed with generating the table
    this.generating = true;
    this.tableResult = [];
    const apiUrl = `${this.config[getENV()].raceAPI}/geteqtrack?ExternalVenueId=${this.data.venueid}`;
    const apiKey = 'e2bvuAoslY1VJvFMqhr5x7nxoEhFVRii9jRngS3U';
    this.trackLengths = [];
    const headers: any = {
      accept: 'application/json',
      'x-api-key': apiKey,
    };

    const data: any = await this.apiService.getDataPromis(apiUrl, {}, headers);

    if (data && data.venue) {
      let tracklist = data.venue.TrackList;
      const resultArray = this.data.races
        .map((race) => {
          const trackKey =
            `eqt_trace_track_${race.ExternalRaceId}` as keyof typeof this.data.tracks;
          const trackValue = this.data.tracks[trackKey];
          return { EQTrackName: trackValue };
        })
        .filter((item) => item !== null);

      resultArray.forEach((item) => {
        if (item['EQTrackName'] === null) {
          this.generating = false;
          this.errorMessage = 'Missing EQTrack! Please submit it!';
          return;
        }
      });
      resultArray.forEach((item) => {
        let shouldContinue = false;
        tracklist.forEach((trackname: apiEQTrack) => {
          if (trackname.EQTrackName === item.EQTrackName) {
            if (trackname.TrackLength === null) {
              this.errorMessage =
                'Missing TrackLength! Let Carl to add them in!';
            }
            if (trackname.TrackLength && !shouldContinue) {
              this.trackLengths.push([
                trackname.TrackLength,
                trackname.AdditionalStart,
                trackname.TrackType,
                trackname.RaceLength
              ]);
              shouldContinue = true;
              return;
            }
          }
        });

        if (shouldContinue) {
          return;
        }
      });
    }
    if (this.selectedOption === 'split') {
      this.trackLengths.forEach((item, index) => {
        const raceLength = this.data.racelengths[index];
        const startingPos = Math.max(
          parseFloat(item[0]),
          parseFloat(raceLength)
        );
        const pushing: any[] = [];
        let lowerItem = item[2].toLowerCase();
        if (!lowerItem.includes('turf')) {
          pushing.push({
            add: 0,
            start: parseFloat(item[0]),
            end: 0,
            transition: 0,
          });
        } else {
          var newstart: number = 0;
          if (parseFloat(raceLength) - parseFloat(item[0]) > 0) {
            if (parseFloat(this.splitDetails3) != 0) {
              if (parseFloat(item[0]) >= parseFloat(this.splitDetails2)) {
                pushing.push({
                  add: parseFloat(this.splitDetails4),
                  start: newstart === 0 ? parseFloat(item[0]) : newstart,
                  end:
                    parseFloat(this.splitDetails2) +
                    parseFloat(this.splitDetails5) / 2,
                  transition: parseFloat(this.splitDetails5),
                });
                pushing.push({
                  add: parseFloat(this.splitDetails1),
                  start:
                    parseFloat(this.splitDetails2) +
                    parseFloat(this.splitDetails5) / 2,
                  end:
                    parseFloat(this.splitDetails3) +
                    parseFloat(this.splitDetails5) / 2,
                  transition: parseFloat(this.splitDetails5),
                });
              } else {
                pushing.push({
                  add: parseFloat(this.splitDetails1),
                  start: newstart === 0 ? parseFloat(item[0]) : newstart,
                  end:
                    parseFloat(this.splitDetails3) +
                    parseFloat(this.splitDetails5) / 2,
                  transition: parseFloat(this.splitDetails5),
                });
              }
              pushing.push({
                add: parseFloat(this.splitDetails4),
                start:
                  parseFloat(this.splitDetails3) +
                  parseFloat(this.splitDetails5) / 2,
                end: 0,
                transition: 0,
              });
            } else {
              if (parseFloat(item[0]) >= parseFloat(this.splitDetails2)) {
                pushing.push({
                  add: parseFloat(this.splitDetails1),
                  start: newstart === 0 ? parseFloat(item[0]) : newstart,
                  end:
                    parseFloat(item[0]) -
                    parseFloat(this.splitDetails5) / 2,
                  transition: parseFloat(this.splitDetails5),
                });
                pushing.push({
                  add: parseFloat(this.splitDetails4),
                  start:
                    parseFloat(item[0]) -
                    parseFloat(this.splitDetails5) / 2,
                  end:
                    parseFloat(this.splitDetails2) +
                    parseFloat(this.splitDetails5) / 2,
                  transition: parseFloat(this.splitDetails5),
                });
                pushing.push({
                  add: parseFloat(this.splitDetails1),
                  start:
                    parseFloat(this.splitDetails2) +
                    parseFloat(this.splitDetails5) / 2,
                  end: 0,
                  transition: 0,
                });
              } else {
                pushing.push({
                  add: parseFloat(this.splitDetails1),
                  start: newstart === 0 ? parseFloat(item[0]) : newstart,
                  end: 0,
                  transition: 0,
                });
              }
            }
          } else {
            if (parseFloat(this.splitDetails3) != 0) {
              if (parseFloat(item[0]) >= parseFloat(this.splitDetails2)) {
                pushing.push({
                  add: parseFloat(this.splitDetails4),
                  start: startingPos,
                  end:
                    parseFloat(this.splitDetails2) +
                    parseFloat(this.splitDetails5) / 2,
                  transition: parseFloat(this.splitDetails5),
                });
                pushing.push({
                  add: parseFloat(this.splitDetails1),
                  start:
                    parseFloat(this.splitDetails2) +
                    parseFloat(this.splitDetails5) / 2,
                  end:
                    parseFloat(this.splitDetails3) +
                    parseFloat(this.splitDetails5) / 2,
                  transition: parseFloat(this.splitDetails5),
                });
              } else {
                pushing.push({
                  add: parseFloat(this.splitDetails1),
                  start: startingPos,
                  end:
                    parseFloat(this.splitDetails3) +
                    parseFloat(this.splitDetails5) / 2,
                  transition: parseFloat(this.splitDetails5),
                });
              }
              pushing.push({
                add: parseFloat(this.splitDetails4),
                start:
                  parseFloat(this.splitDetails3) +
                  parseFloat(this.splitDetails5) / 2,
                end: 0,
                transition: 0,
              });
            } else {
              if (parseFloat(item[0]) >= parseFloat(this.splitDetails2)) {
                pushing.push({
                  add: parseFloat(this.splitDetails4),
                  start: startingPos,
                  end:
                    parseFloat(this.splitDetails2) +
                    parseFloat(this.splitDetails5) / 2,
                  transition: parseFloat(this.splitDetails5),
                });
                pushing.push({
                  add: parseFloat(this.splitDetails1),
                  start:
                    parseFloat(this.splitDetails2) +
                    parseFloat(this.splitDetails5) / 2,
                  end: 0,
                  transition: 0,
                });
              } else {
                pushing.push({
                  add: parseFloat(this.splitDetails1),
                  start: startingPos,
                  end: 0,
                  transition: 0,
                });
              }
            }
          }
        }
        this.tableResult.push(pushing);
      });
    } else if (this.selectedOption === 'entireCourse') {
      this.trackLengths.forEach((item, index) => {
        const raceLength = item[0];
        let lowerItem = item[2].toLowerCase();
        const raceLengthTrack: string = Number(item[3]).toFixed(1);
        if (!lowerItem.includes('turf')) {
          this.tableResult.push([
            {
              add: 0,
              start: item[0],
              end: 0,
              transition: 0,
            },
          ]);
        } else if (this.data.venueid == '83' && raceLengthTrack === '2000.0' && Number(this.entireCourseDetails) > 0) {
          // Specific logic for Caulfield 2000m race length
          this.tableResult.push([
            {
              add: this.entireCourseDetails,
              start: 2150,
              end: 1450,
              transition: 200,
            },
            {
              add: 0,
              start: 1450,
              end: 1060,
              transition: 200,
            },
            {
              add: this.entireCourseDetails,
              start: 1060,
              end: 0,
              transition: 0,
            },
          ]);
        } else if (this.data.venueid == '151' && (raceLengthTrack === '1400.0' || raceLengthTrack === '1600.0') && Number(this.entireCourseDetails) > 5) {
          // Specific logic for Flemington 1400m and 1600m race lengths
          this.tableResult.push([
            {
              add: 5,
              start: raceLength,
              end: Number(raceLengthTrack) - 100,
              transition: 100,
            },
            {
              add: this.entireCourseDetails,
              start: Number(raceLengthTrack) - 100,
              end: 0,
              transition: 0,
            },
          ]);
        } else {
          this.tableResult.push([
            {
              add: parseFloat(this.entireCourseDetails),
              start: Math.max(parseFloat(item[0]), parseFloat(raceLength)),
              end: 0,
              transition: 0,
            },
          ]);
        }
      });
    }
  }
  async fetchTracks(): Promise<void> {
    this.generating = true;

    const apiUrl = `${this.config[getENV()].raceAPI}/geteqtrack?ExternalVenueId=${this.data.venueid}`;
    const apiKey = 'e2bvuAoslY1VJvFMqhr5x7nxoEhFVRii9jRngS3U';

    const headers: any = {
      'x-api-key': apiKey,
      'Content-Type': 'application/json',
    };

    const data: any = await this.apiService.getDataPromis(apiUrl, {}, headers);
    if (data && data.venue) {
      let tracklist = data.venue.TrackList;
      const resultArray = this.data.races
        .map((race) => {
          const trackKey =
            `eqt_trace_track_${race.ExternalRaceId}` as keyof typeof this.data.tracks;
          const trackValue = this.data.tracks[trackKey];
          return { EQTrackName: trackValue };
        })
        .filter((item) => item !== null);

      resultArray.forEach((item) => {
        if (item['EQTrackName'] === null) {
          this.generating = false;
          this.errorMessage = 'Missing EQTrack! Please submit it!';
          return;
        }
      });
      resultArray.forEach((item) => {
        let shouldContinue = false;
        tracklist.forEach((trackname: apiEQTrack) => {
          if (trackname.EQTrackName === item.EQTrackName) {
            if (trackname.TrackLength === null) {
              this.errorMessage =
                'Missing TrackLength! Let Carl to add them in!';
            }
            if (trackname.TrackLength && !shouldContinue) {
              this.trackLengths.push([
                trackname.TrackLength,
                trackname.AdditionalStart,
                trackname.TrackType,
                trackname.RaceLength,
              ]);
              shouldContinue = true;
              return;
            }
          }
        });

        if (shouldContinue) {
          return;
        }
      });
    }
  }

  onSplitDetails5Change() {
    this.tableResult.forEach((tracker: any) => {
      tracker.forEach((track: any) => {
        track.transition = this.splitDetails5;
      });
    });
  }

  async fetchRailPositions(): Promise<void> {
    this.notifier.loading(true);
    this.generating = true;
    this.tableResult = [];
    await this.fetchTracks();
    // Assuming `HttpHeaders` and `HttpParams` are from Angular's HttpClient

    const haaders: any = {
      'x-api-key': this.apiKey,
      'Content-Type': 'application/json',
    };

    const apiURL: string = `${this.config[getENV()].raceAPI}/railpositiondatamessage?ExternalEventId=${this.data.externalEventId}`;

    try {
      // Using toPromise() to handle the HTTP request asynchronously but wait for the result
      const response: any = await this.apiService.getDataPromis(
        apiURL,
        {},
        haaders
      );

      // Process the response here
      this.tableResult = this.data.races.map((race) => {
        const raceRailPositions = response['rail positions']
          .filter((rp: any) => rp.ExternalRaceID === race.ExternalRaceId)
          .map((rp: any) => ({
            add: parseFloat(rp.RailShift),
            start: parseFloat(rp.Start),
            end: parseFloat(rp.End),
            transition: parseFloat(rp.Transition),
          }));

        return raceRailPositions.length > 0
          ? raceRailPositions
          : [
            {
              add: 0, // Default or placeholder values if no match found
              start: 0,
              end: 0,
              transition: 0,
            },
          ];
      });
    } catch (error) {
      console.error('Error fetching rail positions:', error);
      // Handle the error appropriately
    }
    this.notifier.loading(false);
  }

  updateSelection(): void {
    // // Add any logic you want to execute when the radio button selection changes
    // if (this.selectedOption === 'entireCourse') {
    //   // Do something specific for 'entireCourse'
    // } else if (this.selectedOption === 'split') {
    //   // Do something specific for 'split'
    // }
    // this.tableResult =[];
    // this.isButtonDisabled = false;
    // this.splitDetails1 = "";
    // this.entireCourseDetails = "";
    // this.splitDetails2 = "";
    // this.splitDetails3 = "";
    // this.splitDetails4 = "";
    // this.splitDetails5 = "";
  }
  async onSubmit() {
    if (this.selectedOption === 'entireCourse') {
      this.splitDetails1 = '0';
      this.splitDetails2 = '0';
      this.splitDetails3 = '0';
      this.splitDetails4 = '0';
      this.splitDetails5 = '0';
    } else if (this.selectedOption === 'split') {
      this.entireCourseDetails = '';
    }
    const races = this.data.races;
    let event_update = {
      Rail: parseFloat(this.splitDetails1),
      RailStart: parseFloat(this.splitDetails2),
      RailEnd: parseFloat(this.splitDetails3),
      Remainder: parseFloat(this.splitDetails4),
      Transition: parseFloat(this.splitDetails5),
      EntireCourseRail: parseFloat(this.entireCourseDetails),
      RailUpdate: null,
      ExternalEventID: this.data.externalEventId,
    };
    let rail_update: any = [];
    races.forEach((item, index) => {
      this.tableResult[index].forEach((rail_information: any) => {
        rail_update.push({
          ExternalRaceId: item.ExternalRaceId || 0,
          Additional: parseFloat(rail_information.add) || 0,
          Start: parseFloat(rail_information.start) || 0,
          End: parseFloat(rail_information.end) || 0,
          Transition: parseFloat(rail_information.transition) || 0,
        });
      });
    });
    event_update['RailUpdate'] = rail_update;
    const headers: any = {
      'Content-Type': 'application/json',
    };
    this.notifier.loading(true);
    const apiUrl = `${this.config[getENV()].raceAPI}/update-rail`;
    let result: any = await this.apiService.postDataPromis(
      apiUrl,
      event_update,
      headers
    );
    if (typeof result == 'string') {
      this.notifier.alert(
        'Info',
        '',
        result,
        'info',
        5000
      );
    } else if (result.success) {
      this.hideModel.emit(true);
      this.notifier.alert(
        'Success',
        '',
        'Rail data saved successfully',
        'success',
        5000
      );
    }
    this.notifier.loading(false);
  }
  addRow(trackerIndex: number): void {
    const newRow = { add: '', start: '', end: '', transition: '' };
    this.tableResult[trackerIndex].push(newRow);
  }

  deleteRow(trackerIndex: number, rowIndex: number): void {
    this.tableResult[trackerIndex].splice(rowIndex, 1);
  }
}
