import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ActivatedRoute, Router
} from '@angular/router';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css', './../login/login.component.css'],
})
export class AuthComponent implements OnInit, OnDestroy {
  currentImage: number = 0;
  // imageTimer: any = null;

  ngOnInit(): void {
    // this.imageTimer = setInterval(() => {
    //   this.nextImage();
    // }, 5000);
  }

  constructor(private route: ActivatedRoute, private router: Router) {

  }

  trackByFn(index: number, obj: any) {
    return obj.id;
  }

  // carousel: any = [
  //   {
  //     title: 'Dashboards',
  //     description:
  //       'Gain valuable insights and make data-driven decisions faster and more effectively, while also improving collaboration and productivity within your team',
  //     image:
  //       'https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/swayam-features/login-carousel/2_Dashboard_BlueBG.svg',
  //   },
  //   {
  //     title: 'Security',
  //     description:
  //       'Advanced algorithms and analytics, the platform can analyze vast amounts of data in real-time and provide you with valuable insights into your cloud operations.',
  //     image:
  //       'https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/swayam-features/login-carousel/2_+Security_BlueBG.svg',
  //   },
  //   {
  //     title: 'Cost Optimisation',
  //     description:
  //       "Optimize your cloud costs and performance with TripleSData's advanced analytics and automation, freeing up resources for business innovation.",
  //     image:
  //       'https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/swayam-features/login-carousel/3_Cost+Optimisation_BlueBg.svg',
  //   },
  // ];

  changeFavicon(newFaviconURL: any) {
    const favicon: any = document.getElementById('favicon');
    if (favicon) {
      favicon.href = newFaviconURL;
    } else {
      const newFavicon = document.createElement('link');
      newFavicon.rel = 'icon';
      newFavicon.type = 'image/png';
      newFavicon.href = newFaviconURL;
      newFavicon.id = 'favicon';
      document.head.appendChild(newFavicon);
    }
  }

  // nextImage() {
  //   if (this.currentImage == this.carousel.length - 1) {
  //     this.currentImage = 0;
  //   } else {
  //     this.currentImage += 1;
  //   }
  // }

  // setImage(count: number) {
  //   this.currentImage = count;
  //   if (this.imageTimer) {
  //     clearInterval(this.imageTimer);
  //   }
  //   this.imageTimer = setInterval(() => {
  //     this.nextImage();
  //   }, 5000);
  // }

  ngOnDestroy(): void {
    // if (this.imageTimer) {
    //   clearInterval(this.imageTimer);
    // }
  }
}
