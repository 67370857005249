import { gql } from '@apollo/client/core';

export function generateDeviceQuery(venueStateQ: string, codeTypeQ: string, cursor = null, limit = 100) {
    const boardsQuery = `boards(ids:  ["4354573675"], limit:${limit})`


    let defaultRules = `
        { column_id: "group", compare_value: ["new_group33389"]},
        { column_id: "status", compare_value: [1], operator: any_of }
        { column_id: "dup__of_location", compare_value: ${venueStateQ}, operator: any_of }
        { column_id: "dup__of_venuestate", compare_value: ${codeTypeQ}, operator: any_of }
    `;



    let itemsPageQuery = `
        items_page(query_params: {
            rules: [${defaultRules}]
        }) {
            cursor
            items {
                column_values(ids: ["kit_id"]) {
                    text
                }
            }
        }
    `;

    if (cursor === null) {
        const graphqlQuery = gql`
          query {
            ${boardsQuery} {
              ${itemsPageQuery}
            }
          }
        `;
        return graphqlQuery;
    } else {
        const graphqlQuery = gql`
          query {
            next_items_page(
              limit: ${limit},
              cursor: "${cursor}"
            ) {
              cursor
              items {
                column_values(ids: ["kit_id"]) {
                  text
                }
              }
            }
          }
        `;
        return graphqlQuery;
    }
}

export function generateTagQuery(kitIdQ: string, cursor = null, limit = 100) {
    const boardsQuery = `boards(ids:  ["4354573675"], limit:${limit})`
    let defaultRules = `
      { column_id: "group", compare_value: ["new_group33389"]},
      { column_id: "status", compare_value: [1], operator: any_of},
      { column_id: "kit_id", compare_value: ${kitIdQ}, operator: any_of}
  `;



    let itemsPageQuery = `
        items_page(query_params: {
            rules: [${defaultRules}]
        }) {
            cursor
            items {
                name
                column_values(ids: ["tag_id"]) {
                    text
                }
            }
        }
    `;

    if (cursor === null) {
        const graphqlQuery = gql`
          query {
            ${boardsQuery} {
              ${itemsPageQuery}
            }
          }
        `;
        return graphqlQuery;
    } else {
        const graphqlQuery = gql`
          query {
            next_items_page(
              limit: ${limit},
              cursor: "${cursor}"
            ) {
              cursor
              items {
                name
                column_values(ids: ["tag_id"]) {
                  text
                }
              }
            }
          }
        `;
        return graphqlQuery;
    }
}

export function generateBarrierQuery(venueStateQ: string, codeTypeQ: string, cursor = null, limit = 100) {
    const boardsQuery = `boards(ids:  ["4354573675"], limit:${limit})`


    let defaultRules = `
        { column_id: "group", compare_value: ["new_group33389"]},
        { column_id: "status", compare_value: [0], operator: any_of }
        { column_id: "dup__of_location", compare_value: ${venueStateQ}, operator: any_of }
        { column_id: "dup__of_venuestate", compare_value: ${codeTypeQ}, operator: any_of }
    `;



    let itemsPageQuery = `
        items_page(query_params: {
            rules: [${defaultRules}]
        }) {
            cursor
            items {
                column_values(ids: ["tag_id"]) {
                    text
                }
            }
        }
    `;

    if (cursor === null) {
        const graphqlQuery = gql`
          query {
            ${boardsQuery} {
              ${itemsPageQuery}
            }
          }
        `;
        return graphqlQuery;
    } else {
        const graphqlQuery = gql`
          query {
            next_items_page(
              limit: ${limit},
              cursor: "${cursor}"
            ) {
              cursor
              items {
                name
              }
            }
          }
        `;
        return graphqlQuery;
    }
}

export function generateKitIDOptionsQuery() {
  const graphqlQuery = gql`{
    boards(ids: ["4354573675"], limit: 100) {
      columns(ids:["kit_id"]){
        id
        settings_str
      }
    }
  }`

  return graphqlQuery;
};

export function extractItems(items: any) {
    let tempSet = new Set();
    for (const item of items) {
        const textValue = item.column_values[0].text;
        tempSet.add(textValue);
    }
    return tempSet
}
export function extractSN(items: any): Record<string, string> {
  let dict: Record<string, string> = {};

  for (const item of items) {
    const textValue = item.column_values[0].text;
    const serialNumber = item.name;
    dict[serialNumber] = textValue;
  }

  return dict;
}
export function barrierItems(items: any) {
    let tempSet = new Set();
    for (const item of items) {
        const textValue = item.name;
        tempSet.add(textValue);
    }
    return tempSet
}
export interface InitialQuery {
    boards: {
        items_page: {
            cursor: string;
            items: {
                column_values: {
                    text: string;
                }[];
            };
        };
    }[];
}

export interface SettingsQuery {
  boards: [{
    columns: [{
      id:string,
      settings_str:string
    }]
  }]
}

export interface InitialResponse {
    boards: {
        items_page: {
            cursor: string;
            items: {
                column_values: {
                    text: string;
                }[];
            }[];
        }[];
    };
}

export interface NextPageQuery {
    next_items_page: {
        cursor: string;
        items: {
            column_values: {
                text: string;
            }[];
        }[];
    };
}

export interface NextResponse {
    next_items_page: {
        cursor: string;
        items: {
            column_values: {
                text: string;
            }[];
        }[];
    };
}
