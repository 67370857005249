<div class="card">
  <div class="card-header flex-between">
    <h5>Upcoming Events</h5>
    <button class="btn btn-primary-soft" (click)="showManualRaceCards()">
      Manual Race Cards
    </button>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-md-4" *ngFor="let date of upcoming">
        <div class="card">
          <div class="card-header fw-bold bg-swayam-blue center">
            {{ date["dateStr"] }}
          </div>
          <div class="card-body">
            <table class="table table-docs">
              <thead>
                <tr>
                  <th>Location</th>
                  <th class="center">Race Type</th>
                  <th class="center">Event Status</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngIf="!date.loading">
                  <tr *ngFor="let event of date['events']">
                    <td>
                      <a
                        routerLink="/dash/venue-details"
                        [queryParams]="{
                          event_id: event['external_event_id'],
                          venue_id: event['external_venue_id'],
                          date: date['dateStr'],
                          event_name: event['venue_name']
                        }"
                        class="nav-link"
                        >{{ event["venue_name"] }}</a
                      >
                    </td>
                    <td class="center">{{ event["raceType"] }}</td>
                    <td class="center">
                      <ng-container *ngIf="event.loading; else showStatus">
                        <div
                          class="spinner-border spinner-border-sm text-secondary mr-2"
                        ></div>
                      </ng-container>

                      <ng-template #showStatus>
                        <div>
                          <span
                            class="status-text-container alert"
                            [ngClass]="{
                              'alert-primary': event.event_state == 'RUNNING',
                              'alert-success':
                                event.event_state == 'COMPLETED' ||
                                event.event_state == 'RUNNING' ||
                                event.prepare,
                              'alert-dark':
                                (event.event_state == 'PLANNED' ||
                                  event.event_state == 'PUBLISHED') &&
                                !event.prepare,
                              'alert-danger':
                                event.event_state == 'INCOMPLETED' ||
                                event.event_state == 'CANCELLED' ||
                                event.event_state == 'ABANDONED' ||
                                event.event_state == 'FAILED_TO_LOAD'
                            }"
                          >
                            {{ event["event_state"] || "-" }}
                          </span>
                        </div>
                      </ng-template>
                    </td>
                  </tr>
                </ng-container>
                <ng-container *ngIf="date.loading">
                  <tr>
                    <td class="center" colspan="3">
                      <span
                        class="spinner-border spinner-border-sm text-secondary mr-2"
                      ></span>
                    </td>
                  </tr>
                </ng-container>

                <tr *ngIf="date['events'].length == 0 && !date.loading">
                  <td [attr.colspan]="3" class="center">No Events Found</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="card">
  <div class="card-header flex-between">
    <h5>Previous Events</h5>
    <div>
      <input
        type="text"
        class="form-control datepicker"
        [(ngModel)]="this.previous['dateStr']"
      />
    </div>
  </div>
  <div class="card-body">
    <table class="table table-docs">
      <thead>
        <tr>
          <th>Location</th>
          <th class="center">Race Type</th>
          <th class="center">Event Status</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngIf="!previous['loading']">
          <tr *ngFor="let event of previous['events']">
            <td>
              <a
                routerLink="/dash/venue-details"
                [queryParams]="{
                  event_id: event['external_event_id'],
                  venue_id: event['external_venue_id'],
                  date: this.previous['dateStr'],
                  event_name: event['venue_name']
                }"
                class="nav-link"
                >{{ event["venue_name"] }}</a
              >
            </td>
            <td class="center">{{ event["raceType"] }}</td>
            <td class="center">
              <ng-container *ngIf="event.loading; else showStatus">
                <div
                  class="spinner-border spinner-border-sm text-secondary mr-2"
                ></div>
              </ng-container>

              <ng-template #showStatus>
                <div>
                  <span
                    class="status-text-container alert"
                    [ngClass]="{
                      'alert-primary': event.event_state == 'RUNNING',
                      'alert-success':
                        event.event_state == 'COMPLETED' ||
                        event.event_state == 'RUNNING' ||
                        event.event_state == 'PUBLISHED' ||
                        event.prepare,
                      'alert-dark':
                        event.event_state == 'PLANNED' && !event.prepare,
                      'alert-danger':
                        event.event_state == 'INCOMPLETED' ||
                        event.event_state == 'CANCELLED' ||
                        event.event_state == 'ABANDONED' ||
                        event.event_state == 'FAILED_TO_LOAD'
                    }"
                  >
                    {{ event["event_state"] || "PLANNED" }}
                  </span>
                </div>
              </ng-template>
            </td>
          </tr>
        </ng-container>
        <ng-container *ngIf="previous['loading']">
          <tr>
            <td colspan="3" class="center">
              <span
                class="spinner-border spinner-border-sm text-secondary mr-2"
              ></span>
            </td>
          </tr>
        </ng-container>
        <tr *ngIf="previous['events'].length == 0 && !previous['loading']">
          <td [attr.colspan]="3" class="center">No Events Found</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<eod-confirm-box
  *ngIf="manualCard"
  title="Manual Race Cards"
  [closeTitle]="true"
  [buttons]="[]"
  (hideModel)="this.hideManualCard($event)"
>
  <ng-container ngProjectAs="main-body">
    <app-manual-race-cards (hideModel)="this.hideManualCard($event)"></app-manual-race-cards>
  </ng-container>
</eod-confirm-box>
