<span class="center" *ngIf="(data.RaceState == 'PUBLISHED' || data.RaceState == 'FINISHED') && data.download; else notPublished">
  <button
    type="button"
    class="btn btn-success-soft flex-start btn-dropdown"
  >
    Download
    <i class="fa fa-angle-down color-inherit" aria-hidden="true"></i>
    <div class="dropdown-menu">
      <span
        class="dropdown-item"
        href="#"
        (click)="reviewPDF($event, '.pdf')"
        >PDF File</span
      >
      <span
        class="dropdown-item"
        href="#"
        (click)="reviewPDF($event, '.csv')"
        >CSV File</span
      >
      <span
        class="dropdown-item"
        href="#"
        (click)="reviewPDF($event, '.xml')"
        >XML File</span
      >
    </div>
  </button>
</span>

<ng-template #notPublished>
  <div class="center">-</div>
</ng-template>
