<div
  class="modal fade"
  [class.show]="showModal"
  [style.display]="showModal ? 'block' : 'none'"
  tabindex="-1"
  role="dialog"
  aria-labelledby="kitDetailsModalLabel"
  aria-modal="true"
  style="background-color: rgba(0, 0, 0, 0.5)"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <form (submit)="submitForm()">
        <div class="modal-header">
          <h5 class="modal-title" id="kitDetailsModalLabel">
            {{ isEditMode ? "Edit" : "Add" }} Kit Details
          </h5>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            (click)="toggleModal()"
          ></button>
        </div>
        <div class="modal-body">
          <!-- Adjusted form fields to use index signature for all properties -->
          <div class="mb-3">
            <label for="Kit" class="form-label">Kit</label>
            <input
              type="text"
              class="form-control"
              id="KitID"
              [(ngModel)]="form['KitID']"
              name="KitID"
            />
          </div>
          <div class="mb-3">
            <label for="VenueState" class="form-label">Venue State</label>
            <input
              type="text"
              class="form-control"
              id="VenueState"
              [(ngModel)]="form['VenueState']"
              name="VenueState"
            />
          </div>
          <div class="mb-3">
            <label for="CodeType" class="form-label">Code Type</label>
            <select
              class="form-control"
              id="CodeType"
              [(ngModel)]="form['CodeType']"
              name="CodeType"
            >
              <option value="HARNESS" selected>HARNESS</option>
              <option value="GALLOPS">GALLOPS</option>
              <option value="GREYHOUNDS">GREYHOUNDS</option>
            </select>
          </div>
        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btn-primary">
            {{ isEditMode ? "Save Changes" : "Add Kit" }}
          </button>
          <button
            type="button"
            class="btn btn-secondary"
            (click)="toggleModal()"
          >
            Close
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
