import { environment } from 'src/environments/environment';
import * as momentTz from 'moment-timezone';

export const convertTimestamp = function (
  timestamp: string,
  venueState: string,
  ignoreTZ: boolean = false
): string {
  if (!timestamp) {
    return '';
  }
  let tz = momentTz(timestamp, 'YYYY-MM-DD-HH:mm:ss');


  let timezone = 'Australia/Sydney';
  let diffHours = 10;
  let diffMinutes = 0;
  if(!ignoreTZ) {
    if (venueState == 'NZ') {
      timezone = 'Pacific/Auckland';
      diffHours = 12;
    } else if (venueState == 'SA') {
      timezone = 'Australia/Adelaide';
      diffHours = 9;
      diffMinutes = 30;
    } else if (venueState == 'QLD') {
      timezone = 'Australia/Brisbane';
      diffHours = 10;
    }
  }

  tz.add(diffHours, 'hours');
  tz.add(diffMinutes, 'minutes');

  const isDST = momentTz().tz(timezone).isDST();

  if(isDST) {
    tz.add(1, 'hours');
  }

  const raceTimeHHMM = tz.format('H') + 'H' + tz.format('mm');
  return raceTimeHHMM;
};

export const getENV = function (): string | null {
  const url = window.location.hostname;
  if (url.includes('localhost')) {
    return environment.configStage;
  } else if (url.includes('dev.')) {
    return environment.configStage;
  } else if (url.includes('staging.')) {
    return 'staging';
  } else {
    return 'prod';
  }
};

export const formatFileSize = function (bytes: number): string {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
};

export const getRaceType = function (venueId: string): {
  raceTypeStr: 'Greyhound Racing' | 'Thoroughbred Racing' | 'Harness Racing';
  raceTypeShortStr: 'Greyhound' | 'Thoroughbred' | 'Harness';
  raceTypeCode: 'GR' | 'TH' | 'HR';
} {
  if (venueId.startsWith('GR')) {
    return {
      raceTypeStr: 'Greyhound Racing',
      raceTypeShortStr: 'Greyhound',
      raceTypeCode: 'GR',
    };
  }
  if (venueId.length <= 7) {
    return {
      raceTypeStr: 'Thoroughbred Racing',
      raceTypeShortStr: 'Thoroughbred',
      raceTypeCode: 'TH',
    };
  } else {
    return {
      raceTypeStr: 'Harness Racing',
      raceTypeShortStr: 'Harness',
      raceTypeCode: 'HR',
    };
  }
};

export const checkForVenuePermission = function (venueId: string): boolean {
  const ut: string = localStorage.getItem('ut');
  const isUserAdmin: string = localStorage.getItem('isUserAdmin');
  const venueIds = localStorage.getItem('venueList');
  if (!venueIds) {
    return true;
  }
  if (ut == 'client' && isUserAdmin == '1') {
    return true;
  } else {
    try {
      if (venueIds) {
        const venueIdsArray = JSON.parse(venueIds);
        return venueIdsArray.includes(venueId);
      }
    } catch (error) {
      return true;
    }
    return false;
  }
};

export const fetchVenues = function () {
  let venueList = localStorage.getItem('venueList');
  if (venueList) {
    try {
      return JSON.parse(venueList);
    } catch (error) {
      return [];
    }
  } else {
    return [];
  }
};
