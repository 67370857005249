import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment-timezone';
import { getENV } from 'src/app/_helpers/helpers';
import { APIService } from 'src/app/services/api.service';
import { NotifierService } from 'src/app/services/notifier.service';


@Component({
  selector: 'app-pc-status',
  templateUrl: './pc-status.component.html',
  styleUrls: ['./pc-status.component.css'],
})
export class PcStatusComponent implements OnInit {
  pcs: any = [];

  config: any;
  constructor(
    private apiService: APIService,
    private router: ActivatedRoute,
    private notifier: NotifierService
  ) {
    this.config = this.router.snapshot.data['config'];
  }
  ngOnInit(): void {
    this.load();
  }

  async load() {
    await this.loadPCs();
    this.getAssignedPCs();
  }

  selectedPC: any = null;
  managePC(pc: any) {
    this.selectedPC = pc;
  }

  async loadPCs() {
    this.notifier.loading(true);
    let apiURL = `${
      this.config[getENV()].raceAPI
    }/flask-operations?action=get_pcs`;
    let data = await this.apiService.getDataPromis(apiURL, {}, {});
    this.pcs = data.PCs;
    this.notifier.loading(false);
  }

  async getAssignedPCs() {
    this.notifier.loading(true);
    let apiURL = `${
      this.config[getENV()].raceAPI
    }/flask-operations?action=get_assigned_pcs`;
    let data = await this.apiService.getDataPromis(apiURL, {}, {});
    if (data.status == '1' || data.s == '1') {
      this.pcs.forEach((pc: any) => {
        let found = data.pcs.find((p: any) => {
          return p['AssignedPCName'].trim() == pc['name'].trim();
        });
        if (found) {
          found['MeetingDate'] = moment(
            found['MeetingDate'],
            'YYYY-MM-DD'
          ).format('DD MMMM YYYY');
          pc['found'] = found;
        }
      });
    }
    this.notifier.loading(false);
  }
}
