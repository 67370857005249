import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import VenueEmails from '../../../services/emails';
import { APIService } from 'src/app/services/api.service';
import { ActivatedRoute } from '@angular/router';
import { venueEmail } from './../../venue-emails.component';
import { NotifierService } from 'src/app/services/notifier.service';
import { getENV } from 'src/app/_helpers/helpers';

@Component({
  selector: 'edit-venue-email',
  templateUrl: './edit-venue-email.component.html',
  styleUrls: ['./edit-venue-email.component.css'],
})
export class EditVenueEmailComponent implements OnInit {
  @Input('venue') venue: venueEmail;
  @Input('config') config: any;
  @Output('hideModel') hideModel: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  constructor(
    private apiService: APIService,
    private notifier: NotifierService
  ) {
  }

  ngOnInit() {
    this.venue = JSON.parse(JSON.stringify(this.venue));
  }

  loading: boolean = false;
  errors: any = {
    startlist_id: '',
    racereports_id: '',
    unofficialracereports_id: '',
    eod_id: '',
  };
  async saveVenue() {
    this.loading = true;
    let venueEmail = new VenueEmails(this.apiService, this.config[getENV()], this.notifier);
    let result: any = await venueEmail.saveVenueEmail(
      this.venue.ExternalVenueID,
      this.venue.RaceReportsEmailDL || '',
      this.venue.UnofficialEmailDL || '',
      this.venue.EODEmailDL || '',
      this.venue.StartlistEmailDL || ''
    );
    if (result.status == '1') {
      this.notifier.alert(
        'Success',
        '',
        'Emails saved successfully!',
        'success',
        5000
      );
      this.hideModel.emit(true);
    } else {
      if (result.failed) {
        Object.keys(this.errors).forEach((key: any) => {
          if (result.failed[key]) {
            this.errors[key] = result.failed[key];
          } else {
            this.errors[key] = '';
          }
        });
      } else {
        this.notifier.alert('Error', '', result.error_message, 'error', 5000);
      }
    }
    this.loading = false;
  }
}
