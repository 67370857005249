<div class="card">
  <div class="card-header flex-between">
    <h5>Emails Configuration</h5>
    <div class="flex-start">
      <button
        class="btn btn-primary-soft flex-start"
        (click)="refresh()"
        *ngIf="!loading['venues']; else showLoader"
      >
        <i class="fa fa-refresh color-inherit"></i> Refresh
      </button>
      <button
        class="btn btn-success-soft flex-start"
        (click)="export()"
        *ngIf="!loading['venues']"
      >
        <i class="fa fa-download color-inherit"></i> Export
      </button>
      <ng-template #showLoader>
        <div class="spinner-border spinner-border-sm text-secondary mr-2"></div>
      </ng-template>
    </div>
  </div>
  <div class="card-body">
    <table class="table table-docs table-hover">
      <thead>
        <tr>
          <th>#</th>
          <th *ngFor="let h of headers">
            <span>{{ h.name }}</span>
            <span *ngIf="h.filter" class="inputseach">
              <input type="text" [(ngModel)]="h.filterText" />
              <i class="fa fa-search" aria-hidden="true"></i>
            </span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="loading['venues']; else showEmails">
          <td class="center" [attr.colspan]="headers.length + 1">
            <div
              class="center spinner-border spinner-border-sm text-secondary mr-2"
            ></div>
          </td>
        </tr>
        <ng-template #showEmails>
          <ng-container *ngFor="let venue of emails; let i = index">
            <tr *ngIf="notifier.filterTableColums(headers, venue)">
              <td>{{ i + 1 }}</td>
              <td *ngFor="let h of headers">
                <span
                  [ngClass]="{ 'nav-link': h.click }"
                  (click)="h.click ? h.click(venue) : null"
                >
                  {{ venue[h.id] || "-" }}
                </span>
              </td>
            </tr>
          </ng-container>
        </ng-template>
      </tbody>
    </table>
  </div>
</div>
<edit-venue-email
  *ngIf="editVenue"
  [venue]="editVenue"
  [config]="config"
  (hideModel)="removeVenueEmail($event)"
></edit-venue-email>
