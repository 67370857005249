import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { getENV } from 'src/app/_helpers/helpers';
import VenueEmails from 'src/app/modules/globals/dash/modules/pages/components/services/emails';
import { APIService } from 'src/app/services/api.service';
import { NotifierService } from 'src/app/services/notifier.service';


@Component({
  //moduleId: module.id.toString(),
  templateUrl: './manage.policies.component.html',
  styleUrls: ['./manage.policies.component.css'],
})
export class PoliciesComponenet implements OnInit, OnDestroy {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  role: string | null = localStorage.getItem('role');
  urlPrefix: string | null = null;
  isUserAdmin: boolean = localStorage.getItem('isUserAdmin') == '1';
  writeAccess: boolean = true;
  loading = true;
  policies: any = [];
  updatePolicy: any;
  policyAction: any;

  accessTypes: any = {
    readandwrite: 'Read and Write',
    readOnly: 'Read Only',
  };

  headers: any = [
    {
      id: 'policyName',
      name: 'Policy Name',
    },
    {
      id: 'mlistStr',
      name: 'Service List',
    },
    {
      id: 'venuelistStr',
      name: 'Venue List',
    },
    { id: 'accessTypeStr', name: 'Access Type' },
  ];

  config: any;

  constructor(
    private apiServer: APIService,
    private notifier: NotifierService,
    private route: ActivatedRoute
  ) {
    this.config = this.route.snapshot.data['config'];
  }
  ngOnInit(): void {
    if (this.role == 'Admin') {
      this.urlPrefix = 'admin';
    }
    if (this.role == 'Client') {
      this.urlPrefix = 'client';
    }
    if (this.role == 'Superadmin') {
      this.urlPrefix = 'sa';
    }
    this.load();
  }

  ngOnDestroy(): void {
  }

  services: any = [];
  policyNames: string[] = [];
  async load() {
    this.loadVenues();
    this.loadUsers();
    this.notifier.loading(true);
    let data = {
      a: 'fetchAll',
      clientId: this.userId,
      userType: this.urlPrefix,
    };

    let header: any = {
      Authorization: localStorage.getItem('t'),
    };

    let apiURL: string | null;

    if (this.urlPrefix == 'sa') {
      header['Authorization'] = localStorage.getItem('sessiontoken');
      header['X-Api-Key'] = localStorage.getItem('clientid');
      apiURL = `${this.config[getENV()].apiURL}/${this.urlPrefix}/policies`;
    } else {
      apiURL = `${this.config[getENV()].apiURL}/${this.urlPrefix}/Settings/policies`;
    }

    this.policyNames = [];

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.policies =
        result.policies.map((policy: any) => {
          if(policy['venueList']) {
            policy['venuelistStr'] = this.venues.filter((venue: any) => {
              return policy['venueList'].indexOf(venue['ExternalVenueID']) > -1;
            }).map((venue: any) => {
              return venue['VenueName'];
            }).join(', ')
          } else {
            policy['venuelistStr'] = '-';
          }
          policy['accessTypeStr'] = this.accessTypes[policy['accessType']];
          this.policyNames.push(policy.policyName);
          let temp = policy['mList']
            ? Object.keys(policy['mList'])
            : policy['menuList'];
          policy['mlistStr'] = `<ul>${temp
            .map((menu: any) => {
              return `<li>${menu}</li>`;
            })
            .join('')}</ul>`;
          return policy;
        }) || [];
      this.services =
        this.urlPrefix === 'client'
          ? JSON.parse(localStorage.getItem('menuList') || '{}')
          : result.menuList;
    } else {
      this.notifier.alert('Error', '', result.error, 'error', 5000);
    }

    this.notifier.loading(false);
  }

  userList: any = [];
  userTypes: any = {
    userAdmin: 'Admin',
    user: 'General',
    superAdmin: 'Master Admin',
  };
  async loadUsers() {
    this.userList = [];
    let data: any = {
      a: 'fetchUsers',
      requester: this.userId,
    };

    // if (this.urlPrefix == "admin") {
    //   data = { userId: this.userId };
    // }

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${this.config[getENV()].apiURL}/${this.urlPrefix}/Settings/usermanagement`;

    if (this.urlPrefix == 'client') {
      data['a'] = 'fetchAll';
      data['email'] = this.userId;
    }

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.s == '1' || result.status == '1') {
      if (this.urlPrefix == 'admin') {
        this.userList = result.data;
      } else {
        this.userList = result.clients;
      }
      this.userList = this.userList.filter((user: any) => {
        return this.userTypes[user['userType']] && user['policyName'];
      });
    }
  }

  updatePolicyAction(group: any) {
    this.policyAction = 'update';
    this.updatePolicy = group;
  }

  async hideUpdatePolicy(event: any) {
    if (event) {
      this.updatePolicy = event;
    }
    await this.load();
    this.updatePolicy = null;
  }

  venues: any = [];
  async loadVenues() {
    let venueEmail: VenueEmails = new VenueEmails(
      this.apiServer,
      this.config[getENV()],
      this.notifier
    );
    let result: any = await venueEmail.loadEmails();
    if(result.status == '1') {
      this.venues = result.data;
    } else {
      this.venues = [];
    }
  }

  createPolicy() {
    this.policyAction = 'add';
    this.updatePolicy = {
      policyName: '',
      menuList: [],
      venueList: [],
      accessType: 'readandwrite',
    };
  }

  callFunction(name: any, param: any) {
    return;
  }

  deletePolicy: any = null;
  deletePolicyAction(policy: any) {
    this.deletePolicy = policy;
  }

  hideDeleteModel(event: any) {
    this.deletePolicy = null;
    if (event) {
      this.load();
    }
  }
}
