import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ActivatedRoute, Params } from '@angular/router';
import { APIService } from 'src/app/services/api.service';

import { NotifierService } from 'src/app/services/notifier.service';
import { getENV } from 'src/app/_helpers/helpers';

@Component({
  selector: 'app-device-history',
  templateUrl: './device-history.component.html',
  styleUrls: ['./device-history.component.css']
})
export class DeviceHistoryComponent implements OnChanges {
  @Input() SerialNumber: any;
  @Input() showModal: boolean = false;
  @Output() modalClose = new EventEmitter<void>();
  deviceHistory: any[] = []; // To store device history
  newHistory: any = {};
  loading: boolean = false;
  config: any;
  error = '';
  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private apiService: APIService,
    private notifier: NotifierService
  ) {
    this.config = this.route.snapshot.data['config'];
  }

  ngOnChanges(): void {
    if (this.showModal && this.SerialNumber) {
      this.fetchDeviceHistory(this.SerialNumber);
    }
  }

  toggleModal(): void {
    this.showModal = !this.showModal;
    if (!this.showModal) {
      this.modalClose.emit();
    }
  }

  async fetchDeviceHistory(serialNumber: string): Promise<void> {
      // this.loading = true; // Show spinner at the start of the fetch operation
      // const apiUrl = `https://4otldh9um5.execute-api.ap-southeast-2.amazonaws.com/tsd-prd-api/devices/history?serial_number=${encodeURIComponent(serialNumber)}`;
      let apiURL: string = `${this.config[getENV()].raceAPI}/devices/history?serial_number=${encodeURIComponent(serialNumber)}`;
      let response = await this.apiService.getDataPromis(apiURL, {}, {});
      response => response.json();
      this.deviceHistory = response.data || [];
      console.log(response);
      // fetch(apiUrl)
      //   .then(response => response.json())
      //   .then(data => {
      //     this.deviceHistory = data.data || [];
      //   })
      //   .catch(error => {
      //     console.error('There was an error fetching the device history', error);
      //   })
      //   .finally(() => {
      //     this.loading = false; // Hide spinner when the fetch operation is complete
      //   });
  }
  async addHistoryEntry(): Promise<void> {

    this.newHistory['SerialNumber'] = this.SerialNumber;
    console.log('Adding history entry:', this.newHistory);
    const headers = new HttpHeaders()
    .set('accept', 'application/json')

    const httpOptions = {
      headers: headers
    };
    this.loading = true;
    //const apiUrl = 'https://4otldh9um5.execute-api.ap-southeast-2.amazonaws.com/tsd-prd-api/devices/history';
    const apiUrl: string = `${this.config[getENV()].raceAPI}/devices/history`;
    let result = await this.apiService.postDataPromis(apiUrl, this.newHistory).then((data) => {
      alert("Submitted Data!");
      this.loading = false;
      window.location.reload();
    },
    (error) => {
      console.error('Error:', error);
      alert("Error occurred while submitting data!");
      this.loading = false;
    });
    console.log(result);
  }
}
