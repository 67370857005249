import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { RaceRunnerService } from '../../../../../../../services/race-runner.service';
import { MqttService } from '../../../../../../../services/mqtt.service';
import { NotifierService } from 'src/app/services/notifier.service';
import { Router } from '@angular/router';
import { APIService } from 'src/app/services/api.service';

import { convertTimestamp, getENV } from 'src/app/_helpers/helpers';

interface RaceEntrant {
  RaceState: string;
  StartNumber: number;
  BarrierNumber: string;
  DeviceID: number | null;
  DeviceNumber: string | null;
  ExternalSilkID: string;
  AnimalName: string;
  ExternalAnimalID: string;
  TrainerName: string;
  ExternalTrainerID: string;
  JockeyName: string;
  ExternalJockeyID: string;
  ReasonScratching: string | null;
  IsLateScratching: boolean | null;
}

interface RaceResponse {
  entrants: RaceEntrant[];
  track_id: number;
  venue: string;
  date: string;
  race_name: string;
  race_number: number;
  venue_id: string;
  race_length: string;
  current_eq_trace_track: number;
  code_type: string;
  tag_id: string;
  event_id: string;
  event_name: string;
  event_date: string;
  race_time: string;
  previous_race_exists: boolean;
  previous_race_id: string;
  next_race_exists: boolean;
  next_race_id: string;
  race_state: string;
  venue_state: string;
}

interface Runner {
  StartNumber: number;
  BarrierNumber: string;
  ExternalSilkID: string;
  State: string;
  isLoadedChecked: boolean;
  isDNS: boolean;
  LoadedRadio: string;
}

@Component({
  selector: 'app-race-runner',
  templateUrl: './race-runner.component.html',
  styleUrls: ['./race-runner.component.css'],
})
export class RaceRunnerComponent implements OnInit, OnDestroy {
  raceResponse: RaceResponse = {
    entrants: [],
    track_id: 0,
    venue: '',
    date: '',
    race_name: '',
    race_number: 0,
    venue_id: '',
    race_length: '',
    current_eq_trace_track: 0,
    code_type: '',
    tag_id: '',
    event_id: '',
    event_name: '',
    event_date: '',
    race_time: '',
    previous_race_exists: false,
    previous_race_id: '',
    next_race_exists: false,
    next_race_id: '',
    race_state: '',
    venue_state: '',
  };

  runners: { [key: string]: Runner } = {};
  simulationData: any[] = [];
  victoriaData: any = null;
  firstTimestamp: string = '';
  currentTime: string = '';
  timer: any;
  mqttPublishTimer: any;
  isPlaying: boolean = false;
  isConnected: boolean = false;
  speed: number = 1;
  simulation_index = 0;
  interval: number = 1000;
  venue_id = '';
  event_id = '';
  race_id = '';
  race_runner_topic = '';
  race_runner_override_topic = '';
  race_runner_victoria_feed = '';
  current_connection_time: Date = new Date();
  current_connection_time_interval: any;
  formatted_race_time: string = '';
  victoria_feed_forwarder: any;
  loaded_options: string[] = ['', 'BehindBarrier', 'Loaded'];
  venueState = '';
  config: any;
  private headers = new HttpHeaders({
    Accept: 'application/json',
    'User-Agent':
      'Mozilla/5.0 (Linux; Android 6.0; Nexus 5 Build/MRA58N) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36 Edg/125.0.0.0',
  });

  constructor(
    public raceRunnerService: RaceRunnerService,
    private http: HttpClient,
    private route: ActivatedRoute,
    private mqttService: MqttService,
    private notifier: NotifierService,
    private apiService: APIService,
    private navigator: Router
  ) {
    this.config = this.route.snapshot.data['config'];
  }

  ngOnInit(): void {
    this.current_connection_time_interval = setInterval(() => {
      this.updateCurrentTime();
    }, 100);
    this.route.params.subscribe((params) => {
      this.notifier.loading(true);
      this.race_id = params['race_id'];
      this.venue_id = params['venue_id'];
      this.event_id = params['event_id'];
      const raceDetailsUrl = `${
        this.config[getENV()].raceAPI
      }/race-entrant-details?ExternalRaceId=${this.race_id}&ExternalEventId=${
        this.event_id
      }`;
      const statusFeedUrl = `${
        this.config[getENV()].raceAPI
      }/get-status-feed/?ExternalRaceId=${this.race_id}`;
      const victoriaFeedUrl = `${
        this.config[getENV()].raceAPI
      }/get-victoria-feed/?ExternalRaceId=${this.race_id}`;
      this.race_runner_topic = `TRACKING/AUS/${this.venue_id}/${this.event_id}/${this.race_id}/RunnerStatus`;
      this.race_runner_override_topic = `INTERNAL/AUS/${this.venue_id}/${this.event_id}/${this.race_id}/RSFoverwrite`;
      this.race_runner_victoria_feed = `INTERNAL/AUS/${this.venue_id}/${this.event_id}/${this.race_id}/RunnerStatus/VictoriaFeed`;

      // Fetch race details
      this.http.get<RaceResponse>(raceDetailsUrl).subscribe(
        (data: RaceResponse) => {
          this.raceResponse = data;
          this.loadRacesBasedOnEvent();

          this.venueState = data.venue_state;
          this.raceResponse.entrants.sort(
            (a, b) => Number(a.StartNumber) - Number(b.StartNumber)
          );
          for (const raceEntrant of this.raceResponse.entrants) {
            this.runners[raceEntrant.ExternalAnimalID] = {
              StartNumber: raceEntrant.StartNumber,
              BarrierNumber: raceEntrant.BarrierNumber,
              ExternalSilkID: raceEntrant.ExternalSilkID,
              State: 'Unknown', // Initial state
              isLoadedChecked: false, // Initial checkbox state
              isDNS: raceEntrant.RaceState === 'DNS',
              LoadedRadio: '',
            };
          }
          this.formatted_race_time = convertTimestamp(
            this.raceResponse.race_time,
            null
          );
          const today = new Date();
          today.setHours(0, 0, 0, 0);
          const eventDate = new Date(this.raceResponse.event_date);
          console.log(eventDate, today);
          if (eventDate <= today) {
            // If event date is yesterday or earlier
            this.http.get<any[]>(statusFeedUrl).subscribe(
              (simulationData) => {
                console.log(simulationData);
                this.simulationData = simulationData;
              },
              (error) => {
                console.error('Error fetching simulation data:', error);
                this.toggleConnectDisconnect(); // Automatically connect if no simulation data
              }
            );
          } else {
            this.toggleConnectDisconnect();
            console.log('Event date is in the future. No action needed.');
          }

          this.http.get<any>(victoriaFeedUrl).subscribe(
            (response) => {
              console.log(response);
              this.victoriaData = response;
              this.notifier.loading(false);
            },
            (error) => {
              console.error('Error fetching victoria data:', error);
              this.notifier.loading(false);
            }
          );
        },
        (error) => {
          console.error('Error fetching race details:', error);
        }
      );
    });
  }

  races: any = [];
  async loadRacesBasedOnEvent(): Promise<boolean> {
    return new Promise(async (resolve: any) => {
      let apiURL: string = `${
        this.config[getENV()].raceAPI
      }/getracedetails?ExternalVenueId=${this.venue_id}&ExternalEventId=${
        this.event_id
      }`;

      let result = await this.apiService.getDataPromis(apiURL, {}, {});

      if (result && Array.isArray(result) && result.length > 0) {
        result = result.sort((a: any, b: any) => {
          return a['SquentialRaceOrderNumber'] - b['SquentialRaceOrderNumber'];
        });
        this.races = result.map((race: any) => {
          return {
            ExternalVenueId: race['ExternalVenueId'],
            ExternalEventId: race['ExternalEventId'],
            ExternalRaceId: race['ExternalRaceId'],
            SquentialRaceOrderNumber: race['SquentialRaceOrderNumber'],
            RaceName: race['RaceName'],
            RaceTime: convertTimestamp(
              race['RaceTime'],
              this.raceResponse.venue_state
            ),
            RaceState: race['RaceState'] || 'PLANNED',
          };
        });
      }

      resolve(true);
    });
  }

  async prev() {
    let link: any = `/dash/race-runner/${this.venue_id}/${this.event_id}/${this.raceResponse['previous_race_id']}`;
    this.navigator.navigate([link]);
  }

  async next() {
    let link: any = `/dash/race-runner/${this.venue_id}/${this.event_id}/${this.raceResponse['next_race_id']}`;
    this.navigator.navigate([link]);
  }

  updateCurrentTime() {
    this.current_connection_time = new Date();
  }

  processRunnerStatusMessage(message: string): void {
    const data = JSON.parse(message);
    this.currentTime = data.TimeStamp;

    data.RunnerStatusSet.forEach((runnerStatus: any) => {
      const runner = this.runners[runnerStatus.ExternalAnimalId];
      if (runner) {
        runner.State = runnerStatus.RunnerStatus;
      }
    });
  }

  togglePlayPause(): void {
    this.isPlaying = !this.isPlaying;
    if (this.isPlaying) {
      this.startSimulation();
    } else {
      this.stopSimulation();
    }
  }

  startSimulation() {
    this.timer = setInterval(() => {
      if (this.simulation_index >= this.simulationData.length) {
        clearInterval(this.timer);
        this.simulation_index = 0;
        return;
      }

      const data = this.simulationData[this.simulation_index];
      if (this.simulation_index === 0) {
        this.firstTimestamp = data['TimeStamp'];
      }
      this.currentTime = data['TimeStamp'];
      data.RunnerStatusSet.forEach((runnerStatus: any) => {
        const runner = this.runners[runnerStatus.ExternalAnimalId];
        if (runner) {
          runner.State = runnerStatus.RunnerStatus;
        }
      });
      // console.log(data)
      this.simulation_index++;
    }, this.interval / this.speed);
  }

  stopSimulation(): void {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  changeSpeed(event: any): void {
    this.speed = +event.target.value;
    if (this.isPlaying) {
      this.stopSimulation();
      this.startSimulation();
    }
  }

  toggleConnectDisconnect(): void {
    this.isConnected = !this.isConnected;
    if (this.isConnected) {
      this.connect();
    } else {
      this.disconnect();
    }
  }

  connect(): void {
    this.mqttService.subscribe(this.race_runner_topic);
    this.mqttService.client.on('message', (topic: string, payload: Buffer) => {
      this.isConnected = true;
      if (topic === this.race_runner_topic) {
        this.processRunnerStatusMessage(payload.toString());
      }
    });
    this.mqttService.client.on('reconnect', () => {
      console.log('Disconnected');
      this.isConnected = false;
    });
    console.log(this.race_runner_override_topic);
    this.mqttPublishTimer = setInterval(() => {
      const runnerStateSet = Object.keys(this.runners)
        .filter((key) => this.runners[key].LoadedRadio !== '')
        .map((key) => ({
          ExternalAnimalID: key,
          RunnerStatus: this.runners[key].LoadedRadio,
        }));

      if (runnerStateSet.length > 0) {
        const overrideData = {
          ExternalVenueId: this.venue_id,
          ExternalEventId: this.event_id,
          ExternalRaceId: this.race_id,
          TimeStamp: new Date().toISOString(),
          RunnerStateSet: runnerStateSet,
        };

        this.mqttService.publish(this.race_runner_override_topic, overrideData);
      }
    }, 1000);

    const feed_headers = new HttpHeaders({
      Accept: 'application/json',
    });
  }

  disconnect(): void {
    if (this.mqttPublishTimer) {
      clearInterval(this.mqttPublishTimer);
    }
  }

  ngOnDestroy(): void {
    this.stopSimulation();
    this.disconnect();
    clearInterval(this.current_connection_time_interval);
    clearInterval(this.victoria_feed_forwarder);
  }

  getSilkUrl(entrant: RaceEntrant): string {
    return `https://tsd-prd01-silks.s3.ap-southeast-2.amazonaws.com/Racing_Australia/${entrant.ExternalSilkID}.png`;
  }

  downloadVictoriaData(): void {
    if (this.victoriaData) {
      const dataStr =
        'data:text/json;charset=utf-8,' +
        encodeURIComponent(JSON.stringify(this.victoriaData));
      const downloadAnchorNode = document.createElement('a');
      downloadAnchorNode.setAttribute('href', dataStr);
      downloadAnchorNode.setAttribute('download', `${this.race_id}.json`);
      document.body.appendChild(downloadAnchorNode); // Required for Firefox
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    }
  }
  generateVictoriaData(): void {
    const generateUrl = `${
      this.config[getENV()].raceAPI
    }/generate-victoria-json?external_race_id=${this.race_id}`;
    this.notifier.loading(true);
    this.http.get<any>(generateUrl).subscribe(
      (response) => {
        alert(
          'Victoria data generated successfully: ' + JSON.stringify(response)
        );
        this.notifier.loading(false);
        location.reload();
      },
      (error) => {
        console.error('Error generating Victoria data:', error);
        this.notifier.loading(false);
      }
    );
  }
  toggleState(animalId: string, state: string): void {
    const runner = this.runners[animalId];
    console.log(runner);
    if (runner) {
      if (runner.State === 'Behind the Bar') {
        runner.State = 'Unknown';
        runner.isLoadedChecked = false;
      } else {
        runner.State = state;
        runner.isLoadedChecked = true;
      }
    }
  }
}
