import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { APIService } from 'src/app/services/api.service';
import { NotifierService } from 'src/app/services/notifier.service';
import {
  checkForVenuePermission,
  convertTimestamp,
  getENV,
  getRaceType,
} from '../../../../../../../_helpers/helpers';
import { typeBasedLocations } from '../post-race/harness-reports/components/race-reports/locations';
import { Title } from '@angular/platform-browser';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-race-details',
  templateUrl: './race-details.component.html',
  styleUrls: ['./race-details.component.css'],
})
export class RaceDetailsComponent implements OnInit, OnDestroy {
  raceId: string = '';
  eventId: string = '';
  event: any = {};
  loading: boolean = false;
  horses: any = [];
  traceList: any = [];
  race_states = [
    'PLANNED',
    'OFFICIAL',
    'RUNNING',
    'FINISHED',
    'PUBLISHED',
    'ABANDONED',
    'DIDNOTTRACK',
  ];
  horse_race_states = [
    { name: 'PLANNED', value: 'PLANNED' },
    { name: 'DNS', value: 'DNS' },
    { name: 'DNT', value: 'DNT' },
    { name: 'DNF', value: 'DNF' },
    { name: 'DSQ', value: 'DSQ' },
    { name: 'OFFICIAL', value: 'OFFICIAL' },
    { name: 'FINISHED', value: 'FINISHED' },
    { name: 'PUBLISHED', value: 'PUBLISHED' },
    { name: 'ABANDONED', value: 'ABANDONED' },
  ];

  track_condition_options: string[] = [
    'Firm 1',
    'Firm 2',
    'Good 3',
    'Good 4',
    'Soft 5',
    'Soft 6',
    'Soft 7',
    'Heavy 8',
    'Heavy 9',
    'Heavy 10',
  ];
  weather_options: string[] = [
    'Fine',
    'Hot',
    'Cloudy',
    'Overcast',
    'Raining',
    'Showers',
  ];

  statuses: any = [
    {
      type: 'success',
      value: 'FINISHED',
    },
    {
      type: 'pending',
      value: 'PLANNED',
    },
    {
      type: 'warning',
      value: 'DNS',
    },
  ];

  headers: any = [
    {
      id: 'StartNumber',
      name: 'SCN',
      type: 'string',
      show: true,
      // filter: true,
      // link: {
      //   href: '',
      //   queryParamsKey: ''
      // }
    },
    {
      id: 'BarrierNumber',
      name: () => {
        if (this.event['race_type'] == 'Greyhound') {
          return 'Box';
        } else {
          return 'Barrier';
        }
      },
      type: 'inputbox',
      inputtype: 'text',
      style: {
        width: '60px',
      },
      show: true,
    },
    {
      id: 'imagePath',
      name: 'Image',
      type: 'image',
      show: true,
      // filter: true,
    },
    {
      id: 'selectedTagId',
      name: 'Tag ID',
      type: 'multi-select',
      dropdown: {
        placeholder: '',
        multiple: false,
      },
      show: true,
      // filter: true,
    },
    {
      id: 'AnimalName',
      name: 'Animal Name',
      type: 'string',
      show: true,
      // filter: true,
    },
    {
      id: 'selectedJockey',
      name: () => {
        if (this.event['race_type'] == 'Thoroughbred') {
          return 'Jockey';
        } else {
          return 'Driver';
        }
      },
      type: 'multi-select',
      dropdown: {
        placeholder:
          this.event['race_type'] == 'Thoroughbred' ? 'Jockey' : 'Driver',
        multiple: false,
        onchange: true,
      },
      show: true,
      // filter: true,
    },
    {
      id: 'selectedTrainer',
      name: 'Trainer',
      type: 'multi-select',
      dropdown: {
        placeholder: 'Trainer',
        multiple: false,
        onchange: true,
      },
      show: true,
      // filter: true,
    },
    {
      id: 'ExternalAnimalID',
      name: 'Animal ID',
      type: 'string',
      show: true,
      // filter: true,
    },
    {
      id: 'ExternalSilkID',
      name: 'Image ID',
      type: 'string',
      show: true,
      // filter: true,
    },
    {
      id: 'RaceState',
      name: 'State',
      type: 'dropdown',
      show: true,
      dropdown: {
        getOptions: () => {
          return this.horse_race_states || [];
        },
        key: 'RaceState',
      },
    },
  ];

  config: any;
  ut: string = localStorage.getItem('ut');

  constructor(
    private router: ActivatedRoute,
    private apiService: APIService,
    private notifier: NotifierService,
    private title: Title,
    private navigator: Router
  ) {
    this.config = this.router.snapshot.data['config'];
  }

  ngOnInit(): void {
    this.router.queryParams.subscribe((params: any) => {
      this.raceId = params['race_id'];
      this.eventId = params['event_id'];
      this.load();
    });
  }

  async checkButtonCondition(button: any) {
    let status = await this.checkButtonAPIStatus(button.action, true);
    if (status != 'COMPLETED' && status != 'PENDING' && status != 'FAILED') {
      this.loadingActions[button.action] = true;
      setTimeout(() => {
        this.checkButtonCondition(button);
      }, 5000);
    } else if (status == 'COMPLETED') {
      this.loadingActions[button.action] = false;
      if (button.completed_key) {
        this.event[button.completed_key] = 1;
      }
    }
  }

  oldEvent: any = null;
  // interval: any = null;
  async load(): Promise<void> {
    this.notifier.loading(true);
    this.event = {};
    try {
      await this.loadHorsesBasedOnRace();
      let HideGRHeaders: any = ['ExternalSilkID', 'selectedJockey'];
      let raceType: any = getRaceType(this.event['venue_id']);
      if (raceType?.raceTypeCode == 'GR') {
        this.headers = this.headers.filter(
          (header) => !HideGRHeaders.includes(header.id)
        );
      }
      let status: boolean = checkForVenuePermission(this.event['venue_id']);
      if (!status) {
        this.notifier.alert(
          'Error',
          '',
          'You do not have permission to access this venue',
          'error',
          5000
        );
        this.notifier.loading(false);
        this.navigator.navigate(['/dash/race-overview']);
        return;
      }
    } catch (err) {
      this.notifier.loading(false);
      return;
    }
    if (this.races.length == 0) {
      this.loadRacesBasedOnEvent();
    }
    // await this.getKitIds();
    await this.getTracts();
    this.oldEvent = JSON.parse(JSON.stringify(this.event));
    this.getPDFDetails(false);

    this.loadAvailableTags();
    // if (this.event.entrants.length > 0) {
    //   this.interval = setTimeout(() => {
    //     this.load();
    //   }, 30000);
    // }
    this.notifier.loading(false);
  }

  async loadAvailableTags() {
    let apiURL: string = `${
      this.config[getENV()].raceAPI
    }/devices/kits?kit_id=${this.eventDetails.kit}`;

    let result = await this.apiService.getDataPromis(apiURL, {}, {});

    if (result && Array.isArray(result) && result.length > 0) {
      this.kit_options = result
        .filter((item: any) => {
          return item.Status == 'In operation';
        })
        .map((item: any) => {
          return {
            item_text: parseInt(item['TagID']),
            item_id: item['SerialNumber'],
          };
        })
        .sort((a: any, b: any) => {
          return a.item_text - b.item_text;
        });
    }
    if (
      this.event?.entrants &&
      Array.isArray(this.event.entrants) &&
      this.event.entrants.length > 0
    ) {
      this.event.entrants.forEach((animal: any, index: number) => {
        animal['RaceState'] = animal['RaceState'] || 'PLANNED';
        animal['RaceState'] = animal['RaceState'].toUpperCase();
        animal['rowClassConfition'] = (animal: any) => {
          return animal['RaceState'] == 'DNS' ? 'line-through bg-grey' : null;
        };
        animal['dropdown'] = {
          error: {
            selectedTagId: false,
            selectedJockey: false,
            selectedTrainer: false,
          },
          options: {
            selectedTagId: this.kit_options,
            selectedTrainer: [
              {
                item_id: animal['ExternalTrainerID'],
                item_text: animal['TrainerName'],
              },
            ],
            selectedJockey: [],
          },
          defaultOptions: {
            selectedTagId: this.kit_options,
            selectedTrainer: [
              {
                item_id: animal['ExternalTrainerID'],
                item_text: animal['TrainerName'],
              },
            ],
            selectedJockey: [],
          },
          selected: {
            selectedTagId: animal['DeviceID']
              ? [
                  {
                    item_id: animal['DeviceNumber'],
                    item_text: animal['DeviceID'].toString(),
                  },
                ]
              : [],
            selectedTrainer: animal['ExternalTrainerID']
              ? [
                  {
                    item_id: animal['ExternalTrainerID'],
                    item_text: animal['TrainerName'],
                  },
                ]
              : [],
            selectedJockey: animal['ExternalJockeyID']
              ? [
                  {
                    item_id: animal['ExternalJockeyID'],
                    item_text: animal['JockeyName'],
                  },
                ]
              : [],
          },
        };
        if (animal['JockeyName']) {
          let temp: any = this.jockeys.find((jockey: any) => {
            return jockey['item_id'] == animal['ExternalJockeyID'];
          });
          if (!temp) {
            this.jockeys.push({
              item_id: animal['ExternalJockeyID'],
              item_text: animal['JockeyName'],
              j_id: index,
            });
          }
        }

        animal['imagePath'] = (() => {
          if (this.event.code_type === 'HARNESS') {
            return `https://tsd-prd01-silks.s3.ap-southeast-2.amazonaws.com/Harness_Racing_Australia/${animal.StartNumber}.png`;
          } else if (this.event.venue_id.includes('STC')) {
            return `https://tsd-prd01-silks.s3.ap-southeast-2.amazonaws.com/singapore/${animal.ExternalSilkID}.png`;
          } else if (this.event.venue_id.startsWith('GR')) {
            return `https://tsd-prd01-silks.s3.ap-southeast-2.amazonaws.com/Greyhounds/${animal.StartNumber}.png`;
          } else {
            return `https://tsd-prd01-silks.s3.ap-southeast-2.amazonaws.com/Racing_Australia/${animal.ExternalSilkID}.png`;
          }
        })();
      });

      this.checkDuplicateJockey();

      this.event.entrants.forEach((horse: any) => {
        horse['dropdown']['defaultOptions']['selectedJockey'] = [
          ...this.jockeys,
        ];
        horse['dropdown']['options']['selectedJockey'] = [...this.jockeys];
      });
      this.event.entrants = this.event.entrants.sort((a: any, b: any) => {
        return a['StartNumber'] - b['StartNumber'];
      });
    }
  }

  races: {
    ExternalVenueId: string;
    ExternalEventId: string;
    ExternalRaceId: string;
    SquentialRaceOrderNumber: string;
    RaceName: string;
    RaceTime: string;
    RaceState: string;
  }[] = [];
  async loadRacesBasedOnEvent(): Promise<boolean> {
    return new Promise(async (resolve: any) => {
      let apiURL: string = `${
        this.config[getENV()].raceAPI
      }/getracedetails?ExternalVenueId=${this.event.venue_id}&ExternalEventId=${
        this.event.event_id
      }`;

      let result = await this.apiService.getDataPromis(apiURL, {}, {});

      if (result && Array.isArray(result) && result.length > 0) {
        result = result.sort((a: any, b: any) => {
          return a['SquentialRaceOrderNumber'] - b['SquentialRaceOrderNumber'];
        });
        this.races = result.map((race: any) => {
          return {
            ExternalVenueId: race['ExternalVenueId'],
            ExternalEventId: race['ExternalEventId'],
            ExternalRaceId: race['ExternalRaceId'],
            SquentialRaceOrderNumber: race['SquentialRaceOrderNumber'],
            RaceName: race['RaceName'],
            RaceTime: convertTimestamp(
              race['RaceTime'],
              this.event.venue_state
            ),
            RaceState: race['RaceState'] || 'PLANNED',
          };
        });
      }

      resolve(true);
    });
  }

  captureEvent(event: any) {
    if (!event['event']) {
      event['item']['dropdown']['options'][event['h']['id']] =
        event['item']['dropdown']['defaultOptions'][event['h']['id']];
    } else {
      if (event.type == 'filter') {
        if (event.h.id == 'selectedTrainer') {
          this.loadTrainers(event['event'], event['item'], event['h']);
        }
        if (event.h.id == 'selectedJockey') {
          this.loadJockeys(event['event'], event['item'], event['h']);
        }
      } else {
        if (event.h.id == 'selectedJockey') {
          this.checkDuplicateJockey();
        } else if (event.h.id == 'selectedTagId') {
          event['item']['dropdown']['error']['selectedTagId'] = false;
          this.event.entrants.forEach((race_entrant: any) => {
            // race_entrant.dropdown.error.selectedTagId = false;
            if (
              race_entrant?.dropdown?.selected?.selectedTagId[0]?.item_id ==
                event['event']['item_id'] &&
              race_entrant['StartNumber'] != event['item']['StartNumber']
            ) {
              race_entrant.dropdown.error.selectedTagId = true;
              event['item'].dropdown.error.selectedTagId = true;
            } else if (
              race_entrant['StartNumber'] != event['item']['StartNumber']
            ) {
              race_entrant.dropdown.error.selectedTagId = false;
            }
          });
        }
      }
    }
  }

  async checkButtonAPIStatus(action: string, errorHide: boolean = false) {
    return new Promise(async (resolve: any, reject: any) => {
      let payload: any = {
        action: 'action_status',
        event_id: this.event['event_id'],
        button_action: action,
        race_number: this.event['race_number'],
        race_id: this.raceId,
        venue_id: this.event['venue_id'],
      };

      let apiURL: string = `${this.config[getENV()].raceAPI}/flask-operations`;

      let result: any = await this.apiService.postDataPromis(
        apiURL,
        payload,
        {}
      );

      if (result.status == '1' || result.s == '1') {
        if (
          result?.response?.StepCompletionStatus?.toUpperCase() == 'FAILED' &&
          !errorHide
        ) {
          if (this.loadingActions[action]) {
            this.notifier.alert(
              'Info',
              '',
              result?.response?.Comments ||
                'Something went wrong. Please try again!',
              'info',
              5000
            );
          }
        }
        resolve(
          result?.response?.StepCompletionStatus?.toUpperCase() || 'PENDING'
        );
      } else {
        reject('FAILED');
      }
    });
  }

  loadingActions: any = {};

  async handleClicks(event: any = null, params: any) {
    if (params.isCompleted && params.isCompleted()) {
      this.event[params.completed_key] = 1;
      if (event) {
        this.notifier.alert(
          'Info',
          '',
          'Action is already completed!',
          'info',
          5000
        );
      }
      return;
    }
    if (event) {
      event.preventDefault();
    }
    if (!this.eventDetails || !this.eventDetails['pc']) {
      this.notifier.alert(
        'Info',
        '',
        'Please complete prepare for race step!',
        'info',
        5000
      );
      return;
    }

    if (this.loadingActions[params['action']] && event) {
      this.notifier.alert(
        'Info',
        '',
        'Action is already in progress!',
        'info',
        5000
      );
      return;
    }

    this.loadingActions[params['action']] = true;
    let status: any = await this.checkButtonAPIStatus(
      params['action'],
      event ? true : false
    );
    if (status == 'COMPLETED') {
      this.loadingActions[params['action']] = false;
      this.event[params['completed_key']] = 1;
      if (event) {
        this.notifier.alert(
          'Info',
          '',
          'Action is already completed!',
          'info',
          5000
        );
      }
      return;
    } else if (status == 'PENDING' || (status == 'FAILED' && event)) {
      let data: any = {
        action: params['action'],
        location: this.event['venue'],
        race_number: this.event['race_number'],
        race_id: this.raceId,
        race_date: this.event['date'],
        event_id: this.event['event_id'],
        jurisdiction: this.event['eventDetails']['JurisdictionCode'],
        type: this.event['race_type'] + ' Racing',
      };

      let apiURL: string = `${this.config[getENV()].raceAPI}/flask-operations`;
      let result: any = await this.apiService.postDataPromis(apiURL, data, {});

      if (result.status == '1' || result.s == '1') {
        this.notifier.alert(
          'Success',
          '',
          result.msg || result.message || result.success,
          'success',
          5000
        );
        setTimeout(() => {
          this.handleClicks(null, params);
        }, 5000);
      } else {
        this.notifier.alert(
          'Info',
          '',
          result.error_message || result.error || result.message,
          'info',
          5000
        );
      }
    } else if (status == 'FAILED' && this.loadingActions[params['action']]) {
      this.loadingActions[params['action']] = false;
    } else {
      setTimeout(async () => {
        this.handleClicks(null, params);
      }, 5000);
      return;
    }

    this.notifier.loading(false);
  }

  timer: ReturnType<typeof setTimeout> = null;
  lastSearch: { trainer: string; jockey: string } = { trainer: '', jockey: '' };

  async loadTrainers(key: string, item: any, h: any) {
    clearTimeout(this.timer);
    item.loading[h.id] = true;
    this.timer = setTimeout(async () => {
      if (this.lastSearch['trainer'] == key) {
        item.loading[h.id] = false;
        return;
      }
      this.lastSearch['trainer'] = key;
      let apiURL: string = `${
        this.config[getENV()].raceAPI
      }/trainer-jockey-name?table=trainer&mode=3&name=${key}`;

      let result: any = await this.apiService.getDataPromis(apiURL, {}, {});

      item.loading[h.id] = false;

      item.dropdown.options[h.id] = [
        ...item.dropdown.defaultOptions[h.id],
        ...result.map((trainer: any) => {
          return {
            item_id: trainer['id'],
            item_text: trainer['name'],
          };
        }),
      ];
    }, 1000);
  }

  jockeys: any = [];

  async loadJockeys(key: string, item: any, h: any) {
    clearTimeout(this.timer);
    item.loading[h.id] = true;
    this.timer = setTimeout(async () => {
      if (this.lastSearch['jockey'] == key) {
        item.loading[h.id] = false;
        return;
      }
      this.lastSearch['jockey'] = key;
      let apiURL: string = `${
        this.config[getENV()].raceAPI
      }/trainer-jockey-name?table=jockey&mode=3&name=${key}`;

      let result: any = await this.apiService.getDataPromis(apiURL, {}, {});

      item.loading[h.id] = false;

      item.dropdown.options[h.id] = [
        ...this.jockeys,
        ...result.map((trainer: any) => {
          return {
            item_id: trainer['id'],
            item_text: trainer['name'],
          };
        }),
      ];
    }, 1000);
  }

  selectWeatherStatus() {
    this.event.weather = this.event.weather_edit;
    this.event.weather_edit = undefined;
  }

  selectTrackConditionStatus() {
    this.event.track_condition = this.event.track_condition_edit;
    this.event.track_condition_edit = undefined;
  }

  deleteData() {
    this.dataTransfer = JSON.parse(JSON.stringify(this.event));
    this.dataTransfer['action_type'] = 'delete';
  }

  dataTransfer: any = null;

  error: string | null = null;

  async loadHorsesBasedOnRace(): Promise<boolean> {
    return new Promise(async (resolve: any, reject: any) => {
      let apiURL: string = `${
        this.config[getENV()].raceAPI
      }/race-entrant-details?ExternalRaceId=${this.raceId}&ExternalEventId=${
        this.eventId
      }`;

      let result: any = await this.apiService.getDataPromis(apiURL, {}, {});

      if (typeof result == 'string') {
        this.error = result;
        this.notifier.alert('Info', '', result, 'info', 5000);
        this.horses = [];
        this.event = null;
        reject(false);
        return;
      }

      this.event = { ...result };

      if (
        this.event['weather'] &&
        !this.weather_options.includes(this.event['weather'])
      ) {
        this.weather_options.push(this.event['weather']);
      }
      if (
        this.event['track_condition'] &&
        !this.track_condition_options.includes(this.event['track_condition'])
      ) {
        this.track_condition_options.push(this.event['track_condition']);
      }

      this.event.entrants.forEach((horse: any) => {
        horse['loading'] = {};
      });
      this.event['date'] = moment(this.event['date'], 'YYYY-MM-DD').format(
        'DD MMMM YYYY'
      );
      this.title.setTitle(
        `Race ${this.event.race_number} - ${this.event.race_name} - ${this.raceId}`
      );
      this.loadEventDetails();
      this.event['race_type'] = getRaceType(this.event['venue_id'])[
        'raceTypeShortStr'
      ];
      this.event['race_id'] = this.raceId;
      if (this.event['race_type'] == 'Thoroughbred') {
        if (this.event['event_name'] == 'Cranbourne') {
          this.event['event_name'] = 'Cranbourne Turf';
        }
      }

      let eventDetails: any = typeBasedLocations[
        this.event['race_type'] + ' Racing'
      ].find((jurisdiction: any) => {
        return jurisdiction.Locations.find((location: any) => {
          return (
            location['Name'].toLowerCase() == this.event['venue'].toLowerCase()
          );
        });
      });

      if (eventDetails) {
        this.event['eventDetails'] = {
          Jurisdiction: eventDetails['Jurisdiction'],
          JurisdictionCode: eventDetails['JurisdictionCode'],
          State: eventDetails['State'],
          Location: this.event['venue'],
        };
      }
      await this.getRaceResult();

      resolve(true);
    });
  }

  eventDetails: any = null;

  async loadEventDetails() {
    let apiURL: string = `${
      this.config[getENV()].raceAPI
    }/getevent?ExternalEventId=${this.event['event_id']}`;

    let header: any = {
      'X-Api-Key': 'e2bvuAoslY1VJvFMqhr5x7nxoEhFVRii9jRngS3U',
    };

    let result: any = await this.apiService.getDataPromis(apiURL, {}, header);

    this.eventDetails = result.event;
  }

  async getRaceResult() {
    return new Promise(async (resolve: any) => {
      let apiURL: string = `${
        this.config[getENV()].raceAPI
      }/getraceresults?ExternalEventId=${this.event.event_id}&ExternalVenueId=${
        this.event.venue_id
      }&raceIds%5B%5D=${this.raceId}`;

      let result: any = await this.apiService.getDataPromis(apiURL, {}, {});

      if (result && typeof result == 'object') {
        this.event['race_status'] =
          this.event['race_state']?.toUpperCase() ||
          result[this.raceId]?.toUpperCase() ||
          'PLANNED';

        if (this.event['race_status'] == 'ABANDONED') {
          this.event.entrants.forEach((horse: any) => {
            horse['RaceState'] = 'ABANDONED';
          });
        }
      } else {
        this.event['race_status'] = 'PLANNED';
      }

      resolve(true);
    });
  }

  async getTracts() {
    return new Promise(async (resolve: any) => {
      let apiURL: string = `${
        this.config[getENV()].raceAPI
      }/geteqtrack?ExternalVenueId=${this.event.venue_id}`;
      let header: any = {
        'X-Api-Key': 'e2bvuAoslY1VJvFMqhr5x7nxoEhFVRii9jRngS3U',
      };
      let result: any = await this.apiService.getDataPromis(apiURL, {}, header);
      if (result.venue && result.venue.TrackList) {
        let tracks: any = [];
        this.traceList = result.venue.TrackList.map((trace: any) => {
          return {
            name: trace['EQTrackName'],
            value: Number(trace['TrackID']),
          };
        }).filter((trace: any) => {
          if (tracks.indexOf(trace['name']) === -1) {
            tracks.push(trace['name']);
            return true;
          } else {
            return false;
          }
        });
      }
      resolve(true);
    });
  }

  getIdFromName(originalArray: any, targetName: any) {
    for (const item of originalArray) {
      if (item.name === targetName) {
        return item.id;
      }
    }
    // Return null or another value to indicate that the name was not found
    return null;
  }

  tag_list: any = [];
  kit_options: any = [];

  checkDuplicateJockey() {
    let checked: any = [];
    this.event.entrants.forEach((horse: any) => {
      if (
        horse['dropdown']['selected']['selectedJockey'].length == 0 ||
        horse['ExternalJockeyID'] == '0'
      ) {
        return;
      }
      if (
        checked.indexOf(
          horse['dropdown']['selected']['selectedJockey'][0]['item_id']
        ) === -1
      ) {
        horse['dropdown']['error']['selectedJockey'] = false;
      } else {
        horse['dropdown']['error']['selectedJockey'] = true;
        this.event.entrants[
          checked.indexOf(
            horse['dropdown']['selected']['selectedJockey'][0]['item_id']
          )
        ]['dropdown']['error']['selectedJockey'] = true;
      }
      checked.push(
        horse['dropdown']['selected']['selectedJockey'][0]['item_id']
      );
    });
  }

  selectRaceStatus() {
    this.event['race_status'] = this.event['race_status_edit'];
    this.event['race_status_edit'] = undefined;
  }


  checkEntrantChanges(oldEntrant: any, newEntrant: any) {
    let isRaceStateChanged = false;
    let isDropdownChanged = false;
    let isBarrierNumberChanged = false;

    // Check if RaceState is changed
    if (oldEntrant.RaceState !== newEntrant.RaceState) {
      isRaceStateChanged = true;
    }

    // Check if any dropdown item_Id is changed
    if (oldEntrant.dropdown && newEntrant.dropdown) {
      for (const key in oldEntrant.dropdown['selected']) {
        const oldItemId: any = oldEntrant.dropdown['selected'][key];
        const newItemId: any = newEntrant.dropdown['selected'][key];
        if (oldItemId.length == newItemId.length) {
          if (oldItemId.length > 0 && newItemId.length > 0) {
            if (oldItemId[0].item_id != newItemId[0].item_id) {
              isDropdownChanged = true;
              break;
            }
          }
        } else {
          isDropdownChanged = true;
          break;
        }
      }
    }

    // Check if barrier number is changed
    if (oldEntrant.BarrierNumber !== newEntrant.BarrierNumber) {
      isBarrierNumberChanged = true;
    }

    return { isRaceStateChanged, isDropdownChanged, isBarrierNumberChanged };
  }

  async prev() {
    const status: boolean = await this.submitRace(null, false);
    if (status) {
      let link: any = '/dash/race-details';
      let queryParams: any = {
        race_id: this.event['previous_race_id'],
        event_id: this.eventId,
      };
      this.navigator.navigate([link], { queryParams: queryParams });
    }
  }

  async next() {
    const status: boolean = await this.submitRace(null, false);
    if (status) {
      let link: any = '/dash/race-details';
      let queryParams: any = {
        race_id: this.event['next_race_id'],
        event_id: this.eventId,
      };
      this.navigator.navigate([link], { queryParams: queryParams });
    }
  }

  async submitRace(
    event: any = null,
    reload: boolean = true
  ): Promise<boolean> {
    return new Promise(async (resolve: any, reject: any) => {
      if (event) {
        event.preventDefault();
      }

      if (!event) {
        let check: boolean = false;
        for (let i = 0; i < this.event.entrants.length; i++) {
          const {
            isDropdownChanged,
            isBarrierNumberChanged,
            isRaceStateChanged,
          } = this.checkEntrantChanges(
            this.oldEvent.entrants[i],
            this.event.entrants[i]
          );

          if (
            isDropdownChanged ||
            isBarrierNumberChanged ||
            isRaceStateChanged
          ) {
            check = true;
            break;
          }
        }

        if (!check) {
          if (this.oldEvent['race_status'] != this.event['race_status']) {
            check = true;
          } else {
            resolve(true);
            return;
          }
        }
      }

      let trackData: any = this.event['current_eq_trace_track']
        ? this.traceList.find((track: any) => {
            return this.event['current_eq_trace_track'] == track['value'];
          })
        : null;

      let eventData: any = {
        external_race_id: this.raceId,
        track_id: trackData ? Number(trackData['value']) : null,
        track_name: trackData ? trackData['name'] : null,
        race_state: this.event['race_status'],
        update_data: {},
        weather:
          this.event['weather'] !== '' && this.event['weather'] != undefined
            ? this.event['weather']
            : null,
        track_condition:
          this.event['track_condition'] !== '' &&
          this.event['track_condition'] != undefined
            ? this.event['track_condition']
            : null,
      };

      // Track used tag IDs to check for uniqueness
      const usedTagIds: Set<any> = new Set();
      let isValidationFailed = false;
      this.event.entrants.forEach((race_entrant: any) => {
        let tag_sn =
          race_entrant.dropdown?.selected?.selectedTagId[0]?.item_id || null;
        let trainer_name =
          race_entrant?.dropdown?.selected?.selectedTrainer[0]?.item_id || null;
        let dns_id = race_entrant?.RaceState?.toUpperCase() == 'DNS' || false;
        let jockey_name =
          race_entrant?.dropdown?.selected?.selectedJockey[0]?.item_id || null;
        let tag_id =
          race_entrant?.dropdown?.selected?.selectedTagId[0]?.item_text || null;
        eventData['update_data'][race_entrant.ExternalAnimalID] = {
          tag: tag_id ? Number(tag_id) : null,
          tag_sn: tag_sn,
          trainer: trainer_name,
          jockey: jockey_name,
          is_dns: dns_id,
          barrier: race_entrant.BarrierNumber || '',
          state: race_entrant?.RaceState,
        };
        // Check if tag_id is unique (or null)
        if (tag_id !== '' && tag_id !== null && usedTagIds.has(tag_id)) {
          this.notifier.alert(
            'Info',
            '',
            `Duplicate tag_id detected for race entrant ${race_entrant.ExternalAnimalID}`,
            'info',
            5000
          );
          isValidationFailed = true;
        }
        if (tag_id != undefined) {
          usedTagIds.add(tag_id);
        }

        // Check for null or empty values for trainer and jockey names
        if (!trainer_name && !dns_id) {
          this.notifier.alert(
            'Info',
            '',
            `Trainer name is required for race entrant ${race_entrant.ExternalAnimalID}`,
            'info',
            5000
          );
          isValidationFailed = true;
        }

        if (
          !jockey_name &&
          !dns_id &&
          getRaceType(this.event.venue_id)['raceTypeCode'] != 'GR'
        ) {
          this.notifier.alert(
            'Info',
            '',
            `Jockey name is required for race entrant ${race_entrant.ExternalAnimalID}`,
            'info',
            5000
          );
          isValidationFailed = true;
        }
      });
      if (isValidationFailed) {
        this.notifier.alert(
          'Info',
          '',
          'Form validation failed. Please check error messages.',
          'info',
          5000
        );
        resolve(false);
        return;
      }
      const apiUrl = `${this.config[getENV()].raceAPI}/update-race-details`;
      const apiKey = 'U7RoenAJeFp21d07UQMY7Y1TAitCuR76nuXQJ8pg';
      const headers = { 'x-api-key': apiKey };
      this.notifier.loading(true);
      let result: any = await this.apiService.postDataPromis(
        apiUrl,
        eventData,
        headers
      );

      this.notifier.loading(false);
      // if (generate_start_list) {
      //   await this.generateStartList();
      // } else {
      if (reload) {
        location.reload();
      }
      resolve(true);
    });
    // }
  }

  async updateScratchings() {
    this.event.loadingScrating = true;
    let apiURL: string = `${
      this.config[getENV()].raceAPI
    }/scratching?raceIds[]=${this.raceId}`;

    let result: any = await this.apiService.postDataPromis(apiURL, {}, {});

    if (result.status == '1' || result.s == '1') {
      this.notifier.alert(
        'Success',
        '',
        'Scratchings updated successfully',
        'success',
        5000
      );
      this.load();
    } else {
      this.notifier.alert('Error', '', result.error_message, 'error', 5000);
    }

    this.event.loadingScrating = false;
  }

  async generateStartList() {
    // if (!this.event.kit) {
    //   this.notifier.alert('Info', '', 'No kit assigned to event', 'info', 5000);
    //   return;
    // }
    this.notifier.loading(true);
    let apiURL: string = `${
      this.config[getENV()].raceAPI
    }/pdf-task?ExternalEventId=${this.event['event_id']}`;

    let header: any = {
      'X-Api-Key': 'e2bvuAoslY1VJvFMqhr5x7nxoEhFVRii9jRngS3U',
    };

    let result: any = await this.apiService.postDataPromis(apiURL, {}, header);

    if (result.task_id && result.status == '1') {
      this.notifier.alert('Success', '', 'Generating PDF...', 'success', 5000);
      setTimeout(() => {
        this.getPDFDetails();
      }, 5000);
    } else {
      this.event['pdf_status'] = 'FAILED';
    }
    this.notifier.loading(false);
  }

  async getPDFDetails(refresh: boolean = true) {
    return new Promise(async (resolve: any) => {
      let apiURL: string = `${
        this.config[getENV()].raceAPI
      }/pdf-task?ExternalEventId=${this.event.event_id}`;

      let result: any = await this.apiService.getDataPromis(apiURL, {}, {});

      if (result.status == '1') {
        if (result['pdf_file_path']) {
          this.event['pdf_file_path'] = result['pdf_file_path'];
          this.event['pdf_status'] = 'GENERATED';
          if (refresh) {
            this.notifier.alert(
              'Success',
              '',
              'PDF Generated Successfully',
              'success',
              5000
            );
            location.reload();
          }
        } else if (result['is_running']) {
          this.event['pdf_status'] = 'GENERATING';
        } else if (!result['is_running'] && result['error_status']) {
          this.event['pdf_status'] = 'FAILED';
          this.event['pdf_error'] = result['error_status'];
        }
        setTimeout(() => {
          if (this.event['pdf_status'] == 'GENERATING' && !this.destroyed) {
            this.getPDFDetails();
          }
        }, 5000);
      }
      resolve(true);
    });
  }

  destroyed: boolean = false;

  ngOnDestroy(): void {
    this.destroyed = true;
    // if (this.interval) {
    //   clearInterval(this.interval);
    // }
  }

  protected readonly convertTimestamp = convertTimestamp;
}
