import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { getENV } from 'src/app/_helpers/helpers';
import { APIService } from 'src/app/services/api.service';
import { NotifierService } from 'src/app/services/notifier.service';


declare let Jhxlsx: any;

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.css'],
})
export class UserDetailsComponent implements OnInit, OnDestroy {
  @Input('user') user: any;
  @Output('hideModal') hideModal: any = new EventEmitter<boolean>();
  @Input('venues') venues: any;
  userDetails: any = {};
  userId: string | null = localStorage.getItem('eId');
  ut: string | null = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  userList: any = [];

  currentPage: string = 'details';

  selectedUser: boolean = false;
  filterText: string = '';

  funRef: any = {
    selectUser: this.selectUser,
  };

  loading: any = {
    details: false,
    users: false,
  };

  userTypes: any = {
    userAdmin: 'Admin',
    user: 'General',
    superAdmin: 'Master Admin',
    undefined: 'User',
  };

  updateUser: any = null;

  isUserAdmin: boolean = localStorage.getItem('isUserAdmin') == '1';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin' ||
    this.isUserAdmin;

  config: any;

  constructor(
    private apiServer: APIService,
    private notifier: NotifierService,
    private navigator: Router,
    private route: ActivatedRoute
  ) {
    this.config = this.route.snapshot.data['config'];
  }

  ngOnInit(): void {
    this.userDetails = JSON.parse(JSON.stringify(this.user));
    this.load();
  }

  load() {
    if (this.userDetails['userType'] != 'superAdmin') {
      this.loadUserDetails();
    }
    if (
      this.user['userType'] == 'userAdmin' ||
      this.user['userType'] == 'superAdmin'
    ) {
      this.loadUsers();
    }
  }

  viewAudit() {
    this.navigator.navigate(['/dash/settings/audittrail'], {
      queryParams: {
        user_id: this.userDetails['emaiId'],
        source: 'user_details',
      },
    });
  }

  async sendResendLink(user: any) {
    this.loading['resend'] = true;
    let data: any = {
      action: 'resendRegistrationLink',
      clientEmailID: user['emaiId'],
      clientName: user['clientName'],
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${this.config[getENV()].apiURL}/${this.urlPrefix}/Settings/usermanagement`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.s == '1' || result.status == '1') {
      this.notifier.alert('Success', '', result.msg, 'success', -1);
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }
    this.loading['resend'] = false;
  }

  statusFilter: string = 'ALL';
  filteredUserList() {
    if (this.filterText == '' && this.statusFilter == 'ALL') {
      return this.userList;
    } else {
      return this.userList.filter((user: any) => {
        let check: boolean = true;
        if (this.statusFilter != 'ALL' && check) {
          if (this.statusFilter == 'ACTIVE') {
            check = user['accountStatus'];
          } else {
            check = !user['accountStatus'];
          }
        }
        return (
          (user['clientName'].toLowerCase().indexOf(this.filterText) > -1 ||
            user['emaiId'].toLowerCase().indexOf(this.filterText) > -1) &&
          check
        );
      });
    }
  }

  async closeModal(event: boolean) {
    this.updateUser = null;
    this.load();
  }

  changePassword: any = null;
  changePasswordAction() {
    this.changePassword = this.userDetails;
  }

  hideChangePassword(event?: boolean) {
    this.changePassword = null;
  }

  setUpdateUser() {
    this.updateUser = JSON.parse(JSON.stringify(this.userDetails));
  }

  async loadUserDetails(clear: boolean = true) {
    if (clear) {
      this.updateUser = null;
    }
    this.loading['details'] = true;
    let data = { a: 'fetchUser', email: this.userDetails['email'] };
    let header = {
      Authorization: localStorage.getItem('t'),
    };

    let apiURL: string = `${this.config[getENV()].apiURL}/${this.urlPrefix}/Settings/usermanagement`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.s == '1' || result.status == '1') {
      result.client['accountStatus'] = result.client['accountStatus']
        ? 'active'
        : 'inActive';
      result.client = {
        ...result.client,
        ...{
          clientName: result.client['userName'],
          emaiId: result.client['email'],
          clientCmpName: result.client['company'],
          mobileNumber: result.client['mobile'],
          userType: result.client['userType'],
          masterClientId: result.client['masterId'],
          otpReq: result.client['mfaRequired'],
          policyEnabled: result.client['policyEnabled'],
          policyName: result.client['policyName'],
          venueList: result.client['venueList'] || [],
        },
      };
      this.userDetails = { ...this.userDetails, ...result.client };
    } else {
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
    }

    this.loading['details'] = false;
  }

  get getVenues() {
    if(!this.userDetails['venueList']) return '-';
    return this.venues.filter((venue: any) => {
      return this.userDetails['venueList'].indexOf(venue['ExternalVenueID']) > -1;
    }).map((venue: any) => {
      return venue['VenueName'];
    }).join(', ')
  }

  userHeaders: any = [
    {
      id: 'clientName',
      name: 'Client Name',
    },
    { id: 'emaiId', name: 'Email ID', click: 'selectUser' },
    {
      id: 'mobileNumber',
      name: 'Phone Number',
    },
    // {
    //   id: 'accountGroup',
    //   name: 'AWS Account Groups',
    // },
  ];

  adminUsersheader: any = [
    {
      id: 'userName',
      name: 'User Name',
    },
    {
      id: 'userId',
      name: 'User ID',
    },
    {
      id: 'cmpName',
      name: 'Company Name',
    },
    {
      id: 'phNumber',
      name: 'Phone Number',
    },
    // {
    //   id: 'accountGroup',
    //   name: 'Account Groups',
    // },
  ];

  export() {
    var dict: any = {
      sheetName: 'Budgets',
      data: [],
    };
    let headers: any = [
      { text: 'Sl. No.' },
      { text: 'Client Name' },
      { text: 'Email ID' },
      { text: 'Phone Number' },
      { text: 'AWS Account Groups' },
      { text: 'User Type' },
      { text: 'Created By' },
    ];
    dict.data.push(headers);
    this.filteredUserList().forEach((user: any, index: number) => {
      let data = [];
      data.push({ text: index + 1 });
      data.push({ text: user['clientName'] });
      data.push({ text: user['emaiId'] });
      data.push({ text: user['mobileNumber'] });
      data.push({
        text:
          user['accountDetailsList'] && user['accountDetailsList'].length > 0
            ? user['accountDetailsList']
              .map((account: any) => {
                return account['accountId'];
              })
              .join(', ')
            : '-',
      });
      data.push({ text: this.userTypes[user['userType']] });
      data.push({ text: user['masterClientId'] });
      dict.data.push(data);
    });
    var options = {
      fileName: `Users - ${this.userId}`,
    };
    Jhxlsx.export([dict], options);
  }

  disableUser: string | null = null;
  async switchStatus(event: boolean) {
    this.disableUser = null;
    if (!event) {
      return;
    }
    this.loading['status'] = true;
    let data: any = {
      clientId: this.userDetails['emaiId'],
      email: this.userDetails['emaiId'],
      a: 'updateUserStatus',
      ut: this.urlPrefix,
      masterClientId: this.userId,
      accountStatus:
        this.userDetails['accountStatus'] == 'active' ? 'inActive' : 'active',
      userType:
        this.userDetails['userType'] == 'superAdmin' ? 'admin' : 'userAdmin',
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${this.config[getENV()].apiURL}/${this.urlPrefix}/Settings/usermanagement`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.s == '1' || result.status == '1') {
      this.userDetails['accountStatus'] =
        this.userDetails['accountStatus'] == 'active' ? 'inActive' : 'active';
      this.notifier.alert('Success', '', result.m, 'success', 5000);
    } else {
      this.notifier.alert('Info', '', result.error || result.m, 'info', 5000);
    }

    this.loading['status'] = false;
  }

  async loadUsers() {
    this.userList = [];
    this.loading['users'] = true;
    let data: any = {
      a: 'fetchAll',
      email: this.userDetails['emaiId'],
    };

    if (this.urlPrefix == 'admin') {
      data = { userId: this.userId };
    }

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${this.config[getENV()].apiURL}/${this.urlPrefix}/Settings/usermanagement`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.s == '1' || result.status == '1') {
      this.userList = result.clients
        .filter((user: any) => {
          return user['masterId'] && user['masterId'] == this.user['emaiId'];
        })
        .map((user: any) => {
          if (this.urlPrefix == 'admin') {
            user['clientName'] = user['userName'];
            user['emaiId'] = user['email'];
            user['userId'] = user['email'];
            user['cmpName'] = user['company'];
            user['phNumber'] = user['mobile'];
            // user['accountGroup'] = user.accountGroups;
          } else {
            user['clientName'] = user['userName'];
            user['emaiId'] = user['email'];
            user['clientName'] = user['userName'];
            user['clientCmpName'] = user['company'];
            user['mobileNumber'] = user['mobile'];
            user['userType'] = user['userType'];
            user['masterClientId'] = user['masterId'];
            // user["accountDetailsList"] = user["accountIdList"].join(', ');
            // user["accountsList"] = user["accountIdList"];
            user['otpReq'] = user['mfaRequired'];
          }
          return user;
        });
    }

    this.loading['users'] = false;
  }

  close(event: any) {
    if (this.selectedUser) {
      this.currentPage = 'details';
      this.selectedUser = false;
      this.userDetails = this.user;
      if (this.userDetails['userType'] != 'superAdmin') {
        this.loadUserDetails();
      }
      this.loadUsers();
      this.currentPage = 'details';
      return;
    }
    this.hideModal.emit(event);
  }

  selectUser(ref: any, value: any) {
    ref.selectedUser = true;
    ref.userDetails = value;
    ref.currentPage = 'details';

    ref.loadUserDetails();

    if (ref.userDetails['userType'] == 'userAdmin') {
      ref.loadUsers();
    }
  }

  deleteUser: any = null;
  deleteUserAction() {
    this.deleteUser = JSON.parse(JSON.stringify(this.userDetails));
  }

  hideDeleteUser(event: any) {
    this.deleteUser = null;
    if (event) {
      this.hideModal.emit(true);
    }
  }

  callFunction(name: any, param: any) {
    this.funRef[name](this, param);
  }

  ngOnDestroy(): void { }
}
