<div class="card">
  <div class="card-header flex-between">
    <div class="flex-start">
      <a
        routerLink="/dash/venue-details"
        [queryParams]="{
          event_id: selected['external_event_id'],
          venue_id: selected['external_venue_id'],
          date: date,
          event_name: selected['venue_name']
        }"
        class="flex-start btn btn-light"
        *ngIf="selected"
      >
        <i class="fa fa-angle-left"></i>
        <span>{{ selected["venue_name"] }}</span>
      </a>
      <h5 class="mb-0">Race Reports</h5>
    </div>
    <input type="text" class="datetimepicker" />
  </div>
  <div class="card-body">
    <div>
      <div class="form-group mt-2">
        <label>Select your Event</label>
        <post-race-location-picker
          [locations]="locations"
          [selected]="selected"
          (selectedLocation)="selectEvent($event)"
          type="event"
        ></post-race-location-picker>
        <div *ngIf="locations.length == 0">
          No Events Found on <b>{{ date }}</b>
        </div>
      </div>

      <div class="form-group mt-2">
        <div class="card-view flex-between">
          <label class="mb-0">Races</label>
          <div class="flex-start" *ngIf="selected">
            <span class="nav-link flex-start" (click)="showVenueNotes()">
              <i class="fa fa-sticky-note color-inherit" aria-hidden="true"></i>
              Venue Notes
            </span>
            <div class="btn-group flex-start">
              <button
                class="btn btn-primary-soft flex-start btn-dropdown"
                *ngIf="selected['JurisdictionCode'] == 'MMR'"
                (click)="generateAllPDF()"
              >
                Generate All
              </button>
              <button
                class="btn btn-success-soft flex-start btn-dropdown"
                [disabled]="downloadingAll ? true : null"
                *ngIf="checkIfAtleastOneRacePDFisCreated()"
              >
                Download All
                <i
                  class="fa fa-angle-down color-inherit"
                  aria-hidden="true"
                ></i>
                <div class="dropdown-menu">
                  <span
                    class="dropdown-item"
                    href="#"
                    (click)="downloadAll('.pdf')"
                    >PDF File</span
                  >
                  <span
                    class="dropdown-item"
                    href="#"
                    (click)="downloadAll('.csv')"
                    >CSV File</span
                  >
                  <span
                    class="dropdown-item"
                    href="#"
                    *ngIf="selected['type'] == 'LPS'"
                    (click)="downloadAll('.csv', 'tol')"
                    >Processed CSV File</span
                  >
                  <span
                    class="dropdown-item"
                    href="#"
                    (click)="downloadAll('.xml')"
                    >XML File</span
                  >
                </div>
              </button>
            </div>
          </div>
        </div>
        <div
          class="race form-group"
          [class.completed]="isRaceCompleted(race)"
          [class.error]="isRaceError(race)"
          *ngFor="let race of races"
        >
          <div class="card-view">
            <div class="flex-start">
              <div class="flex-1">
                <div class="flex-start">
                  <div class="fw-bold fs-5">
                    #{{ race["SquentialRaceOrderNumber"] }} {{ race.RaceName }}
                  </div>
                  <span
                    class="status-text-container alert inline-flex-start"
                    [ngClass]="{
                      'alert-success':
                        race.RaceState == 'FINISHED' ||
                        race.RaceState == 'RUNNING' ||
                        race.RaceState == 'PUBLISHED',
                      'alert-dark':
                        race.RaceState == 'PLANNED' ||
                        race.RaceState == 'OFFICIAL',
                      'alert-danger':
                        race.RaceState == 'INCOMPLETED' ||
                        race.RaceState == 'CANCELLED' ||
                        race.RaceState == 'DIDNOTTRACK' ||
                        race.RaceState == 'ABANDONED'
                    }"
                  >
                    <i
                      class="fa fa-circle color-inherit"
                      aria-hidden="true"
                    ></i>
                    <span class="color-inherit">{{
                      race["RaceState"] || "-"
                    }}</span>
                  </span>
                  <a
                    routerLink="/dash/race-details"
                    [queryParams]="{
                      race_id: race['ExternalRaceId'],
                      event_id: selected['external_event_id']
                    }"
                    target="_blank"
                    class="nav-link flex-start"
                  >
                    <i class="fa fa-external-link color-inherit"></i>
                    Go to race
                  </a>
                  <span
                    class="nav-link flex-start"
                    (click)="showRaceNotes(race)"
                  >
                    <i class="fa fa-sticky-note" aria-hidden="true"></i>
                    Race Notes
                  </span>
                </div>
                <div class="flex-start pt-2">
                  <div>
                    <div>
                      <strong>Location:</strong> {{ selected.venue_name }}
                    </div>
                  </div>
                  <div>
                    <div><strong>Date:</strong> {{ date }}</div>
                  </div>
                  <div>
                    <div><strong>Time:</strong> {{ race.RaceTime }}</div>
                  </div>
                </div>
              </div>
              <div>
                <div class="d-flex flex-wrap gap-3">
                  <ng-container *ngFor="let button of race['Buttons']">
                    <div class="d-flex flex-column h-100">
                      <div class="btn-group">
                        <button
                          class="btn flex-grow-1 position-relative"
                          [ngClass]="{
                            'btn-success': button.completed,
                            'btn-danger': button.error,
                            'btn-secondary': !button.completed && !button.error
                          }"
                          [disabled]="button.loading"
                        >
                          <div
                            class="color-inherit cursor-pointer"
                            (click)="handleClicks(race, true, button)"
                          >
                            {{ button.name }}
                            <span
                              *ngIf="button.loading"
                              class="spinner-border spinner-border-sm ml-2"
                            ></span>
                          </div>
                          <div class="tooltip-container">
                            <div
                              class="tooltip-content"
                              *ngIf="
                                (button.action == 'publish' ||
                                  (button.action == 'generate_pdf' &&
                                    button.type == 'LPS')) &&
                                button.steps &&
                                button.steps.length > 0
                              "
                            >
                              <div
                                class="step-container"
                                *ngFor="let step of button.steps"
                              >
                                <div
                                  class="step-status"
                                  [ngClass]="{
                                    completed:
                                      step.StepCompletionStatus.toUpperCase() ==
                                      'COMPLETED',
                                    'in-progress':
                                      step.StepCompletionStatus.toUpperCase() ==
                                      'IN PROGRESS',
                                    failed:
                                      step.StepCompletionStatus.toUpperCase() ==
                                      'FAILED'
                                  }"
                                >
                                  <i
                                    class="fa"
                                    [ngClass]="{
                                      'fa-check-circle':
                                        step.StepCompletionStatus.toUpperCase() ==
                                        'COMPLETED',
                                      'fa-clock-o':
                                        step.StepCompletionStatus.toUpperCase() ==
                                        'IN PROGRESS',
                                      'fa-exclamation-circle':
                                        step.StepCompletionStatus.toUpperCase() ==
                                        'FAILED'
                                    }"
                                  ></i>
                                  <strong>
                                    {{ step.StepType }}:
                                    {{ step.StepCompletionStatus }}
                                  </strong>
                                  <ng-container
                                    *ngIf="
                                      step.StepCompletionStatus.toUpperCase() ==
                                      'FAILED'
                                    "
                                  >
                                    <br />
                                    {{ step.Comments }}
                                  </ng-container>
                                </div>
                              </div>
                            </div>
                            <div
                              class="tooltip-content"
                              *ngIf="
                                button.error &&
                                !(
                                  button.action == 'publish' ||
                                  (button.action == 'generate_pdf' &&
                                    button.type == 'LPS')
                                )
                              "
                            >
                              <div class="failed p-2">
                                <i
                                  class="fa fa-exclamation-circle text-danger"
                                ></i>
                                {{ button.error }}
                              </div>
                            </div>
                            <div
                              class="tooltip-content"
                              [class.p-0]="
                                (button.action == 'publish' ||
                                  (button.action == 'generate_pdf' &&
                                    button.type == 'LPS')) &&
                                button.preview_email
                              "
                              *ngIf="button.completed && !button.error"
                            >
                              <i class="fa fa-check-circle text-success"></i>
                              Completed
                            </div>
                            <div class="tooltip-content" *ngIf="button.pending">
                              <i class="fa-regular fa-clock"></i> Pending
                            </div>
                            <div
                              class="tooltip-content"
                              *ngIf="button.preview_email"
                            >
                              <i class="fa-regular fa-envelope"></i>
                              <span class="email-text"
                                >Email will be sent to:
                                <strong>{{
                                  venueEmailData.data[0].RaceReportsEmailDL ||
                                    "-"
                                }}</strong></span
                              >
                            </div>
                            <div
                              class="tooltip-content"
                              *ngIf="button.preview_key"
                            >
                              <i class="fa-regular fa-envelope"></i>
                              <span class="email-text"
                                >Email will be sent to:
                                <strong>{{
                                  venueEmailData.data[0][button.preview_key] ||
                                    "-"
                                }}</strong></span
                              >
                            </div>
                          </div>
                        </button>
                        <ng-container
                          *ngIf="
                            !button.loading &&
                            button.name == 'Get Official Result'
                          "
                        >
                          <button
                            type="button"
                            class="btn btn-dropdown"
                            [ngClass]="{
                              'btn-success': button.completed,
                              'btn-danger': button.error,
                              'btn-secondary':
                                !button.completed && !button.error
                            }"
                          >
                            <i
                              class="fa fa-angle-down color-inherit"
                              aria-hidden="true"
                            ></i>
                            <div class="dropdown-menu">
                              <span
                                class="dropdown-item"
                                href="#"
                                (click)="uploadFile(race, button, 'normal_lip')"
                                >Upload LIF File</span
                              >
                              <div class="dropdown-divider"></div>
                              <span
                                class="dropdown-item"
                                href="#"
                                (click)="generateLIPFile(race, button)"
                                >Generate LIF File</span
                              >
                              <ng-container *ngIf="selected['RaceType'] == 'Harness Racing'">
                                <div class="dropdown-divider"></div>
                                <span
                                  class="dropdown-item"
                                  href="#"
                                  (click)="handleClicks(race, true, button, false, true)"
                                  >Use TSD HR Sectionals</span
                                >
                              </ng-container>
                            </div>
                          </button>
                        </ng-container>
                        <ng-container
                          *ngIf="
                            !button.loading &&
                            button.action == 'generate_pdf' &&
                            selected['RaceType'] != 'Harness Racing'
                          "
                        >
                          <button
                            type="button"
                            class="btn btn-dropdown"
                            [ngClass]="{
                              'btn-success': button.completed,
                              'btn-danger': button.error,
                              'btn-secondary': !button.completed && !button.error
                            }"
                          >
                            <i
                              class="fa fa-angle-down color-inherit"
                              aria-hidden="true"
                            ></i>
                            <div class="dropdown-menu">
                              <span
                                class="dropdown-item"
                                href="#"
                                (click)="handleClicks(race, true, button)"
                              >
                                Generate PDF Using TOL File
                              </span>
                              <span
                                class="dropdown-item"
                                href="#"
                                (click)="handleClicks(race, true, button, false, false, 'database')"
                              >
                                Generate PDF Using Database
                              </span>
                            </div>
                          </button>
                        </ng-container>
                      </div>
                      <div
                        class="btn-group"
                        *ngIf="
                          button.preview &&
                          (button.completed ||
                            checkIfAtleastOnePDFisCreated(button)) &&
                          !button.loading
                        "
                      >
                        <button
                          type="button"
                          class="btn btn-outline-secondary flex-start mt-2 btn-dropdown"
                        >
                          Review / Download
                          <i
                            class="fa fa-angle-down color-inherit"
                            aria-hidden="true"
                          ></i>
                          <div class="dropdown-menu">
                            <span
                              class="dropdown-item"
                              href="#"
                              (click)="reviewPDF(race, button, '.pdf')"
                              >{{
                                button.lastUpdatedStepType
                                  ? button.lastUpdatedStepType + " "
                                  : ""
                              }}PDF File</span
                            >
                            <span
                              class="dropdown-item"
                              href="#"
                              (click)="reviewPDF(race, button, '.csv')"
                              >CSV File</span
                            >
                            <span
                              class="dropdown-item"
                              href="#"
                              *ngIf="selected['type'] == 'LPS'"
                              (click)="reviewPDF(race, button, '.csv', 'tol')"
                              >Processed CSV File</span
                            >
                            <span
                              class="dropdown-item"
                              href="#"
                              (click)="reviewPDF(race, button, '.xml')"
                              >XML File</span
                            >
                          </div>
                        </button>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--<race-reports-pc-selector *ngIf="selectPC" [venue]="selected" (hideModel)="hideSelectPC($event)"></race-reports-pc-selector>-->
<confirm-box
  *ngIf="cooldownAlert"
  (hideModel)="hideCooldownAlert($event)"
></confirm-box>

<eod-confirm-box
  *ngIf="confirmPublishCheck"
  title="Publish Report"
  message="Are you sure you want to publish this report?"
  (hideModel)="this.hideConfirmPublish($event)"
>
  <div
    class="center alert alert-primary p-1"
    role="alert"
    ngProjectAs="main-body"
    *ngIf="venueEmailData && confirmPublish.race['email']"
  >
    Email will be sent to:
    <strong>{{ venueEmailData.data[0].RaceReportsEmailDL || "-" }}</strong>
  </div>
</eod-confirm-box>

<race-reports-notes
  *ngIf="showNotes"
  [meta]="showNotes"
  [title]="showNotes.title"
  (hideModel)="hideNotes($event)"
></race-reports-notes>

<eod-confirm-box
  *ngIf="confirmToPublishOnlyOneStep"
  title="Select Actions to Run"
  [buttons]="['confirm', 'close']"
  (hideModel)="this.closeActionsConfirm($event)"
>
  <ng-container ngProjectAs="main-body">
    Some actions have already been completed. Please select the actions you want
    to run
    <div
      class="alert alert-danger flex-start p-1 mb-0 mt-1"
      role="alert"
      *ngIf="confirmToPublishOnlyOneStep.error"
    >
      <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
      <span>
        <strong>Error: </strong>
        <span> {{ confirmToPublishOnlyOneStep.error }} </span></span
      >
    </div>
    <div class="steps-container">
      <div
        class="step-container"
        *ngFor="
          let step of confirmToPublishOnlyOneStep.params.steps;
          let i = index
        "
      >
        <div class="flex-start">
          <input
            type="checkbox"
            [(ngModel)]="step.selected"
            [id]="step.StepType + '_' + i"
          />
          <label class="flex-start flex-1" [for]="step.StepType + '_' + i">
            <span class="flex-1 fw-bold">
              {{ step.StepType }}
            </span>
            <span
              class="status-text-container alert inline-flex-start"
              [ngClass]="{
                'alert-success':
                  step.StepCompletionStatus?.toUpperCase() == 'COMPLETED',
                'alert-dark':
                  step.StepCompletionStatus?.toUpperCase() == 'PENDING',
                'alert-danger':
                  step.StepCompletionStatus?.toUpperCase() == 'FAILED'
              }"
            >
              <span class="color-inherit">{{
                step.StepCompletionStatus.toUpperCase() || "PENDING"
              }}</span>
            </span>
          </label>
        </div>
      </div>
    </div>
  </ng-container>
</eod-confirm-box>

<race-reports-lps-generate-report
  *ngIf="lpsPopup"
  [config]="config"
  [lps]="lpsPopup"
>
  <button
    class="btn btn-primary-soft"
    [class.btn-success-soft]="
      lpsPopup?.params?.completed && !lpsPopup.params.loading
    "
    ngProjectAs="generatePDF"
    [attr.disabled]="
      lpsPopup?.params?.loading || !lpsPopup.reportType || !lpsPopup.sourceType
        ? true
        : null
    "
    (click)="handleClicks(lpsPopup.race, lpsPopup.event, lpsPopup.params, true)"
  >
    <span class="color-inherit cursor-pointer flex-start">
      <span
        *ngIf="lpsPopup?.params?.loading"
        class="spinner-border spinner-border-sm ml-2"
      ></span>
      Generate PDF
    </span>
  </button>
  <button
    class="btn btn-success-soft"
    ngProjectAs="downloadPDF"
    [attr.disabled]="
      !lpsPopup?.params?.completed &&
      !checkIfAtleastOnePDFisCreated(lpsPopup.params)
        ? true
        : null
    "
    (click)="reviewPDF(lpsPopup.race, lpsPopup.params)"
  >
    Review / Download
  </button>
  <button
    ngProjectAs="closePopup"
    class="btn btn-light"
    [disabled]="
      lpsPopup.uploadStatus && lpsPopup.uploadStatus != 'COMPLETED'
        ? true
        : null
    "
    (click)="lpsPopup = null"
  >
    Close
  </button>
</race-reports-lps-generate-report>
<upload-file-lip *ngIf="uploadFileObj" [config]="config" [meta]="uploadFileObj">
  <button
    ngProjectAs="closePopup"
    class="btn btn-light"
    [disabled]="
      uploadFileObj.uploadStatus && uploadFileObj.uploadStatus != 'COMPLETED'
        ? true
        : null
    "
    (click)="uploadFileObj = null"
  >
    Close
  </button>
</upload-file-lip>
<generate-lip
  *ngIf="generateFileObj"
  [meta]="generateFileObj"
  [config]="config"
  (onClose)="getOfficialReport()"
>
  <button
    ngProjectAs="closePopup"
    class="btn btn-light"
    (click)="getOfficialReport()"
    [disabled]="
      generateFileObj.generateStatus &&
      generateFileObj.generateStatus != 'COMPLETED'
        ? true
        : null
    "
  >
    Close
  </button>
</generate-lip>
