import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'post-race-location-picker',
  templateUrl: './location-picker.component.html',
  styleUrls: ['./location-picker.component.css'],
})
export class LocationPickerComponent {
  @Input() locations: any = [];
  @Input() selected: any;
  @Input() type: string = 'location';
  @Input() multiple: boolean = false;
  @Output('selectedLocation') selectedLocation = new EventEmitter<any>();
  statuses: any = [
    {
      type: 'success',
      value: 'FINISHED',
    },
    {
      type: 'pending',
      value: 'PLANNED',
    },
  ];
}
