import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
  HttpErrorResponse,
  HttpClient,
  HttpHeaders,
} from '@angular/common/http';
@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  constructor(private http: HttpClient) {}
  uploadFile(url: string, image: File, metaData?: any): Observable<any> {
    let headers = new HttpHeaders();
    console.log('Image object:', image);
      // Set Content-Type to the file's type or a default
    headers = headers.set('Content-Type', image.type || 'application/octet-stream');

    // Add metadata headers if provided
    if (metaData) {
      for (const key in metaData) {
        if (metaData.hasOwnProperty(key)) {
          headers = headers.set(`x-amz-meta-${key}`, metaData[key]);  // Reassign headers each time
        }
      }
    }
    return this.http
      .put(url, image, {
        reportProgress: true,
        observe: 'events',
        headers,
      })
      .pipe(catchError(this.errorMgmt));
  }
  
  errorMgmt(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
      return errorMessage;
    });
  }
}
