<div class="card">
  <div class="card-header flex-between">
    <h5>Runner Status - {{ raceResponse["race_name"] }}</h5>
    <div class="race-paginator flex-start" *ngIf="races.length > 0">
      <a
        class="race-navigator flex-start"
        [class.disabled]="raceResponse['race_number'] == 1"
        (click)="prev()"
      >
        <i class="fa fa-angle-left"></i>
        Previous
      </a>
      <a
        class="race-navigator"
        routerLink="/dash/race-runner/{{ venue_id }}/{{ event_id }}/{{race.ExternalRaceId}}"
        [class.active]="race.ExternalRaceId == race_id"
        *ngFor="let race of races"
      >
        <span class="race-text">{{ race.SquentialRaceOrderNumber }}</span>
        <div class="tooltip-container">
          <div class="tooltip-content">
            <div class="flex-start">
              <div class="fw-bold white-space-none">
                #{{ race["SquentialRaceOrderNumber"] }} {{ race.RaceName }} |
                {{ race.RaceTime }}
              </div>
              <span
                class="status-text-container alert inline-flex-start"
                [ngClass]="{
                  'alert-success':
                    race.RaceState == 'FINISHED' ||
                    race.RaceState == 'PUBLISHED',
                  'alert-dark':
                    race.RaceState == 'PLANNED' || race.RaceState == 'OFFICIAL',
                  'alert-danger':
                    race.RaceState == 'INCOMPLETED' ||
                    race.RaceState == 'CANCELLED' ||
                    race.RaceState == 'DIDNOTTRACK' ||
                    race.RaceState == 'ABANDONED'
                }"
              >
                <i class="fa fa-circle color-inherit" aria-hidden="true"></i>
                <span class="color-inherit">{{
                  race["RaceState"] || "-"
                }}</span>
              </span>
            </div>
          </div>
        </div>
      </a>
      <a
        class="race-navigator flex-start"
        [class.disabled]="raceResponse['race_number'] == races.length"
        (click)="next()"
      >
        Next
        <i class="fa fa-angle-right"></i>
      </a>
    </div>
  </div>
  <div class="card-body">
    <div class="runner_status">
      <table class="runners">
        <thead>
          <tr class="race-header">
            <th class="left-corner">AU</th>
            <th colspan="2">{{ raceResponse.venue }}</th>
            <th colspan="3">{{ raceResponse.race_name }}</th>
            <th>{{ raceResponse.event_date }} - {{ formatted_race_time }}</th>
            <th>Race - {{ raceResponse.race_number }}</th>
            <th>{{ firstTimestamp | date : "HH:mm:ss.SSS" }}</th>
            <th>{{ currentTime | date : "HH:mm:ss.SSS" }}</th>
            <th *ngIf="simulationData.length > 0">
              <button (click)="togglePlayPause()">
                {{ isPlaying ? "Pause" : "Play" }}
              </button>
            </th>
            <th *ngIf="simulationData.length > 0" class="right-corner">
              <select (change)="changeSpeed($event)" [disabled]="!isPlaying">
                <option value="1">x1</option>
                <option value="2">x2</option>
                <option value="5">x5</option>
                <option value="10">x10</option>
              </select>
            </th>
            <th *ngIf="simulationData.length === 0">
              {{ current_connection_time | date : "HH:mm:ss.SSS" }}
            </th>
            <th *ngIf="simulationData.length === 0" class="right-corner">
              <button
                [ngClass]="{
                  connected: isConnected,
                  disconnected: !isConnected
                }"
              >
                {{ isConnected ? "Connected" : "Not Connected" }}
              </button>
            </th>
          </tr>
          <tr>
            <th class="name" colspan="3">Name</th>
            <th class="SD col-1">Saddling</th>
            <th class="PR col-1">Parading</th>
            <th class="OT col-1">On Track</th>
            <th class="BB col-1">Behind Barrier</th>
            <th class="LD col-1">Loaded</th>
            <th class="stall col-1">SCN</th>
            <th class="R col-1">Running</th>
            <th class="F col-1">Finished</th>
            <th class="DSQ col-1">Status</th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let entrant of raceResponse.entrants"
            [ngClass]="{ 'dark-grey': runners[entrant.ExternalAnimalID].isDNS }"
          >
            <td class="name" colspan="3">{{ entrant.AnimalName }}</td>
            <td class="status SD">
              <img
                *ngIf="runners[entrant.ExternalAnimalID]?.State === 'Saddling'"
                [src]="getSilkUrl(entrant)"
                onerror="this.onerror=null;this.src='assets/static/NoImageSilk.png'"
              />
            </td>
            <td class="status PR">
              <img
                *ngIf="runners[entrant.ExternalAnimalID]?.State === 'Parading'"
                [src]="getSilkUrl(entrant)"
                onerror="this.onerror=null;this.src='assets/static/NoImageSilk.png'"
              />
            </td>
            <td class="status OT">
              <img
                *ngIf="runners[entrant.ExternalAnimalID]?.State === 'OnTrack'"
                [src]="getSilkUrl(entrant)"
                onerror="this.onerror=null;this.src='assets/static/NoImageSilk.png'"
              />
            </td>
            <td class="BB">
              <div class="image-container">
                <img
                  [src]="
                    runners[entrant.ExternalAnimalID].State === 'BehindBarrier'
                      ? getSilkUrl(entrant)
                      : 'assets/static/blank.png'
                  "
                  class="runner-image"
                  onerror="this.onerror=null;this.src='assets/static/NoImageSilk.png'"
                />
                <input
                  *ngIf="simulationData.length === 0"
                  type="radio"
                  class="radio-right"
                  value="BehindBarrier"
                  [disabled]="
                    !isConnected || runners[entrant.ExternalAnimalID].isDNS
                  "
                  name="LoadedRadio_{{ entrant.ExternalAnimalID }}"
                  [(ngModel)]="runners[entrant.ExternalAnimalID].LoadedRadio"
                  [ngModelOptions]="{ standalone: true }"
                />
              </div>
            </td>
            <td class="LD">
              <div class="image-container">
                <img
                  [src]="
                    runners[entrant.ExternalAnimalID].State === 'Loaded'
                      ? getSilkUrl(entrant)
                      : 'assets/static/blank.png'
                  "
                  class="runner-image"
                  onerror="this.onerror=null;this.src='assets/static/NoImageSilk.png'"
                />
                <input
                  *ngIf="simulationData.length === 0"
                  type="radio"
                  class="radio-right"
                  value="Loaded"
                  [disabled]="
                    !isConnected || runners[entrant.ExternalAnimalID].isDNS
                  "
                  name="LoadedRadio_{{ entrant.ExternalAnimalID }}"
                  [(ngModel)]="runners[entrant.ExternalAnimalID].LoadedRadio"
                  [ngModelOptions]="{ standalone: true }"
                />
              </div>
            </td>
            <td class="stall">{{ entrant.StartNumber }}</td>
            <td class="status R">
              <img
                *ngIf="runners[entrant.ExternalAnimalID]?.State === 'Running'"
                [src]="getSilkUrl(entrant)"
                onerror="this.onerror=null;this.src='assets/static/NoImageSilk.png'"
              />
            </td>
            <td class="status F">
              <img
                *ngIf="runners[entrant.ExternalAnimalID]?.State === 'Finished'"
                [src]="getSilkUrl(entrant)"
                onerror="this.onerror=null;this.src='assets/static/NoImageSilk.png'"
              />
            </td>
            <td class="State">
              {{ runners[entrant.ExternalAnimalID].State }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
