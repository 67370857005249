<div class="card">
  <div class="card-header flex-between">
    <div class="flex-start">
      <h5 class="mb-0">Silk Upload</h5>
    </div>
  </div>
  <div class="card-body">
    <div class="row">
      <!-- Existing Image Preview with Red Background -->
      <div class="col-md-6" *ngIf="existingImage; else noImage">
        <div class="card" style="background-color: lightcoral;">
          <div class="card-header">
            Existing Image Preview
          </div>
          <div class="card-body">
            <img [src]="existingImage" class="img-fluid" alt="Existing image preview" style="max-width: 100%; height: auto; object-fit: cover;">
          </div>
        </div>
      </div>
    
      <!-- Placeholder when no existing image is found -->
      <ng-template #noImage>
        <div class="col-md-6">
          <div class="alert alert-info" role="alert">
            No existing image found. Please upload the new silk image.
          </div>
        </div>
      </ng-template>
    
      <!-- New Image Preview -->
      <div class="col-md-6">
        <div class="card">
          <div class="card-header">
            New Image Preview
          </div>
          <div class="card-body">
            <img *ngIf="imageUrl" [src]="imageUrl" class="img-fluid" alt="New image preview" style="max-width: 100%; height: auto; object-fit: cover;">
          </div>
        </div>
      </div>
    </div>
    
    <!-- File Selection and Upload Row -->
    <div class="input-group mb-3">
      <!-- Dropdown for Race Type -->
      <label for="raceTypeDropdown" class="input-group-text">Race Type</label>
      <select
        id="raceTypeDropdown"
        class="form-select me-3"
        [(ngModel)]="selectedRaceType"
        (change)="onRaceTypeChange()"
        required
      >
        <option *ngFor="let key of racingTypeKeys" [value]="key">
          {{ key }}
        </option>
      </select>
    
      <!-- File Input -->
      <input
        type="file"
        class="form-control"
        id="fileInput"
        aria-label="Upload"
        accept=".png"
        (change)="onFileSelected($event)"
        required
      />
    
      <!-- Upload Button -->
      <button
        type="button"
        class="btn btn-primary"
        (click)="onSubmit()"
        [disabled]="!selectedFile || !selectedRaceType"
      >
        Upload File
      </button>
    </div>
  </div>
</div>
